import React from "react";
import { Form, Field } from "react-final-form";
import createFocusOnErrorDecorator from "final-form-focus";
import { FORM_ERROR } from "final-form";
import * as domain from "common/domain";
import * as FormUtils from "common/FormUtils";
import * as geoJsonUtils from "common/geoJsonUtils";
import * as utils from "common/utils";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Grid, GridCell } from "components/Grid";
import SelectField from "components/FormFields/SelectField";
import TextField from "components/FormFields/TextField";
import CheckboxField from "components/FormFields/CheckboxField";
import ConfirmationField from "components/FormFields/ConfirmationField";
import Alert from "components/Alert";
import { useOnline, useRefData } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";

const focusOnError = createFocusOnErrorDecorator();

export default function ChangeBlockTypeModal({ analysis, block, close }) {
    const online = useOnline();
    const refData = useRefData();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    const drawnArea = utils.round(
        (analysis.features || []).filter((f) => f.properties.blockId === block.id).reduce((sum, feature) => (sum += geoJsonUtils.area(feature)), 0),
        1
    );
    const blockType = utils.getBlockTypeText(refData, block);
    const blockTypeOptions = (refData.blockTypes || [])
        .filter((bt) => bt.value !== "FodderCrop")
        .map((bt) => {
            if (bt.value === block.type) return { ...bt, text: blockType, groupIndex: 0, groupLabel: "Original block type", disabled: true };

            return { ...bt, groupIndex: 1, groupLabel: "New block type" };
        });
    const wetlandConditionOptions = (refData.wetlandConditionType || []).filter((w) => [domain.WetlandCondition.Natural.key, domain.WetlandCondition.Artificialtype1.key, domain.WetlandCondition.Artificialtype2.key, domain.WetlandCondition.Artificialtype3.key].includes(w.value));

    const validate = (formValues) => {
        const validation = {};

        validation.type = FormUtils.validators.required(formValues.type);

        validation.name = FormUtils.validators.required(formValues.name);
        validation.name = validation.name || FormUtils.validators.maxLength(50)(formValues.name);

        validation.areaInHectares = FormUtils.validators.required(formValues.areaInHectares);
        validation.areaInHectares = validation.areaInHectares || FormUtils.validators.range(0.1, 40000)(formValues.areaInHectares);

        if (isPastureBlock(formValues.type)) {
            const { pasture = {} } = formValues;
            validation.pasture = {};
            validation.pasture.topography = FormUtils.validators.required(pasture.topography);
        } else if (isTreeBlock(formValues.type)) {
            validation.bushType = FormUtils.validators.required(formValues.bushType);
        } else if (isHouseBlock(formValues.type)) {
            validation.countOfResidents = FormUtils.validators.required(formValues.countOfResidents);
            validation.countOfResidents = validation.countOfResidents || FormUtils.validators.range(1, 9999)(formValues.countOfResidents);
            validation.sewageDisposalMethod = FormUtils.validators.required(formValues.sewageDisposalMethod);
            validation.cultivatedAreaPercent = FormUtils.validators.range(0, 80)(formValues.cultivatedAreaPercent);
        } else if (isWetlandBlock(formValues.type)) {
            const { wetland = {} } = formValues;
            validation.wetland = {};
            validation.wetland.effectiveArea = FormUtils.validators.required(wetland.effectiveArea);
            validation.wetland.effectiveArea = validation.wetland.effectiveArea || FormUtils.validators.range(0.1, 40000)(wetland.effectiveArea);
            validation.wetland.condition = FormUtils.validators.required(wetland.condition);
            validation.wetland.type = FormUtils.validators.required(wetland.type);
        }

        // if (false && viewModel.overrideClimateLocation) {
        //     validation.latitude = FormUtils.validators.required(viewModel.latitude);
        //     validation.latitude = validation.latitude || FormUtils.validators.range(-48, -34)(viewModel.latitude);
        //     validation.longitude = FormUtils.validators.required(viewModel.longitude);
        //     validation.longitude = validation.longitude || FormUtils.validators.range(165, 179)(viewModel.longitude);
        // }

        return validation;
    };

    const submitAsync = async (formValues) => {
        const updatedAnalysis = { ...analysis };
        const updatedBlock = { ...formValues };

        const blockTypeChanged = block.type !== formValues.type;
        if (blockTypeChanged) {
            updatedBlock.isProductive = updatedBlock.type.startsWith("Productive") || updatedBlock.type === "FodderCrop";
            updatedBlock.requiresSoil = updatedBlock.type.startsWith("Productive") && updatedBlock.type !== "FodderCrop";

            if (!isDistanceFromCoastRequired(formValues.type)) {
                delete updatedBlock.distanceFromCoastInKilometres;
            }

            if (!isPastureBlock(updatedBlock.type)) {
                delete updatedBlock.pasture;
            }

            if (!isTreeBlock(updatedBlock.type)) {
                delete updatedBlock.bushType;
                delete updatedBlock.forests;
            }

            if (!isHouseBlock(updatedBlock.type)) {
                delete updatedBlock.countOfResidents;
                delete updatedBlock.sewageDisposalMethod;
                delete updatedBlock.cultivatedAreaPercent;
            }

            if (!isWetlandBlock(updatedBlock.type)) {
                delete updatedBlock.wetland;
            }

            if (!isRiparianBlock(updatedBlock.type)) {
                delete updatedBlock.riparianStrip;
            }

            // Clear management practices from the block.
            delete updatedBlock.cropBlock;
            delete updatedBlock.cropGrowthCurves;
            delete updatedBlock.croppingEvents;
            delete updatedBlock.crops;
            delete updatedBlock.fruit;
            delete updatedBlock.animals;
            delete updatedBlock.relativeProductivity;
            delete updatedBlock.runoffCharacteristics;
            delete updatedBlock.bandedNfert;
            delete updatedBlock.monthResown;
            delete updatedBlock.currentResults;
            delete updatedBlock.confirmationText;
            delete updatedBlock.soilTestId;

            const options = {
                keepDrainage: updatedBlock.isProductive,
                keepSoils: updatedBlock.requiresSoil,
            };
            utils.deleteOrphanBlockRef(updatedAnalysis, updatedBlock.id, options);
        }

        updatedAnalysis.blocks = updatedAnalysis.blocks.map((block) => {
            if (block.id === updatedBlock.id) return updatedBlock;
            return block;
        });

        try {
            await updateAnalysisAsync(updatedAnalysis);
            close();
        } catch (ex) {
            return { [FORM_ERROR]: ex.message };
        }
    };

    return (
        <Form initialValues={block} decorators={[focusOnError]} validate={validate} onSubmit={submitAsync}>
            {({ form, values, handleSubmit, submitting, submitError, dirtySinceLastSubmit }) => {
                const error = (values && values.error) || (!dirtySinceLastSubmit && submitError);

                const blockTypeHelp = getBlockTypeHelp(values.type);
                const topographyInfo = getTopographyInfo(values.pasture && values.pasture.topography);
                const wetlandConditionInfo = getWetlandConditionInfo(values.wetland);
                const wetlandTypeInfo = getWetlandTypeInfo(values.wetland);

                const showPastureBlockFields = isPastureBlock(values.type);
                const showTreeBlockFields = isTreeBlock(values.type);
                const showHouseBlockFields = isHouseBlock(values.type);
                const showWetlandBlockFields = isWetlandBlock(values.type);

                const showClimateOverride = online && ["ProductivePasture", "ProductiveCrop", "ProductiveFruit", "ProductiveOutdoorPigs", "NonProductiveHouse", "NonProductiveTreesAndScrub"].includes(values.type);

                const blockTypeChanged = block.type && block.type !== values.type;

                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Change block type" close={close} submitting={submitting} allowOffline>
                            <ModalBody error={error}>
                                <Field name="id" type="hidden" component="input" />
                                <div className="Alert--warning u-textCenter u-p-20 u-pt-sm" style={{ margin: "0px -20px" }}>
                                    <i className="icon icon-alert icon--lg icon--confirm" />
                                    <h2>
                                        Changing the block type of <b>{block.name}</b> will remove all existing farm management practices from this block
                                    </h2>
                                    <p>The area, climate, soils, and any artificial drainage systems associated with this block will not be changed</p>
                                </div>
                                <Grid>
                                    <GridCell className="u-lg-width1of2a">
                                        <Field name="type" label="Block type" placeholder="Select a new block type" options={blockTypeOptions} tip={blockTypeHelp} required component={SelectField} />
                                    </GridCell>
                                    {blockTypeChanged && (
                                        <>
                                            <GridCell className="u-lg-width1of2a">
                                                <Field name="name" label="Block name" required component={TextField} />
                                            </GridCell>
                                            {false && (
                                                <GridCell className="u-lg-width1of2a">
                                                    <Field name="areaInHectares" label="Effective area" placeholder="Enter the area of the block in hectares" uom="ha" tip="The effective area excludes raceways and fenced-off wetlands or non-productive areas. When entering for non-productive blocks multiple block outlines can be drawn. The effective area should be the sum of all outlines drawn." format={FormUtils.formatters.formatDecimal(1)} formatOnBlur required component={TextField} />
                                                    {drawnArea > 0 && drawnArea !== values.areaInHectares && (
                                                        <Alert
                                                            type="warning"
                                                            text={
                                                                <span>
                                                                    <b>Drawn area:</b> {drawnArea + "ha"}
                                                                </span>
                                                            }
                                                            actionMessage="Use drawn area as effective area"
                                                            onAction={() => form.change("areaInHectares", drawnArea)}
                                                            actionIcon="IconLink--arrow-plus"
                                                            actionAlignRight
                                                            className="u-mb-0"
                                                        />
                                                    )}
                                                </GridCell>
                                            )}

                                            {showPastureBlockFields && (
                                                <GridCell className="u-lg-width1of2a">
                                                    <Field name="pasture.topography" label="Topography" placeholder="Select a topography that best represents this block" options={refData.slopes} info={topographyInfo} required component={SelectField} />
                                                </GridCell>
                                            )}
                                            {showTreeBlockFields && (
                                                <GridCell className="u-lg-width1of2a">
                                                    <Field name="bushType" label="Bush type" placeholder="Select a bush type" options={refData.bushTypes} required component={SelectField} />
                                                </GridCell>
                                            )}
                                            {showHouseBlockFields && (
                                                <>
                                                    <GridCell className="u-lg-width1of2a">
                                                        <Field name="countOfResidents" label="Number of people living on the property" placeholder="Enter the number of people living on the property" format={FormUtils.formatters.formatInt} formatOnBlur required component={TextField} />
                                                    </GridCell>
                                                    <GridCell className="u-lg-width1of2a">
                                                        <Field name="sewageDisposalMethod" label="Sewage management method" placeholder="Select a sewage management method" options={refData.sewageDisposalMethods} required component={SelectField} />
                                                    </GridCell>
                                                    <GridCell className="u-lg-width1of2a">
                                                        <Field name="cultivatedAreaPercent" label="Cultivated area" placeholder="Enter % of the block that is under cultivation" uom="% of block area" format={FormUtils.formatters.formatDecimal(1)} formatOnBlur component={TextField} />
                                                    </GridCell>
                                                </>
                                            )}
                                            {showWetlandBlockFields && (
                                                <>
                                                    <GridCell className="u-lg-width1of2a">
                                                        <Field name="wetland.effectiveArea" label="Wetland area" placeholder="Enter the area of the fenced off wetland" uom="ha" format={FormUtils.formatters.formatDecimal(1)} formatOnBlur required component={TextField} />
                                                    </GridCell>
                                                    <GridCell className="u-lg-width1of2a">
                                                        <Field name="wetland.condition" label="Wetland condition" placeholder="Select a wetland condition" options={wetlandConditionOptions} info={wetlandConditionInfo} required component={SelectField} />
                                                    </GridCell>
                                                    <GridCell className="u-lg-width1of2a">
                                                        <Field name="wetland.type" label="Wetland type" placeholder="Select a wetland type" options={refData.wetlandType} info={wetlandTypeInfo} required component={SelectField} />
                                                    </GridCell>
                                                </>
                                            )}
                                            {false && showClimateOverride && (
                                                <>
                                                    <GridCell>
                                                        <Field name="overrideClimateLocation" label={block.climateLatitude ? `Override climate location` : `Enter climate location (load S-Map data for default location)`} component={CheckboxField} />
                                                    </GridCell>
                                                    {values.overrideClimateLocation && (
                                                        <>
                                                            <GridCell className="u-lg-width1of2">
                                                                <Field name="latitude" label="Latitude" placeholder="Climate location latitude" tip="Only change the block coordinates if you have a specific location for the block climate data" format={FormUtils.formatters.formatDecimal(14)} formatOnBlur required component={TextField} />
                                                            </GridCell>
                                                            <GridCell className="u-lg-width1of2">
                                                                <Field name="longitude" label="Longitude" placeholder="Climate location longitude" tip="Only change the block coordinates if you have a specific location for the block climate data" format={FormUtils.formatters.formatDecimal(14)} formatOnBlur required component={TextField} />
                                                            </GridCell>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    {blockTypeChanged && <Field name="confirmed" confirmationPhrase="CHANGE" component={ConfirmationField} />}
                                    <Button id="submit" submit primary disabled={!values.confirmed || submitting}>
                                        Save
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
}

const isDistanceFromCoastRequired = (type) => !["NonProductiveWetland", "NonProductiveRiparian"].includes(type);

const isPastureBlock = (type) => type === "ProductivePasture";

const isTreeBlock = (type) => type === "NonProductiveTreesAndScrub";

const isHouseBlock = (type) => type === "NonProductiveHouse";

const isWetlandBlock = (type) => type === "NonProductiveWetland";

const isRiparianBlock = (type) => type === "NonProductiveRiparian";

const getBlockTypeHelp = (type) => {
    const blockTypeTips = domain.BlockTypeDescription[type];
    const blockTypeHelp = blockTypeTips && (
        <p className="u-mt-0">
            <b>{blockTypeTips.bold}:</b> <span>{blockTypeTips.text}</span>
        </p>
    );
    return blockTypeHelp;
};

const getTopographyInfo = (topography) => {
    const topographyTips = domain.TopographyTips[topography];
    const topographyInfo = topographyTips && (
        <>
            <p className="u-mt-0">
                <b>Description:</b> <span>{topographyTips.text}</span>
            </p>
            <p className="u-mt-0">
                <b>Slope:</b> <span>{topographyTips.slope}</span>
            </p>
            <p className="u-mt-0">
                <b>LRI1 class:</b> <span>{topographyTips.lriClass}</span>
            </p>
        </>
    );
    return topographyInfo;
};

const getWetlandConditionInfo = (wetland) => {
    if (!wetland || !wetland.condition) return null;
    const wetlandConditionTips = domain.WetlandCondition[wetland.condition];
    const wetlandConditionInfo = wetlandConditionTips && (
        <p className="u-mt-0">
            <b>{wetlandConditionTips.bold}:</b> <span>{wetlandConditionTips.text}</span>
        </p>
    );
    return wetlandConditionInfo;
};

const getWetlandTypeInfo = (wetland) => {
    if (!wetland || !wetland.type) return null;
    const wetlandTypeTips = domain.WetlandType[wetland.type];
    const wetlandTypeInfo = wetlandTypeTips && (
        <>
            <p className="u-mt-0">
                <b>Wetness:</b> <span>{wetlandTypeTips.wetness}</span>
            </p>
            <p className="u-mt-0">
                <b>Vegetation:</b> <span>{wetlandTypeTips.vegetation}</span>
            </p>
            <p className="u-mt-0">
                <b>Stock:</b> <span>{wetlandTypeTips.stock}</span>
            </p>
        </>
    );
    return wetlandTypeInfo;
};
