import * as utils from "common/utils";
import * as domain from "common/domain";
import { Panel, PanelBody } from "components/Panel";
import Watermark from "components/Watermark";
import { useAnalysisResults } from "containers/BudgetHome";
import { HelpTooltip, ForMoreInfoZendeskLink } from "components/Help";

export default function DairyGrassFedStandardReport({ analysis }) {
    const { data: analysisResults } = useAnalysisResults(analysis);
    if (!analysisResults.animals) {
        return null;
    }

    const dairyGrassFedStandardReport = analysisResults?.animals?.dairyGrassFedStandard;
    if (!dairyGrassFedStandardReport) {
        return null;
    }

    const diet = dairyGrassFedStandardReport.diet;
    const location = dairyGrassFedStandardReport.location;

    return (
        <Panel title="Dairy grass-fed percentage" info={PAGE.INFO} skyBlue>
            <PanelBody>
                <GrassFedResults diet={diet} location={location} />
                <GrassFedDietBySource diet={diet} testMode={false} />
                <GrassFedTimeOnNonMilkingStructures location={location} />
            </PanelBody>
        </Panel>
    )
}

function GrassFedResults({ diet, location }) {
    return (
        <div className="HeroPanel u-mt-md">
            <div className="HeroValue">
                <div className="HeroValue-item">
                    <h5>
                        Wet matter from grass-fed feed
                        <HelpTooltip tip={diet.percentageOfDietFromGrassFedFeed?.description} />
                    </h5>
                    <div className="u-flex u-flexColumn">
                        <span id="methane-emissions" className="h1">
                            {utils.numeric.format(diet.percentageOfDietFromGrassFedFeed?.value, 1)} <small>{diet.percentageOfDietFromGrassFedFeed?.unit?.notation}</small>
                        </span>
                    </div>
                </div>
                <div className="HeroValue-item">
                    <h5>
                        Time with access to pasture
                        <HelpTooltip tip={location.percentageOfTimeWithAccessToPasture?.description} />
                    </h5>
                    <div className="u-flex u-flexColumn">
                        <span id="n2O-emissions" className="h1">
                            {utils.numeric.format(location.percentageOfTimeWithAccessToPasture?.value, 1)} <small>{location.percentageOfTimeWithAccessToPasture?.unit?.notation}</small>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

function GrassFedDietBySource({ diet, testMode }) {
    if (!diet) {
        return null;
    }

    const dietKey = `dairy_grass_fed_diet`;

    const showTotalWetMatter = testMode;
    const showWetMatterFromGrassFedFeed = testMode;
    const showFeedWithNoWetMatterFromGrassFedFeed = diet.bySource.some(feedSource => feedSource.byMonth.some(m => m.totalWetMatter?.value > 0 && m.wetMatterFromGrassFedFeed?.value === 0));

    return (
        <>
            <h3 className="u-mt-lg">Sources of grass-fed feed</h3>
            <p>
                The following table shows the monthly breakdown of the wet matter fed to the dairy enterprise from grass-fed feed as a percentage of the total wet matter they were fed in each month.
            </p>
            <div className="Table u-mt-md">
                <Watermark />
                <table>
                    <thead>
                        <tr>
                            <th data-width="20">
                                Wet matter from grass-fed feed (%)
                            </th>
                            {domain.farmYear.map((month) => (
                                <th key={month} data-width="5" className="numeric">
                                    {utils.month.format(month)}
                                </th>
                            ))}
                            <th data-width="5" className="numeric">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {diet?.bySource?.map(feedSource => {
                            const feedSourceKey = `${dietKey}_${feedSource.name}`;
                            return (
                                <tr key={feedSourceKey}>
                                    <td>{feedSource.name}</td>
                                    {feedSource.byMonth.map((m) => {
                                        const monthKey = `${feedSourceKey}_${m.month}`;
                                        const monthHasFeedButNoneOfItIsGrassFed = m.totalWetMatter?.value > 0 && m.wetMatterFromGrassFedFeed?.value === 0;
                                        return (
                                            <td key={monthKey} className="numeric">
                                                <div id={monthKey}>
                                                    {m.totalWetMatter?.value > 0
                                                        ? <>{utils.numeric.formatAllowZeroWithUnit(m.percentageOfDietFromGrassFedFeed, 1)}</>
                                                        : utils.numeric.formatWithUnit(m.percentageOfDietFromGrassFedFeed, 1)
                                                    }
                                                    {monthHasFeedButNoneOfItIsGrassFed && <sup>^</sup>}
                                                </div>
                                                {showWetMatterFromGrassFedFeed && <>
                                                    <div className="u-text-sm">
                                                        {m.totalWetMatter?.value > 0
                                                            ? utils.numeric.formatAllowZero(m.wetMatterFromGrassFedFeed?.value)
                                                            : utils.numeric.format(m.wetMatterFromGrassFedFeed?.value)
                                                        }
                                                    </div>
                                                </>}
                                                {showTotalWetMatter && <>
                                                    <div className="u-text-sm u-textGrey">
                                                        {utils.numeric.format(m.totalWetMatter?.value)}
                                                    </div>
                                                </>}
                                            </td>
                                        )
                                    })}
                                    <td id={`${feedSourceKey}_total`} className="numeric total">
                                        <div>{utils.numeric.formatWithUnit(feedSource.percentageOfDietFromGrassFedFeed, 1)}</div>
                                        {showWetMatterFromGrassFedFeed && <>
                                            <div className="u-text-sm">
                                                {feedSource.totalWetMatter?.value > 0
                                                    ? utils.numeric.formatAllowZero(feedSource.wetMatterFromGrassFedFeed?.value)
                                                    : utils.numeric.format(feedSource.wetMatterFromGrassFedFeed?.value)
                                                }
                                            </div>
                                        </>}
                                        {showTotalWetMatter && <>
                                            <div className="u-text-sm u-textGrey">
                                                {utils.numeric.format(feedSource.totalWetMatter?.value)}
                                            </div>
                                        </>}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                    <tfoot>
                        <tr className="totals">
                            <th>Totals</th>
                            {diet.byMonth.map((m) => {
                                const monthKey = `${dietKey}_${m.month}`;
                                return (
                                    <th key={monthKey} className="numeric total">
                                        <div id={monthKey}>
                                            {m.totalWetMatter?.value > 0
                                                ? utils.numeric.formatAllowZeroWithUnit(m.percentageOfDietFromGrassFedFeed, 1)
                                                : utils.numeric.formatWithUnit(m.percentageOfDietFromGrassFedFeed, 1)
                                            }
                                        </div>
                                        {showWetMatterFromGrassFedFeed && <>
                                            <div className="u-text-sm">
                                                {m.totalWetMatter?.value > 0
                                                    ? utils.numeric.formatAllowZero(m.wetMatterFromGrassFedFeed?.value)
                                                    : utils.numeric.format(m.wetMatterFromGrassFedFeed?.value)
                                                }
                                            </div>
                                        </>}
                                        {showTotalWetMatter && <>
                                            <div className="u-text-sm u-textGrey">
                                                {utils.numeric.format(m.totalWetMatter?.value)}
                                            </div>
                                        </>}
                                    </th>
                                )
                            })}
                            <th id={`${dietKey}_total`} className="numeric total">
                                <div>{utils.numeric.formatWithUnit(diet.percentageOfDietFromGrassFedFeed, 1)}</div>
                                {showWetMatterFromGrassFedFeed && <>
                                    <div className="u-text-sm">
                                        {diet.totalWetMatter?.value > 0
                                            ? utils.numeric.formatAllowZero(diet.wetMatterFromGrassFedFeed?.value)
                                            : utils.numeric.format(diet.wetMatterFromGrassFedFeed?.value)
                                        }
                                    </div>
                                </>}
                                {showTotalWetMatter && <>
                                    <div className="u-text-sm u-textGrey">
                                        {utils.numeric.format(diet.totalWetMatter?.value)}
                                    </div>
                                </>}
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
            {showFeedWithNoWetMatterFromGrassFedFeed &&
                <sub>^ The dairy enterprise is fed during this month but none of the wet matter is from grass-fed feed</sub>
            }
        </>
    )
}

function GrassFedTimeOnNonMilkingStructures({ location }) {
    if (!location) {
        return null;
    }

    const locationKey = `dairy_grass_fed_location`;

    return (
        <>
            <h3 className="u-mt-lg">Understanding time with access to pasture</h3>
            <p>
                To understand the amount of time dairy enterprise animals have access to pasture, it is helpful to consider where they are when not milking and not on structures.
                The following table shows a monthly breakdown of the estimated time the dairy enterprise spent on non-milking structures as a percentage of the total hours in each month.
            </p>
            <div className="Table u-mt-md">
                <Watermark />
                <table>
                    <thead>
                        <tr>
                            <th data-width="20">
                                Time on non-milking structures (%)
                            </th>
                            {domain.farmYear.map((month) => (
                                <th key={month} data-width="5" className="numeric">
                                    {utils.month.format(month)}
                                </th>
                            ))}
                            <th data-width="5" className="numeric">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {location?.byStructure?.map(structure => {
                            const structureKey = `${locationKey}_${structure.name}`;
                            return (
                                <tr key={structureKey}>
                                    <td>{structure.name}</td>
                                    {structure.byMonth.map((m) => {
                                        const monthKey = `${structureKey}_${m.month}`;
                                        return (
                                            <td key={monthKey} className="numeric">
                                                <div id={monthKey}>
                                                    {utils.numeric.formatWithUnit(m.percentageOfTimeOnStructure, 1)}
                                                </div>
                                            </td>
                                        )
                                    })}
                                    <td id={`${structureKey}_total`} className="numeric total">
                                        {utils.numeric.formatWithUnit(structure.percentageOfTimeOnStructure, 1)}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                    <tfoot>
                        <tr className="totals">
                            <th>Totals</th>
                            {location.byMonth.map((m) => {
                                const monthKey = `${locationKey}_${m.month}`;
                                return (
                                    <th key={monthKey} className="numeric total">
                                        <div id={monthKey}>
                                            <div id={monthKey}>
                                                {utils.numeric.formatWithUnit(m.percentageOfTimeOnStructure, 1)}
                                            </div>
                                        </div>
                                    </th>
                                )
                            })}
                            <th id={`${locationKey}_total`} className="numeric total">
                                {utils.numeric.formatWithUnit(location.percentageOfTimeOnStructures, 1)}
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </>
    )
}

const PAGE = {
    INFO: <>
        The draft <strong>Grass-fed Standard by AsureQuality</strong> allows a farm, or group of farms, to be certified as grass-fed where bovine milk producing animals have been fed a <strong>defined percentage of grass-fed feed</strong> and spent a <strong>defined percentage of their non-milking time with access to pasture</strong>.
        <ForMoreInfoZendeskLink message={<>For more information about the draft Grass-fed Standard by AsureQuality, including <strong>which Overseer crops and supplements meet the definition of grass-fed feed</strong>,</>} url="https://support.overseer.org.nz/hc/en-us/articles/43392437466777-The-New-Zealand-Grass-Fed-Standard-By-AsureQuality" />
    </>
}
