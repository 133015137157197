import React, { useEffect } from "react";
import { matchPath, withRouter } from "react-router-dom";
import ActionLink from "components/ActionLink";
import { compose, withAppContext, withState, withActions } from "containers/HOC";
import { setInvalidVersionCount } from "containers/App/_actions";

const VersionUpdator = ({ location = {}, online, invalidVersionCount = 0, setInvalidVersionCount, version }) => {
    const { pathname = {} } = location;

    const reloadRoutes = ["/", "/app/userManagement", "/app/farm/:farmId", "/app/farm/:farmId/analysis/:budgetId", "/app/farm/:farmId/analysis/:budgetId/blocks", "/app/farm/:farmId/analysis/:budgetId/climate", "/app/farm/:farmId/analysis/:budgetId/soil", "/app/farm/:farmId/analysis/:budgetId/drainage", "/app/farm/:farmId/analysis/:budgetId/crops", "/app/farm/:farmId/analysis/:budgetId/animals", "/app/farm/:farmId/analysis/:budgetId/supplements", "/app/farm/:farmId/analysis/:budgetId/fertiliser", "/app/farm/:farmId/analysis/:budgetId/irrigation", "/app/farm/:farmId/analysis/:budgetId/structures", "/app/farm/:farmId/analysis/:budgetId/overview", "/app/farm/:farmId/analysis/:budgetId/overview/farmDetails", "/app/farm/:farmId/analysis/:budgetId/overview/compareBlocks", "/app/farm/:farmId/analysis/:budgetId/overview/blockDetails", "/app/farm/:farmId/analysis/:budgetId/overview/animalReports", "/app/farm/:farmId/analysis/:budgetId/overview/ghg"];
    const matches = reloadRoutes.map((r) =>
        matchPath(pathname, {
            path: r,
            exact: true,
            strict: false,
        })
    );

    const requiresRefresh = online && version !== process.env.REACT_APP_VERSION.split("+")[0] && process.env.REACT_APP_DISABLE_REFRESH !== "true" && invalidVersionCount > 20 && matches.some((m) => m);

    const doRefresh = (evt = {}) => {
        const { target = {} } = evt;
        if (target.innerText !== "RELOAD NOW") return;
        target.innerText = "Reloading...";
    };

    useEffect(() => {
        if (!requiresRefresh) return;
        console.log("doRefresh");
        setInvalidVersionCount(0);
        window.location.reload();
    }, [requiresRefresh, setInvalidVersionCount, version]);

    const refreshMessage =
        online && version !== process.env.REACT_APP_VERSION.split("+")[0] && process.env.REACT_APP_DISABLE_REFRESH !== "true" ? (
            <span>
                {" "}
                | New Version Available: {version} |{" "}
                <ActionLink style={{ cursor: "pointer" }} onClick={(evt) => doRefresh(evt)}>
                    RELOAD NOW
                </ActionLink>
            </span>
        ) : null;

    return (
        <span>
            Overseer &copy; 2025 | <span id="ui-version">v{process.env.REACT_APP_VERSION.split("+")[0]}</span> {refreshMessage}
        </span>
    );
};

export default compose(
    withRouter,
    withAppContext,
    withState((state) => {
        return {
            invalidVersionCount: state.app.invalidVersionCount,
        };
    }),
    withActions({ setInvalidVersionCount })
)(VersionUpdator);
