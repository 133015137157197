import * as utils from "common/utils";
import Alert from "components/Alert";
import { HelpTooltip } from "components/Help";

export default function DairyGrassFedStandard({ aggregatedData }) {
    if (!aggregatedData?.milkPool?.totalMilkSolidsInTonnes) return null;

    return (
        <div className="HeroPanel u-mt-md u-page-break u-print-border">
            <div className="HeroPanel-title">Milk pool</div>
            <Alert type="info" text={
                <>
                    <p>Shows aggregated metrics for the <b>milk pool</b>, as defined by your chosen report filters. Where applicable, these metrics are calculated using a weighted average based on milk solids production. This means that farms contributing more milk solids have a greater influence on the final percentage. By weighting values this way, the results more accurately reflect the characteristics of the milk pool as a whole, rather than treating all farms equally regardless of their production volume.</p>
                    <p>To see which farms in the milk pool meet your desired <b>wet matter from grass-fed feed</b> and <b>time with access to pasture</b> thresholds, use the <b>Benchmarking by farms</b> view with appropriate <b>Dairy grass-fed feed</b> and <b>Dairy time with access to pasture</b> filters.</p>
                </>
            } />
            <div className="HeroValue">
                <div className="HeroValue-item">
                    <h5>
                        Total milk solids
                        <HelpTooltip tip="The total tonnes of milk solids produced from the milk pool" />
                    </h5>
                    <div className="u-flex u-flexColumn">
                        <span id="dairy-grass-fed-milkSolids" className="h1">
                            {utils.numeric.format(aggregatedData.milkPool.totalMilkSolidsInTonnes)} <small>t MS</small>
                        </span>
                    </div>
                </div>
                <div className="HeroValue-item">
                    <h5>
                        Wet matter from grass-fed feed, weighted by milk solids
                        <HelpTooltip tip="The mean percentage of wet matter fed to the milk pool from feed that meets the definition of grass-fed as defined by the draft Grass-fed Standard by AsureQuality, weighted by milk solids" />
                    </h5>
                    <div className="u-flex u-flexColumn">
                        <span id="dairy-grass-fed-averagePercentageOfDietFromGrassFedFeed" className="h1">
                            {utils.numeric.format(aggregatedData.milkPool.averagePercentageOfDietFromGrassFedFeed, 1)} <small>%</small>
                        </span>
                    </div>
                </div>
                <div className="HeroValue-item">
                    <h5>
                        Time with access to pasture, weighted by milk solids
                        <HelpTooltip tip="The mean percentage of non-milking time the milk pool spent with access to pasture, weighted by milk solids" />
                    </h5>
                    <div className="u-flex u-flexColumn">
                        <span id="dairy-grass-fed-averagePercentageOfTimeWithAccessToPasture" className="h1">
                            {utils.numeric.format(aggregatedData.milkPool.averagePercentageOfTimeWithAccessToPasture, 1)} <small>%</small>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
