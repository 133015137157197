import { Fragment, useState } from "react";
import { useRefData } from "common/hooks";
import * as utils from "common/utils";
import { Grid, GridCell } from "components/Grid";
import { Doughnut } from "react-chartjs-2";
import ProgressBar from "components/ProgressBar";
import NLossByBlockTypesChart from "./NLossByBlockTypesChart";
import PLossByBlockTypesChart from "./PLossByBlockTypesChart";
import Alert from "components/Alert";
import ActionLink from "components/ActionLink";
import NAppliedByBlockTypesChart from "./NAppliedByBlockTypesChart";
import PAppliedByBlockTypesChart from "./PAppliedByBlockTypesChart";
import KAppliedByBlockTypesChart from "./KAppliedByBlockTypesChart";
import SAppliedByBlockTypesChart from "./SAppliedByBlockTypesChart";
import { BlockType } from "common/domain";

export default function BlockTypes({ aggregatedData }) {
    const refData = useRefData();
    const [accordionState, setAccordionState] = useState({});

    if (!aggregatedData || !aggregatedData.results || aggregatedData.totalArea === 0) return null;

    const clonedData = utils.clone(aggregatedData);
    const { blocks = [] } = clonedData;
    const OTHER_BLOCK_TYPES = [];
    const PASTURE_BLOCK_TYPE = "ProductivePasture";
    const totalBlockArea = blocks.reduce((total, block) => (total += block.totalArea), 0);
    const nonBlockedArea = utils.round(aggregatedData.totalArea - totalBlockArea, 0);
    const nonBlockedColour = utils.GetBlockColour("NonBlock");

    const specificBlockTypes = blocks.filter((block) => !OTHER_BLOCK_TYPES.includes(block.type)).map((b) => ({ ...b, ...{ name: utils.valueToText(refData.blockTypes, b.type) } }));
    const productiveBlocksTypes = specificBlockTypes.filter((b) => [BlockType.FodderCrop, BlockType.ProductiveCrop, BlockType.ProductiveFruit, BlockType.ProductiveOutdoorPigs, BlockType.ProductivePasture].includes(b.type));

    const combineBlocks = (blockSet, name) =>
        blockSet.reduce((combined, block) => {
            if (!combined.totalArea) {
                combined = block;
                combined.name = name;
            } else {
                combined.totalArea += block.totalArea;
                combined.farmCount += block.farmCount;
                combined.blockCount += block.blockCount;
            }
            return combined;
        }, {});

    const otherBlockTypes = blocks.filter((block) => OTHER_BLOCK_TYPES.includes(block.type));
    const otherBlocks = combineBlocks(otherBlockTypes, "Other blocks");
    const cutAndCarry = specificBlockTypes.find((b) => b.type === PASTURE_BLOCK_TYPE && b.cutAndCarry);
    if (cutAndCarry) {
        cutAndCarry.name = "Cut and carry";
    }

    const blockTypes = otherBlocks.name ? [...specificBlockTypes, ...[otherBlocks]] : specificBlockTypes;
    const colours = blockTypes.map((b, i) => utils.GetBlockColour(b.type, b.cutAndCarry));
    const nonBlocked = utils.round((nonBlockedArea / aggregatedData.totalArea) * 100, 0);

    const specificSortOrder = ["Pasture", "Cut and carry", "Crop"];
    blockTypes.sort((a, b) => {
        const indexA = specificSortOrder.indexOf(a.name);
        const indexB = specificSortOrder.indexOf(b.name);

        if (indexA > -1 && indexB > -1) {
            return indexA - indexB; // sort by specific order
        } else if (indexA > -1) {
            return -1; // a is higher in the specific order
        } else if (indexB > -1) {
            return 1; // b is higher in the specific order
        } else {
            return a.name.localeCompare(b.name); // sort the rest alphabetically by name
        }
    });

    productiveBlocksTypes.sort((a, b) => {
        const indexA = specificSortOrder.indexOf(a.name);
        const indexB = specificSortOrder.indexOf(b.name);

        if (indexA > -1 && indexB > -1) {
            return indexA - indexB; // sort by specific order
        } else if (indexA > -1) {
            return -1; // a is higher in the specific order
        } else if (indexB > -1) {
            return 1; // b is higher in the specific order
        } else {
            return a.name.localeCompare(b.name); // sort the rest alphabetically by name
        }
    });

    const data = {
        labels: blockTypes.map((b) => b.name),
        datasets: [
            {
                data: blockTypes.map((b) => utils.round(b.totalArea, 0)),
                backgroundColor: colours,
                hoverBackgroundColor: colours,
            },
        ],
    };

    data.labels.push("Unblocked area");
    data.datasets[0].data.push(utils.round(nonBlockedArea, 0));
    data.datasets[0].backgroundColor.push(nonBlockedColour);
    data.datasets[0].hoverBackgroundColor.push(nonBlockedColour);

    const options = {
        maintainAspectRatio: false,
        responsive: false,
        plugins: {
            legend: {
                display: true,
            },
        },
    };

    const onAccordionClick = (blockType) => {
        setAccordionState((prevState) => {
            return {
                ...prevState,
                [blockType]: !prevState[blockType],
            };
        });
    };

    return (
        <div className="HeroPanel u-mt-md u-page-break u-print-border">
            <div className="HeroPanel-title">Block types</div>
            <Grid>
                <GridCell className="u-width1of1">
                    <Alert type="info" html="Shows the total number of blocks by type. The farm column shows how many farms have that block type. Unblocked area for each farm is the difference between total farm area and total of the blocks. Crops are only included for crops sown in the reporting year." />
                </GridCell>
                <GridCell className="u-xxl-width1of2 u-xl-width1of1">
                    <div className="Table u-mt-md">
                        <table>
                            <thead>
                                <tr>
                                    <th>Block type</th>
                                    <th className="u-textRight">Farms</th>
                                    <th className="u-textRight">Blocks</th>
                                    <th className="u-textRight">Area (ha)</th>
                                    <th className="u-textCenter">Percentage of area</th>
                                </tr>
                            </thead>
                            <tbody>
                                {blockTypes.map((blockType, i) => {
                                    const progress = utils.round((blockType.totalArea / aggregatedData.totalArea) * 100, 1);
                                    const lineClass = blockType.name === "Cut and carry" ? "Table-tr--CutAndCarry" : "Table-tr--" + blockType.type;
                                    const { farmCount = 0, blockCount = 0, totalArea = 0 } = blockType;

                                    const cropTypes = blockType.crops && blockType.crops.length > 0 && blockType.crops.sort((a, b) => (a.name < b.name ? -1 : b.name < a.name ? 1 : 0));
                                    const pastureTypes = blockType.pastures && blockType.pastures.length > 0 && blockType.pastures.sort((a, b) => (a.name < b.name ? -1 : b.name < a.name ? 1 : 0));
                                    const showAccordionLink = cropTypes || pastureTypes;
                                    const isAccordionOpen = showAccordionLink && accordionState[blockType.name] === true;

                                    return (
                                        <Fragment key={blockType.name}>
                                            <tr className={lineClass}>
                                                <td>
                                                    {showAccordionLink && (
                                                        <>
                                                            <ActionLink id={`${blockType.name}-accordion`} onClick={() => onAccordionClick(blockType.name)} className="u-flex u-flexAlignItemsCenter u-flexJustifyBetween">
                                                                {blockType.name}
                                                                {isAccordionOpen && <i className="icon icon-arrow-up"></i>}
                                                                {!isAccordionOpen && <i className="icon icon-arrow-down"></i>}
                                                            </ActionLink>
                                                        </>
                                                    )}
                                                    {!showAccordionLink && <>{blockType.name}</>}
                                                </td>
                                                <td className="u-textRight" id={`${blockType.type}-farms`}>
                                                    {farmCount.toLocaleString()}
                                                </td>
                                                <td className="u-textRight" id={`${blockType.type}-blocks`}>
                                                    {blockCount.toLocaleString()}
                                                </td>
                                                <td className="u-textRight" id={`${blockType.type}-area`}>
                                                    {utils.round(totalArea, 0).toLocaleString()}
                                                </td>
                                                <td>
                                                    <ProgressBar colour={colours[i]} endLabel={`${progress}%`} progress={progress} showZero={true} />
                                                </td>
                                            </tr>
                                            {isAccordionOpen && (
                                                <>
                                                    {cropTypes &&
                                                        cropTypes.map((cropType) => {
                                                            const cropTypeKey = `${blockType.type}-${cropType.id}`;
                                                            return (
                                                                <Fragment key={cropTypeKey}>
                                                                    <tr className="tr-nested">
                                                                        <td>{cropType.name}</td>
                                                                        <td className="u-textRight" id={`${cropTypeKey}-farms`}>
                                                                            {cropType.farmCount.toLocaleString()}
                                                                        </td>
                                                                        <td className="u-textRight" id={`${cropTypeKey}-blocks`}>
                                                                            {cropType.blockCount.toLocaleString()}
                                                                        </td>
                                                                        <td className="u-textRight" id={`${cropTypeKey}-area`}>
                                                                            {utils.round(cropType.areaInHectares, 0).toLocaleString()}
                                                                        </td>
                                                                        <td className="u-textCenter">-</td>
                                                                    </tr>
                                                                </Fragment>
                                                            );
                                                        })}
                                                    {pastureTypes &&
                                                        pastureTypes.map((pastureType) => {
                                                            const pastureTypeKey = `${blockType.type}-${pastureType.id}`;
                                                            return (
                                                                <Fragment key={pastureTypeKey}>
                                                                    <tr className="tr-nested">
                                                                        <td>{pastureType.name}</td>
                                                                        <td className="u-textRight" id={`${pastureTypeKey}-farms`}>
                                                                            {pastureType.farmCount.toLocaleString()}
                                                                        </td>
                                                                        <td className="u-textRight" id={`${pastureTypeKey}-blocks`}>
                                                                            {pastureType.blockCount.toLocaleString()}
                                                                        </td>
                                                                        <td className="u-textRight" id={`${pastureTypeKey}-area`}>
                                                                            {utils.round(pastureType.areaInHectares, 0).toLocaleString()}
                                                                        </td>
                                                                        <td className="u-textCenter">-</td>
                                                                    </tr>
                                                                </Fragment>
                                                            );
                                                        })}
                                                </>
                                            )}
                                        </Fragment>
                                    );
                                })}
                                {aggregatedData.totalArea > totalBlockArea && (
                                    <tr className="Table-tr--NonBlock">
                                        <td>Unblocked area</td>
                                        <td></td>
                                        <td></td>
                                        <td className="u-textRight">{nonBlockedArea.toLocaleString()}</td>
                                        <td>
                                            <ProgressBar colour={nonBlockedColour} endLabel={`${nonBlocked}%`} progress={nonBlocked} showZero={true} />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td></td>
                                    <td></td>
                                    <td className="u-textRight">{utils.round(aggregatedData.totalArea, 0).toLocaleString()}</td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </GridCell>
                <GridCell className="u-lg-width1of2 u-md-width1of2 u-print-none">
                    <div className="u-pt-xl u-pl-xl u-pb-lg" style={{ maxWidth: "600px" }}>
                        <div className="u-flex u-flexJustifyCenter">
                            <Doughnut data={data} options={options} height={400} width={400} />
                        </div>
                    </div>
                </GridCell>
            </Grid>
            <Grid className="u-page_break">
                <GridCell className="u-mt-lg">
                    <Alert className="u-mb-md" type="info" text="The following graphs show the nitrogen and phosphorus losses by block type. You can switch between total loss and loss per hectare using the buttons on the right. Losses by hectare are the total losses for that type divided by the total area of blocks of that type." />
                </GridCell>
                <GridCell className="u-xxl-width1of2 u-xl-width1of1">
                    <NLossByBlockTypesChart blocks={specificBlockTypes} />
                </GridCell>
                <GridCell className="u-xxl-width1of2 u-xl-width1of1 u-page_break">
                    <PLossByBlockTypesChart blocks={specificBlockTypes} />
                </GridCell>
            </Grid>
            <Grid>
                <GridCell className="u-mt-xl">
                    <div className="HeroPanel-heading">Nutrients applied from synthetic fertiliser</div>
                    <Alert className="u-mb-md" type="info" text="The following graphs show the nutrients applied by synthetic fertiliser by block type. You can switch between total loss and loss per hectare using the buttons on the right. " />
                </GridCell>

                <GridCell className="u-xxl-width1of2 u-xl-width1of1 u-page_break">
                    <NAppliedByBlockTypesChart blocks={productiveBlocksTypes} />
                </GridCell>
                <GridCell className="u-xxl-width1of2 u-xl-width1of1 u-page_break">
                    <PAppliedByBlockTypesChart blocks={productiveBlocksTypes} />
                </GridCell>
                <GridCell className="u-xxl-width1of2 u-xl-width1of1 u-page_break">
                    <KAppliedByBlockTypesChart blocks={productiveBlocksTypes} />
                </GridCell>
                <GridCell className="u-xxl-width1of2 u-xl-width1of1 u-page_break">
                    <SAppliedByBlockTypesChart blocks={productiveBlocksTypes} />
                </GridCell>
            </Grid>
        </div>
    );
}
