import { Fragment, useState } from "react";
import * as utils from "common/utils";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import ActionLink from "components/ActionLink";
import { ZendeskLink } from "components/Help";
import { useAnalysisAuditLog } from "containers/hooks";
import { useRefData, useModal, useAuthContext } from "common/hooks";

export default function AnalysisAuditLogModal({ farm, analysis, close }) {
    const authContext = useAuthContext();
    const { data: auditLog } = useAnalysisAuditLog(farm.id, analysis.id);
    const [isLogOpen, setIsLogOpen] = useState();

    const toggleSysLog = (changedOn) => {
        if (isLogOpen) {
            setIsLogOpen(false);
        } else {
            setIsLogOpen(changedOn);
        }
    };

    const showResultsColumn = !farm.expired;

    const info = "This table shows a list of changes for this analysis. Entries are only created when the change has an effect on the modelled results. We will be adding more information about what has changed to this log over time.";

    return (
        <Modal title={`Analysis audit log - ${analysis.name}`} close={close} fluid wide>
            <ModalBody loading={!auditLog} info={info} error={auditLog && auditLog.error}>
                <div className="Table u-mt-md">
                    <div style={{ overflowY: "scroll" }}>
                        <table>
                            <thead>
                                <tr>
                                    <th data-width="15">Date</th>
                                    <th data-width="15">Organisation</th>
                                    <th data-width="15">User</th>
                                    {showResultsColumn && <th data-width="15">Results</th>}
                                    <th data-width="40">Changes</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div style={{ maxHeight: "400px", overflowY: "scroll" }}>
                        <table>
                            <tbody>
                                {(!auditLog || (auditLog.changes || []).length === 0) && (
                                    <tr key={analysis.created}>
                                        <td data-width="15" className="td--alignTop">
                                            {utils.dateLong(analysis.created)}
                                        </td>
                                        <td data-width="15"></td>
                                        <td data-width="15"></td>
                                        {showResultsColumn && <td data-width="15"></td>}
                                        <td data-width="40">
                                            <p className="u-textBold u-textTertiary">Analysis created</p>
                                        </td>
                                    </tr>
                                )}
                                {auditLog?.changes &&
                                    auditLog.changes
                                        .sort((a, b) => (b.changedOn < a.changedOn ? -1 : 1))
                                        .map((logEntry) => {
                                            const modelVersionUpgrade = logEntry.inputChanges?.find((c) => c.section.startsWith("Model version upgrade:"));
                                            return (
                                                <Fragment key={logEntry.changedOn}>
                                                    <tr>
                                                        <td data-width="15" className="td--alignTop">
                                                            <div className="u-flex u-flexJustifyBetween">
                                                                <span>{utils.dateLong(logEntry.changedOn)}</span>
                                                                {logEntry.objectDataChanges && authContext.isSystemAdmin && <ActionLink className={`RoundButton RoundButton--${isLogOpen === logEntry.changedOn ? "up" : "down"} u-font-md`} onClick={() => toggleSysLog(logEntry.changedOn)} name="override-smap-button" />}
                                                            </div>
                                                        </td>
                                                        <td data-width="15" className="td--alignTop">
                                                            {modelVersionUpgrade ? <>Model version upgrade</> : <>{logEntry.accountName}</>}
                                                        </td>
                                                        <td data-width="15" className="td--alignTop">
                                                            {modelVersionUpgrade ? <>{modelVersionUpgrade.section.replace("Model version upgrade: ", "")}</> : <>{logEntry.usersName}</>}
                                                        </td>
                                                        {showResultsColumn && (
                                                            <td data-width="15" className="td--alignTop">
                                                                <ResultChanges logEntry={logEntry} />
                                                                <Results logEntry={logEntry} />
                                                                <NoResults logEntry={logEntry} />
                                                            </td>
                                                        )}
                                                        <td data-width="40" className="td--alignTop">
                                                            <ChangeSummary logEntry={logEntry} />
                                                            <ModelVersionUpgrade logEntry={logEntry} />
                                                            <SystemChanges logEntry={logEntry} />
                                                            <PhysicalChanges logEntry={logEntry} />
                                                            <OtherChanges logEntry={logEntry} />
                                                        </td>
                                                    </tr>
                                                    {isLogOpen === logEntry.changedOn && (
                                                        <tr>
                                                            <td colSpan={6}>
                                                                <div className="h5 u-mb-sm">Auto-generated input comparison</div>
                                                                {false && <div style={{ whiteSpace: "pre-wrap" }}>{logEntry.objectDataChanges}</div>}
                                                                <div className="Table">
                                                                    <span dangerouslySetInnerHTML={{ __html: logEntry.objectDataChanges }} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </Fragment>
                                            );
                                        })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <ModalFooterLeft></ModalFooterLeft>
                <ModalFooterRight>
                    <Button id="cancel" onClick={close} secondary>
                        Close
                    </Button>
                </ModalFooterRight>
            </ModalFooter>
        </Modal>
    );
}

function ResultChanges({ logEntry }) {
    const resultChanges = logEntry?.resultChanges;
    if (!resultChanges) return null;

    return (
        <>
            {resultChanges.map((change, i) => (
                <p key={`results-${logEntry.changedOn}-${i}`}>
                    <b>{change.result}:</b> {change.from} to {change.to}
                </p>
            ))}
        </>
    );
}

function Results({ logEntry }) {
    const r = logEntry?.comparison?.results;
    if (!r) return null;

    return (
        <>
            {r.nLossPerHa && (
                <p>
                    <b>N (kg/ha):</b> {r.nLossPerHa.from === 0 ? "X" : r.nLossPerHa.from} to {r.nLossPerHa.to === 0 ? "X" : r.nLossPerHa.to}
                </p>
            )}
            {r.nLossTotal && (
                <p>
                    <b>N (kg):</b> {r.nLossTotal.from === 0 ? "X" : r.nLossTotal.from.toLocaleString()} to {r.nLossTotal.to === 0 ? "X" : r.nLossTotal.to.toLocaleString()}
                </p>
            )}
            {r.pLossPerHa && (
                <p>
                    <b>P (kg/ha):</b> {r.pLossPerHa.from === 0 ? "X" : r.pLossPerHa.from} to {r.pLossPerHa.to === 0 ? "X" : r.pLossPerHa.to}
                </p>
            )}
            {r.pLossTotal && (
                <p>
                    <b>P (kg):</b> {r.pLossTotal.from === 0 ? "X" : r.pLossTotal.from.toLocaleString()} to {r.pLossTotal.to === 0 ? "X" : r.pLossTotal.to.toLocaleString()}
                </p>
            )}
            {r.ghg && (
                <p>
                    <b>GHG (kg/ha):</b> {r.ghg.from === 0 ? "X" : r.ghg.from.toLocaleString()} to {r.ghg.to === 0 ? "X" : r.ghg.to.toLocaleString()}
                </p>
            )}
        </>
    );
}

function NoResults({ logEntry }) {
    if (logEntry?.resultChanges || logEntry?.comparison?.results) return null;

    return <p>No results have yet been created for this analysis</p>;
}

function ChangeSummary({ logEntry }) {
    if (!logEntry?.path) return null;

    function getChangeSummaryText(path) {
        if (path.includes("blocks/new")) return "Blocks - A new block was created";
        if (path.includes("blocks/")) return "Blocks - A block was edited";

        if (path.includes("snowfall/new")) return "Climate - Snowfall was created";
        if (path.includes("snowfall/")) return "Climate - Snowfall was edited";

        if (path.includes("soil/soilTests/new")) return "Soil test - A soil test was added";
        if (path.includes("soil/soilTests/")) return "Soil test - A soil test was edited";
        if (path.includes("soil")) return "Soil - A farm soil was edited";

        if (path.includes("drainage/unfenced/new")) return "Drainage - An unfenced wetland was added";
        if (path.includes("drainage/unfenced/")) return "Drainage - An unfenced wetland was edited";
        if (path.includes("drainage/fenced/")) return "Drainage - A fenced wetland was edited on the drainage tab";
        if (path.includes("drainage/moletilesystem")) return "Drainage - A mole/tile drainage system was added/edited or deleted";
        if (path.includes("drainage/other")) return "Drainage - An other drainage system was added/edited or deleted";

        if (path.includes("crops/pasture")) return "Pasture/Crops - Pasture was added to or edited on pasture blocks";
        if (path.includes("crops/rotation/new")) return "Pasture/Crops - A fodder crop rotation was added";
        if (path.includes("crops/rotation/")) return "Pasture/Crops - A fodder crop rotation was edited";
        if (path.includes("crops/fruit/")) return "Pasture/Crops - Fruit has been added to or edited on fruit blocks";
        if (path.includes("crops/crop/")) return "Pasture/Crops - Crops have been added to or edited on crop blocks";

        if (path.includes("animals/enterprises/beef")) return "Animals - Beef enterprise has been edited";
        if (path.includes("animals/enterprises/dairy")) return "Animals - Dairy enterprise has been edited";
        if (path.includes("animals/enterprises/sheep")) return "Animals - Sheep enterprise has been edited";
        if (path.includes("animals/enterprises/dairygrazing")) return "Animals - Dairy grazing enterprise has been edited";
        if (path.includes("animals/enterprises/deer")) return "Animals - Deer grazing enterprise has been edited";
        if (path.includes("animals/enterprises/dairygoat")) return "Animals - Dairy goat enterprise has been edited";
        if (path.includes("animals/enterprises/outdoorpigs")) return "Animals - Outdoor pigs enterprise has been edited";
        if (path.includes("animals/enterprises/other")) return "Animals - Other animals enterprise has been edited";
        if (path.includes("animals/distribution")) return "Animals - Animal distribution has been edited";
        if (path.includes("animals")) return "Animals - Animals enterprise has been deleted";

        if (path.includes("comments")) return "A comment has been added";

        if (path.includes("structures/structure")) return "Structures/Effluent - A structure was edited";
        if (path.includes("structures/effluent")) return "Structures/Effluent - An effluent system has been edited";

        if (path.includes("supplements/harvested")) return "Supplements - A harvested supplement has been edited";
        if (path.includes("supplements/imported")) return "Supplements - An imported supplement has been edited";
        if (path.includes("supplements/purchased")) return "Supplements - A purchased supplement has been edited";
        if (path.includes("supplements/fromStorage")) return "Supplements - A supplement from storage has been edited";
        if (path.includes("distribution")) return "Supplements - Supplements have been distributed";

        if (path.includes("fertiliser")) return "Fertiliser - A fertiliser has been edited";
        if (path.includes("fertiliser/applications")) return "Fertiliser - A fertiliser application has been edited";

        if (path.includes("irrigation/new")) return "Irrigation - An irrigation system has been created";
        if (path.includes("irrigation/applications")) return "Irrigation - An irrigation application has been edited";

        if (path.includes("ghg/fuel")) return "GHG - Fuel footprint overrides were edited";
        if (path.includes("ghg/fertiliser")) return "GHG - Fertiliser application footprint overrides were edited";
        if (path.includes("ghg/transport")) return "GHG - Transport footprint overrides were edited";
        if (path.includes("ghg/electricity")) return "GHG - Electricity footprint overrides were edited";

        return "Analysis updated";
    }

    const changeSummaryText = getChangeSummaryText(logEntry.path);
    return <p className="u-textTertiary u-textBold">{changeSummaryText}</p>;
}

function ModelVersionUpgrade({ logEntry }) {
    const modelVersionUpgrade = logEntry?.inputChanges?.find((c) => c.section.startsWith("Model version upgrade:"));
    if (!modelVersionUpgrade) return null;

    function getReleaseNotesUrl(modelVersion) {
        const baseUrl = "https://support.overseer.org.nz/hc/en-us/articles/";
        switch (modelVersion) {
            case "6.5.9":
                return baseUrl + "43950806466457";
            case "6.5.8":
                return baseUrl + "39449309242009";
            case "6.5.7":
                return baseUrl + "37595129798425";
            case "6.5.6":
                return baseUrl + "34614448647833";
            case "6.5.5":
                return baseUrl + "32903554057241";
            case "6.5.4":
                return baseUrl + "25848909075865";
            case "6.5.3":
                return baseUrl + "23986325469337";
            case "6.5.2":
                return baseUrl + "19315463463065";
            case "6.5.1":
                return baseUrl + "17839069845145";
            case "6.5.0":
                return baseUrl + "12730566419481";
            case "6.4.3":
                return baseUrl + "5342784444441";
            case "6.4.2":
                return baseUrl + "4412310769561";
            case "6.4.1":
                return baseUrl + "4407107447193";
            case "6.4.0":
                return baseUrl + "4404231934617";
            default:
                return null;
        }
    }

    const releaseNotesUrl = getReleaseNotesUrl(logEntry.modelVersion);
    return (
        <>
            <p className="u-textTertiary u-textBold">{modelVersionUpgrade.section}</p>
            {releaseNotesUrl && <ZendeskLink title={`${logEntry.modelVersion} release notes`} url={releaseNotesUrl} className="IconLink--new-tab u-font-green" />}
        </>
    );
}

function SystemChanges({ logEntry }) {
    const refData = useRefData();

    const system = logEntry?.comparison?.system;
    if (!system) return null;

    const hasSystemChanges =
        system.syntheticFertiliserNutrientsAppliedKG?.N ||
        system.syntheticFertiliserNutrientsAppliedKG?.P ||
        system.organicFertiliserNutrientsAppliedKG?.N ||
        system.organicFertiliserNutrientsAppliedKG?.P ||
        system.animalRSU ||
        //|| system.animalNumbers
        system.supplementsImportedKG?.N ||
        system.supplementsImportedKG?.P ||
        system.totalIrrigationApplied ||
        system.milkSolidsProductionKG ||
        system.pastureProduction ||
        system.averagePastureProduction ||
        system.croppingArea;
    if (!hasSystemChanges) return null;

    return (
        <>
            <p className="u-textSecondary u-textBold">Farm system changes</p>
            {system.syntheticFertiliserNutrientsAppliedKG?.N && (
                <>
                    <p className="u-textBold">- Synthetic fertiliser N (kg)</p>{" "}
                    <ul className="disc u-mt-sm">
                        {system.syntheticFertiliserNutrientsAppliedKG.N.map((f) => (
                            <li key={`synthetic-fert-N-${f.month}-${logEntry.changedOn}`}>
                                <b>{f.month}:</b> {f.from.toLocaleString()} to {f.to.toLocaleString()}
                            </li>
                        ))}
                    </ul>
                </>
            )}
            {system.syntheticFertiliserNutrientsAppliedKG?.P && (
                <>
                    <p className="u-textBold">- Synthetic fertiliser P (kg)</p>{" "}
                    <ul className="disc u-mt-sm">
                        {system.syntheticFertiliserNutrientsAppliedKG.P.map((f) => (
                            <li key={`synthetic-fert-P-${f.month}-${logEntry.changedOn}`}>
                                <b>{f.month}:</b> {f.from.toLocaleString()} to {f.to.toLocaleString()}
                            </li>
                        ))}
                    </ul>
                </>
            )}
            {system.organicFertiliserNutrientsAppliedKG?.N && (
                <>
                    <p className="u-textBold">- Organic fertiliser N (kg)</p>{" "}
                    <ul className="disc u-mt-sm">
                        {system.organicFertiliserNutrientsAppliedKG.N.map((f) => (
                            <li key={`organic-fert-N-${f.month}-${logEntry.changedOn}`}>
                                <b>{f.month}:</b> {f.from.toLocaleString()} to {f.to.toLocaleString()}
                            </li>
                        ))}
                    </ul>
                </>
            )}
            {system.organicFertiliserNutrientsAppliedKG?.P && (
                <>
                    <p className="u-textBold">- Organic fertiliser P (kg)</p>{" "}
                    <ul className="disc u-mt-sm">
                        {system.organicFertiliserNutrientsAppliedKG.P.map((f) => (
                            <li key={`organic-fert-P-${f.month}-${logEntry.changedOn}`}>
                                <b>{f.month}:</b> {f.from.toLocaleString()} to {f.to.toLocaleString()}
                            </li>
                        ))}
                    </ul>
                </>
            )}
            {system.animalRSU &&
                Object.keys(system.animalRSU).map((enterpriseType) => {
                    const change = system.animalRSU[enterpriseType];
                    return (
                        <>
                            <p key={`rsu-${enterpriseType}-${logEntry.changedOn}`}>
                                <b>- {utils.valueToText(refData.enterpriseTypes, enterpriseType)} (RSU):</b> {change.from.toLocaleString()} to {change.to.toLocaleString()}
                            </p>
                        </>
                    );
                })}
            {system.animalNumbers &&
                false &&
                Object.keys(system.animalNumbers).map((enterpriseType) => {
                    const changes = system.animalNumbers[enterpriseType].filter((f) => f.percentageChange > 0);
                    return (
                        <>
                            <p className="u-textBold">- {utils.valueToText(refData.enterpriseTypes, enterpriseType)} (numbers)</p>
                            <ul className="disc u-mt-sm">
                                {changes.map((f, j) => (
                                    <li key={`animalNumbers-${enterpriseType}-${logEntry.changedOn}`}>
                                        <p>
                                            <b>{f.month}:</b> {f.from.toLocaleString()} to {f.to.toLocaleString()}
                                        </p>
                                    </li>
                                ))}
                            </ul>
                        </>
                    );
                })}
            {(system.supplementsImportedKG?.N || system.supplementsImportedKG?.P) && (
                <>
                    <p className="u-textBold">- Supplement nutrients (kg)</p>
                    <ul className="disc u-mt-sm">
                        {system.supplementsImportedKG?.N && (
                            <li>
                                <b>N:</b> {system.supplementsImportedKG.N.from.toLocaleString()} to {system.supplementsImportedKG.N.to.toLocaleString()}
                            </li>
                        )}
                        {system.supplementsImportedKG?.P && (
                            <li>
                                <b>P:</b> {system.supplementsImportedKG.P.from.toLocaleString()} to {system.supplementsImportedKG.P.to.toLocaleString()}
                            </li>
                        )}
                    </ul>
                </>
            )}
            {system?.totalIrrigationApplied && (
                <p>
                    <b>- Irrigation applied (mm):</b> {system.totalIrrigationApplied.from.toLocaleString()} to {system.totalIrrigationApplied.to.toLocaleString()}
                </p>
            )}
            {system.milkSolidsProductionKG && (
                <p>
                    <b>- Milk solids (kg):</b> {system.milkSolidsProductionKG.from.toLocaleString()} to {system.milkSolidsProductionKG.to.toLocaleString()}
                </p>
            )}
            {system.pastureProduction && (
                <p>
                    <b>- Pasture production (kg DM):</b> {system.pastureProduction.from.toLocaleString()} to {system.pastureProduction.to.toLocaleString()}
                </p>
            )}
            {system.averagePastureProduction && (
                <p>
                    <b>- Average pasture (kg DM/ha):</b> {system.averagePastureProduction.from.toLocaleString()} to {system.averagePastureProduction.to.toLocaleString()}
                </p>
            )}
            {system.croppingArea && (
                <p>
                    <b>- Cropping area (ha):</b> {system.croppingArea.from} to {system.croppingArea.to.toLocaleString()}
                </p>
            )}
        </>
    );
}

function PhysicalChanges({ logEntry }) {
    const physical = logEntry?.comparison?.physical;
    if (!physical) return null;

    const hasPhysicalChanges = physical.farmArea || physical.productiveBlockArea || !isNaN(physical.temperature?.from) || !isNaN(physical.temperature?.to) || physical.rainfallMM || physical.petmm || physical.soilAreas?.length > 0;
    if (!hasPhysicalChanges) return null;

    return (
        <>
            <p className="u-textSecondary u-textBold">Physical farm changes</p>
            {physical.farmArea && (
                <p>
                    <b>- Farm area (ha):</b> {physical.farmArea.from === 0 ? "Block area" : physical.farmArea.from.toLocaleString()} to {physical.farmArea.to === 0 ? "Block area" : physical.farmArea.to.toLocaleString()}
                </p>
            )}
            {physical.productiveBlockArea && (
                <p>
                    <b>- Block area (ha):</b> {physical.productiveBlockArea.from.toLocaleString()} to {physical.productiveBlockArea.to.toLocaleString()}
                </p>
            )}
            {!isNaN(physical.temperature?.from) && !isNaN(physical.temperature?.to) && (
                <p>
                    <b>- Temp (C):</b> {physical.temperature.from === 0 ? "default" : physical.temperature.from} to {physical.temperature.to}
                </p>
            )}
            {physical.rainfallMM && (
                <p>
                    <b>- Rain (mm):</b> {physical.rainfallMM.from === 0 ? "default" : physical.rainfallMM.from.toLocaleString()} to {physical.rainfallMM.to.toLocaleString()}
                </p>
            )}
            {physical.petmm && (
                <p>
                    <b> - PET (mm):</b> {physical.petmm.from === 0 ? "default" : physical.petmm.from.toLocaleString()} to {physical.petmm.to.toLocaleString()}
                </p>
            )}
            {physical.soilAreas &&
                physical.soilAreas.map((soil, i) => (
                    <p key={`physical-soil-${logEntry.changedOn}-${soil.name}-${i}`}>
                        <b>- {soil.name} area (ha):</b> {soil.from.toLocaleString()} to {soil.to.toLocaleString()}
                    </p>
                ))}
        </>
    );
}

function OtherChanges({ logEntry }) {
    if (!logEntry?.inputChanges) return null;

    // Model version upgrades are handled in the ModelVersionUpgrade component
    const modelVersionUpgrade = logEntry?.inputChanges?.find((c) => c.section.startsWith("Model version upgrade:"));
    if (modelVersionUpgrade) return null;

    return (
        <>
            <p className="u-textSecondary u-textBold">Other changes</p>
            {logEntry.inputChanges.map((inputChange, i) => {
                return (
                    <div key={`inputChange-${logEntry.changedOn}-${i}`}>
                        <p className="u-textBold">{inputChange.section}</p>
                        <ul className="disc u-mt-sm">
                            {inputChange.changes.map((change, j) => (
                                <li key={`change-${logEntry.changedOn}-${j}`}>{change}</li>
                            ))}
                        </ul>
                    </div>
                );
            })}
        </>
    );
}

export function useAnalysisAuditLogModal(farm, analysis) {
    const [modal, openModal] = useModal(AnalysisAuditLogModal);

    const openAnalysisAuditLogModal = () => {
        const modalProps = {
            farm,
            analysis,
        };
        openModal(modalProps);
    };

    return [modal, openAnalysisAuditLogModal];
}
