import Alert from "components/Alert";
import GhgEmissions from "./GhgEmissions";
import BlockTypes from "./BlockTypes";
import Livestock from "./Livestock";
import SoilOrders from "./SoilOrders";
import Irrigation from "./Irrigation";
import MonthlyTiming from "./MonthlyTiming";
import Supplements from "./Supplements";
import MilkPool from "./MilkPool";

export default function AggregatedReporting({ aggregatedData, farmsWithResultsCount, farmGroup, farmLookup }) {
    const hasAggregatedData = aggregatedData && farmsWithResultsCount > 0;

    return (
        <>
            {farmGroup && !farmGroup.showAggregatedReport && farmGroup.role === "Admin" && <Alert type="warning" text="Aggregated reporting is hidden from group members" />}
            {hasAggregatedData ? (
                <>
                    <GhgEmissions aggregatedData={aggregatedData} />
                    <BlockTypes aggregatedData={aggregatedData} />
                    <SoilOrders aggregatedData={aggregatedData} />
                    <Livestock aggregatedData={aggregatedData} />
                    <Supplements aggregatedData={aggregatedData} />
                    <MilkPool aggregatedData={aggregatedData} />
                    <Irrigation aggregatedData={aggregatedData} />
                    <MonthlyTiming aggregatedData={aggregatedData} farmLookup={farmLookup} />
                </>
            ) : (
                <>
                    <div className="HeroPanel">
                        <div className="Tile-body-message">
                            <h3 className="u-mt-md">There are no results for this period</h3>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
