import { Fragment, useState } from "react";
import { useRefData } from "common/hooks";
import * as utils from "common/utils";
import { Grid, GridCell } from "components/Grid";
import ProgressBar from "components/ProgressBar";
import Alert from "components/Alert";
import { Bar } from "react-chartjs-2";
import ActionLink from "components/ActionLink";

const Supplements = ({ aggregatedData }) => {
    const refData = useRefData();
    const [showImported, setShowImported] = useState([]);
    if (!aggregatedData || !aggregatedData.results || aggregatedData.totalArea === 0) return null;
    const { supplements = [] } = aggregatedData;
    const unique = [...new Set(supplements.map((item) => item.category))];
    const { supplementCategories = [] } = refData;
    if (supplements.length === 0) return null;
    const totalDm = unique.reduce((t, n) => t + supplements.filter((item) => item.category === n).reduce((t1, n1) => t1 + n1.dryMatterWeightInTonnes, 0), 0);

    const data = {
        labels: unique.map((e) => utils.valueToText(supplementCategories, e)),
        datasets: [
            {
                label: "Harvested",
                data: unique.map((uniqueType) => Math.round(supplements.filter((item) => item.category === uniqueType && item.type === "Harvested").reduce((t, s) => t + s.dryMatterWeightInTonnes, 0))),
                backgroundColor: utils.graphColours[1],
                hoverBackgroundColor: utils.graphColours[1],
            },
            {
                label: "Purchased",
                data: unique.map((uniqueType) => Math.round(supplements.filter((item) => item.category === uniqueType && item.type === "Purchased").reduce((t, s) => t + s.dryMatterWeightInTonnes, 0))),
                backgroundColor: utils.graphColours[14],
                hoverBackgroundColor: utils.graphColours[14],
            },
            {
                label: "From Storage",
                data: unique.map((uniqueType) => Math.round(supplements.filter((item) => item.category === uniqueType && item.type === "FromStorage").reduce((t, s) => t + s.dryMatterWeightInTonnes, 0))),
                backgroundColor: utils.graphColours[3],
                hoverBackgroundColor: utils.graphColours[3],
            },
        ],
    };

    const options = {
        maintainAspectRatio: true,
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                stacked: true,
                title: { display: true, text: "Supplement type" },
            },
            y: {
                stacked: true,
                title: { display: true, text: "Tonnes of DM" },
            },
        },
    };

    const toggleImported = (ut) => {
        if (showImported.includes(ut)) {
            setShowImported(showImported.filter((item) => item !== ut));
        } else {
            setShowImported([...showImported, ut]);
        }
    };

    return (
        <div className="HeroPanel u-mt-md u-page-break u-print-border">
            <div className="HeroPanel-title">Supplements fed</div>
            <Alert type="info" html="Shows the different types of <b>supplements</b> that are fed to animals. These supplements are harvested, purchased, and from storage, from the farms within the group. The supplements that have an estimated weight by volume, bales, and cuts are converted to an estimated weight of dry matter in tonnes." />
            <Grid>
                <GridCell className="u-lg-width2of3 u-md-width1of1">
                    <div className="Table u-mt-md">
                        <table>
                            <thead>
                                <tr>
                                    <th rowSpan={2}>Supplement</th>
                                    <th className="u-textRight th--shrink" rowSpan={2}>
                                        Farms
                                    </th>
                                    <th className="u-textCenter" colSpan={2}>
                                        Harvested
                                    </th>
                                    <th className="u-textCenter" colSpan={2}>
                                        Purchased
                                    </th>
                                    <th className="u-textCenter" colSpan={2}>
                                        From storage
                                    </th>
                                    <th className="u-textRight th--shrink" rowSpan={2}>
                                        Total t DM
                                    </th>
                                    <th className="u-textRight" rowSpan={2}>
                                        Percentage
                                    </th>
                                </tr>
                                <tr>
                                    <th className="u-textRight" style={{ borderLeft: "1px solid #E5E9E7" }}>
                                        t DM
                                    </th>
                                    <th className="u-textRight">Farms</th>
                                    <th className="u-textRight">t DM</th>
                                    <th className="u-textRight">Farms</th>
                                    <th className="u-textRight">t DM</th>
                                    <th className="u-textRight">Farms</th>
                                </tr>
                            </thead>
                            <tbody>
                                {unique
                                    .sort((a, b) => a.localeCompare(b))
                                    .map((uniqueType, i) => {
                                        const supplement = supplements.filter((item) => item.category === uniqueType);
                                        const farmCount = supplement.reduce((acc, item) => (acc += item.farmCount), 0);
                                        const displayName = utils.valueToText(supplementCategories, uniqueType);
                                        const harvested = supplement.filter((item) => item.type === "Harvested");
                                        const harvestedFarmCount = harvested.reduce((acc, item) => (acc += item.farmCount), 0);
                                        const harvestedDm = harvested.reduce((t, s) => t + s.dryMatterWeightInTonnes, 0);
                                        const purchased = supplement.filter((item) => item.type === "Purchased");
                                        const purchasedFarmCount = purchased.reduce((acc, item) => (acc += item.farmCount), 0);
                                        const purchasedDm = purchased.reduce((t, s) => t + s.dryMatterWeightInTonnes, 0);
                                        const storage = supplement.filter((item) => item.type === "FromStorage");
                                        const storageFarmCount = storage.reduce((acc, item) => (acc += item.farmCount), 0);
                                        const storageDm = storage.reduce((t, s) => t + s.dryMatterWeightInTonnes, 0);
                                        const uniquePurchased = [...new Set(purchased.map((item) => item.name))];
                                        const percentage = utils.round((supplement.reduce((t, s) => (t += s.dryMatterWeightInTonnes), 0) / totalDm) * 100, 1);

                                        return (
                                            <Fragment key={i}>
                                                <tr>
                                                    <td>
                                                        <div className="u-flex u-flexJustifyBetween">
                                                            <div>{displayName}</div>
                                                            {purchased.length > 0 && (
                                                                <div>
                                                                    <ActionLink className="u-flex u-flexAlignItemsCenter u-flexJustifyBetween" onClick={() => toggleImported(uniqueType)}>
                                                                        <i className={`icon icon-arrow-${showImported.includes(uniqueType) ? "up" : "down"}`}></i>
                                                                    </ActionLink>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className="u-textRight">{farmCount.toLocaleString()}</td>
                                                    <td className="u-textRight">{Math.round(harvestedDm).toLocaleString()}</td>
                                                    <td className="u-textRight">{harvestedFarmCount.toLocaleString()}</td>
                                                    <td className="u-textRight">{Math.round(purchasedDm).toLocaleString()}</td>
                                                    <td className="u-textRight">{purchasedFarmCount.toLocaleString()}</td>
                                                    <td className="u-textRight">{Math.round(storageDm).toLocaleString()}</td>
                                                    <td className="u-textRight">{storageFarmCount.toLocaleString()}</td>
                                                    <td className="u-textRight">
                                                        <b> {Math.round(harvestedDm + purchasedDm + storageDm).toLocaleString()}</b>
                                                    </td>
                                                    <td className="u-textRight">
                                                        <ProgressBar colour={utils.graphColours[1]} endLabel={`${percentage}%`} progress={percentage} showZero={true} />
                                                    </td>
                                                </tr>

                                                {showImported.includes(uniqueType) &&
                                                    uniquePurchased
                                                        .sort((a, b) => a.localeCompare(b))
                                                        .map((u) => {
                                                            const groupedByName = purchased.filter((p) => p.name === u);
                                                            const groupedByNameDm = groupedByName.reduce((t, s) => t + s.dryMatterWeightInTonnes, 0);
                                                            const groupedByNameFarmCount = groupedByName.reduce((acc, item) => (acc += item.farmCount), 0);

                                                            return (
                                                                <tr className="tr-nested">
                                                                    <td colSpan={4}>{u}</td>
                                                                    <td className="u-textRight">{Math.round(groupedByNameDm).toLocaleString()}</td>
                                                                    <td className="u-textRight">{groupedByNameFarmCount.toLocaleString()}</td>
                                                                    <td className="u-textRight" colSpan={4}></td>
                                                                </tr>
                                                            );
                                                        })}
                                            </Fragment>
                                        );
                                    })}
                            </tbody>
                            <tfoot>
                                <tr style={{ borderBottom: "1px solid #E5E9E7", borderRight: "1px solid #E5E9E7" }}>
                                    <td></td>
                                    <td className="u-textRight">{supplements.reduce((acc, item) => (acc += item.farmCount), 0).toLocaleString()}</td>
                                    <td className="u-textRight">{Math.round(supplements.filter((item) => item.type === "Harvested").reduce((acc, item) => (acc += item.dryMatterWeightInTonnes), 0)).toLocaleString()}</td>
                                    <td className="u-textRight">
                                        {supplements
                                            .filter((item) => item.type === "Harvested")
                                            .reduce((acc, item) => (acc += item.farmCount), 0)
                                            .toLocaleString()}
                                    </td>
                                    <td className="u-textRight">{Math.round(supplements.filter((item) => item.type === "Purchased").reduce((acc, item) => (acc += item.dryMatterWeightInTonnes), 0)).toLocaleString()}</td>
                                    <td className="u-textRight">
                                        {supplements
                                            .filter((item) => item.type === "Purchased")
                                            .reduce((acc, item) => (acc += item.farmCount), 0)
                                            .toLocaleString()}
                                    </td>
                                    <td className="u-textRight">{Math.round(supplements.filter((item) => item.type === "FromStorage").reduce((acc, item) => (acc += item.dryMatterWeightInTonnes), 0)).toLocaleString()}</td>
                                    <td className="u-textRight">
                                        {supplements
                                            .filter((item) => item.type === "FromStorage")
                                            .reduce((acc, item) => (acc += item.farmCount), 0)
                                            .toLocaleString()}
                                    </td>
                                    <td className="u-textRight">{Math.round(supplements.reduce((acc, item) => (acc += item.dryMatterWeightInTonnes), 0)).toLocaleString()}</td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </GridCell>
                <GridCell className="u-lg-width1of3 u-md-width1of1">
                    <div className="u-pt-xl">
                        <Bar data={data} options={options} />
                    </div>
                </GridCell>
            </Grid>
        </div>
    );
};

export default Supplements;
