import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import { useRefData } from "common/hooks";
import { HelpTooltip } from "components/Help";

export default function SelectedFarm({ selectedFarm, farmId }) {
    const refData = useRefData();

    const farmName = selectedFarm ? (selectedFarm.farmId === farmId || selectedFarm.isMyFarm ? selectedFarm.farmName : "Anonymous farm") : "";
    return (
        <Panel title="Selected farm" openIfChanges={selectedFarm ? selectedFarm.analysisId : undefined} inError={selectedFarm} skyBlue={!selectedFarm}>
            <PanelBody>
                <div id="selected-farm">
                    {!selectedFarm && <div>Click on a bar within a chart to select a farm</div>}
                    {selectedFarm && (
                        <>
                            <div className="Table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th colSpan="2" className="u-bg-midblue u-textWhite" title={farmName}>
                                                <span style={{ display: "inline-block", maxWidth: "255px", overflow: "hidden", textOverflow: "ellipsis" }}>{farmName}</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr valign="top">
                                            <td>Region</td>
                                            <td>{utils.valueToText(refData.regions, selectedFarm.farmRegion)}</td>
                                        </tr>
                                        <tr valign="top">
                                            <td>Avg temp</td>
                                            <td>{utils.round(selectedFarm.avgTemp, 2)} &deg;C</td>
                                        </tr>
                                        <tr valign="top">
                                            <td>Avg rain</td>
                                            <td>{utils.round(selectedFarm.avgRain, 2)} mm/yr</td>
                                        </tr>
                                        <tr valign="top">
                                            <td>Avg PET</td>
                                            <td>{utils.round(selectedFarm.avgAnnualPET, 2)} mm/yr</td>
                                        </tr>
                                        <tr valign="top">
                                            <td>Productive area</td>
                                            <td>{utils.round(selectedFarm.productiveArea, 1)} ha</td>
                                        </tr>
                                        <tr valign="top">
                                            <td>Irrigated area</td>
                                            <td>{utils.round(selectedFarm.irrigatedArea, 1)} ha</td>
                                        </tr>
                                        <tr valign="top">
                                            <td>Total area</td>
                                            <td>{utils.round(selectedFarm.farmArea, 1)} ha</td>
                                        </tr>
                                        {
                                            // Oddly, we have to use a ternary operator here or React has a wobbly.
                                            selectedFarm.enterprises && selectedFarm.enterprises.length > 0 ? (
                                                <>
                                                    <tr valign="top">
                                                        <th colSpan="2">Animal enterprise - RSU</th>
                                                    </tr>
                                                    {selectedFarm.enterprises.map((enterprise) => (
                                                        <tr key={enterprise.type} valign="top">
                                                            <td>{enterprise.label}</td>
                                                            <td>{utils.round(enterprise.rsu, 0) + " rsu"}</td>
                                                        </tr>
                                                    ))}
                                                </>
                                            ) : null
                                        }
                                        {
                                            selectedFarm.dairyGrassFedStandard?.percentageOfDietFromGrassFedFeed > 0 &&
                                            <>
                                                <tr valign="top">
                                                    <th colSpan="2">Dairy grass-fed percentage</th>
                                                </tr>
                                                <tr valign="top">
                                                    <td>
                                                        Wet matter from grass-fed feed
                                                        <HelpTooltip tip="The estimated percentage of wet matter fed to the dairy enterprise from feed that meets the definition of grass-fed as defined by the draft Grass-fed Standard by AsureQuality" />
                                                    </td>
                                                    <td>{utils.round(selectedFarm.dairyGrassFedStandard.percentageOfDietFromGrassFedFeed, 1) + " %"}</td>
                                                </tr>
                                                <tr valign="top">
                                                    <td>
                                                        Time with access to pasture
                                                        <HelpTooltip tip="The estimated percentage of non-milking time the dairy enterprise spent with access to pasture" />
                                                    </td>
                                                    <td>{utils.round(selectedFarm.dairyGrassFedStandard.percentageOfTimeWithAccessToPasture, 1) + " %"}</td>
                                                </tr>
                                            </>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {false && (
                                <>
                                    <div className="ColourPanel ColourPanel--nloss">
                                        <div className="u-pl-md">
                                            <h5>Nitrogen loss (after wetland removal)</h5>
                                            <div className="u-mt-sm u-pl-lg">
                                                <div className="HeroValue-item-number">
                                                    {utils.round(selectedFarm.nLossTotal, 1).toLocaleString()} <small>kg</small>
                                                </div>
                                                <div className="HeroValue-item-number">
                                                    {utils.round(selectedFarm.nLossPerHa, 1)} <small>kg/ha</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ColourPanel ColourPanel--ploss">
                                        <div className="u-pl-md">
                                            <h5>Phosphorus loss</h5>
                                            <div className="u-mt-sm u-pl-lg">
                                                <div className="HeroValue-item-number">
                                                    {utils.round(selectedFarm.pLossTotal, 1).toLocaleString()} <small>kg</small>
                                                </div>
                                                <div className="HeroValue-item-number">
                                                    {utils.round(selectedFarm.pLossPerHa, 1).toLocaleString()} <small>kg/ha</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ColourPanel ColourPanel--ghg">
                                        <div className="u-pl-md">
                                            <h5>GHG emissions</h5>
                                            <div className="u-mt-sm u-pl-lg">
                                                <div className="HeroValue-item-number">
                                                    {utils.numeric.format(selectedFarm.ghgTotal, 1)} <small>t CO2e/yr</small>
                                                </div>
                                                <div className="HeroValue-item-number">
                                                    {utils.numeric.format(selectedFarm.ghgPerHa, 1)} <small>kg CO2e/ha/yr</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {true && (
                                <>
                                    <div className="Table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th colSpan="2" className="u-bg-nloss u-textWhite">
                                                        Nitrogen
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr valign="top">
                                                    <td>Total N loss</td>
                                                    <td>{utils.round(selectedFarm.nLossTotal, 1).toLocaleString()} kg</td>
                                                </tr>
                                                <tr valign="top">
                                                    <td>N loss per ha</td>
                                                    <td>{utils.round(selectedFarm.nLossPerHa, 1).toLocaleString()} kg/ha</td>
                                                </tr>
                                                <tr valign="top">
                                                    <td>N surplus</td>
                                                    <td>{utils.round(selectedFarm.nSurplus || 0, 1).toLocaleString()} kg/ha</td>
                                                </tr>
                                                <tr valign="top">
                                                    <td>NCE</td>
                                                    <td>{utils.round(selectedFarm.nce || 0, 1).toLocaleString()} %</td>
                                                </tr>
                                                {
                                                    // Oddly, we have to use a ternary operator here or React has a wobbly.
                                                    selectedFarm.maxNAppliedFromFertiliserToPastoralBlocks > 0 ? (
                                                        <>
                                                            <tr valign="top">
                                                                <th colSpan="2" className="u-white-space-normal">
                                                                    N applied to pastoral blocks from fertiliser
                                                                </th>
                                                            </tr>
                                                            <tr valign="top">
                                                                <td>Avg rate</td>
                                                                <td>{(selectedFarm.avgNAppliedFromFertiliserToPastoralBlocks || 0).toLocaleString()} kg/ha</td>
                                                            </tr>
                                                            <tr valign="top">
                                                                <td>Max rate</td>
                                                                <td>{(selectedFarm.maxNAppliedFromFertiliserToPastoralBlocks || 0).toLocaleString()} kg/ha</td>
                                                            </tr>
                                                        </>
                                                    ) : null
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="Table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th colSpan="2" className="u-bg-ploss u-textWhite">
                                                        Phosphorus
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr valign="top">
                                                    <td>Total P loss</td>
                                                    <td>{utils.round(selectedFarm.pLossTotal, 1).toLocaleString()} kg</td>
                                                </tr>
                                                <tr valign="top">
                                                    <td>P loss per ha</td>
                                                    <td>{utils.round(selectedFarm.pLossPerHa, 1).toLocaleString()} kg/ha</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="Table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th colSpan="2" className="u-bg-ghg u-textWhite">
                                                        GHG emissions
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr valign="top">
                                                    <td>Total GHG emissions</td>
                                                    <td>{utils.numeric.format(selectedFarm.ghgTotal / 1000, 1)} t CO2e/yr</td>
                                                </tr>
                                                <tr valign="top">
                                                    <td>GHG emissions per ha</td>
                                                    <td>{utils.numeric.format(selectedFarm.ghgPerHa, 1)} kg CO2e/ha/yr</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </PanelBody>
        </Panel>
    )
}
