import { Route } from "react-router-dom";
import * as utils from "common/utils";
import NotFound from "components/NotFound";
import Loader from "components/Loader";
import Page from "components/Page";
import PageBody from "components/PageBody";
import BlocksTab from "./Blocks/BlocksTab";
import GHGTab from "./GHG/GHGTab";
import SoilTab from "./SoilTests/SoilTab";
import Compare from "containers/BudgetHome/Reporting/Compare";
import WetlandsTab from "./Wetlands/WetlandsTab";
import CropsTab from "./Crops/CropsTab";
import AnimalsTab from "./Animals/AnimalsTab";
import SupplementsTab from "./Supplements/SupplementsTab";
import FertiliserTab from "./Fertiliser/FertiliserTab";
import IrrigationTab from "./Irrigation/IrrigationTab";
import EffluentTab from "./Effluent/EffluentTab";
import OverviewTab from "./Overview/OverviewTab";
import BudgetHomePageHead from "./BudgetHomePageHead";
import Benchmarking from "../Benchmarking/Benchmarking";
import { useAnalysis } from "containers/hooks";

export default function BudgetHome({ farm }) {
    const { isLoading: analysisIsLoading, data: analysis } = useAnalysis();

    if (analysisIsLoading) {
        return <Loader message="Loading..." />
    }

    if (!analysisIsLoading && !analysis) {
        return <NotFound />
    }

    const isFertiliserRecommendation = analysis?.type === "FertiliserRecommendation";
    const breadcrumbTitle = isFertiliserRecommendation ? "Fertiliser recommendations" : "Analyses";
    const breadcrumbLink = isFertiliserRecommendation ? `/app/farm/${farm?.id}/fertiliser-recommendations` : `/app/farm/${farm?.id}/analysis`;
    const breadcrumb = [{ name: farm?.displayName, to: `/app/farm/${farm?.id}` }, { name: breadcrumbTitle, to: breadcrumbLink }, { name: analysis?.name }];

    // Clone the analysis object to avoid mutating the original object
    const mutableAnalysisClone = utils.clone(analysis);

    const publication = (farm?.publications || []).find((p) => p.id === analysis?.publicationId);

    return (
        <Page>
            <BudgetHomePageHead breadcrumb={breadcrumb} />
            <input type="hidden" value="budgetHome" id="budgetHome" />
            {analysis?.class === "PublishedAnalysis" && (
                <PageBody>
                    <Route path="/app/farm/:farmId/analysis/:budgetId/overview" render={() => <OverviewTab farm={farm} analysis={analysis} publication={publication} />} />
                </PageBody>
            )}
            {analysis?.class !== "PublishedAnalysis" && (
                <PageBody>
                    <Route path="/app/farm/:farmId/analysis/:budgetId/soil" render={() => <SoilTab farm={farm} analysis={mutableAnalysisClone} />} />
                    <Route path="/app/farm/:farmId/analysis/:budgetId/drainage" render={() => <WetlandsTab farm={farm} analysis={mutableAnalysisClone} />} />
                    <Route path="/app/farm/:farmId/analysis/:budgetId/crops" render={() => <CropsTab farm={farm} analysis={mutableAnalysisClone} />} />
                    <Route path="/app/farm/:farmId/analysis/:budgetId/animals" render={() => <AnimalsTab farm={farm} analysis={mutableAnalysisClone} />} />
                    <Route path="/app/farm/:farmId/analysis/:budgetId/structures" render={() => <EffluentTab farm={farm} analysis={mutableAnalysisClone} />} />
                    <Route path="/app/farm/:farmId/analysis/:budgetId/supplements" render={() => <SupplementsTab farm={farm} analysis={mutableAnalysisClone} />} />
                    <Route path="/app/farm/:farmId/analysis/:budgetId/fertiliser" render={() => <FertiliserTab farm={farm} analysis={mutableAnalysisClone} />} />
                    <Route path="/app/farm/:farmId/analysis/:budgetId/irrigation" render={() => <IrrigationTab farm={farm} analysis={mutableAnalysisClone} />} />
                    <Route path="/app/farm/:farmId/analysis/:budgetId/ghg" render={() => <GHGTab farm={farm} analysis={mutableAnalysisClone} />} />
                    <Route path="/app/farm/:farmId/analysis/:budgetId/overview" render={() => <OverviewTab farm={farm} analysis={mutableAnalysisClone} />} />
                    <Route path="/app/farm/:farmId/analysis/:budgetId/compare" render={() => <Compare farm={farm} analysis={mutableAnalysisClone} />} />
                    <Route path="/app/farm/:farmId/analysis/:budgetId/benchmarking" render={() => <Benchmarking farm={farm} />} />
                    <Route path="/app/farm/:farmId/analysis/:budgetId" render={() => <BlocksTab farm={farm} analysis={mutableAnalysisClone} />} />
                </PageBody>
            )}
        </Page>
    )
}
