import ActionLink from "components/ActionLink";
import { Field } from "react-final-form";
import { useRefData } from "common/hooks";
import { useFarmTags } from "containers/Reporting/_hooks";
import { getFilterLabel } from "./_utils";

export default function FilterRow({ deleteRow, allowRemove, groupIndex, index, values, form, exclusions = [] }) {
    const refData = useRefData();
    const { reportFilters = [], reportFilterConditions = [] } = refData;
    const type = values.filterGroups[groupIndex].rules[index].type;
    const filterType = reportFilters.find((f) => f.type === type);
    const { data: farmTags = [] } = useFarmTags();
    const uniqueFarmTags = [...new Set(farmTags.flatMap((farm) => farm.tags))];
    const options = type === "Tag" ? uniqueFarmTags.map((tag) => ({ value: tag, text: tag })) : filterType?.options || [];
    const selectType = filterType?.controlType === "Select";
    const isLocked = values?.filterGroups[groupIndex]?.rules[index]?.locked;
    const filteredTypes = uniqueFarmTags.length === 0 ? [...reportFilters.filter((f) => f.type !== "Tag" && (!exclusions.includes(f.type) || isLocked))] : [...reportFilters.filter((f) => !exclusions.includes(f.type) || isLocked)];
    const showRemoveButton = allowRemove && !isLocked;

    const setDefaultForType = (e) => {
        const type = e.target.value;
        const filterType = reportFilters.find((f) => f.type === type);
        const condition = filterType?.conditions[0];
        const value = filterType.type === "Tag" ? uniqueFarmTags[0] : filterType?.options[0]?.value;

        form.change(`filterGroups[${groupIndex}].rules[${index}].type`, type);
        form.change(`filterGroups[${groupIndex}].rules[${index}].condition`, condition);
        form.change(`filterGroups[${groupIndex}].rules[${index}].values`, [value]);
    };

    return (
        <div className="FilterRow u-flex u-mb-md u-flexAlignItemsCenter">
            {index === 0 ? (
                <div className="RuleAndOr">Where</div>
            ) : (
                <>
                    {index === 1 ? (
                        <div className="RuleAndOr">
                            <Field
                                name={`filterGroups[${groupIndex}]operator`}
                                render={({ input, meta }) => (
                                    <select {...input} className="FilterRow-and">
                                        <option value="And">And</option>
                                        <option value="Or">Or</option>
                                    </select>
                                )}
                            />
                        </div>
                    ) : (
                        <div className="RuleAndOr">{values.filterGroups[groupIndex]?.operator === "And" ? "And:" : "Or:"}</div>
                    )}
                </>
            )}
            <div className="FilterRow-wrapper">
                <Field name={`filterGroups[${groupIndex}]rules[${index}]type`}>
                    {({ input, meta }) => (
                        <select {...input} className="FilterRow-select" onChange={setDefaultForType} disabled={isLocked}>
                            {filteredTypes.map((filter) => (
                                <option value={filter.type} key={filter.type}>
                                    {getFilterLabel(filter.type)}
                                </option>
                            ))}
                        </select>
                    )}
                </Field>
                <Field name={`filterGroups[${groupIndex}]rules[${index}]condition`}>
                    {({ input, meta }) => (
                        <select {...input} className="FilterRow-select" disabled={isLocked}>
                            {reportFilterConditions
                                .filter((c) => filterType && filterType.conditions.includes(c.value))
                                .map((condition) => (
                                    <option value={condition.value} key={condition.value}>
                                        {condition.text}
                                    </option>
                                ))}
                        </select>
                    )}
                </Field>
                {selectType && (
                    <Field name={`filterGroups[${groupIndex}]rules[${index}]values[0]`}>
                        {({ input, meta }) => (
                            <select {...input} className="FilterRow-select" disabled={isLocked}>
                                {options.map((value) => (
                                    <option value={String(value.value)} key={value.value}>
                                        {value.text}
                                    </option>
                                ))}
                            </select>
                        )}
                    </Field>
                )}
            </div>
            {showRemoveButton && (
                <div className="u-pl-sm">
                    <ActionLink className="IconLink--trash" onClick={deleteRow} id={`group_${groupIndex}_rule_${index}-remove`}>
                        Remove
                    </ActionLink>
                </div>
            )}
            {isLocked && (
                <div className="u-pl-sm">
                    <i className="icon icon-lock"></i>
                </div>
            )}
        </div>
    );
}
