import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import * as FormUtils from "common/FormUtils";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Grid, GridCell } from "components/Grid";
import { SelectField } from "components/FormFields";
import { useModal } from "common/hooks";
import { useAnalysis, useUpdateAnalysisAsync } from "containers/hooks";
import { useDatasets } from "./_hooks";
import { Spinner } from "components/Spinner";

export default function AddToDatasetModal({ farmId, analysisId, close }) {
    const { data: analysis } = useAnalysis(farmId, analysisId);
    const datasets = useDatasets();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    const validate = (formValues) => {
        const validation = {};

        validation.datasetId = FormUtils.validators.required(formValues.datasetId);

        return validation;
    };

    const submitAsync = async (formValues) => {
        try {
            const updatedAnalysis = {
                ...formValues,
                datasetId: formValues.datasetId === "remove" ? undefined : formValues.datasetId,
                datasetName: formValues.datasetId === "remove" ? undefined : datasets.find((d) => d.id === formValues.datasetId)?.name,
            };
            await updateAnalysisAsync(updatedAnalysis);
            close();
        } catch (ex) {
            return { [FORM_ERROR]: ex.message };
        }
    };

    const isLoading = !analysis || !datasets;

    let datasetOptions =
        !isLoading &&
        datasets.map((d) => {
            return { value: d.id, text: d.name };
        });
    !isLoading && datasetOptions.unshift({ value: "remove", text: "No dataset" });

    return isLoading ? (
        <Spinner />
    ) : (
        <Form initialValues={analysis} validate={validate} onSubmit={submitAsync}>
            {({ handleSubmit, submitting }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Update dataset for analysis" loading={isLoading} close={close} skinny fluid>
                            <ModalBody loading={isLoading}>
                                <Grid>
                                    <GridCell>
                                        <Field name="datasetId" label="Dataset" placeholder="Select a dataset" options={datasetOptions} required component={SelectField} />
                                    </GridCell>
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel-button" secondary disabled={submitting} onClick={close}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="save-button" submit primary waiting={submitting}>
                                        Save
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
}

export function useAddToDatasetModal(farmId, analysisId) {
    const [modal, openModal] = useModal(AddToDatasetModal);

    const openAddToDatasetModal = () => {
        const modalProps = {
            farmId,
            analysisId,
        };
        openModal(modalProps);
    };

    return [modal, openAddToDatasetModal];
}
