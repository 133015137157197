import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as utils from "common/utils";
import Page from "components/Page";
import PageBody from "components/PageBody";
import { Panel, PanelBody } from "components/Panel";
import TileActionsBar from "components/TileActionsBar";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import Alert from "components/Alert";
import ActionLink from "components/ActionLink";
import { ZendeskLink } from "components/Help";
import { getDataset, deleteRun, updateModelRun, getRun, updateCropsPreviousYears, exportDataset } from "./_actions";
import { useCopyDatasetAnalysisAsync, useDataset, useScienceSettings, useServerNotifications } from "./_hooks";
import { useDatasetModal } from "./DatasetModal";
import { useModelRunModal } from "./ModelRunModal";
import ModellingRun from "./ModellingRun";
import { useRemoveAnalysisFromDatasetAsync } from "containers/hooks";
import { useAuthContext, useConfirm, useOnline, useRefData } from "common/hooks";
import { httpClient } from "common/httpClient";
import BulkImportMapsLink from "containers/BudgetHome/Blocks/Modals/BulkImportMapsLink";
import BulkResetSMapDefaultSoilsLink from "containers/BudgetHome/SoilTests/BulkResetSMapDefaultSoilsLink";

export default function Dataset() {
    const [modified, setModified] = useState(new Date());
    const { datasetId, accountId: organisationId } = useParams();
    const isSystemAdmin = useAuthContext()?.isSystemAdmin;
    const { data: settings } = useScienceSettings();
    const dispatch = useDispatch();
    const { dataset, analyses, runs } = useDataset(datasetId, modified);
    const [openDatasetModal, datasetModal] = useDatasetModal();
    const serverNotification = useServerNotifications();
    const isLoading = useSelector((state) => state.dataset.isLoading) || !dataset;
    const hasProjectDetails = dataset && dataset.project && dataset.description;
    const referrer = organisationId ? `/app/admin/orgs/${organisationId}/datasets` : "/app/datasets";

    const _editDataset = () => {
        openDatasetModal(dataset, () => dispatch(getDataset(dataset.id)));
    };

    const info = 'This screen shows all farm analyses that are present within the dataset. A dataset is a collection of farm analyses that can be run through the model (a model run) with or without a selection of model parameter changes. The results of each model run are stored and model run results can be compared to see the effect on the specified model parameter change. Model run results at the analysis and block level can be downloaded into csv files. Turn on the "Quick tips" up top right of the screen for more help.';
    const help = (
        <>
            <p className="u-mb-sm">The sections within this page are listed below but more details of the purpose of each section is displayed under each section heading:</p>
            <ul>
                <li>
                    {" "}
                    <b>Failed analysis uploads section - </b>View the farm analyses that have failed to upload into the dataset and why they failed. If there are no failed uploads a message will be displayed stating this.
                </li>
                <li>
                    {" "}
                    <b>Analyses section- </b>Manage (add, edit, copy and delete) farm analyses in the dataset.
                </li>
                <li>
                    {" "}
                    <b>Model runs section- </b> Manage all model runs that have been executed on the dataset.
                </li>
            </ul>
        </>
    );
    const error = !dataset || hasProjectDetails ? undefined : "Edit the dataset to enter the required details before continuing";

    return (
        <>
            <Page>
                <PageBody>
                    {serverNotification && <Alert type="info" text={serverNotification.message} />}
                    <Panel title={dataset ? `Dataset: ${dataset.name}` : "Dataset"} info={info} help={help} error={error} referrer={referrer} iconClassName="IconLink--equalizer" actions={<ZendeskLink title="Page help" url="https://support.overseer.org.nz/hc/en-us/articles/4414695497369" className="IconLink--new-tab u-font-white u-hover-white" />} loading={isLoading}>
                        <PanelBody loading={!dataset}>
                            {dataset && (
                                <ul className="DataWidthTable FarmTable">
                                    <li data-width="30">
                                        <div className="FarmTable-keyValuePair">
                                            <div className="FarmTable-keyValue">
                                                <span className="FarmTable-key">Dataset name</span>
                                                <span className="FarmTable-value">
                                                    <span className="FarmTable-value-link u-link a" onClick={_editDataset}>
                                                        <span>{dataset.name}</span>
                                                        <span className="icon icon-edit"></span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-width="30">
                                        <div className="FarmTable-keyValuePair">
                                            <div className="FarmTable-keyValue">
                                                <span className="FarmTable-key">Project name</span>
                                                <span className="FarmTable-value">{dataset.project ? dataset.project : <span className="IconLink--alert u-textError">Project name required</span>}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-width="30">
                                        <div className="FarmTable-keyValuePair">
                                            <div className="FarmTable-keyValue">
                                                <span className="FarmTable-key">Dataset description</span>
                                                <span className="FarmTable-value">{dataset.description ? dataset.description : <span className="IconLink--alert u-textError">Dataset description required</span>}</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            )}
                            {(hasProjectDetails || isSystemAdmin) && (
                                <>
                                    <DatasetLoadFailures dataset={dataset} />
                                    <DatasetAnalyses dataset={dataset} analyses={analyses} settings={settings} setModified={setModified} />
                                    <DatasetModelRuns dataset={dataset} runs={runs} analyses={analyses} settings={settings} />
                                </>
                            )}
                        </PanelBody>
                    </Panel>
                </PageBody>
            </Page>
            {datasetModal}
        </>
    );
}

function DatasetLoadFailures({ dataset }) {
    const online = useOnline();

    if (!dataset) return null;

    const hasUploadFailures = Object.keys(dataset.analysisLoadFailures).length > 0;

    return (
        <Panel title="Failed analysis uploads" className="u-mt-md" stickyHeaderName="dataset_Summary" midBlue>
            <PanelBody grey>
                {online && (
                    <>
                        {hasUploadFailures ? (
                            <div>
                                <Alert type="info" className="u-mb-sm" text="All farm analyses that failed to upload into the dataset are shown here with a message stating what went wrong on the upload" />
                                <div className="FarmTable_wrapper">
                                    <div className="FarmTable-supplementary">
                                        <div className="Table u-mt-md">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>{hasUploadFailures ? "Analysis loading errors" : "No analysis in this dataset loaded with errors"}</th>
                                                        <th>Error description</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Object.keys(dataset.analysisLoadFailures).map((k) => (
                                                        <tr key={k}>
                                                            <td>{k}</td>
                                                            <td>{dataset.analysisLoadFailures[k]}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <Alert type="success" text="There are no errors uploading any farm analyses into this dataset" className="u-mb-0" />
                        )}
                    </>
                )}
            </PanelBody>
        </Panel>
    );
}

function DatasetAnalyses({ dataset, analyses, settings, setModified }) {
    const online = useOnline();
    const isLoading = useSelector((state) => state.dataset.isLoading);
    const dispatch = useDispatch();
    const [error, setError] = useState();
    const [cropMessage, setCropMessage] = useState();
    const copyAnalysisAsync = useCopyDatasetAnalysisAsync();
    const removeAnalysisFromDatasetAsync = useRemoveAnalysisFromDatasetAsync();
    const confirm = useConfirm();

    const _copyAnalysis = (analysis) => async (e) => {
        e.stopPropagation();
        await copyAnalysisAsync({ ...analysis, datasetId: dataset.id });
    };

    const _removeAnalysis = (analysis) => async (e) => {
        e.stopPropagation();
        confirm("Are you sure you want to remove this analysis from the dataset?", async () => {
            await removeAnalysisFromDatasetAsync({ ...analysis });
            setModified(new Date());
        });
    };

    const _downloadAnalysis = (analysisId) => (e) => {
        e.stopPropagation();
        getAnalysis(analysisId)
            .then((results) => {
                if (results) {
                    const analysis = results;
                    const { soils = [] } = results;
                    soils.forEach((s) => {
                        if (s.sibling && s.sibling.smapReference) {
                            s.sibling = { smapReference: s.sibling.smapReference, description: s.sibling.description, downloadedAt: s.sibling.downloadedAt, level: s.sibling.level };
                        }
                    });
                    var blob = new Blob([JSON.stringify(analysis, null, 2)], { type: "application/json" });
                    var url = URL.createObjectURL(blob);
                    var link = document.createElement("a");
                    link.setAttribute("href", url);
                    link.setAttribute("download", `${analysis.name}.json`);
                    document.body.appendChild(link);
                    link.click();
                }
            })
            .catch((results) => {
                setError(`An error ocurred while downloading analysis. Http status ${results.status}: ${results.xhr && results.xhr.statusText}`);
            });
    };

    const _downloadAllAnalyses = () => {
        dispatch(exportDataset(dataset.id))
            .then((results) => {
                if (results) {
                    var blob = new Blob([JSON.stringify(results, null, 2)], { type: "application/json" });
                    var url = URL.createObjectURL(blob);
                    var link = document.createElement("a");
                    link.setAttribute("href", url);
                    link.setAttribute("download", `${dataset.project}-${dataset.name}.json`);
                    document.body.appendChild(link);
                    link.click();
                }
            })
            .catch((results) => {
                this.setState({ errorMessage: "An error ocurred while downloading dataset. Http status " + results.status + ": " + (results.xhr && results.xhr.statusText) });
            });
    };

    const _updateCropsPreviousYears = () => {
        confirm(`This will update crop blocks for all farms in this dataset. Are you sure you wish to continue?`, () => {
            dispatch(updateCropsPreviousYears(dataset.id, true))
                .then(() => setCropMessage("Crop details successfully updated"))
                .catch((results) => {
                    setError(results.status ? `An error ocurred while downloading dataset. Http status ${results.status}: ${results.xhr && results.xhr.statusText}` : `Error processing blocks: ${results.message}`);
                });
        });
    };

    if (!dataset) return null;

    const info = "All farm analyses that are in the dataset are shown here. Each analyses can be copied, viewed, downloaded and deleted below. Lysimeter files can also be uploaded into the dataset here.";
    const help = (
        <>
            <p className="u-mb-sm">Analyses can be added to a dataset here by either uploading xml/ovr legacy files or copying an existing analyses in the dataset. Analyses can also be added to a dataset from within a farm by adding the analyses to the dataset from the list of analyses in a farm.</p>
            <ul>
                <li>
                    <b>Create lysimeter inputs button - </b>Along with lysimeter file uploads, lysimeter data can also be entered manually. Entered lysimeter data will also appear as an analysis in the analyses list below.{" "}
                </li>
                <li>
                    <b>Enter measured values button - </b>Measured values can be entered for monthly drainage for each block within an analyses of a dataset.
                </li>
            </ul>
            <p className="u-mb-sm">The following functions are available for each analysis within the dataset:</p>
            <ul>
                <li>
                    <b>Copy analysis - </b>Copies the analysis into the dataset. The copied analysis is created in it's own new farm.
                </li>
                <li>
                    <b>View lysimeter - </b>Views the lysimeter data of the analysis in the dataset.
                </li>
                <li>
                    <b>View analysis - </b>Opens the analysis in another tab to view the analysis details and the ability to edit the analysis.
                </li>
                <li>
                    <b>Delete analysis - </b>Deletes the analysis from the dataset. Model run results will remain for the deleted analyses but will not appear in any subsequent model run executions.
                </li>
            </ul>
        </>
    );

    return (
        <>
            <Panel title="Analyses" info={info} help={help} error={error} loading={isLoading} className="u-mt-md" stickyHeaderName="dataset_Analyses" midBlue>
                <PanelBody grey>
                    {cropMessage && <Alert type="success" text={cropMessage} />}
                    <TileActionsBar className="u-mb-sm">
                        <ul className="ActionsBar-links">
                            {online && (!settings || analyses.length >= settings.maxAnalyses) && (
                                <li>
                                    <span className="UnpaidSubscription">{"Reached limit of " + (settings ? settings.maxAnalyses : "") + " analyses in this dataset."}</span>
                                </li>
                            )}
                            {settings && settings.allowedFunctions && settings.allowedFunctions.includes("Lysimeter") && analyses.length < settings.maxAnalyses && (
                                <li>
                                    <Link id="create-analysis" to={`/app/datasets/${dataset.id}/farm/${uuidv4()}/analysis/${uuidv4()}/new`} className="IconLink--arrow-plus">
                                        Create lysimeter inputs
                                    </Link>
                                </li>
                            )}
                            {online && settings && settings.allowedFunctions && settings.allowedFunctions.includes("Measurements") && (
                                <li>
                                    <Link id="enter-measured-values" to={`/app/datasets/${dataset.id}/measurements`} className="IconLink--edit">
                                        Enter measured values
                                    </Link>
                                </li>
                            )}
                            {online && settings && settings.allowedFunctions && settings.allowedFunctions.includes("UserDefinedCrops") && (
                                <li>
                                    <ActionLink id="update-crop-years" onClick={_updateCropsPreviousYears} className="u-link IconLink--edit">
                                        Update crop years
                                    </ActionLink>
                                </li>
                            )}
                            {online && settings && settings.allowedFunctions && settings.allowedFunctions.includes("Download") && (
                                <li>
                                    <ActionLink id="download-all-analyses" onClick={_downloadAllAnalyses} className="IconLink--download">
                                        Download analyses
                                    </ActionLink>
                                </li>
                            )}
                            {settings?.allowedFunctions?.includes("BulkImportMaps") && (
                                <li>
                                    <BulkImportMapsLink analyses={analyses} />
                                </li>
                            )}
                            {settings?.allowedFunctions?.includes("BulkResetSMapDefaults") && (
                                <li>
                                    <BulkResetSMapDefaultSoilsLink analyses={analyses} />
                                </li>
                            )}
                        </ul>
                    </TileActionsBar>
                    {analyses.length > 0 ? (
                        <div className="Table u-bg-white">
                            <SortableTable data={analyses} defaultSortCriteria={{ fieldName: "farmName", fieldType: "string" }}>
                                {({ data, sortCriteria, handleSort }) => {
                                    return (
                                        <table>
                                            <thead>
                                                <tr>
                                                    <SortableTableHeader label="Farm name" fieldName="farmName" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                    <SortableTableHeader label="Analysis name" fieldName="name" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                    <th colSpan="5"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((analysis) => {
                                                    return (
                                                        <tr key={analysis.id}>
                                                            <td>{analysis.farmName}</td>
                                                            <td>{analysis.name}</td>
                                                            {settings && analyses.length < settings.maxAnalyses && (
                                                                <td>
                                                                    <ActionLink id={`copy-analysis-${analysis.id}`} className="u-link IconLink--copy" onClick={_copyAnalysis(analysis)}>
                                                                        Copy analysis
                                                                    </ActionLink>
                                                                </td>
                                                            )}
                                                            {settings && settings.allowedFunctions && settings.allowedFunctions.includes("Lysimeter") && (
                                                                <td>
                                                                    <Link id={`view-lysimeter-${analysis.id}`} className="u-link IconLink--edit" to={`/app/datasets/${dataset.id}/farm/${analysis.farmId}/analysis/${analysis.id}`}>
                                                                        View lysimeter
                                                                    </Link>
                                                                </td>
                                                            )}
                                                            <td>
                                                                <Link className="u-link IconLink--edit" target="_blank" to={`/app/farm/${analysis.farmId}/analysis/${analysis.id}`}>
                                                                    View analysis
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <ActionLink id={`download-analysis-${analysis.id}`} onClick={_downloadAnalysis(analysis.id)} className="u-link IconLink--download">
                                                                    Download analysis
                                                                </ActionLink>
                                                            </td>
                                                            <td>
                                                                <ActionLink id={`delete-analysis-${analysis.id}`} onClick={_removeAnalysis(analysis)} className="IconLink--trash" title="Remove from dataset" />
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    );
                                }}
                            </SortableTable>
                        </div>
                    ) : (
                        <Alert type="error" text="The are currently no analyses within this dataset. Analyses can be added to a dataset here by either uploading xml/ovr legacy files or copying an existing analyses in the dataset. Analyses can also be added to a dataset from within a farm by adding the analyses to the dataset from the list of analyses in a farm." className="u-mb-0" />
                    )}
                </PanelBody>
            </Panel>
        </>
    );
}

function DatasetModelRuns({ dataset, runs, analyses, settings }) {
    const online = useOnline();
    const isLoading = useSelector((state) => state.dataset.isLoading);
    const refData = useRefData();
    const dispatch = useDispatch();
    const [selectedRuns, setSelectedRuns] = useState([]);
    const [openModelRunModal, modelRunModal] = useModelRunModal(dataset, analyses);
    const confirm = useConfirm();

    const launchModelRun =
        (modelType, isSensitivity = false) =>
        () => {
            openModelRunModal(modelType, isSensitivity, () => {
                dispatch(getDataset(dataset.id));
                setSelectedRuns([]);
            });
        };

    const _selectRun = (run) => (e) => {
        e.preventDefault();

        if (!run.analyses || run.analyses.length === 0) {
            dispatch(getRun(dataset.id, run.id));
        }

        setSelectedRuns((prevState) => {
            const first = prevState.length > 0 && prevState[0];
            const second = prevState.length === 2 && prevState[1];

            const selectingFirst = !first;
            if (selectingFirst) return [run];

            const deselectingFirst = first && !second && run.id === first.id;
            if (deselectingFirst) return [];

            const selectingSensitivityRun = run.type === "Sensitivity" || (first && first.type === "Sensitivity");
            if (selectingSensitivityRun) return [run];

            const selectingLysimeterRun = run.modelType === "Lysimeter" || (first && first.modelType === "Lysimeter");
            if (selectingLysimeterRun) return [run];

            const selectingAnimalMERun = run.modelType === "AnimalME" || (first && first.modelType === "AnimalME");
            if (selectingAnimalMERun) return [run];

            const selectingSecond = first && !second && run.id !== first.id;
            if (selectingSecond) return [first, run];

            const deselectingSecond = second && run.id === second.id;
            if (deselectingSecond) return [first];

            const deselectingFirstWithASecond = second && run.id === first.id;
            if (deselectingFirstWithASecond) return [second]; // Second becomes the first

            return [run];
        });
    };

    const _deleteRun = (runId) => (e) => {
        e.stopPropagation();
        confirm("Are you sure you want to delete this model run?", () => {
            dispatch(deleteRun(dataset.id, runId));
            setSelectedRuns((prevState) => prevState.filter((r) => r.id !== runId));
        });
    };

    const _toggleBaseline = (run, toggle) => (e) => {
        e.stopPropagation();
        run.baseline = toggle;
        run.datasetId = dataset.id;
        dispatch(updateModelRun(dataset.id, run));
    };

    const _getModelTypeText = (modelType) => {
        if (modelType === "AnimalME") return "Animal ME";
        return modelType;
    };

    const _getRunTypeText = (runType) => {
        if (runType === "UseDefaultClimateAndSMapSoil") return "Use default climate and S-Map soils";
        if (runType === "UseDefaultClimate") return "Use default climate";
        if (runType === "UseDefaultSMapSoils") return "Use default S-Map soils";
        if (runType === "SoilUpdate") return "Soil Update";
        if (runType === "ModelTest") return "Model Test";
        return runType;
    };

    if (!dataset) return null;

    const info = (
        <>
            <p>A model run is when all analyses within a dataset are run through the model or sub models. Model runs contain the results of the model execution for all analyses in the dataset at the time of the run.</p>
            <p>When a model run is created and executed a table of model runs will be shown. Select a model run within this table to view it's results. This information is stored independently of the farm analysis and so will not change even if the analysis is changed or removed from the dataset.</p>
            <p>You can compare two model runs by selecting them both from the list. One of the runs (the first one selected) will be highlighted in red and the second highlighted in blue. This will compare each analysis and block and show where the nutrient budget results differ as shown below. Model runs can be compared against other model runs by selecting two model runs.</p>
            <p>For more help on 'Model runs' turn on the 'Quick tips' up top right of the screen.</p>
        </>
    );
    const help = (
        <>
            <p className="u-mb-sm">Create a new model by selecting one of the following links. When creating a model run you can enter parameters you want to use in specific parts of the model to use in place of what the model currently uses.</p>
            <ul>
                <li>
                    <b>Full model run - </b>Runs all analyses in the dataset through the current Overseer model version using specified parameters and results stored.
                </li>
                <li>
                    <b>Lysimeter model run - </b>Runs all analyses in the dataset through the lysimeter model and results stored. TOVERIFY
                </li>
                <li>
                    <b>Animal ME model run - </b>Runs all analyses in the dataset through the current Overseer "Animal ME" sub model and outputs of the sub model stored.
                </li>
                <li>
                    <b>Sensitivity analysis - </b>
                </li>
            </ul>
            <p>Each model run has an engine version against which it was run. As Overseer Ltd releases new versions of the model, you will be able to run the dataset against that new version and compare the results. This assumes that the analyses within the dataset have not changed in that time.</p>
            <p className="u-mb-sm">
                <b>Run types</b> - There are three different types of run types when a model run is executed;
            </p>
            <ul>
                <li>
                    <b>Imported - </b>If files that contained results were uploaded into this dataset, an "Imported" model run is created. This model run contains the results from those imported files.
                </li>
                <li>
                    <b>Modelled - </b>The results of analyses run through the model where using model parameters or not is a "Modelled" run type. Every model run apart from a "Sensitivity" model run should create one of these.
                </li>
                <li>
                    <b>Measured - </b>The results of analyses using the measured values entered instead of the modelled values when executing the model. Every model run apart from a "Sensitivity" model run should create one of these if measured values are entered against analyses in the dataset.
                </li>
                <li>
                    <b>Sensitivity - </b>The results (only in csv format) of all combinations of sensitivity attributes that are run against all analyses in the dataset. This type cannot be compared with another model run.
                </li>
            </ul>
        </>
    );

    const firstRun = selectedRuns.length > 0 && runs.find((r) => r.id === selectedRuns[0].id);
    const secondRun = selectedRuns.length === 2 && runs.find((r) => r.id === selectedRuns[1].id);

    return (
        <>
            <Panel title="Model runs" info={info} help={help} loading={isLoading} waiting={isLoading} className="u-mt-md" stickyHeaderName="dataset_ModelRuns" midBlue>
                <PanelBody grey>
                    {online && analyses.length > 0 && (
                        <TileActionsBar className="u-mb-sm">
                            <ul className="ActionsBar-links">
                                {settings && runs.length < settings.maxModelRuns && (
                                    <li>
                                        <ActionLink onClick={launchModelRun("Full")} id="add-model-run" className="IconLink--arrow-plus">
                                            Full model run
                                        </ActionLink>
                                    </li>
                                )}
                                {settings && settings.allowedFunctions && settings.allowedFunctions.includes("Lysimeter") && runs.length < settings.maxModelRuns && (
                                    <li>
                                        <ActionLink onClick={launchModelRun("Lysimeter")} id="add-lysimeter-run" className="IconLink--arrow-plus">
                                            Lysimeter model run
                                        </ActionLink>
                                    </li>
                                )}
                                {settings && settings.allowedFunctions && settings.allowedFunctions.includes("AnimalME") && runs.length < settings.maxModelRuns && (
                                    <li>
                                        <ActionLink onClick={launchModelRun("AnimalME")} id="add-me-run" className="IconLink--arrow-plus">
                                            Animal ME model run
                                        </ActionLink>
                                    </li>
                                )}
                                {settings && settings.allowedFunctions && settings.allowedFunctions.includes("SensitivityAnalysis") && runs.length < settings.maxModelRuns && (
                                    <li>
                                        <ActionLink onClick={launchModelRun("Full", true)} id="add-sens-run" className="IconLink--arrow-plus">
                                            Sensitivity analysis
                                        </ActionLink>
                                    </li>
                                )}
                                {(!settings || runs.length >= settings.maxModelRuns) && (
                                    <li>
                                        <span className="UnpaidSubscription">{"Reached limit of " + (settings ? settings.maxModelRuns : "") + " model runs so cannot create new runs."}</span>
                                    </li>
                                )}
                            </ul>
                        </TileActionsBar>
                    )}
                    {online && analyses.length === 0 && <Alert className="u-mt-0" type="error" text="The are currently no analyses within this dataset. Analyses can be added to a dataset here by either uploading xml/ovr legacy files or copying an existing analyses in the dataset. Analyses can also be added to a dataset from within a farm by adding the analyses to the dataset from the list of analyses in a farm." />}
                    {online && analyses.length > 0 && runs.length === 0 && <Alert className="u-mt-0 u-mb-sm" type="error" text="There currently has not been any model runs executed on all of the analyses within this dataset." />}
                    {runs.length > 0 && (
                        <div className="Table u-bg-white u-mb-sm">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Select</th>
                                        <th>Description</th>
                                        <th>Date run</th>
                                        <th>Model</th>
                                        <th>Run type</th>
                                        <th>Baseline?</th>
                                        <th>Engine version</th>
                                        <th>Number of analyses</th>
                                        <th>Criteria</th>
                                        {online && <th></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {runs &&
                                        runs
                                            .sort((a, b) => new Date(a.dateRun) - new Date(b.dateRun))
                                            .map((r, i) => {
                                                const redChecked = firstRun && r.id === firstRun.id;
                                                const blueChecked = secondRun && r.id === secondRun.id;
                                                const className = redChecked ? "Table-tr--red is-selected" : blueChecked ? "Table-tr--blue is-selected" : "";
                                                const criteria = r.criteria && r.criteria.analysisType + " | " + (r.criteria.blockType ? r.criteria.blockType : "Any block type") + " | " + (r.criteria.enterpriseType ? r.criteria.enterpriseType : "Any enterprise");
                                                return (
                                                    <tr key={i} className={className} onClick={_selectRun(r)}>
                                                        <td>
                                                            <div className="Field">
                                                                <label className="Checkbox">
                                                                    <input className="Checkbox-input" checked={redChecked || blueChecked} readOnly type="checkbox" />
                                                                    <span className="Checkbox-label"></span>
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>{r.description}</td>
                                                        <td>{utils.dateLong(r.dateRun)}</td>
                                                        <td>{_getModelTypeText(r.modelType)}</td>
                                                        <td>{_getRunTypeText(r.type)}</td>
                                                        <td className="u-textCenter">
                                                            {online && settings && settings.allowedFunctions && settings.allowedFunctions.includes("Testing") && (
                                                                <ActionLink id={`baseline-${r.id}`} onClick={_toggleBaseline(r, !r.baseline)}>
                                                                    {r.baseline ? "Yes" : "No"}
                                                                </ActionLink>
                                                            )}
                                                        </td>
                                                        <td>{r.engineVersion}</td>
                                                        <td>{r.analysisCount}</td>
                                                        <td>{criteria}</td>
                                                        <td>
                                                            <div className="u-flex">
                                                                <ActionLink id={`delete-dataset-${r.id}`} onClick={_deleteRun(r.id)} className="IconLink--trash" title="Delete" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {runs && runs.length > 0 && !firstRun && !secondRun && <Alert type="error" text={`Select a model run above to view its results. You can compare two model runs by selecting them both from the list.`} />}
                    {firstRun && secondRun && <Alert type="warning" text={`You are currently comparing the "${firstRun.description} - ${firstRun.type}" model run (highlighted in red) with the "${secondRun.description} - ${secondRun.type}" model run. (highlighted in blue). If any model parameters were used, in either model runs, these are will be populated in the model run parameters table below in the red and blue columns. The results of both of the model runs can be compared against each other and viewed within the light blue panels below. Results can be also be downloaded into CSV files within the light blue panels`} />}
                    {!secondRun && firstRun && <Alert type="warning" text={`You have selected the "${firstRun.description} - ${firstRun.type}" model run which is higlighted in red. If any model parameters were used during the model run, these are will be populated in the model run parameters table below in the red column. The results of this model run can be viewed within the light blue panels below. Results can be also be downloaded into CSV files within the light blue panels`} />}
                    {firstRun && (
                        <div className="u-border u-bg-white u-p-10">
                            <ModellingRun online={online} dataset={dataset} analyses={analyses} run1={firstRun} run2={secondRun} refData={refData} isLoading={isLoading} />
                        </div>
                    )}
                </PanelBody>
            </Panel>
            {modelRunModal}
        </>
    );
}

async function getAnalysis(analysisId) {
    return await httpClient.get(`v1/analysis-details/${analysisId}`);
}
