import React from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { usePortalModal } from "common/effects";
import * as FormUtils from "common/FormUtils";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Grid, GridCell } from "components/Grid";
import TextField from "components/FormFields/TextField";
import TextareaField from "components/FormFields/TextareaField";
import { createDataset, updateDataset } from "./_actions";

export default function DatasetModal({ dataset, onSave, close }) {
    const dispatch = useDispatch();

    const validate = async (dataset) => {
        const validation = {};

        validation.name = FormUtils.validators.required(dataset.name);
        validation.name = validation.name || FormUtils.validators.maxLength(100)(dataset.name);

        validation.project = FormUtils.validators.required(dataset.project);
        validation.project = validation.project || FormUtils.validators.maxLength(100)(dataset.project);

        validation.description = FormUtils.validators.required(dataset.description);
        validation.description = validation.description || FormUtils.validators.maxLength(255)(dataset.description);

        return validation;
    };

    const submit = async (dataset) => {
        if (dataset.isNew) {
            const submitResult = await dispatch(createDataset(dataset))
                .then(() => {
                    close();
                    onSave && onSave(dataset);
                })
                .catch((error) => ({ [FORM_ERROR]: error }));
            return submitResult;
        } else {
            const submitResult = await dispatch(updateDataset(dataset))
                .then(() => {
                    close();
                    onSave && onSave(dataset);
                })
                .catch((error) => ({ [FORM_ERROR]: error }));
            return submitResult;
        }
    };

    return (
        <Form initialValues={dataset} validate={validate} onSubmit={submit}>
            {({ values, handleSubmit, submitting, submitError }) => {
                const disableSave = submitting;
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title={`${values.isNew ? "Add" : "Edit"} dataset`} close={close} submitting={submitting} fluid>
                            <ModalBody info="Enter the dataset details." error={submitError}>
                                <Grid>
                                    <GridCell>
                                        <Field name="id" type="hidden" component="input" />
                                        <Field name="name" label="Dataset name" required component={TextField} />
                                        <Field name="project" label="Project" placeholder="Enter the name of the project that this dataset is for" required component={TextField} />
                                        <Field name="description" label="Description" placeholder="Enter a description of the project and the dataset" required component={TextareaField} />
                                    </GridCell>
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="submit" submit primary waiting={submitting} disabled={disableSave}>
                                        Save
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
}

export function useDatasetModal() {
    const { openModal, getModal } = usePortalModal();

    const openDatasetModal = (dataset, onSave) => {
        const modalProps = {
            dataset: dataset || { id: uuidv4(), isNew: true },
            onSave,
        };
        openModal("DatasetModal", modalProps);
    };

    const datasetModal = getModal("DatasetModal", DatasetModal);

    return [openDatasetModal, datasetModal];
}
