import "./PreviewImportedMapModal.css";
import { useCallback, useEffect, useState } from "react";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import * as geoJsonUtils from "common/geoJsonUtils";
import Spinner from "components/Spinner/Spinner";
import { useGoogleApi, useGoogleMap } from "components/Map/hooks";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import ActionLink from "components/ActionLink";
import MapZoomButton from "components/Map/MapZoomButton";
import MapErrorLabels from "components/Map/MapErrorLabels";
import { BlockLabelsLayerToggle, DrawnIrrigatorsLayerToggle } from "components/Map";
import { useFeatureTracker, FEATURES } from "components/FeatureTracker/FeatureTracker";
import { useUpdateAnalysisAsync } from "containers/hooks";
import { useOnline, useModal } from "common/hooks";

const MIN_DRAWN_AREA = 0.1;
const MIN_DRAWN_AREA_ERROR_MESSAGE = "Must be greater than 0.1 ha";
const OVERLAP_SELF_ERROR_MESSAGE = "Must not overlap itself";

export default function PreviewImportedMapModal({ farm, analysis, importedGeoJson, source, close }) {
    const online = useOnline();
    const { map, mapId, setMapRef } = useGoogleMap();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    const [geoJson, syncMapDataToGeoJson] = useAnalysisGeoJson(analysis);
    const featureTracker = useFeatureTracker(FEATURES.IMPORT_MAP, "Preview " + source, farm);
    const validatePolygons = useCallback(
        (mapData) => {
            mapData.toGeoJson((mapGeoJson) => {
                mapGeoJson.features.forEach((feature) => {
                    // RULE 1 - must not overlap itself.
                    let error = geoJsonUtils.selfIntersects(feature) ? OVERLAP_SELF_ERROR_MESSAGE : undefined;

                    // RULE 2 - must be min size.
                    if (!error) {
                        const drawnArea = geoJsonUtils.area(feature);
                        if (drawnArea < MIN_DRAWN_AREA) error = MIN_DRAWN_AREA_ERROR_MESSAGE;
                    }
                    mapData.getFeatureById(feature.id).setProperty("error", error);
                });
                syncMapDataToGeoJson(mapData);
            });
        },
        [syncMapDataToGeoJson]
    );

    const showDrawnIrrigatorsLayerToggle = geoJson?.features?.some((f) => f.properties.layer === geoJsonUtils.LAYER.Irrigator) === true;

    const mapData = useMapData(map, geoJson);
    const importError = useImportedGeoJson(mapData, importedGeoJson, geoJson, validatePolygons);
    useMapStyle(mapData);
    useOnPolygonHover(mapData);
    useOnPolygonClick(mapData, validatePolygons);
    useOnPolygonEdit(mapData, validatePolygons);

    const deleteSelectedPolygons = useCallback(
        (mapData) => {
            mapData.forEach((feature) => {
                if (feature.getProperty("selected")) {
                    mapData.remove(feature);
                }
            });
            mapData.setDrawingMode(null);
            syncMapDataToGeoJson(mapData);
        },
        [syncMapDataToGeoJson]
    );

    const deleteInvalidPolygons = useCallback(
        (mapData) => {
            mapData.forEach((feature) => {
                if (feature.getProperty("error")) {
                    mapData.remove(feature);
                }
            });
            mapData.setDrawingMode(null);
            syncMapDataToGeoJson(mapData);
        },
        [syncMapDataToGeoJson]
    );

    const validate = async () => {
        const validation = {};
        validation.polygons = geoJson && geoJson.features.filter((f) => f.properties.error).map((f) => ({ id: f.id, error: f.properties.error }));
        return validation;
    };

    const submit = async () => {
        // Merge features into the analysis.
        analysis.features = geoJson.features
            .filter((feature) => ["Block", "UnassignedLand", "Irrigator"].includes(feature.properties.layer))
            .map((feature) => {
                // Only save the feature properties we won't to store.
                const properties = geoJsonUtils.sanitizeProperties(feature.properties);

                // Make sure blockName property is removed from any non-block features.
                if (!properties.blockId) {
                    delete properties.blockName;
                }

                return { ...feature, properties };
            });
        analysis.isDrawn = analysis.features.length > 0;
        analysis.hasUnassignedLand = analysis.features.some((feature) => feature.properties.layer === "UnassignedLand");

        try {
            await updateAnalysisAsync(analysis);
            close();
            featureTracker.track("Save " + source);
        } catch (ex) {
            return { [FORM_ERROR]: ex.message };
        }
    };

    const mapError = importError || (!online && "Overseer is currently offline. Mapping is unavailable.");
    const mapLoading = online && !geoJson && !mapError;

    return (
        <Form initialValues={{}} validate={validate} onSubmit={submit}>
            {({ errors, handleSubmit, submitting, submitError }) => {
                const hasInvalidPolygons = errors && errors.polygons && errors.polygons.length > 0;
                const hasSelectedPolygons = geoJson && geoJson.features.some((f) => f.properties.selected);
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Preview imported map" close={close} waiting={mapLoading || submitting} full>
                            <ModalBody info="Use this screen to edit or delete any unassigned imported land parcels before saving the map. Unassigned land parcels are shown on the map in pink. Land parcels that are already assigned to an existing block are shown in green. Click on one or more of the unassigned land parcels to select the ones you wish to edit/delete." error={submitError}>
                                <div className="ActionsBar ActionsBar--super u-mt-sm u-mb-sm">
                                    <div className="ActionsBar-right">
                                        <ul className="ActionsBar-links">
                                            {hasInvalidPolygons && (
                                                <li>
                                                    <ActionLink id="delete-invalid" className="IconLink--trash u-textError" onClick={() => deleteInvalidPolygons(mapData)}>
                                                        Delete invalid land
                                                    </ActionLink>
                                                </li>
                                            )}
                                            <li>
                                                <ActionLink id="delete-selected" className="IconLink--trash" onClick={() => deleteSelectedPolygons(mapData)} disabled={!hasSelectedPolygons}>
                                                    Delete selected land
                                                </ActionLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="importMapWrapper">
                                    {mapLoading && (
                                        <div className="Tile-body-message">
                                            <Spinner dark />
                                            <p className="lead">Loading map...</p>
                                        </div>
                                    )}
                                    {mapError && !mapLoading && (
                                        <div className="Tile-body-message u-textError">
                                            <i className="icon icon--md icon-alert" />
                                            <p className="lead">{mapError}</p>
                                        </div>
                                    )}
                                    <div className={`map ${hasInvalidPolygons ? "map__invalid" : ""} ${hasSelectedPolygons ? "map__selected" : ""} ${mapLoading || mapError ? "u-hidden" : ""}`} ref={setMapRef} />
                                    <BlockLabelsLayerToggle mapData={mapData} mapId={mapId} blocks={analysis.blocks} geoJson={geoJson} />
                                    {showDrawnIrrigatorsLayerToggle && <DrawnIrrigatorsLayerToggle mapData={mapData} mapId={mapId} />}
                                    <MapZoomButton mapData={mapData} mapId={mapId} />
                                    <MapErrorLabels map={map} geoJson={geoJson} />
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="submit" submit primary waiting={submitting} disabled={mapError || submitting}>
                                        Save map
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
}

export function usePreviewImportedMapModal(farm, analysis) {
    const [modal, openModal] = useModal(PreviewImportedMapModal);

    const openPreviewImportedMapModal = (importedGeoJson, source) => {
        const modalProps = {
            farm,
            analysis,
            importedGeoJson,
            source,
        };
        openModal(modalProps);
    };

    return [modal, openPreviewImportedMapModal];
}

function useAnalysisGeoJson(analysis) {
    const [geoJson, setGeoJson] = useState();

    const syncMapDataToGeoJson = (mapData) => mapData.toGeoJson((geoJson) => setGeoJson(geoJson));

    useEffect(() => {
        if (!geoJson) {
            const analysisGeoJson = { type: "FeatureCollection", features: [] };
            if (analysis.features && analysis.features.length > 0) {
                analysisGeoJson.features = analysis.features.reduce((results, feature) => {
                    // Flatten and hide irrigators by default.
                    const flattenedFeatures = geoJsonUtils.flatten(feature, ["Irrigator"]);
                    return [...results, ...flattenedFeatures];
                }, []);
            }
            setGeoJson(analysisGeoJson);
        }
    }, [analysis.features, geoJson]);

    return [geoJson, syncMapDataToGeoJson];
}

function useImportedGeoJson(mapData, importedGeoJson, existingGeoJson, onMapDataChange) {
    const google = useGoogleApi();
    const [imported, setImported] = useState(false);
    const [importError, setImportError] = useState();

    useEffect(() => {
        if (!imported && !importError && mapData && importedGeoJson && existingGeoJson && onMapDataChange) {
            const newGeoJson = { type: "FeatureCollection" };

            newGeoJson.features = importedGeoJson.features.reduce((results, f) => {
                // Only import polygons/multipolygons and do not import irrigators.
                if (f.properties.layer !== "Irrigator" && ["Polygon", "MultiPolygon", "GeometryCollection"].includes(f.geometry.type)) {
                    const flattenedFeatures = geoJsonUtils.flatten(f);
                    // Do not import duplicates.
                    flattenedFeatures.forEach((importedFeature) => {
                        const isDuplicateFeature = existingGeoJson.features.some((existingFeature) => geoJsonUtils.similar(importedFeature, existingFeature)) || results.some((alreadyImportedFeature) => geoJsonUtils.similar(importedFeature, alreadyImportedFeature));
                        if (!isDuplicateFeature) {
                            results.push(importedFeature);
                        }
                    });
                }
                return results;
            }, []);

            try {
                mapData.addGeoJson(newGeoJson);
                onMapDataChange(mapData);
                geoJsonUtils.zoomTo(mapData);
                setImported(true);
            } catch (ex) {
                setImportError("The imported file has invalid polygons. Please fix the file and try again.");
            }
        }
    }, [google, mapData, importedGeoJson, existingGeoJson, onMapDataChange, imported, importError]);

    return importError;
}

function useMapData(map, geoJson) {
    const google = useGoogleApi();
    const [mapData, setMapData] = useState();

    useEffect(() => {
        if (google && map && geoJson && !mapData) {
            const data = new google.maps.Data({ map });
            data.addGeoJson(geoJson);
            setMapData(data);
        }
    }, [google, map, geoJson, mapData]);

    return mapData;
}

function useMapStyle(mapData) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && !runOnce) {
            mapData.setStyle((feature) => {
                const imported = feature.getProperty("imported");
                const hovered = feature.getProperty("hovered");
                const selected = feature.getProperty("selected");
                const visible = feature.getProperty("visible");
                const error = feature.getProperty("error");
                const layer = feature.getProperty("layer") || "Irrigator";
                const slope = feature.getProperty("slope");
                const layerColor = geoJsonUtils.getMapLayerColor(layer, slope);

                let zIndex = 0;
                if (hovered || selected) {
                    zIndex = 3;
                } else if (imported) {
                    zIndex = 2;
                } else if (layer === "Block") {
                    zIndex = 1;
                }

                return {
                    strokeColor: error ? "red" : layerColor,
                    strokeWeight: hovered || error ? 4 : 3,
                    fillColor: imported || layer === "Irrigator" || layer === "Slope" ? layerColor : "white",
                    fillOpacity: 0.6,
                    editable: selected,
                    zIndex,
                    visible,
                };
            });
            setRunOnce(true);
        }
    }, [mapData, runOnce]);
}

function useOnPolygonHover(mapData) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && !runOnce) {
            // Add 'mouseover' event for hover styling.
            mapData.addListener("mouseover", ({ feature }) => {
                if (feature.getProperty("imported")) {
                    feature.setProperty("hovered", true);
                }
            });

            // Add 'mouseout' event for un-hover styling.
            mapData.addListener("mouseout", ({ feature }) => {
                if (feature.getProperty("imported")) {
                    feature.setProperty("hovered", false);
                }
            });

            setRunOnce(true);
        }
    }, [mapData, runOnce]);
}

function useOnPolygonClick(mapData, onMapDataChange) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && onMapDataChange && !runOnce) {
            mapData.addListener("click", ({ feature }) => {
                if (feature.getProperty("imported")) {
                    feature.setProperty("selected", !feature.getProperty("selected"));
                    onMapDataChange(mapData);
                }
            });
            setRunOnce(true);
        }
    }, [mapData, onMapDataChange, runOnce]);
}

function useOnPolygonEdit(mapData, onMapDataChange) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && onMapDataChange && !runOnce) {
            mapData.addListener("setgeometry", ({ feature }) => {
                feature.toGeoJson((geoJsonFeature) => {
                    const drawnArea = geoJsonUtils.area(geoJsonFeature);
                    feature.setProperty("drawnArea", drawnArea);
                    onMapDataChange(mapData);
                });
            });
            setRunOnce(true);
        }
    }, [mapData, onMapDataChange, runOnce]);
}
