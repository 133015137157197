import React, { useState, useRef, useEffect } from "react";
import { Panel } from "components/Panel";
import ActionLink from "components/ActionLink";
import FilterModal from "./FilterModal";
import { useRefData } from "common/hooks";
import { getFilterLabel } from "./_utils";

export default function Filters({ onFilterChanged, filter, defaultRule, exclusions }) {
    const [showFilter, setShowFilter] = useState(false);
    const refData = useRefData();
    const { reportFilters = [] } = refData;
    const modalRef = useRef(null);
    const linkRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            const outsideModal = modalRef.current && !modalRef.current.contains(event.target);
            const outsideLink = linkRef.current && !linkRef.current.contains(event.target);

            if (outsideLink && outsideModal) {
                setShowFilter(false);
            }
        };

        if (showFilter) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showFilter]);

    const toggleFilter = () => {
        setShowFilter(!showFilter);
    };
    const applyFilters = (filters) => {
        setShowFilter(false);
        onFilterChanged(filters);
    };

    const formatSelection = (rule, option) => {
        const value = option ? option.text : rule.values[0];
        const filterLabel = getFilterLabel(rule.type);
        let formattedValue = "";
        switch (rule.type) {
            case "Year":
                formattedValue = rule.condition === "Equals" ? `Year: ${value}` : rule.condition === "IsSelectedYearOrLatest" ? `Year: ${value} or latest` : `Year: ${value} or within 5 years`;
                break;
            case "Enterprise":
            case "Crop":
            case "Tag":
                formattedValue = rule.condition === "Contains" ? `${filterLabel}: Contains ${value}` : `${filterLabel}: Not contains ${value}`;
                break;
            case "Status":
            case "FarmType":
            case "Region":
                formattedValue = rule.condition === "Equals" ? `${filterLabel}: ${value}` : `${filterLabel}: Not ${value}`;
                break;
            case "DairyGrassFedFeed":
            case "DairyTimeWithAccessToPasture":
                if (rule.condition === "GreaterThanOrEqual") {
                    formattedValue = `${filterLabel}: >= ${value}`;
                } else if (rule.condition === "LessThan") {
                    formattedValue = `${filterLabel}: < ${value}`;
                } else {
                    formattedValue = `${filterLabel}: ${value}`;
                }
                break;
            default:
                formattedValue = `${filterLabel}: ${value}`;
        }

        return <>{formattedValue}</>;
    };

    return (
        <Panel title="Filters">
            <div className="u-print-border u-bg-grey u-p-10">
                <div className="FilterPanel u-flex u-flexAlignItemsCenter u-flex-wrap">
                    <div ref={linkRef}>
                        <ActionLink className="IconLink--equalizer u-mr-md Filter-btn" id="add-filter-link" onClick={() => toggleFilter()}>
                            <span className="u-ml-sm">{showFilter ? "Close filter" : "Open filter"}</span>
                        </ActionLink>
                    </div>
                    {filter &&
                        filter.filterGroups.map((group, groupIndx) => {
                            return (
                                <div className="u-flex u-flexAlignItemsCenter" key={groupIndx}>
                                    {groupIndx > 0 && <div className="u-ml-sm u-mr-md u-mb-sm u-mt-sm u-textLower u-textBold">{filter.operator}</div>}
                                    <div className={`u-flex u-flexAlignItemsCenter ${filter.filterGroups.length > 1 ? "Container-pill" : "u-mb-sm u-mt-sm "}`}>
                                        {group.rules.map((rule, ruleIndx) => {
                                            const filterType = reportFilters?.find((f) => f.type === rule.type);
                                            const filterOption = filterType?.options?.find((o) => o.value === rule.values[0]);
                                            return (
                                                <div className="u-flex u-flexAlignItemsCenter" key={ruleIndx}>
                                                    {ruleIndx > 0 && <div className="u-ml-sm u-textLower u-textBold">{group.operator}</div>}
                                                    <span id={`group_${groupIndx}-rule_${ruleIndx}`} className={`u-info-pill  u-ml-sm`}>
                                                        {formatSelection(rule, filterOption)}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}

                    {showFilter && <FilterModal viewModel={filter} applyFilters={applyFilters} defaultRule={defaultRule} modalRef={modalRef} exclusions={exclusions} />}
                </div>
            </div>
        </Panel>
    );
}
