import React from "react";

const PrintDisclaimer = () => {
    return (
        <div>
            <p style={{ textAlign: "justify" }}>
                <b>DISCLAIMER:</b> This Report has been prepared solely for registered users of Overseer who download it from the Overseer application, and have accepted Overseer's Terms of Use. While reasonable efforts have been made to ensure that the Overseer software model used to prepare this Report keeps up with the latest scientific research, Overseer Limited gives no warranties, representation or guarantees, express or implied in relation to the quality, reliability, accuracy and/or fitness for any purpose of the Report. Overseer Limited expressly disclaims and assumes no liability whatsoever arising directly or indirectly from the use of, or reliance on this Report.
            </p>
            <p style={{ textAlign: "justify" }}>
                <b>COPYRIGHT:</b> With the exception of user-supplied data, this Report is &copy; 2025 Overseer Limited.All rights reserved. You may copy and distribute this Report in its entirety, as long as you do not mislead anyone as to its origin or implications, and provided you do not remove or alter the disclaimer above or this copyright notice.
            </p>
            <div className="u-flex u-flexJustifyCenter u-mt-sm">
                <div>Overseer &copy; 2025 | v{process.env.REACT_APP_VERSION.split("+")[0]}</div>
            </div>
        </div>
    );
};
export default PrintDisclaimer;
