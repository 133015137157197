import { Panel, PanelBody } from "components/Panel";
import { ZendeskLink } from "components/Help";
import { StockingRate, EnterpriseMonthResults, PastureAndDiet, DairyGrassFedStandardReport } from "containers/BudgetHome/Animals/Reports";
import { useAnalysisResults } from "containers/BudgetHome";

export default function AnimalReports({ farm, analysis }) {
    const { isStale } = useAnalysisResults(analysis);

    return (
        <Panel title="Animal reports" info="Contains information about animal RSU by block and the dietary requirements for all enterprises." moreInfo={<ZendeskLink title="Page help" url="https://support.overseer.org.nz/hc/en-us/articles/900002144106" className="IconLink--new-tab u-font-green" />} loading={isStale} waiting={isStale} className="u-mt-lg" midBlue>
            <PanelBody loading={isStale}>
                <StockingRate analysis={analysis} />
                <EnterpriseMonthResults analysis={analysis} />
                <PastureAndDiet farm={farm} analysis={analysis} />
                <DairyGrassFedStandardReport analysis={analysis} />
            </PanelBody>
        </Panel>
    )
}
