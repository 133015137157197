import { useEffect, useState } from "react";
import { compose, withAppContext, withActions } from "containers/HOC";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import * as FormUtils from "common/FormUtils";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Grid, GridCell } from "components/Grid";
import TextField from "components/FormFields/TextField";
import CheckboxField from "components/FormFields/CheckboxField";
import { createAnalysisUpgradeRun } from "../_actions";
import { useRefData } from "common/hooks";

const CreateUpgradeRunModal = ({ createAnalysisUpgradeRun, close }) => {
    const refData = useRefData();
    const [initialValues, setInitialValues] = useState({ modelFeatures: [] });

    useEffect(() => {
        const modelFeatures = (refData.modelFeatures || []).filter((feature) => !["ANIMAL_WEIGHT_GAIN_SINGLE_MONTH", "WEIGHT_GAIN_WHEN_NO_ANIMALS"].includes(feature.value)).map((feature) => ({ name: feature.value, selected: false }));
        setInitialValues({ modelFeatures });
    }, [refData]);

    const toggleRunModel = (form) => (checked) => {
        if (checked) form.change("runModel", true);
    };

    const toggleModelFeatures = (form) => (checked) => {
        if (!checked) form.change("modelFeatures", initialValues.modelFeatures);
    };

    const toggleSaveBudgetBaseAsCopy = (form) => (checked) => {
        if (checked) form.change("saveBudgetBaseAsCopy", false);
    };

    const toggleUpdateBudgetBase = (form) => (checked) => {
        if (checked) form.change("updateBudgetBase", false);
    };

    const toggleCropsOnly = (form) => (checked) => {
        if (checked) {
            form.change("cropsOnly", false);
            form.change("exportedMaizeSilageOnly", false);
            form.change("dairyOnly", false);
            form.change("dairyGoatsOnly", false);
            form.change("outdoorPigsOnly", false);
            form.change("withSnowfallOnly", false);
            form.change("withDdgOnly", false);
        }
    };

    const toggleNonCropsOnly = (form) => (checked) => {
        if (checked) {
            form.change("nonCropsOnly", false);
            form.change("exportedMaizeSilageOnly", false);
            form.change("dairyOnly", false);
            form.change("dairyGoatsOnly", false);
            form.change("outdoorPigsOnly", false);
            form.change("withSnowfallOnly", false);
            form.change("withDdgOnly", false);
        }
    };

    const toggleExportedMaizeSilageOnly = (form) => (checked) => {
        if (checked) {
            form.change("cropsOnly", false);
            form.change("nonCropsOnly", false);
            form.change("dairyOnly", false);
            form.change("dairyGoatsOnly", false);
            form.change("outdoorPigsOnly", false);
            form.change("withSnowfallOnly", false);
            form.change("withDdgOnly", false);
        }
    };

    const toggleDairyOnly = (form) => (checked) => {
        if (checked) {
            form.change("cropsOnly", false);
            form.change("nonCropsOnly", false);
            form.change("exportedMaizeSilageOnly", false);
            form.change("dairyGoatsOnly", false);
            form.change("outdoorPigsOnly", false);
            form.change("withSnowfallOnly", false);
            form.change("withDdgOnly", false);
        }
    };

    const toggleDairyGoatsOnly = (form) => (checked) => {
        if (checked) {
            form.change("cropsOnly", false);
            form.change("nonCropsOnly", false);
            form.change("exportedMaizeSilageOnly", false);
            form.change("dairyOnly", false);
            form.change("outdoorPigsOnly", false);
            form.change("withSnowfallOnly", false);
            form.change("withDdgOnly", false);
        }
    };

    const toggleOutdoorPigsOnly = (form) => (checked) => {
        if (checked) {
            form.change("cropsOnly", false);
            form.change("nonCropsOnly", false);
            form.change("exportedMaizeSilageOnly", false);
            form.change("dairyOnly", false);
            form.change("dairyGoatsOnly", false);
            form.change("withSnowfallOnly", false);
            form.change("withDdgOnly", false);
        }
    };

    const toggleWithSnowfallOnly = (form) => (checked) => {
        if (checked) {
            form.change("cropsOnly", false);
            form.change("nonCropsOnly", false);
            form.change("dairyOnly", false);
            form.change("dairyGoatsOnly", false);
            form.change("outdoorPigsOnly", false);
            form.change("exportedMaizeSilageOnly", false);
            form.change("withDdgOnly", false);
        }
    };

    const toggleWithDdgOnly = (form) => (checked) => {
        if (checked) {
            form.change("cropsOnly", false);
            form.change("nonCropsOnly", false);
            form.change("dairyOnly", false);
            form.change("dairyGoatsOnly", false);
            form.change("outdoorPigsOnly", false);
            form.change("exportedMaizeSilageOnly", false);
            form.change("withSnowfallOnly", false);
        }
    };

    return (
        <Form initialValues={initialValues} validate={validate} onSubmit={submit(createAnalysisUpgradeRun, close)}>
            {({ form, values, handleSubmit, submitting, submitError, dirtySinceLastSubmit }) => {
                const error = !dirtySinceLastSubmit && submitError;
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Create upgrade run" close={close} submitting={submitting} wide fluid>
                            <ModalBody error={error}>
                                <Grid>
                                    <GridCell className="u-width2of6">
                                        <Field name="name" label="Name" placeholder="Give the run a unique name" required component={TextField} />
                                    </GridCell>
                                    <GridCell className="u-width1of6">
                                        <Field name="batchSize" label="Batch size" placeholder="1" type="number" component={TextField} />
                                    </GridCell>
                                    <GridCell className="u-width2of6">
                                        <Field name="farmId" label="Farm id" placeholder="Create a run for a specific farm" component={TextField} />
                                    </GridCell>
                                    <GridCell className="u-width1of6">
                                        <Field name="limit" label="Max number of analyses" placeholder="No limit" type="number" component={TextField} />
                                    </GridCell>
                                    <GridCell className="u-mt-lg u-width1of3">
                                        <h5 className="u-mb-md">Dataset filters</h5>
                                        <Field name="activeSubscriptionsOnly" label="Active subscriptions only" vertical type="checkbox" component={CheckboxField} />
                                        <br />
                                        <Field name="excludePublications" label="Exclude publications" vertical type="checkbox" component={CheckboxField} />
                                        <br />
                                        <Field name="yearEndsOnly" label="Year ends only" vertical type="checkbox" component={CheckboxField} />
                                        <br />
                                        <Field name="latestYearEndOnly" label="Latest year end only" vertical type="checkbox" component={CheckboxField} />
                                        <br />
                                        <Field name="includeDeletedYearEnds" label="Include deleted year ends" vertical type="checkbox" component={CheckboxField} />
                                        <br />
                                        <Field name="cropsOnly" label="Only analyses with crops/fodder crops" vertical onChange={toggleNonCropsOnly(form)} type="checkbox" component={CheckboxField} />
                                        <br />
                                        <Field name="nonCropsOnly" label="Exclude analyses with crops/fodder crops" vertical onChange={toggleCropsOnly(form)} type="checkbox" component={CheckboxField} />
                                        <br />
                                        <Field name="exportedMaizeSilageOnly" label="Only analyses with exported maize silage" vertical onChange={toggleExportedMaizeSilageOnly(form)} type="checkbox" component={CheckboxField} />
                                        <br />
                                        <Field name="dairyOnly" label="Only analyses with dairy" vertical onChange={toggleDairyOnly(form)} type="checkbox" component={CheckboxField} />
                                        <br />
                                        <Field name="dairyGoatsOnly" label="Only analyses with dairy goats" vertical onChange={toggleDairyGoatsOnly(form)} type="checkbox" component={CheckboxField} />
                                        <br />
                                        <Field name="outdoorPigsOnly" label="Only analyses with outdoor pigs" vertical onChange={toggleOutdoorPigsOnly(form)} type="checkbox" component={CheckboxField} />
                                        <br />
                                        <Field name="withSnowfallOnly" label="Only analyses with snowfall" vertical onChange={toggleWithSnowfallOnly(form)} type="checkbox" component={CheckboxField} />
                                        <br />
                                        <Field name="withDdgOnly" label="Only analyses with 'Dairy goat feed (DDG)'" vertical onChange={toggleWithDdgOnly(form)} type="checkbox" component={CheckboxField} />
                                        <br />
                                        <Field name="analysisSummaryOnly" label="Analysis summary types only" vertical type="checkbox" component={CheckboxField} />
                                        <br />
                                        <Field name="applyEcoPond" label="Apply EcoPond" vertical type="checkbox" component={CheckboxField} />
                                        <br />
                                        {false && <Field name="farmsOnly" label="Farm upgrade" vertical type="checkbox" component={CheckboxField} />}
                                    </GridCell>
                                    <GridCell className="u-mt-lg u-width1of3">
                                        <h5 className="u-mb-md">Run options</h5>
                                        <Field name="runDataFixes" label="Run data fixes" vertical type="checkbox" component={CheckboxField} />
                                        <br />
                                        <Field name="useModelledSlope" label="Write system topography" vertical type="checkbox" component={CheckboxField} />
                                        <br />
                                        <Field name="removeUserEnteredSnowfall" label="Remove user entered snowfall" vertical onChange={toggleRunModel(form)} type="checkbox" component={CheckboxField} />
                                        <br />
                                        <Field name="useModelledDistanceFromCoast" label="Set modelled distance from coast" vertical onChange={toggleRunModel(form)} type="checkbox" component={CheckboxField} />
                                        <br />
                                        <Field name="runModel" label="Run model" vertical onChange={toggleModelFeatures(form)} type="checkbox" disabled={values.useModelledSlope} component={CheckboxField} />
                                        <br />
                                        <Field name="refreshAnalysisSummary" label="Refresh analysis summary" vertical type="checkbox" component={CheckboxField} />
                                        <br />
                                        <Field name="updateBudgetBase" label="Save budget base" vertical onChange={toggleSaveBudgetBaseAsCopy(form)} type="checkbox" component={CheckboxField} />
                                        <br />
                                        <Field name="includeBlockResults" label="Include block results" vertical type="checkbox" component={CheckboxField} />
                                        <br />
                                        {false && <Field name="saveBudgetBaseAsCopy" label="Save budget base as copy" vertical onChange={toggleUpdateBudgetBase(form)} type="checkbox" component={CheckboxField} />}
                                    </GridCell>
                                    {values.modelFeatures && values.modelFeatures.length > 0 && (
                                        <GridCell className="u-mt-lg u-width1of3">
                                            <h5 className="u-mb-md">Model features</h5>
                                            {values.modelFeatures.map((feature, i) => (
                                                <>
                                                    <Field key={feature.name} name={`modelFeatures[${i}].selected`} label={feature.name} disabled={!values.runModel} vertical type="checkbox" component={CheckboxField} />
                                                    <br />
                                                </>
                                            ))}
                                        </GridCell>
                                    )}
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="submit" submit primary disabled={submitting}>
                                        Create
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
};

const validate = (formValues) => {
    const validation = {};
    validation.name = FormUtils.validators.required(formValues.name);
    validation.name = validation.name || FormUtils.validators.maxLength(50)(formValues.name);
    return validation;
};

const submit = (createAnalysisUpgradeRun, closeModal) => async (formValues) => {
    const modelFeatures = formValues.modelFeatures.filter((feature) => feature.selected === true).map((feature) => feature.name);
    const request = { ...formValues, modelFeatures };
    const submitResult = await createAnalysisUpgradeRun(request)
        .then(closeModal)
        .catch((error) => ({ [FORM_ERROR]: error }));
    return submitResult;
};

export default compose(withAppContext, withActions({ createAnalysisUpgradeRun }))(CreateUpgradeRunModal);
