import { useState, useEffect } from "react";
import { useDebounce } from "common/hooks";
import * as geoJsonUtils from "common/geoJsonUtils";

export function useMapLayerToggleControl(mapData, mapId, layerId, maxZoom = 0, initialShowLayer = true) {
    const [showLayer, setShowLayer] = useState(initialShowLayer);
    const bounds = useMapBounds(mapData, maxZoom);

    useEffect(() => {
        if (mapData && mapId && window.google) {
            const map = mapData.getMap();
            if (map) {
                const controls = map.controls[window.google.maps.ControlPosition.TOP_RIGHT];
                const controlId = `map-layer-toggle-${mapId}-${layerId}`;
                const controlIndex = controls.getArray().findIndex((c) => c.id === controlId);
                const controlAlreadyExists = controlIndex !== -1;
                const control = controlAlreadyExists ? controls.getAt(controlIndex) : document.createElement("div");

                const layerIconCssClass = geoJsonUtils.getMapLayerIconCssClass(layerId);
                const layerColor = geoJsonUtils.getMapLayerColor(layerId);

                const enabled = bounds;
                if (enabled) {
                    const displayText = geoJsonUtils.getMapLayerDisplayText(layerId);
                    control.title = showLayer ? `Hide ${displayText}` : `Show ${displayText}`;

                    control.className = "mapButton mapButton--right";
                    const borderColor = showLayer ? layerColor : "none";
                    control.innerHTML = `<i class="icon ${layerIconCssClass}" style="border: 3px solid ${borderColor}; border-radius: 3px;"></i>`;
                } else {
                    const titleText = geoJsonUtils.getMapLayerTitleText(layerId);
                    control.title = `${titleText} not available at this zoom level`;

                    control.className = "mapButton mapButton--right mapButton--disabled";
                    control.innerHTML = `<i class="icon ${layerIconCssClass}" style="border: 3px solid none; border-radius: 3px;"></i>`;
                }

                if (!controlAlreadyExists) {
                    control.id = controlId;

                    // Attach a click event listener
                    control.addEventListener("click", () => {
                        setShowLayer((prevState) => !prevState);
                    });

                    // Add the button to the map's controls
                    map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(control);
                }
            }
        }
    }, [mapData, mapId, layerId, maxZoom, bounds, showLayer]);

    return showLayer && !!bounds;
}

export function useHideShowMapLayer(mapData, layerId, showLayer) {
    useEffect(() => {
        if (mapData) {
            mapData.forEach((feature) => {
                if (feature.getProperty("layer") === layerId) {
                    feature.setProperty("visible", showLayer);
                }
            });
        }
    }, [mapData, layerId, showLayer]);
}

export function useMapBounds(mapData, maxZoom = 0) {
    const [bounds, setBounds] = useState(null);

    // Debounce the bounds to prevent excessive activity
    const debouncedBounds = useDebounce(bounds, 500);

    useEffect(() => {
        if (mapData && window.google) {
            const map = mapData.getMap();
            if (map) {
                // Function for setting bounds on change
                const handleBoundsChange = () => {
                    const zoom = map.getZoom();
                    if (zoom > maxZoom) {
                        const newBounds = map.getBounds();
                        if (newBounds) {
                            setBounds(newBounds.toJSON());
                        }
                    } else {
                        setBounds(null);
                    }
                };

                // Set initial bounds
                handleBoundsChange();

                // Wire up bounds change listener
                const listener = map.addListener("bounds_changed", handleBoundsChange);

                // Cleanup listener on unmount
                return () => {
                    if (listener && window?.google?.maps?.event) {
                        window.google.maps.event.removeListener(listener);
                    }
                };
            }
        }
    }, [mapData, maxZoom]);

    return debouncedBounds;
}
