import { Link } from "react-router-dom";
import TabAlertIcon from "./TabAlertIcon";
import { useAnalysisResults } from "./hooks";

const _renderIfBlocksCompleted = (analysis) => analysis.hasCompletedBlocks;

const _tabs = [
    { id: 0, title: "Blocks", route: "", icon: "blocks" },
    { id: 1, title: "Soil", route: "soil", icon: "soil", renderPredicate: _renderIfBlocksCompleted },
    { id: 2, title: "Drainage", category: "Wetlands", route: "drainage", icon: "wetlands", renderPredicate: _renderIfBlocksCompleted },
    { id: 3, title: "Pasture/Crops", category: "Crops", route: "crops", renderPredicate: _renderIfBlocksCompleted },
    { id: 4, title: "Animals", route: "animals", renderPredicate: _renderIfBlocksCompleted },
    { id: 5, title: "Structures/Effluent", category: "Effluent", route: "structures", renderPredicate: _renderIfBlocksCompleted },
    { id: 6, title: "Supplements", route: "supplements", renderPredicate: _renderIfBlocksCompleted },
    { id: 7, title: "Fertiliser", route: "fertiliser", renderPredicate: _renderIfBlocksCompleted },
    { id: 8, title: "Irrigation", route: "irrigation", renderPredicate: _renderIfBlocksCompleted },
    { id: 9, title: "GHG", route: "ghg", renderPredicate: _renderIfBlocksCompleted },
    { id: 10, title: "Reports", route: "overview", category: "Overview", renderPredicate: _renderIfBlocksCompleted },
];

export const TAB = {
    BLOCKS: _tabs[0],
    SOIL: _tabs[1],
    WETLANDS: _tabs[2],
    CROPS: _tabs[3],
    ANIMALS: _tabs[4],
    EFFLUENT: _tabs[5],
    SUPPLEMENTS: _tabs[6],
    FERTILISER: _tabs[7],
    IRRIGATION: _tabs[8],
    GHG: _tabs[9],
    OVERVIEW: _tabs[10],
};

export default function PageTabs({ farm, analysis, tab, tabs = [] }) {
    const { data } = useAnalysisResults(analysis);
    const noResults = !data;

    return (
        <div className="Tile u-pos-rel u-print-noborder darkblue">
            <ul className="SectionTabs u-print-none">
                {_tabs.map((t) => {
                    const isActive = t.id === tab.id;
                    const shouldRender = (tabs.length === 0 || tabs.includes(t)) && (!t.renderPredicate || t.renderPredicate(analysis) === true);
                    const to = `/app/farm/${farm.id}/analysis/${analysis.id}/${t.route}`;

                    return (
                        shouldRender && (
                            <li key={t.id} className={`SectionTabs-item ${isActive ? " is-active" : ""} ${!isActive && t.route === "overview" ? (noResults ? " is-reports-inactive" : "is-reports") : ""}`}>
                                <Link className={`SectionTabs-item-icon--${t.icon || t.route}`} to={to} id={`budget-home-tab-${t.route}`}>
                                    <span className="SectionTabs-item-name">{t.title}</span>
                                    <TabAlertIcon analysis={analysis} category={t.category || t.title} />
                                </Link>
                            </li>
                        )
                    );
                })}
            </ul>
        </div>
    );
}
