import { Link } from "react-router-dom";
import * as utils from "common/utils";
import * as icons from "common/icons";
import Calendar, { calendarMonths } from "components/Calendar";
import CropCalendar from "components/CropCalendar";
import * as domain from "common/domain";
import * as cropUtils from "./_utils";
import ActionLink from "components/ActionLink";
import { HelpTooltip, helpText } from "components/Help";
import { useOnline, useRefData } from "common/hooks";
import { useBlockResults } from "containers/BudgetHome";

export default function BlockListCard({ farm, analysis, block, onDelete }) {
    const online = useOnline();
    const refData = useRefData();
    const { data: blockResults } = useBlockResults(analysis, block);

    const { messages = [] } = analysis;
    const alertIcon = online && messages.find((m) => m.category === "Crops" && m.entityType === "Block" && m.entityId === block.id && m.severity === "Error") ? "error" : undefined;
    const blockType = utils.valueToText(refData.blockTypes, block.type);
    const calendarData = _getCalendarData(online, farm, analysis, block, blockType, refData);
    const emptyCrop = block.isProductive && calendarData.length === 0 && block.type !== domain.BlockType.ProductiveOutdoorPigs;
    const yieldData = (block.fruit && block.fruit.cropType && refData && refData.fruitTypes && refData.fruitTypes[block.fruit.cropType]) || {};
    const blockTitle = _getBlockTitle(block, refData, emptyCrop || alertIcon);
    let { crops = [] } = block;
    const { cropGrowthCurves = [] } = block;
    const hasFodder = block.type === domain.BlockType.ProductivePasture && analysis.blocks.filter((b) => b.type === domain.BlockType.FodderCrop && b.blockIds.find((i) => i === block.id));
    const isSeed = utils.priorLandUseSeed(block);

    return (
        <div className="FarmTable_wrapper">
            {emptyCrop && (
                <ul className="DataWidthTable FarmTable">
                    {blockTitle}
                    <li data-width="md-grow xl-grow" className="FarmTable-link">
                        <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/crops/${blockType.toLowerCase()}/${block.id}`} className="IconLink--arrow-plus" id={`${block.id}_add`}>
                            Enter {blockType} details
                        </Link>
                    </li>
                </ul>
            )}
            {(block.type === domain.BlockType.ProductiveOutdoorPigs || (!block.isProductive && block.type !== domain.BlockType.FodderCrop)) && (
                <ul className="DataWidthTable FarmTable">
                    {blockTitle}
                    <li data-width="md-grow xl-grow" className="FarmTable-link">
                        <span>Crop data is not required for this block</span>
                    </li>
                </ul>
            )}
            {block.isProductive && calendarData.length > 0 && (
                <ul className="DataWidthTable FarmTable">
                    {blockTitle}

                    {blockResults?.animals?.pastureGrowth && (
                        <li data-width="md-25 xl-15">
                            <div className="FarmTable-keyValuePair">
                                <div className="FarmTable-keyValue">
                                    <span className="FarmTable-key">
                                        Pasture growth
                                        <HelpTooltip tip={helpText.pastureGrowth} />
                                    </span>
                                    <span className="FarmTable-value">{utils.numeric.format(blockResults.animals.pastureGrowth.value)} {blockResults.animals.pastureGrowth.unit.notation}</span>
                                </div>
                            </div>
                        </li>
                    )}
                    {blockResults?.animals?.pastureIntake && (
                        <li data-width="md-25 xl-15">
                            <div className="FarmTable-keyValuePair">
                                <div className="FarmTable-keyValue">
                                    <span className="FarmTable-key">
                                        Pasture intake
                                        <HelpTooltip tip={helpText.pastureIntake} />
                                    </span>
                                    <span className="FarmTable-value">{utils.numeric.format(blockResults.animals.pastureIntake.value)} {blockResults.animals.pastureIntake.unit.notation}</span>
                                </div>
                            </div>
                        </li>
                    )}
                    {blockResults?.animals?.pastureUtilisation && (
                        <li data-width="md-25 xl-15">
                            <div className="FarmTable-keyValuePair">
                                <div className="FarmTable-keyValue">
                                    <span className="FarmTable-key">Pasture utilisation</span>
                                    <span className="FarmTable-value">{utils.numeric.format(blockResults.animals.pastureUtilisation.value)}{blockResults.animals.pastureUtilisation.unit.notation}</span>
                                </div>
                            </div>
                        </li>
                    )}
                    {blockResults?.animals?.supplementsRemoved && (
                        <li data-width="md-25 xl-15">
                            <div className="FarmTable-keyValuePair">
                                <div className="FarmTable-keyValue">
                                    <span className="FarmTable-key">Supplements removed</span>
                                    <span className="FarmTable-value">{utils.numeric.format(blockResults.animals.supplementsRemoved.value)} {blockResults.animals.supplementsRemoved.unit.notation}</span>
                                </div>
                            </div>
                        </li>
                    )}
                    {hasFodder.length > 0 && (
                        <li data-width="md-25 xl-10">
                            <div className="FarmTable-keyValuePair">
                                <div className="FarmTable-keyValue">
                                    <span className="FarmTable-key">Fodder crop</span>
                                    <span className="FarmTable-value">True</span>
                                </div>
                            </div>
                        </li>
                    )}
                    {block.fruit && block.fruit.productYield > 0 && yieldData && (
                        <li data-width="md-25 xl-15">
                            <div className="FarmTable-keyValuePair">
                                <div className="FarmTable-keyValue">
                                    <span className="FarmTable-key">Yield</span>
                                    <span className="FarmTable-value">{block.fruit.productYield + " " + yieldData.unitsDescription}</span>
                                </div>
                            </div>
                        </li>
                    )}
                </ul>
            )}
            {calendarData.length > 0 && <div className="FarmTable-supplementary">{crops.length > 0 || isSeed ? <CropCalendar data={calendarData} growthCurve={cropGrowthCurves} analysis={analysis} block={block} /> : <Calendar data={calendarData} />}</div>}
            {calendarData.length > 0 && [domain.BlockType.ProductiveCrop, domain.BlockType.FodderCrop].includes(block.type) && (
                <div className="ActionsBar">
                    <div className="ActionsBar-right">
                        <ul className="ActionsBar-links">
                            {onDelete !== undefined && (
                                <li>
                                    <ActionLink onClick={onDelete(block.id)} className="IconLink--trash" id={`delete_${block.id}`}>
                                        Delete rotation
                                    </ActionLink>
                                </li>
                            )}
                            <li>
                                <Link to={calendarData[0].url} className="IconLink--edit" id={`edit_${block.id}`}>
                                    Edit crops
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            )}
        </div>
    )
}

const _getBlockTitle = (block, refData, hasError) => {
    const blockIcon = utils.getBlockIcon(block.type);
    const blockType = utils.getBlockTypeText(refData, block);

    return (
        <li className="FarmTable-title" data-width="md-100 xl-30">
            {hasError && (
                <span className="Todo Todo--error error-anchor">
                    <i className="icon icon-alert error-anchor" />
                </span>
            )}
            <div className="FarmTitle">
                <img className="FarmTitle-icon" src={blockIcon} alt="Block" />
                <div className="FarmTitle-heading">
                    <span className="FarmTitle-name" title={block.name}>
                        {block.name}
                    </span>
                    <div className="FarmTitle-sub">{blockType}</div>
                </div>
            </div>
        </li>
    );
}

const _getCalendarData = (online, farm, analysis, block, blockType, refData) => {
    const { crops = [] } = block;
    const { messages = [] } = analysis;

    const hasErrors = online && messages.some((m) => m.category === "Crops" && m.entityType === "Block" && m.entityId === block.id && m.severity === "Error");
    const hasWarnings = online && messages.some((m) => m.category === "Crops" && m.entityType === "Block" && m.entityId === block.id && m.severity === "Warning");

    // Crop rotation.
    if (block.type === domain.BlockType.FodderCrop) {
        //cropForages cropFodder pasture

        const url = `/app/farm/${farm.id}/analysis/${analysis.id}/crops/rotation/${block.id}`;
        const subTitle = (
            <Link to={url} className="FarmTable-value-link">
                <span id={`${block.id}_edit`}>Edit rotation</span>
                <span className="icon icon-edit"></span>
            </Link>
        );

        const rotationYear = cropUtils.rotationYear(block);

        return [
            {
                id: block.id,
                alertIcon: hasErrors ? "error" : hasWarnings ? "warning" : null,
                title: "Crop rotation",
                subTitle: subTitle,
                url: url,
                months: rotationYear,
                slots: rotationYear.map((month, indx) => {
                    let icon = undefined;
                    let iconText = undefined;
                    /* eslint-disable no-unused-vars */
                    for (const crop of block.crops) {
                        if (cropUtils.isCropMonth(rotationYear, crop, indx)) {
                            icon = utils.getCropIcon(crop);
                            iconText = cropUtils.getCropNameLabelWithYield(crop, refData);
                        }
                    }
                    return {
                        month: month,
                        icon: icon,
                        iconText: icon && iconText,
                    };
                }),
            },
        ];
    }

    const isSeed = utils.priorLandUseSeed(block);
    if ((block.crops && block.crops.length > 0) || isSeed) {
        //cropForages cropFodder pasture

        const url = `/app/farm/${farm.id}/analysis/${analysis.id}/crops/${blockType.toLowerCase()}/${block.id}`;

        const subTitle = (
            <Link to={url} className="FarmTable-value-link">
                <span id={`${block.id}_edit`}>Edit crops</span>
                <span className="icon icon-edit"></span>
            </Link>
        );

        const rotationYear = cropUtils.rotationYear(block);

        const result = [
            {
                id: 1,
                alertIcon: hasErrors ? "error" : hasWarnings ? "warning" : null,
                title: "Crops",
                subTitle: subTitle,
                url: url,
                months: rotationYear,
                slots: rotationYear.map((month, indx) => {
                    let icon = undefined;
                    let iconText = undefined;
                    /* eslint-disable no-unused-vars */
                    for (const crop of crops) {
                        if (cropUtils.isCropMonth(rotationYear, crop, indx)) {
                            icon = utils.getCropIcon(crop);
                            iconText = cropUtils.getCropNameLabelWithYield(crop, refData);
                        }
                    }
                    return {
                        month: month,
                        icon: icon,
                        iconText: icon && iconText,
                        monthIndx: indx,
                    };
                }),
            },
        ];

        //Prior land use
        const { cropBlock } = block;
        if (cropBlock && cropBlock.preCrop && cropBlock.preCrop !== "Undefined") {
            const { preCropManagement = [] } = refData;
            const preCropIcon = utils.getPreCropIcon(cropBlock.preCrop);
            const preCropIconText = utils.valueToText(preCropManagement, cropBlock.preCrop);
            const firstCropEventIndx = cropUtils.getFirstEventIndex(block, rotationYear);

            for (let i = 0; i < firstCropEventIndx; i++) {
                const slot = result[0].slots.find((s) => s.monthIndx === i);
                if (slot) {
                    slot.icon = preCropIcon;
                    slot.iconText = preCropIconText;
                    slot.preCrop = true;
                }
            }
        }

        return result;
    }

    // Fruit.
    if (block.fruit) {
        const icon = utils.getFruitIcon(block.fruit.cropType);
        const fruitType = refData.fruitTypes[block.fruit.cropType].name;
        const url = `/app/farm/${farm.id}/analysis/${analysis.id}/crops/fruit/${block.id}`;
        const subTitle = (
            <span className="FarmTable-value-link a">
                <span id={`${block.id}_edit`}>{fruitType}</span>
                <span className="icon icon-edit"></span>
            </span>
        );
        return [
            {
                id: 1,
                alertIcon: hasErrors ? "error" : hasWarnings ? "warning" : null,
                title: `${blockType} type`,
                subTitle: subTitle,
                url: url,
                slots: calendarMonths.map((calendarMonth) => {
                    return {
                        month: calendarMonth,
                        icon: icon,
                    };
                }),
            },
        ];
    }

    // Pasture.
    if (block.pasture && block.pasture.pastureCategory) {
        const pastureType = utils.valueToText(refData.pastureTypes, block.pasture.pastureCategory);
        const url = `/app/farm/${farm.id}/analysis/${analysis.id}/crops/pasture/${block.id}`;
        const subTitle = (
            <span className="FarmTable-value-link a">
                <span id={`${block.id}_edit`}>{pastureType}</span>
                <span className="icon icon-edit"></span>
            </span>
        );
        return [
            {
                id: 1,
                alertIcon: hasErrors ? "error" : hasWarnings ? "warning" : null,
                title: `${blockType} type`,
                subTitle: subTitle,
                url: url,
                slots: calendarMonths.map((calendarMonth) => {
                    return {
                        month: calendarMonth,
                        icon: icons.pasture,
                    };
                }),
            },
        ];
    }

    return [];
}
