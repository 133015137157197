import { useState } from "react";
import { Switch, Route } from "react-router-dom";
import { Panel, PanelBody, PanelTabs } from "components/Panel";
import Overview from "containers/Reporting/Overview";
import DistributionGraphs from "containers/Reporting/DistributionGraphs";
import HistoryGraphs from "../HistoryGraphs";
import AggregatedReporting from "containers/Reporting/AggregatedReporting/AggregatedReporting";
import ReportingHeatmap from "containers/Reporting/ReportingHeatmap";
import { useCouncilReportingData } from "./_hooks";
import { useCouncilHistory } from "./_hooks";
import { useAuthContext, useRefData } from "common/hooks";
import { Filters } from "containers/Reporting/Filters";

export default function CouncilReporting() {
    const refData = useRefData();
    const authContext = useAuthContext();
    const { isSystemAdmin, accountType } = authContext;
    const [criteria, setCriteria] = useState({ includeFarms: "false" });
    const defaultRule = { type: "Year", condition: "Equals", values: [String(new Date().getFullYear())] };
    const councilRule = { type: "Region", condition: "Equals", values: ["EnvironmentCanterbury"] };
    const exclusions = ["Status", "Tag"];

    const filterSet = { filterGroups: [{ rules: [{ ...defaultRule }], operator: "And" }], operator: "Or" };
    if (!isSystemAdmin) {
        exclusions.push("Region");
    } else {
        filterSet.filterGroups[0].rules.unshift({ ...councilRule });
    }

    const [filter, setFilter] = useState(filterSet);
    const { loading: councilReportingDataLoading, data: councilReportingData } = useCouncilReportingData(filter, criteria);
    const { loading: historyLoading, error: historyError, data: historyData } = useCouncilHistory(filter, criteria, councilReportingData);

    if (!isSystemAdmin && accountType !== "Council") {
        return (
            <div className="Tile-body-message">
                <i className="icon icon--md icon-alert u-textError" />
                <h3 className="u-textError">Access denied</h3>
            </div>
        );
    }

    const error = councilReportingData && councilReportingData.error;
    const hasBenchmarkData = councilReportingData && councilReportingData.benchmarkData && councilReportingData.benchmarkData.length > 0;
    const locatedFarms = councilReportingData && councilReportingData.farms ? councilReportingData.farms.filter((f) => f.longitude && f.latitude) : [];

    const handleDownloadsChange = (e) => {
        setCriteria({ ...criteria, includeFarms: e.target.checked ? "true" : "false" });
    };

    const handleIncludeOutliersChange = (e) => {
        setCriteria({ ...criteria, includeOutliers: e.target.checked ? "true" : "false" });
    };

    const includeDownloadCheckbox = (
        <div className="u-flex flex-center">
            <label className="Checkbox Checkbox--slim u-ml-sm" htmlFor="downloads">
                <input id="downloads" className="Checkbox-input" type="checkbox" onChange={handleDownloadsChange} />
                <span className="Checkbox-label">Enable downloads</span>
            </label>
        </div>
    );

    const includeOutliersCheckbox = (
        <div className="u-flex flex-center">
            <label className="Checkbox Checkbox--slim u-ml-sm" htmlFor="includeOutliers">
                <input id="includeOutliers" className="Checkbox-input" type="checkbox" onChange={handleIncludeOutliersChange} checked={criteria.includeOutliers === "true"} />
                <span className="Checkbox-label">Show outliers</span>
            </label>
        </div>
    );

    const baseUrl = "/app/council";
    const tabs = {
        aggregated: { title: "Aggregated", path: `${baseUrl}`, exact: true, show: true },
        distribution: { title: "Distribution", path: `${baseUrl}/distribution`, exact: true },
        history: { title: "Trends", path: `${baseUrl}/history`, exact: true },
        map: { title: "Map view", path: `${baseUrl}/map`, exact: true, show: isSystemAdmin },
    };

    const actions = <>{isSystemAdmin && includeDownloadCheckbox}</>;

    const aggregatedData = councilReportingData && councilReportingData.aggregatedData;

    const farmLookup =
        councilReportingData && councilReportingData.farms
            ? councilReportingData.farms.reduce((d, f) => {
                  d[f.id] = f;
                  return d;
              }, {})
            : undefined;

    const noDataMessage = !error && !hasBenchmarkData && <div className="Tile-body-message">{councilReportingData && councilReportingData.farmCount && councilReportingData.farmCount < 20 ? <p className="lead">There are not enough farms to give valid results.</p> : <p className="lead">There is no data for the current filter. Please adjust your filter and try again.</p>}</div>;

    return (
        <Panel>
            <PanelTabs waiting={councilReportingDataLoading} iconClassName={hasBenchmarkData ? "IconLink--stats-bars" : ""} loading={!hasBenchmarkData} tabs={tabs} actions={actions} />
            <PanelBody>
                <Filters defaultRule={defaultRule} onFilterChanged={setFilter} filter={filter} exclusions={exclusions} />
            </PanelBody>
            <PanelBody loading={councilReportingDataLoading && !councilReportingData} waiting={councilReportingDataLoading}>
                {!error && (
                    <>
                        {tabs && (
                            <Switch>
                                <Route
                                    exact
                                    path={tabs.aggregated.path}
                                    render={() => (
                                        <>
                                            {noDataMessage ? (
                                                noDataMessage
                                            ) : (
                                                <>
                                                    {aggregatedData && <Overview aggregatedData={aggregatedData} farmsWithResultsCount={councilReportingData.farmsWithResultsCount} waiting={councilReportingDataLoading} includeLatestYearEnds={true} />}

                                                    <Panel title="Aggregated reporting" className="u-mt-md" loading={councilReportingDataLoading} skyBlue>
                                                        <PanelBody grey>
                                                            {aggregatedData && <AggregatedReporting aggregatedData={aggregatedData} farmLookup={farmLookup} farmsWithResultsCount={councilReportingData.farmsWithResultsCount} refData={refData} />}
                                                            {!(<p className="lead">There is no data available.</p>)}
                                                        </PanelBody>
                                                    </Panel>
                                                </>
                                            )}
                                        </>
                                    )}
                                />
                                <Route
                                    exact
                                    path={tabs.distribution.path}
                                    render={() => (
                                        <>
                                            {noDataMessage ? (
                                                noDataMessage
                                            ) : (
                                                <Panel title="Distributions" className="u-mt-md" loading={councilReportingDataLoading} skyBlue actions={includeOutliersCheckbox}>
                                                    <PanelBody grey>
                                                        <DistributionGraphs benchmarkData={councilReportingData.benchmarkData} farmLookup={farmLookup} hideTables={true} />
                                                    </PanelBody>
                                                </Panel>
                                            )}
                                        </>
                                    )}
                                />
                                <Route
                                    exact
                                    path={tabs.history.path}
                                    render={() => (
                                        <>
                                            {!historyData || Object.keys(historyData).length === 0 ? (
                                                noDataMessage
                                            ) : (
                                                <Panel title="History" className="u-mt-md" loading={councilReportingDataLoading} skyBlue>
                                                    <PanelBody grey>
                                                        <HistoryGraphs loading={historyLoading} error={historyError} data={historyData} />
                                                    </PanelBody>
                                                </Panel>
                                            )}
                                        </>
                                    )}
                                />
                                {isSystemAdmin && (
                                    <Route
                                        exact
                                        path={tabs.map.path}
                                        render={() => (
                                            <>
                                                <Panel title="Heat map" className="u-mt-md" loading={councilReportingDataLoading} skyBlue>
                                                    <PanelBody grey>
                                                        <ReportingHeatmap locations={locatedFarms} />
                                                    </PanelBody>
                                                </Panel>
                                            </>
                                        )}
                                    />
                                )}
                            </Switch>
                        )}
                    </>
                )}
                {error && (
                    <div className="Tile-body-message">
                        <i className="icon icon--md icon-alert u-textError" />
                        <h3 className="u-textError">{councilReportingData.error}</h3>
                    </div>
                )}
            </PanelBody>
        </Panel>
    );
}
