import { useCallback, useEffect, useState } from "react";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import createFocusOnErrorDecorator from "final-form-focus";
import { v4 as uuidv4 } from "uuid";
import * as utils from "common/utils";
import * as geoJsonUtils from "common/geoJsonUtils";
import { usePortalModal } from "common/effects";
import Spinner from "components/Spinner/Spinner";
import { useGoogleApi, useGoogleMap } from "components/Map/hooks";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import ActionLink from "components/ActionLink";
import BlockFields, { validateBlockFields } from "./BlockFields";
import { ForMoreInfoZendeskLink } from "components/Help";
import MapZoomButton from "components/Map/MapZoomButton";
import MapErrorLabels from "components/Map/MapErrorLabels";
import { BlockLabelsLayerToggle, LinzParcelsLayerToggle, UnassignedLandLayerToggle, DrawnIrrigatorsLayerToggle } from "components/Map";
import EditFarmAreaModal from "./EditFarmAreaModal";
import { useOnline, useShowQuickTips } from "common/hooks";
import { useUpdateAnalysisAsync, useBlockTopology } from "containers/hooks";

const focusOnError = createFocusOnErrorDecorator();
const MIN_DRAWN_AREA = 0.1;
const MIN_DRAWN_AREA_ERROR_MESSAGE = "Must be greater than 0.1 ha";
const OVERLAP_SELF_ERROR_MESSAGE = "Must not overlap itself";

export default function BlockModal({ farm, analysis, block, close }) {
    const online = useOnline();
    const showQuickTips = useShowQuickTips();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);
    const [showUnassignedLandLayerToggle, setShowUnassignedLandLayerToggle] = useState(null);

    const { map, mapId, setMapRef } = useGoogleMap({ center: { lat: farm.latitude, lng: farm.longitude }, zoom: 12 });
    const [geoJson, syncMapDataToGeoJson] = useAnalysisGeoJson(analysis);
    const [topoData, topoError] = useBlockTopology(geoJson, farm.id, analysis.id, block.id);
    topoError && console.log(topoError);

    useEffect(() => {
        if (geoJson) {
            setShowUnassignedLandLayerToggle((prevState) => {
                const hasUnassignedLand = geoJson.features.some((f) => f.properties.layer === geoJsonUtils.LAYER.UnassignedLand);
                if (prevState === null || hasUnassignedLand) {
                    return hasUnassignedLand;
                } else {
                    return prevState;
                }
            });
        }
    }, [geoJson]);

    const validatePolygons = useCallback(
        (mapData) => {
            mapData.toGeoJson((mapGeoJson) => {
                mapGeoJson.features.forEach((feature) => {
                    if (feature.properties.blockId === block.id || feature.properties.layer === "UnassignedLand") {
                        // RULE 1 - must not overlap itself.
                        let error = geoJsonUtils.selfIntersects(feature) ? OVERLAP_SELF_ERROR_MESSAGE : undefined;

                        // RULE 2 - must be min size.
                        if (!error) {
                            const drawnArea = geoJsonUtils.area(feature);
                            if (drawnArea === 0 || (block.requiresSoil && drawnArea < MIN_DRAWN_AREA)) error = MIN_DRAWN_AREA_ERROR_MESSAGE;
                        }
                        mapData.getFeatureById(feature.id).setProperty("error", error);
                    }
                });
                syncMapDataToGeoJson(mapData);
            });
        },
        [block, syncMapDataToGeoJson]
    );

    const mapData = useMapData(map, geoJson, block);
    useValidatePolygonsOnLoad(mapData, validatePolygons);
    useMapStyle(mapData, block.id);
    useOnPolygonHover(mapData, block.id);
    useOnPolygonClick(mapData, validatePolygons, block.id);
    useOnPolygonAdd(mapData, validatePolygons);
    useOnPolygonEdit(mapData, validatePolygons);

    const { openModal, getModal } = usePortalModal();
    const openEditFarmAreaModal = (analysis, closeOnSave) => {
        const modalProps = {
            analysis,
        };
        openModal("EditFarmAreaModal", modalProps, closeOnSave);
    };
    const editFarmAreaModal = getModal("EditFarmAreaModal", EditFarmAreaModal);

    const openSaveAnotherModal = (analysis, block, closeOnSave) => {
        const modalProps = {
            farm,
            analysis,
            block,
        };
        openModal("SaveAnotherModal", modalProps, closeOnSave);
    };
    const saveAnotherModal = getModal("SaveAnotherModal", BlockModal);

    const assignSelectedLand = useCallback(
        (blockId, mapData) => {
            mapData.forEach((mapFeature) => {
                if (mapFeature.getProperty("selected")) {
                    mapFeature.setProperty("layer", "Block");
                    mapFeature.setProperty("blockId", blockId);
                    mapFeature.setProperty("selected", false);
                    mapFeature.setProperty("hovered", false);
                }
            });
            mapData.setDrawingMode(null);
            syncMapDataToGeoJson(mapData);
        },
        [syncMapDataToGeoJson]
    );

    const unassignSelectedLand = useCallback(
        (mapData) => {
            mapData.forEach((mapFeature) => {
                if (mapFeature.getProperty("layer") === "Block" && mapFeature.getProperty("selected")) {
                    mapFeature.setProperty("layer", "UnassignedLand");
                    mapFeature.removeProperty("blockId");
                    mapFeature.setProperty("selected", false);
                    mapFeature.setProperty("hovered", false);
                }
            });
            mapData.setDrawingMode(null);
            syncMapDataToGeoJson(mapData);
        },
        [syncMapDataToGeoJson]
    );

    const deleteSelectedLand = useCallback(
        (mapData) => {
            mapData.forEach((feature) => {
                if (feature.getProperty("selected")) {
                    mapData.remove(feature);
                }
            });
            mapData.setDrawingMode(null);
            syncMapDataToGeoJson(mapData);
        },
        [syncMapDataToGeoJson]
    );

    const deleteAllUnassignedLand = useCallback(
        (mapData) => {
            mapData.forEach((feature) => {
                if (feature.getProperty("layer") === "UnassignedLand" && !feature.getProperty("selected")) {
                    mapData.remove(feature);
                }
            });
            mapData.setDrawingMode(null);
            syncMapDataToGeoJson(mapData);
        },
        [syncMapDataToGeoJson]
    );

    const deleteInvalidPolygons = useCallback(
        (mapData) => {
            mapData.forEach((feature) => {
                if (feature.getProperty("error")) {
                    mapData.remove(feature);
                }
            });
            mapData.setDrawingMode(null);
            syncMapDataToGeoJson(mapData);
        },
        [syncMapDataToGeoJson]
    );

    const validate = async (values) => {
        const validation = validateBlockFields(values);
        validation.polygons = geoJson && geoJson.features.filter((f) => f.properties.error).map((f) => ({ id: f.id, error: f.properties.error }));
        return validation;
    };

    const submit = async (values) => {
        // Tidy up the block before saving.
        values.isProductive = values.type.startsWith("Productive");
        if (!values.overrideClimateLocation) {
            delete values.climateLatitude;
            delete values.climateLongitude;
        }

        if (!values.type === "ProductivePasture") {
            delete values.pasture;
        }

        const updatedAnalysis = utils.clone(analysis);

        // Merge features into the budget.
        updatedAnalysis.features = geoJson.features
            .filter((feature) => ["Block", "UnassignedLand", "Irrigator"].includes(feature.properties.layer))
            .map((feature) => {
                // Only save the feature properties we want to store.
                const properties = geoJsonUtils.sanitizeProperties(feature.properties);

                // Set block names.
                if (properties.blockId === values.id) {
                    properties.blockName = values.name;
                } else if (properties.blockId && !properties.blockName) {
                    const otherBlock = updatedAnalysis.blocks.find((b) => b.id === properties.blockId);
                    if (otherBlock) {
                        properties.blockName = otherBlock.name;
                    }
                }

                // SNEAKILY...set irrigator names too if not set.
                if (properties.irrigatorId && !properties.irrigatorName && updatedAnalysis.irrigators && updatedAnalysis.irrigators.length > 0) {
                    const irrigator = updatedAnalysis.irrigators.find((i) => i.id === properties.irrigatorId);
                    if (irrigator) {
                        properties.irrigatorName = irrigator.name;
                    }
                }

                // Make sure blockName property is removed from any non-block features.
                if (!properties.blockId) {
                    delete properties.blockName;
                }

                return { ...feature, properties };
            });

        updatedAnalysis.isDrawn = updatedAnalysis.features.length > 0;
        updatedAnalysis.hasUnassignedLand = updatedAnalysis.features.some((feature) => feature.properties.layer === "UnassignedLand");

        values.isDrawn = updatedAnalysis.features.some((f) => f.properties.blockId === values.id);

        // Merge updated block details into the budget.
        if (values.isNew) {
            updatedAnalysis.blocks = [...updatedAnalysis.blocks, { ...values, isNew: false }];
        } else {
            updatedAnalysis.blocks = updatedAnalysis.blocks.map((b) => {
                if (b.id === values.id) {
                    const updatedBlock = {
                        ...values,
                        regenerateSoils: b.autoGeneratedSoils,
                    };
                    // Reset block climate if block has been drawn and its climate not overridden.
                    if (values.isDrawn && !values.overrideClimateLocation) {
                        delete updatedBlock.climate;
                    }
                    return updatedBlock;
                }
                return b;
            });
        }
        utils.calculateBudget(updatedAnalysis);

        if (values.closeOnSave && updatedAnalysis.hasCompletedBlocks && updatedAnalysis.totalFarmArea > 0 && updatedAnalysis.totalFarmArea < updatedAnalysis.totalBlockAreaInHectares) {
            openEditFarmAreaModal(updatedAnalysis, close);
        } else {
            try {
                await updateAnalysisAsync(updatedAnalysis);
                if (values.closeOnSave) {
                    close();
                } else {
                    const nextBlock = { id: uuidv4(), isNew: true };
                    openSaveAnotherModal(updatedAnalysis, nextBlock, close);
                }
            } catch (ex) {
                return { [FORM_ERROR]: ex.message };
            }
        }
    };

    const mapError = !online && "Overseer is currently offline. Mapping is unavailable.";
    const mapLoading = online && !geoJson && !mapError;

    if (editFarmAreaModal) return editFarmAreaModal;

    if (saveAnotherModal) return saveAnotherModal;

    const info = (
        <div>
            Draw/select the outlines of the land parcels that make up this block and enter the block details. You should incorporate all land parcels that are managed in the same way as a single block.
            <ForMoreInfoZendeskLink url="https://support.overseer.org.nz/hc/en-us/articles/900000889543" />
            <p>
                <b>IMPORTANT:</b> Drawing the block on the map allows the system to auto assign the climate and, if available, the S-Map soils for the block's location. The system does not require highly detailed land parcel drawings to achieve this. You do not need to draw lanes or raceways or other non-productive areas. A rough outline of the land parcels is sufficient.
            </p>
        </div>
    );

    const help = (
        <div>
            The land parcels assigned to this block are shown on the map in green. The land parcels for other blocks are shown in white with a green border. Any land parcels not already assigned to a block are shown in purple.
            <ul className="disc u-mt-xs">
                <li>Use the drawing tool to draw the land parcels for this block</li>
                <li>Use the hand tool to edit the land parcels for this block or to assign any unassigned land parcels to this block</li>
            </ul>
        </div>
    );

    return (
        <Form initialValues={block} decorators={[focusOnError]} validate={validate} onSubmit={submit}>
            {({ form, values, errors, handleSubmit, submitting, submitError }) => {
                const totalDrawnArea = !geoJson
                    ? 0
                    : utils.round(
                          geoJson.features.filter((f) => f.properties.blockId === values.id).reduce((sum, feature) => (sum += feature.properties.drawnArea), 0),
                          1
                      );
                const hasInvalidPolygons = errors && errors.polygons && errors.polygons.length > 0;
                const hasUnassignedLand = !hasInvalidPolygons && geoJson && geoJson.features.some((f) => f.properties.layer === "UnassignedLand" && !f.properties.selected);
                const hasSelectedUnassignedLand = !hasInvalidPolygons && geoJson && geoJson.features.some((f) => f.properties.layer === "UnassignedLand" && f.properties.selected);
                const hasSelectedAssignedLand = !hasInvalidPolygons && geoJson && geoJson.features.some((f) => f.properties.layer === "Block" && f.properties.selected);
                const hasSelectedLand = !hasInvalidPolygons && geoJson && geoJson.features.some((f) => f.properties.selected);
                const hasDrawnIrrigators = geoJson?.features?.some((f) => f.properties.layer === geoJsonUtils.LAYER.Irrigator);

                const disableSave = mapError || submitting || totalDrawnArea > 40000;

                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title={`${values.isNew ? "Add" : "Edit"} block`} close={close} submitting={submitting} full allowOffline>
                            <ModalBody info={info} help={showQuickTips ? help : undefined} error={submitError}>
                                <div className="ActionsBar ActionsBar--super u-mt-sm u-mb-sm">
                                    <div className="ActionsBar-left">
                                        <ul className="ActionsBar-links">
                                            {hasInvalidPolygons && (
                                                <li>
                                                    <ActionLink id="delete-invalid" className="IconLink--trash u-textError" onClick={() => deleteInvalidPolygons(mapData)}>
                                                        Delete invalid land parcels
                                                    </ActionLink>
                                                </li>
                                            )}
                                            {!hasInvalidPolygons && (
                                                <>
                                                    <li>
                                                        <ActionLink id="unassign-selected" className="IconLink--ungroup" onClick={() => unassignSelectedLand(mapData)} disabled={!hasSelectedAssignedLand}>
                                                            Unassign selected land from this block
                                                        </ActionLink>
                                                    </li>
                                                    {false && (
                                                        <li>
                                                            <ActionLink id="assign-selected" className="IconLink--blocks" onClick={() => assignSelectedLand(values.id, mapData)} disabled={!hasSelectedUnassignedLand}>
                                                                Assign selected land to this block
                                                            </ActionLink>
                                                        </li>
                                                    )}
                                                    <li>
                                                        <ActionLink id="delete-selected" className="IconLink--trash" onClick={() => deleteSelectedLand(mapData)} disabled={!hasSelectedLand}>
                                                            Delete selected land
                                                        </ActionLink>
                                                    </li>
                                                    {hasUnassignedLand && (
                                                        <li>
                                                            <ActionLink id="delete-all-unassigned" className="IconLink--trash" onClick={() => deleteAllUnassignedLand(mapData)}>
                                                                Delete all unassigned land
                                                            </ActionLink>
                                                        </li>
                                                    )}
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>

                                <div className="mapGrid u-mt-0">
                                    <div className="mapGrid_details">
                                        <BlockFields title="Block details" form={form} values={values} drawnArea={totalDrawnArea} topoData={topoData} />
                                    </div>
                                    <div className="mapGrid_map">
                                        {mapLoading && (
                                            <div className="Tile-body-message">
                                                <Spinner dark />
                                                <p className="lead">Loading map...</p>
                                            </div>
                                        )}
                                        {!mapLoading && mapError && (
                                            <div className="Tile-body-message u-textError">
                                                <i className="icon icon--md icon-alert" />
                                                <p className="lead">{mapError}</p>
                                            </div>
                                        )}
                                        <div className={`map ${hasInvalidPolygons ? "map__invalid" : ""} ${mapLoading || mapError ? "u-hidden" : ""}`} ref={setMapRef} />
                                    </div>
                                    <BlockLabelsLayerToggle mapData={mapData} mapId={mapId} blocks={analysis.blocks} geoJson={geoJson} />
                                    <LinzParcelsLayerToggle mapData={mapData} mapId={mapId} />
                                    {hasDrawnIrrigators && <DrawnIrrigatorsLayerToggle mapData={mapData} mapId={mapId} />}
                                    {showUnassignedLandLayerToggle && <UnassignedLandLayerToggle mapData={mapData} mapId={mapId} />}
                                    <MapZoomButton mapData={mapData} mapId={mapId} where={(f) => f.properties.blockId === block.id} padding={100} title="Zoom to block" />
                                    <MapErrorLabels map={map} geoJson={geoJson} />
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    {values.isNew && (
                                        <Button id="submit-another" submit tertiary waiting={submitting} disabled={disableSave}>
                                            Save, create another
                                        </Button>
                                    )}
                                    <Button id="submit" submit primary waiting={submitting} disabled={disableSave} onClick={() => form.change("closeOnSave", true)}>
                                        Save
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
}

export function useBlockModal(farm, analysis) {
    const { openModal, getModal } = usePortalModal();

    const openBlockModal = (blockId) => {
        const block = (analysis.blocks && analysis.blocks.find((b) => b.id === blockId)) || { id: uuidv4(), isNew: true };
        const modalProps = {
            block,
            farm,
            analysis,
        };

        openModal("BlockModal", modalProps);
    };

    const blockModal = getModal("BlockModal", BlockModal);

    return [openBlockModal, blockModal];
}

function useAnalysisGeoJson(analysis) {
    const [geoJson, setGeoJson] = useState();

    const syncMapDataToGeoJson = (mapData) => mapData.toGeoJson((geoJson) => setGeoJson(geoJson));

    useEffect(() => {
        if (!geoJson) {
            const analysisGeoJson = { type: "FeatureCollection", features: [] };
            if (analysis.features && analysis.features.length > 0) {
                analysisGeoJson.features = analysis.features.reduce((results, feature) => {
                    // Flatten and hide irrigators by default.
                    const flattenedFeatures = geoJsonUtils.flatten(feature, ["Irrigator"]);
                    return [...results, ...flattenedFeatures];
                }, []);
            }
            setGeoJson(analysisGeoJson);
        }
    }, [analysis.features, geoJson]);

    return [geoJson, syncMapDataToGeoJson];
}

function useMapData(map, geoJson, block) {
    const google = useGoogleApi();
    const [mapData, setMapData] = useState();

    useEffect(() => {
        if (google && map && geoJson && block && !mapData) {
            const hasUnassignedLand = geoJson.features.some((f) => f.properties.layer === "UnassignedLand");
            const data = new google.maps.Data({
                map,
                controlPosition: google.maps.ControlPosition.TOP_CENTER,
                controls: ["Polygon"],
                drawingMode: block.isNew && !hasUnassignedLand ? "Polygon" : null,
                featureFactory: (geometry) => {
                    return new google.maps.Data.Feature({
                        id: uuidv4(),
                        properties: {
                            layer: "Block",
                            blockId: block.id,
                            selected: true,
                            visible: true,
                        },
                        geometry,
                    });
                },
            });
            data.addGeoJson(geoJson);
            setMapData(data);
        }
    }, [google, map, geoJson, block, mapData]);

    return mapData;
}

function useMapStyle(mapData, blockId) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && blockId && !runOnce) {
            mapData.setStyle((feature) => {
                const hovered = feature.getProperty("hovered");
                const selected = feature.getProperty("selected");
                const visible = feature.getProperty("visible");
                const error = feature.getProperty("error");
                const layer = feature.getProperty("layer") || "Block";
                const slope = feature.getProperty("slope");
                const layerColor = geoJsonUtils.getMapLayerColor(layer, slope);

                const featureBlockId = feature.getProperty("blockId");
                const isCurrentBlock = featureBlockId === blockId;
                //const isOtherBlock = featureBlockId && featureBlockId !== blockId;

                let zIndex = 0;
                if (hovered || selected) {
                    zIndex = 4;
                } else if (isCurrentBlock) {
                    zIndex = 3;
                } else if (layer === "UnassignedLand") {
                    zIndex = 2;
                } else if (layer === "Block") {
                    zIndex = 1;
                }

                const isLegalParcel = layer === "LegalParcel";

                return {
                    clickable: isLegalParcel ? false : true,
                    strokeColor: error ? "red" : layerColor, //isOtherBlock ? 'white' :
                    strokeWeight: hovered || error ? 4 : isLegalParcel ? 1 : 3,
                    fillColor: isCurrentBlock || ["UnassignedLand", "Irrigator", "Slope"].includes(layer) ? layerColor : "white",
                    fillOpacity: isLegalParcel ? 0 : 0.6,
                    editable: selected,
                    zIndex,
                    visible,
                };
            });
            setRunOnce(true);
        }
    }, [mapData, blockId, runOnce]);
}

function useValidatePolygonsOnLoad(mapData, validify) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && validify && !runOnce) {
            validify(mapData);
            setRunOnce(true);
        }
    }, [mapData, validify, runOnce]);
}

function useOnPolygonHover(mapData, blockId) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && blockId && !runOnce) {
            // Add 'mouseover' event for hover styling.
            mapData.addListener("mouseover", ({ feature }) => {
                if (feature.getProperty("blockId") === blockId || feature.getProperty("layer") === "UnassignedLand") {
                    feature.setProperty("hovered", true);
                }
            });

            // Add 'mouseout' event for un-hover styling.
            mapData.addListener("mouseout", ({ feature }) => {
                if (feature.getProperty("blockId") === blockId || feature.getProperty("layer") === "UnassignedLand") {
                    feature.setProperty("hovered", false);
                }
            });

            setRunOnce(true);
        }
    }, [mapData, blockId, runOnce]);
}

function useOnPolygonClick(mapData, onMapDataChange, blockId) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && onMapDataChange && blockId && !runOnce) {
            mapData.addListener("click", ({ feature }) => {
                const clickedLayer = feature.getProperty("layer");
                if (feature.getProperty("blockId") === blockId || clickedLayer === "UnassignedLand") {
                    mapData.forEach((mapFeature) => {
                        if (mapFeature.getId() === feature.getId()) {
                            if (clickedLayer === "UnassignedLand") {
                                mapFeature.setProperty("layer", "Block");
                                mapFeature.setProperty("blockId", blockId);
                                mapFeature.setProperty("selected", true);
                                mapFeature.setProperty("hovered", false);
                            } else {
                                mapFeature.setProperty("selected", !mapFeature.getProperty("selected"));
                            }
                        } else {
                            if (clickedLayer !== mapFeature.getProperty("layer")) {
                                mapFeature.setProperty("selected", false);
                            }
                        }
                    });
                    onMapDataChange(mapData);
                }
            });
            setRunOnce(true);
        }
    }, [mapData, onMapDataChange, blockId, runOnce]);
}

function useOnPolygonAdd(mapData, onMapDataChange) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && onMapDataChange && !runOnce) {
            mapData.addListener("addfeature", ({ feature }) => {
                feature.toGeoJson((geoJsonFeature) => {
                    const drawnArea = geoJsonUtils.area(geoJsonFeature);
                    feature.setProperty("drawnArea", drawnArea);
                    onMapDataChange(mapData);

                    // De-select all other features.
                    mapData.forEach((mapFeature) => {
                        if (mapFeature.getId() !== feature.getId()) {
                            mapFeature.setProperty("selected", false);
                        }
                    });
                });
            });
            setRunOnce(true);
        }
    }, [mapData, onMapDataChange, runOnce]);
}

function useOnPolygonEdit(mapData, onMapDataChange) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && onMapDataChange && !runOnce) {
            mapData.addListener("setgeometry", ({ feature }) => {
                feature.toGeoJson((geoJsonFeature) => {
                    const drawnArea = geoJsonUtils.area(geoJsonFeature);
                    feature.setProperty("drawnArea", drawnArea);
                    onMapDataChange(mapData);
                });
            });
            setRunOnce(true);
        }
    }, [mapData, onMapDataChange, runOnce]);
}
