import React, { useEffect, useState, useContext, useCallback } from "react";
import { compose, withAppContext, withState, withActions } from "containers/HOC";
import { Switch, Route, Link, Redirect, withRouter, matchPath } from "react-router-dom";
import * as icons from "common/icons";
import OfflinePoller from "components/OfflinePoller";
import ModalConductor from "components/ModalConductor";
import FarmRoute from "containers/FarmRoute";
import Dashboard from "containers/Dashboard/Dashboard";
import EmailRequired from "containers/Dashboard/EmailRequired";
import ActivationRequired from "containers/Dashboard/ActivationRequired";
import FarmHome from "containers/FarmHome/FarmHome";
import FertiliserRecommendations from "containers/FarmHome/FertiliserRecommendations";
import FarmSettings from "containers/Farm/FarmSettings";
import FarmTimeline from "containers/Farm/FarmTimeline";
import PublicationAccess from "containers/Farm/PublicationAccess";
import BudgetHome from "containers/BudgetHome/BudgetHome";
import FarmSearch from "containers/FarmSearch/FarmSearch";
import Datasets from "containers/Datasets/Datasets";
import CustomSetup from "containers/Custom/Index";
import Admin from "containers/Admin/Admin";
import CouncilReporting from "containers/Reporting/Council/Index";
import Dataset from "containers/Datasets/Dataset";
import AnalysisCreate from "containers/Datasets/AnalysisCreate";
import AnalysisEdit from "containers/Datasets/AnalysisEdit";
import MeasuredValuesEdit from "containers/Datasets/MeasuredValues";
import AccountDetails from "containers/AccountDetails/AccountDetails";
import StudentManagement from "containers/AccountDetails/StudentManagement";
import { logout } from "containers/Auth/_actions";
import { modal, toggleQuickTips, sendTrack } from "containers/App/_actions";
import MyOrg from "containers/MyOrg/MyOrg";
import PublicationReporting from "containers/PublicationReporting/PublicationReporting";
import ScrollToTop from "react-scroll-up";
import ActionLink from "components/ActionLink";
import FarmGroup from "containers/FarmGroups/FarmGroup";
import FarmHomeGroup from "containers/FarmHome/FarmHomeGroup";
import { AkuakuContext } from "common/AkuakuContext";
import VersionUpdator from "components/VersionUpdator";
import { ZendeskLink } from "components/Help";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import Loader from "components/Loader";
import QuickSearch from "containers/QuickSearch/QuickSearch";
import FarmDashboard from "containers/FarmHome/FarmDashboard";
import FarmAccess from "containers/Farm/FarmAccess";
import FarmActivity from "containers/Farm/Activity";
import FarmTrends from "containers/Farm/Trends";
import FarmGroups from "containers/Farm/Groups";
import FarmBenchmarks from "containers/Farm/Benchmarks";
import RiskTool from "containers/RiskTool/index";
import zoomPlugin from "chartjs-plugin-zoom";

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, RadialLinearScale, ArcElement, Filler, Title, Tooltip, Legend } from "chart.js";
import { NotificationsBell } from "components/NotificationsBell";

import Actions from "containers/Dashboard/MyRequiredActions_TMP";
import Requests from "containers/Dashboard/MyPendingFarmAccessRequests_TMP";
import Publications from "containers/Dashboard/MyPublications_TMP";
import FarmGroupSummary from "containers/Dashboard/MyFarmGroups_TMP";
import WhatsNew from "containers/Dashboard/MyWhatsNew_TMP";
import { useFetchRefDataOnAuth, useFetchCustomCompositionsOnAuth } from "containers/Auth/_hooks";
import "react-contexify/dist/ReactContexify.min.css";
import { useConfirm, useInvalidateAllQueryKeysOnDisconnect } from "common/hooks";
import { OfflinePanel } from "components/OfflinePanel";
import { useChangePasswordModal } from "containers/Auth/ChangePasswordModal";
import { useMyProfileModal } from "containers/Auth/MyProfileModal";
import { useSessionRecording } from 'common/hooks';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, RadialLinearScale, ArcElement, Filler, zoomPlugin, Title, Tooltip, Legend);

const FarmSettingsRoute = FarmRoute(FarmSettings);
const FarmTimelineRoute = FarmRoute(FarmTimeline);
const FarmAccessRoute = FarmRoute(FarmAccess);
const FarmActivityRoute = FarmRoute(FarmActivity);
const FarmTrendsRoute = FarmRoute(FarmTrends);
const FarmGroupsRoute = FarmRoute(FarmGroups);
const FarmBenchmarksRoute = FarmRoute(FarmBenchmarks);
const PublicationAccessRoute = FarmRoute(PublicationAccess);
const FarmHomeRoute = FarmRoute(FarmHome);
const FarmFertiliserRecommendationsRoute = FarmRoute(FertiliserRecommendations);
const FarmDashboardRoute = FarmRoute(FarmDashboard);
const BudgetHomeRoute = FarmRoute(BudgetHome);
const DatasetAnalysisRoute = FarmRoute(AnalysisEdit);
//const FarmSoilsRoute = FarmRoute(FarmSoils)

const SecuredLayout = ({ toggleQuickTips, auth, authContext, refDataExists, modal, logout, persistStore, refData = {}, online, showQuickTips, location, modalState, sendTrack, formState }) => {
    const [showQuickSearch, setShowQuickSearch] = useState(false);
    const [showHelpMenu, setShowHelpMenu] = useState(false);
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [showBellMenu, setShowBellMenu] = useState(false);
    const [context] = useContext(AkuakuContext);
    const { lastFarmName, lastBudgetName } = context;
    const [bellMenuWidth, setBellMenuWidth] = useState(0);
    const [helpMenuWidth, setHelpMenuWidth] = useState(0);
    const [userMenuWidth, setUserMenuWidth] = useState(0);
    const windowLocation = window.location.pathname;
    const windowHash = window.location.hash;
    const confirm = useConfirm();
    const [changePasswordModal, openChangePasswordModal] = useChangePasswordModal();
    const [myProfileModal, openMyProfileModal] = useMyProfileModal();

    useInvalidateAllQueryKeysOnDisconnect();
    useFetchRefDataOnAuth();
    useFetchCustomCompositionsOnAuth();
    useSessionRecording();

    const bellMenuRef = useCallback((element) => {
        if (element !== null) {
            setBellMenuWidth(element.getBoundingClientRect().width);
        }
    }, []);
    const helpMenuRef = useCallback((element) => {
        if (element !== null) {
            setHelpMenuWidth(element.getBoundingClientRect().width);
        }
    }, []);
    const userMenuRef = useCallback((element) => {
        if (element !== null) {
            setUserMenuWidth(element.getBoundingClientRect().width);
        }
    }, []);

    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (window.location.pathname.includes("zendeskout")) {
            //Zendesk does not work well with our # navigation.
            _logout();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        online && sendTrack(windowHash);
    }, [online, sendTrack, windowHash]);

    useEffect(() => {
        const closeAnyOpenNavMenu = (e) => {
            const isNavDescendant = isDescendant(e.target, ["nav-search", "nav-menus"]);
            if (!isNavDescendant) {
                setShowQuickSearch(false);
                setShowUserMenu(false);
                setShowHelpMenu(false);
                setShowBellMenu(false);
            }
        };
        document.addEventListener("click", closeAnyOpenNavMenu);
        return () => document.removeEventListener("click", closeAnyOpenNavMenu);
    }, []);

    const toggleQuickSearch = (show) => {
        setShowQuickSearch(show);
        setShowUserMenu(false);
        setShowHelpMenu(false);
        setShowBellMenu(false);
    };

    const toggleHelpMenu = (e) => {
        const isQuickTipsTogle = isDescendant(e.target, ["toggle-quick-tips"]);
        if (isQuickTipsTogle) return;

        setShowQuickSearch(false);
        setShowHelpMenu((prevState) => !prevState);
        setShowUserMenu(false);
        setShowBellMenu(false);
    };

    const toggleUserMenu = () => {
        setShowQuickSearch(false);
        setShowHelpMenu(false);
        setShowUserMenu((prevState) => !prevState);
        setShowBellMenu(false);
    };

    const toggleBellMenu = (e) => {
        setShowQuickSearch(false);
        setShowHelpMenu(false);
        setShowUserMenu(false);
        setShowBellMenu((prevState) => {
            if (!prevState) return true;
            return isDescendant(e.target, ["nav-menu-bell-notifications"]);
        });
    };

    const _editMyProfile = (e) => {
        e.preventDefault();
        openMyProfileModal();
    };

    const _changePassword = (e) => {
        e.preventDefault();
        setShowUserMenu(false);
        openChangePasswordModal();
    };

    const _promptBeforeLogout = (e) => {
        e.preventDefault();
        setShowUserMenu(false);
        confirm("Are you sure you want to Log out?", () => _logout());
    };

    const _logout = () => {
        logout();
        persistStore.purge();
    };

    const { pathname = {} } = location;

    //need to check state if isAuth
    //TODO look at a more robust method of checking if auth'd.
    //Zendesk does not work well with our # navigation.
    if (!authContext.isAuthenticated || windowLocation.includes("zendesk")) {
        return <Redirect push to={{ pathname: `/pub/login`, state: { from: location } }} />;
    }

    const hasEmailAddress = authContext.email && authContext.email.length > 0;
    const isActivated = authContext.isActivated;
    const isMyFarms = pathname === "/";
    const isFarmSearch = pathname === "/app/search/farms";
    const isDatasets = pathname === "/app/datasets";
    const isStudents = pathname === "/app/students";
    const isCouncil = pathname.startsWith("/app/council");
    const isRiskTool = pathname.startsWith("/app/riskindex");
    const isAdmin = matchPath(pathname, { path: "/app/admin" });
    const isStdUserOfSciEd = ["Education", "Science"].includes(refData.deploymentType) && !authContext.isAdmin;

    let header = process.env.REACT_APP_PRODUCT === "ed" ? "Header--green" : "";
    header = process.env.REACT_APP_PRODUCT === "sci" ? "Header--orange" : header;
    const isCommercial = refData.deploymentType === "Commercial";

    const farmQs = lastFarmName ? `${encodeURI(lastFarmName)}` : "none";
    const budgetQs = lastBudgetName ? `${encodeURI(lastBudgetName)}` : "none";
    const ticketsEnabled = process.env.REACT_APP_ZENDESK_JWT === "true";
    const quickSearchRightPos = bellMenuWidth + helpMenuWidth + userMenuWidth + 41;
    const { isSystemAdmin, accountType, council: accountCouncil } = auth;
    const showCouncilReporting = isSystemAdmin || (accountType === "Council" && accountCouncil);

    return (
        <>
            <div className={"react-page" + (modalState.modalType !== null ? " modal-is-open" : "")}>
                <header className={`Header ${header} ${formState.disableStandardPrintHeader ? "u-print-none" : ""}`}>
                    <div className="Header-logo u-print-none">
                        <Link className="Header-logo-sm" to="/" id="home_small">
                            <img src={icons.logoSmall()} alt="Overseer logo" />
                        </Link>
                        <Link className="Header-logo-lg" to="/" id="home_large">
                            <img src={icons.logo()} alt="Overseer logo" />
                        </Link>
                        <input type="hidden" id="user_id" value={authContext.userId} />
                        <input type="hidden" id="user_name" value={authContext.fullName} />
                        <input type="hidden" id="organisation_id" value={authContext.accountId} />
                        <input type="hidden" id="organisation_name" value={authContext.accountName} />
                    </div>
                    <ul className="Nav u-print-none u-mr-sm">
                        <li className="Nav-item Nav-item--home">
                            <Link className={isMyFarms ? "is-active" : ""} to="/" id="dashboard" title="Dashboard">
                                <span className="u-sm-hidden">Dashboard</span>
                            </Link>
                        </li>
                        {false && online && hasEmailAddress && isActivated && (
                            <li className="Nav-item Nav-item--structures">
                                <Link className={isFarmSearch ? "is-active" : ""} to="/app/search/farms" id="farm_search" title="Farm search">
                                    <span className="u-sm-hidden">Farms</span>
                                </Link>
                            </li>
                        )}
                        {online && hasEmailAddress && isActivated && refDataExists && refData.deploymentType === "Science" && authContext.accountType === "Science" && (
                            <li className="Nav-item Nav-item--equalizer">
                                <Link className={isDatasets ? "is-active" : ""} to="/app/datasets" id="datasets" title="Datasets">
                                    <span className="u-sm-hidden">Datasets</span>
                                </Link>
                            </li>
                        )}
                        {online && hasEmailAddress && isActivated && refDataExists && refData.deploymentType === "Education" && auth.accountType === "EducationProvider" && (
                            <li className="Nav-item Nav-item--profile">
                                <Link className={isStudents ? "is-active" : ""} to="/app/students" id="students" title="Students">
                                    <span className="u-sm-hidden">Students</span>
                                </Link>
                            </li>
                        )}
                        {online && hasEmailAddress && isActivated && showCouncilReporting && (
                            <li className="Nav-item Nav-item--stats-bars">
                                <Link className={isCouncil ? "is-active" : ""} to="/app/council" id="council-reporting" title="Reporting">
                                    <span className="u-sm-hidden">Council reporting</span>
                                </Link>
                            </li>
                        )}
                        {online && hasEmailAddress && isActivated && isSystemAdmin && (
                            <li className="Nav-item Nav-item--stats-bars">
                                <Link className={isRiskTool ? "is-active" : ""} to="/app/riskindex" id="risk-tool" title="Risk tool">
                                    <span className="u-sm-hidden">Risk tool</span>
                                </Link>
                            </li>
                        )}
                        {online && hasEmailAddress && isActivated && authContext.isSystemAdmin && (
                            <li className="Nav-item Nav-item--shield">
                                <Link className={isAdmin ? "is-active" : ""} to="/app/admin" id="admin" title="Admin">
                                    <span className="u-sm-hidden">Admin</span>
                                </Link>
                            </li>
                        )}
                    </ul>
                    {online && <QuickSearch showQuickSearch={showQuickSearch} toggleQuickSearch={toggleQuickSearch} rightPos={quickSearchRightPos} />}
                    <div id="nav-menus" className="UtilityNav u-print-none">
                        <div ref={bellMenuRef} onClick={toggleBellMenu} id="nav-menu-bell" className={"UtilityNav-user" + (showBellMenu ? " is-open" : "")}>
                            <NotificationsBell />
                        </div>
                        <div ref={helpMenuRef} onClick={toggleHelpMenu} className={"UtilityNav-user" + (showHelpMenu ? " is-open" : "")}>
                            <ActionLink id="nav-menu-help" className="UtilityNav-user-trigger" title="Help">
                                <span className="UtilityNav-user-token">
                                    <i className={`icon icon-question ${showQuickTips ? "u-textSuccess" : "u-textCoolBlue"}`} />
                                </span>
                                <span className="UtilityNav-user-name">
                                    <span>Help</span>
                                    {false && <i className="UtilityNav-user-affordance icon icon-arrow-filled" />}
                                </span>
                            </ActionLink>
                            <ul className="UtilityNav-user-dropdown">
                                <li style={{ minWidth: "220px" }}>
                                    <ActionLink id="toggle-quick-tips" className="a u-link" onClick={toggleQuickTips}>
                                        <i className="icon icon-settings" />
                                        <span>Quick tips</span>
                                        <Toggle onChange={toggleQuickTips} checked={showQuickTips} className="Help-toggle u-ml-auto" icons={{ checked: "ON", unchecked: "OFF" }} />
                                    </ActionLink>
                                </li>
                                {online && (
                                    <>
                                        <li>
                                            <hr className="UtilityNav-divider" />
                                        </li>
                                        <li>
                                            <ZendeskLink
                                                id="knowledgebase"
                                                title={
                                                    <>
                                                        <i className="icon IconLink--new-tab" />
                                                        Read help articles
                                                    </>
                                                }
                                                url={`https://support.overseer.org.nz?akuaku=${farmQs}|${budgetQs}`}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                            />
                                        </li>
                                        <li>
                                            <a href="https://www.overseer.org.nz/our-model" alt="Modelling" className="" target="_blank" rel="noopener noreferrer">
                                                <i className="icon IconLink--new-tab" />
                                                Overseer scientific model
                                            </a>
                                        </li>
                                        <li>
                                            <ZendeskLink
                                                id="uncertainty"
                                                title={
                                                    <>
                                                        <i className="icon IconLink--new-tab" />
                                                        Understanding model uncertainty
                                                    </>
                                                }
                                                url="https://support.overseer.org.nz/hc/en-us/articles/19906879612057"
                                                rel="noopener noreferrer"
                                                target="_blank"
                                            />
                                        </li>
                                        <li>
                                            <a href="https://www.overseer.org.nz/" alt="overseer.org.nz" className="" target="_blank" rel="noopener noreferrer">
                                                <i className="icon IconLink--new-tab" />
                                                overseer.org.nz
                                            </a>
                                        </li>
                                    </>
                                )}
                                {online && hasEmailAddress && ticketsEnabled && (
                                    <>
                                        <li>
                                            <hr className="UtilityNav-divider" />
                                        </li>
                                        <li>
                                            <ZendeskLink
                                                id="support"
                                                title={
                                                    <>
                                                        <i className="icon IconLink--new-tab" />
                                                        Submit a helpdesk request
                                                    </>
                                                }
                                                url={`https://support.overseer.org.nz/hc/en-us/requests/new?akuaku=${farmQs}|${budgetQs}`}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                            />
                                        </li>
                                        <li>
                                            <ZendeskLink
                                                id="support"
                                                title={
                                                    <>
                                                        <i className="icon IconLink--new-tab" />
                                                        My helpdesk requests
                                                    </>
                                                }
                                                url={`https://support.overseer.org.nz/hc/en-us/requests?akuaku=${farmQs}|${budgetQs}`}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                            />
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                        <div ref={userMenuRef} onClick={toggleUserMenu} className={"UtilityNav-user" + (showUserMenu ? " is-open" : "")}>
                            <ActionLink id="nav-menu-user" className="UtilityNav-user-trigger" title={`${online ? "Connected" : "Disconnected"}`}>
                                <span className="UtilityNav-user-token">
                                    <i className={`icon icon-profile ${online ? "u-textSuccess" : "u-textError"}`} />
                                </span>
                                <span className="UtilityNav-user-name">
                                    <span>{authContext.fullName}</span>
                                    {false && <i className="UtilityNav-user-affordance icon icon-arrow-filled" />}
                                </span>
                            </ActionLink>
                            <ul className="UtilityNav-user-dropdown">
                                {online && (
                                    <li>
                                        <ActionLink id="my-profile" className="a u-link" onClick={_editMyProfile}>
                                            <i className="icon icon-profile" />
                                            My profile
                                        </ActionLink>
                                    </li>
                                )}
                                {online && hasEmailAddress && isActivated && !isStdUserOfSciEd && (
                                    <li>
                                        <Link className="u-link" to="/app/myOrg" id="manage-users" title="My organisation">
                                            <i className="icon icon-settings" />
                                            My organisation
                                        </Link>
                                    </li>
                                )}
                                {online && hasEmailAddress && isActivated && isCommercial && (
                                    <li>
                                        <Link className="u-link" to={authContext.isAdmin ? "/app/myOrg/payments" : "/app/myOrg"} id="manage-payments" title="My payment transactions">
                                            <i className="icon icon-dollar" />
                                            My payment transactions
                                        </Link>
                                    </li>
                                )}
                                {online && hasEmailAddress && isActivated && isCommercial && (
                                    <li>
                                        <Link className="u-link" to={"/app/myOrg/renewal"} id="manage-payments" title="Billing setup">
                                            <i className="icon icon-creditcard" />
                                            Billing
                                        </Link>
                                    </li>
                                )}
                                {online && authContext.canChangePassword && (
                                    <li>
                                        <ActionLink id="change-password" className="u-link" onClick={_changePassword}>
                                            <i className="icon icon-lock" />
                                            Change password
                                        </ActionLink>
                                    </li>
                                )}
                                {online && hasEmailAddress && isActivated && (
                                    <li>
                                        <Link id="custom-setup" className="u-link" to="/app/custom">
                                            <i className="icon icon-wrench" />
                                            Custom compositions
                                        </Link>
                                    </li>
                                )}
                                {online && (
                                    <li>
                                        <hr className="UtilityNav-divider" />
                                    </li>
                                )}
                                <li>
                                    <ActionLink id="logout" className="u-link" onClick={_promptBeforeLogout}>
                                        <i className="icon icon-switch" />
                                        Log out
                                    </ActionLink>
                                </li>
                            </ul>
                        </div>
                        <OfflinePoller refDataVersion={refData.version} authContext={authContext} />
                    </div>
                    {!formState.disableStandardPrintHeader && (
                        <div className="u-print-show u-pb-lg u-pl-sm u-pr-sm">
                            <b>DISCLAIMER:</b>&nbsp; This Report has been prepared solely for registered users of Overseer who download it from the Overseer application, and have accepted Overseer’s Terms of Use. While reasonable efforts have been made to ensure that the Overseer software model used to prepare this Report keeps up with the latest scientific research, Overseer Limited gives no warranties, representation or guarantees, express or implied in relation to the quality, reliability, accuracy and/or fitness for any purpose of the Report. Overseer Limited expressly disclaims and assumes no liability whatsoever arising directly or indirectly from the use of, or reliance on this Report.
                            <br />
                            <b>COPYRIGHT:</b>&nbsp;With the exception of user-supplied data, this Report is © 2025 Overseer Limited. All rights reserved. You may copy and distribute this Report in its entirety, as long as you do not mislead anyone as to its origin or implications, and provided you do not remove or alter the disclaimer above or this copyright notice.
                        </div>
                    )}
                </header>
                {!hasEmailAddress && <EmailRequired />}
                {hasEmailAddress && !isActivated && <ActivationRequired />}
                {hasEmailAddress && isActivated && (
                    <OfflinePanel>
                        <Switch>
                            <Route exact path="/" component={Dashboard} />

                            <Route exact path="/app/farm/:farmId" component={FarmDashboardRoute} />
                            <Route exact path="/app/farm/:farmId/settings" component={FarmSettingsRoute} />
                            <Route exact path="/app/farm/:farmId/timeline" component={FarmTimelineRoute} />
                            <Route exact path="/app/farm/:farmId/access" component={FarmAccessRoute} />
                            <Route exact path="/app/farm/:farmId/activity" component={FarmActivityRoute} />
                            {/*<Route exact path="/app/farm/:farmId/soils" component={FarmSoilsRoute} />*/}
                            <Route exact path="/app/farm/:farmId/trends" component={FarmTrendsRoute} />
                            <Route exact path="/app/farm/:farmId/groups" component={FarmGroupsRoute} />
                            <Route exact path="/app/farm/:farmId/benchmarks" component={FarmBenchmarksRoute} />
                            <Route exact path="/app/farm/:farmId/publicationAccess" component={PublicationAccessRoute} />
                            <Route exact path="/app/farm/:farmId/dp" component={FarmHomeRoute} />
                            <Route exact path="/app/farm/:farmId/analysis" component={FarmHomeRoute} />
                            <Route exact path="/app/farm/:farmId/fertiliser-recommendations" component={FarmFertiliserRecommendationsRoute} />
                            <Route exact path="/app/farm/:farmId" component={FarmHomeRoute} />
                            <Route path="/app/farm/:farmId/home" component={FarmHomeRoute} />
                            <Route path="/app/farm/:farmId/analysis/:budgetId" component={BudgetHomeRoute} />
                            <Route path="/app/farm/:farmId/farmgroup/:farmGroupId" component={FarmHomeGroup} />
                            <Route exact path="/app/search/farms" component={FarmSearch} />
                            <Route exact path="/app/usermanagement" component={AccountDetails} />
                            <Route exact path="/app/students" component={StudentManagement} />
                            <Route exact path="/app/datasets/:datasetId/farm/:farmId/analysis/:budgetId/new" component={AnalysisCreate} />
                            <Route exact path="/app/datasets/:datasetId/farm/:farmId/analysis/:budgetId" component={DatasetAnalysisRoute} />
                            <Route exact path="/app/datasets/:datasetId" component={Dataset} />
                            <Route exact path="/app/datasets/:datasetId/measurements" component={MeasuredValuesEdit} />
                            <Route exact path="/app/datasets" component={Datasets} />
                            <Route exact path="/app/custom" component={CustomSetup} />
                            <Route path="/app/admin/orgs/:accountId" component={Admin} />
                            <Route path="/app/admin/farms/:farmId" component={Admin} />
                            <Route path="/app/admin" component={Admin} />
                            <Route path="/app/council" component={CouncilReporting} />
                            <Route path="/app/riskindex" component={RiskTool} />
                            <Route path="/app/myOrg" component={MyOrg} />
                            <Route path="/app/publicationReporting" component={PublicationReporting} />
                            <Route path="/app/farmGroup/:farmGroupId" component={FarmGroup} />

                            <Route path="/app/actions" component={Actions} />
                            <Route path="/app/requests" component={Requests} />
                            <Route path="/app/publications" component={Publications} />
                            <Route path="/app/farmgroups" component={FarmGroupSummary} />
                            <Route path="/app/whatsnew" component={WhatsNew} />
                        </Switch>
                    </OfflinePanel>
                )}
                <footer className="Footer u-print-none">
                    <div className="Container">
                        <ScrollToTop showUnder={160} style={{ bottom: 80, cursor: "pointer" }}>
                            <img src={icons.gotoTop} style={{ width: "56px", height: "56px", opacity: "0.4" }} alt="Goto top" />
                        </ScrollToTop>
                        <ul className="Footer-nav">
                            <li>
                                <b>
                                    <ZendeskLink title="Knowledgebase" url="https://support.overseer.org.nz/" rel="noopener noreferrer" target="_blank" />
                                </b>
                            </li>
                            <li>
                                <a href="https://www.overseer.org.nz/" alt="overseer.org.nz" target="_blank" rel="noopener noreferrer">
                                    overseer.org.nz
                                </a>
                            </li>
                            <li>
                                <a href="https://www.overseer.org.nz/our-model" alt="overseer.org.nz" target="_blank" rel="noopener noreferrer">
                                    Modelling overview
                                </a>
                            </li>
                            <li>
                                <a href={`https://docs.overseer.org.nz/${process.env.REACT_APP_PRODUCT}/OverseerTermsOfUse.pdf`} rel="noopener noreferrer" target="_blank">
                                    Terms of use
                                </a>
                            </li>
                            <li>
                                <a href={`https://docs.overseer.org.nz/${process.env.REACT_APP_PRODUCT}/OverseerPrivacyPolicy.pdf`} rel="noopener noreferrer" target="_blank">
                                    Privacy policy
                                </a>
                            </li>
                        </ul>
                        <VersionUpdator />
                    </div>
                </footer>
                <ModalConductor />
                <div id="modal"></div>
                <div id="confirm-modal"></div>
            </div>
            {changePasswordModal}
            {myProfileModal}
        </>
    );
};

const isDescendant = (element, ancestorIds) => {
    if (ancestorIds.includes(element.id)) return true;

    while (element.parentNode) {
        element = element.parentNode;
        if (ancestorIds.includes(element.id)) {
            return true;
        }
    }

    return false;
};

export default compose(
    withRouter,
    withAppContext,
    withState((state) => {
        return {
            auth: state.auth,
            modalState: state.app.modal,
            invalidVersionCount: state.app.invalidVersionCount,
            refDataExists: state.app.refData && state.app.refData.blockTypes ? true : false,
            googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=geometry,drawing,places`,
            loadingElement: <Loader message="Loading..." />,
            formState: state.app.formState,
        };
    }),
    withActions({ logout, modal, toggleQuickTips, sendTrack })
)(SecuredLayout);
