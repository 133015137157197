import { useCallback, useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useQuery, useQueryClient, useMutation, useIsMutating } from "@tanstack/react-query";
import { httpClient } from "common/httpClient";
import * as utils from "common/utils";
import { getDataset } from "./Datasets/_actions";
import { useFarmGroupHistory, useFarmGroupPublications, useAccountReportPublications, useAccountHistory, useMyPublicationsSummary, useFarmTags, useAccountReportContent } from "containers/Reporting/_hooks";
import { useAuthContext, useRefData } from "common/hooks";
import { useAnalysisResults } from "containers/BudgetHome";
import { useOnline } from "common/hooks";
import * as api from "api";

export function useFarm(specifiedFarmId) {
    const { farmId } = useParams();
    const farmIdToUse = specifiedFarmId || farmId;

    const { isFetching: isFetchingFullDeets, isLoading: isLoadingFullDeets, farm: fullDeets, error } = useFarmDetails(farmIdToUse);
    const { isFetching: isFetchingPublicDeets, isLoading: isLoadingPublicDeets, farm: publicDeets } = usePublicFarmDetails(farmIdToUse, error);

    return {
        isFetching: isFetchingFullDeets || isFetchingPublicDeets,
        isLoading: isLoadingFullDeets || isLoadingPublicDeets,
        data: fullDeets || publicDeets,
        error,
    };
}

export function useFarmAccess(specifiedFarmId) {
    const { farmId } = useParams();
    const farmIdToUse = specifiedFarmId || farmId;

    const query = useQuery({
        queryKey: ["farm-details", farmIdToUse, "access"],
        queryFn: async () => httpClient.get(`farms/${farmIdToUse}/access`),
        enabled: !!farmIdToUse,
        retry: false,
        refetchOnWindowFocus: true,
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error,
    };
}

export function useRevokeFarmAccessAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async ({ farmId, farmAccess }) => {
            try {
                const content = {
                    id: farmId,
                    accountId: farmAccess.accountId,
                    userId: farmAccess.userId,
                    role: farmAccess.role,
                };
                await httpClient.del(`farms/${farmId}/access`, content);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["dashboard"] });
            queryClient.invalidateQueries({ queryKey: ["my-org", "farm-access"] });
            queryClient.invalidateQueries({ queryKey: ["farm-details", variables.farmId] });
        },
    });

    return (farmId, farmAccess) => mutation.mutateAsync({ farmId, farmAccess });
}

export function useRevokeAccessInvitationAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (accessInvitation) => {
            try {
                await httpClient.del(`farmAccessInvitations/${accessInvitation.id}`);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else if (error.status === 404) {
                    throw new Error("The invitation is no longer valid.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["dashboard"] });
            queryClient.invalidateQueries({ queryKey: ["farm-details", variables.farmId] });
        },
    });

    return (accessInvitation) => mutation.mutateAsync(accessInvitation);
}

export function useMyFarmAccessRequests() {
    const query = useQuery({
        queryKey: ["dashboard", "my-farm-access-requests"],
        queryFn: async () => httpClient.get(`farmAccessRequests`),
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error,
    };
}

export function useResendFarmAccessRequestAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (accessRequest) => {
            try {
                await httpClient.put(`farmAccessRequests/${accessRequest.id}/resend`, accessRequest);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else if (error.status === 404) {
                    throw new Error("The access request is no longer valid.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["dashboard"] });
            queryClient.invalidateQueries({ queryKey: ["farm-details", variables.farmId] });
        },
    });

    return (accessRequest) => mutation.mutateAsync(accessRequest);
}

export function useWithdrawFarmAccessRequestAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (accessRequest) => {
            try {
                await httpClient.del(`farmAccessRequests/${accessRequest.id}`);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else if (error.status === 404) {
                    // Do nothing, the access request has already been deleted
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSettled: (_, __, variables) => {
            queryClient.invalidateQueries({ queryKey: ["dashboard"] });
            queryClient.invalidateQueries({ queryKey: ["farm-details", variables.farmId] });
        },
    });

    return (accessRequest) => mutation.mutateAsync(accessRequest);
}

export function useCreateFarmAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (farm) => {
            try {
                await httpClient.post(`farms`, farm);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["dashboard"] });
        },
    });

    return (farm) => mutation.mutateAsync(farm);
}

export function useUpdateFarmAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (farm) => {
            const timeout = 1000 * 60 * 2; // 2 minutes
            try {
                await httpClient.put(`farms/${farm.id}`, farm, timeout);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSettled: (_, __, variables) => {
            queryClient.invalidateQueries({ queryKey: ["farm-details", variables.id] });
        },
    });

    return (farm) => mutation.mutateAsync(farm);
}

export function useToggleMyFarmAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async ({ farmId, isMyFarm }) => {
            try {
                await httpClient.put(`farms/${farmId}/myfarm/${isMyFarm}`);
            } catch (error) {
                throw new Error(error.message);
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["dashboard", "my-farms"] });
            queryClient.invalidateQueries({ queryKey: ["farm-details", variables.farmId] });
        },
    });

    return (farmId, isMyFarm) => mutation.mutateAsync({ farmId, isMyFarm });
}

export function useDeleteFarmAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (farmId) => {
            const timeout = 1000 * 60 * 2; // 2 minutes
            try {
                await httpClient.del(`farms/${farmId}`, null, timeout);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else if (error.status === 404) {
                    // Do nothing, the farm has already been deleted
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["dashboard"] });
            queryClient.invalidateQueries({ queryKey: ["farm-details"] });
        },
    });

    return async (farmId) => mutation.mutateAsync(farmId);
}

export function useDeletedAnalyses(farmId) {
    const timeout = 1000 * 60 * 2; // 2 minutes

    const query = useQuery({
        queryKey: ["farm-details", farmId, "deleted-analyses"],
        queryFn: async () => httpClient.get(`farms/${farmId}/deletedBudgets`, timeout),
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error,
    };
}

export function useAnalysis(specifiedFarmId, specifiedAnalysisId) {
    const { farmId, budgetId } = useParams();
    const farmIdToUse = specifiedFarmId || farmId;
    const analysisIdToUse = specifiedAnalysisId || budgetId;

    const query = useQuery({
        queryKey: ["farm-details", farmIdToUse, "analysis-details", analysisIdToUse],
        queryFn: async () => httpClient.get(`v1/analysis-details/${analysisIdToUse}`),
        enabled: !!analysisIdToUse,
        retry: false,
        refetchOnWindowFocus: false,
    });

    const analysis = query.data;
    if (analysis) {
        utils.calculateBudget(analysis);
    }

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: analysis,
        error: query.error,
    };
}

export function useDownloadAnalysis() {
    const dispatch = useDispatch();

    return async (farmId, analysisId) => {
        const response = await httpClient.get(`farms/${farmId}/budgets/${analysisId}/download`);
        dispatch({ type: "APP_DOWNLOAD", payload: { content: response, name: response.budget ? response.budget.name : response.name } });
    };
}

export function useBlockGrowthCurve(farm, analysis, block) {
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: ["farm-details", farm.id, "analysis-details", analysis.id, "block-details", block.id],
        queryFn: async () => httpClient.post(`farms/${farm.id}/budgets/${analysis.id}/blocks/${block.id}/growthcurve`, block),
        placeholderData: block.cropGrowthCurves,
        enabled: !block.isNew,
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        growthCurve: query.data,
        error: query.error,
        resetGrowthCurve: () => queryClient.invalidateQueries({ queryKey: ["farm-details", farm.id, "analysis-details", analysis.id, "block-details", block.id] }),
    };
}

export function useCreateAnalysisAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (analysis) => {
            const timeout = 1000 * 60 * 2; // 2 minutes
            try {
                await httpClient.post(`farms/${analysis.farmId}/budgets`, analysis, timeout);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSettled: (_, __, variables) => {
            queryClient.invalidateQueries({ queryKey: ["farm-details", variables.farmId] });
        },
    });

    return (analysis) => mutation.mutateAsync(analysis);
}

export function useBlockTopology(geoJson, farmId, budgetId, blockId) {
    const online = useOnline();
    const dispatch = useDispatch();
    const [topoData, setTopoData] = useState();
    const [topoError, setTopoError] = useState();
    const [lastDrawnArea, setLastDrawnArea] = useState(0);
    const totalDrawnArea = !geoJson
        ? 0
        : utils.round(
              geoJson.features.filter((f) => f.properties.blockId === blockId).reduce((sum, feature) => (sum += feature.properties.drawnArea), 0),
              1
          );
    useEffect(() => {
        const getBlockTopology = {
            send: (features, farmId, budgetId) => (dispatch) => {
                (features || []).forEach((f) => {
                    delete f.properties.scrollRef;
                });

                return new Promise((resolve, reject) => {
                    dispatch(
                        api.post({
                            path: `farms/${farmId}/budgets/${budgetId}/topology`,
                            timeout: 300000,
                            content: { features },
                            onSuccess: (response) => {
                                resolve(response);
                                return [];
                            },
                            onFailure: (error) => {
                                error.handled = true;
                                reject("Error loading block topology");
                                return [];
                            },
                            cancelActionType: "abort_getBlockTopology",
                        })
                    );
                });
            },
            abort: () => (dispatch) => dispatch({ type: "abort_getBlockTopology" }),
        };

        const fetchData = async () => {
            const blockFeatures = geoJson.features.filter((f) => f.properties.layer === "Block" && f.properties.blockId === blockId);
            await dispatch(getBlockTopology.send(blockFeatures, farmId, budgetId))
                .then((response) => {
                    if (response) {
                        setLastDrawnArea(totalDrawnArea);
                        setTopoData(response);
                    }
                })
                .catch((error) => {
                    setTopoError(error);
                });
        };

        if (online && geoJson && blockId && totalDrawnArea && lastDrawnArea !== totalDrawnArea && totalDrawnArea > 0) {
            fetchData();
        }
    }, [blockId, budgetId, dispatch, farmId, geoJson, online, topoData, lastDrawnArea, totalDrawnArea]);

    return [topoData, topoError];
}

export function useUpdateAnalysisAsync(analysis, path) {
    const mutationKey = ["updateAnalysisAsync", analysis?.id];
    const isSaving = useIsMutating({ mutationKey }) > 0;
    const queryClient = useQueryClient();
    const location = useLocation();
    const pathname = path || location.pathname;
    const dispatch = useDispatch();

    const mutation = useMutation({
        mutationKey,
        mutationFn: async (updatedAnalysis) => {
            // Pending analyses must wait for the previous save to complete before saving
            if (!updatedAnalysis.isPending) {
                const timeout = 1000 * 60 * 2; // 2 minutes
                await httpClient.put(`farms/${updatedAnalysis.farmId}/budgets/${updatedAnalysis.id}`, updatedAnalysis, timeout);
            }
        },
        onError: async () => {
            dispatch({
                type: "APP_OPEN_MODAL",
                payload: {
                    modalType: "FATAL",
                    onConfirm: () => {},
                    props: {
                        message: (
                            <div>
                                <p className="lead u-textError">Oops, something didn't quite go as expected :(</p>
                                <p className="u-text-lg u-mt-md">Please log out and back in again to continue</p>
                            </div>
                        ),
                        logOutOnly: true,
                    },
                },
            });
        },
        onSettled: async (_, __, savedVersion) => {
            // The query keys for the farm, analysis details, and analysis summary
            const farmQueryKey = ["farm-details", savedVersion.farmId];
            const analysisDetailsQueryKey = [...farmQueryKey, "analysis-details", savedVersion.id];

            if (savedVersion.isPending) {
                // If here, then this mutation did not actually make the http request because there was a save in progress.
                // Do nothing here because when the in-progress save completes, it will handle saving the latest version.
            } else {
                // If here, then this mutation did make the http request so we need to check if there were
                // any pending saves that were made while this http request was in progress.
                const latestVersion = queryClient.getQueryData(analysisDetailsQueryKey);
                if (latestVersion.isPending) {
                    // There were pending saves while this http request was in progress. We need to save the latest version.
                    const nextVersion = { ...latestVersion, isPending: false };
                    await save(nextVersion);
                } else {
                    // There were no pending saves while this http request was in progress.
                    // Invalidate all queries for the farm so that everything is refetched e.g. farm details, analysis details, analysis summary, model results, etc.
                    queryClient.invalidateQueries({ queryKey: farmQueryKey });
                }
            }
        },
    });

    /**
     * Optimistically update the react-query cache with the updated analysis and invalidate the analysis summary cache.
     * Await this function so that the cache is updated before the mutation is called.
     */
    const saveToLocalCacheAsync = useCallback(
        async (updatedAnalysis) => {
            // The query keys for the farm, analysis details, and analysis summary
            const farmQueryKey = ["farm-details", updatedAnalysis.farmId];
            const analysisDetailsQueryKey = [...farmQueryKey, "analysis-details", updatedAnalysis.id];
            const analysisSummaryQueryKey = [...farmQueryKey, "analysis-summary", updatedAnalysis.id];

            // Cancel any outgoing refetches so they don't overwrite our optimistic update
            await queryClient.cancelQueries({ queryKey: analysisDetailsQueryKey });

            // Optimistically mutate the react-query cache to replace the cached analysis with the updated analysis
            queryClient.setQueryData(analysisDetailsQueryKey, updatedAnalysis);

            // Optimistically mutate the react-query cache to replace the cached analysis in the farm analysis list
            const farmCache = queryClient.getQueryData(farmQueryKey);
            if (farmCache) {
                const updatedFarm = {
                    ...farmCache,
                    budgets: farmCache.budgets.map((b) => (b.id === updatedAnalysis.id ? updatedAnalysis : b)),
                };
                queryClient.setQueryData(farmQueryKey, updatedFarm);
            }

            // Optimistically invalidate the analysis summary cache so that it's refetched
            queryClient.removeQueries({ queryKey: analysisSummaryQueryKey });
        },
        [queryClient]
    );

    /**
     * Bit of a trick to wrap the mutation.mutate function so that it can be called from within
     * the onSettled callback of the mutation itself. This is needed so that there's only ever
     * one save in progress at a time.
     */
    const save = useCallback(
        async (updatedAnalysis) => {
            // Await the saveToLocalCacheAsync function so that the local cache is updated
            // before the mutation is called
            await saveToLocalCacheAsync(updatedAnalysis);

            // Call the mutation to sync the updated analysis to the server
            mutation.mutateAsync(updatedAnalysis);
        },
        [saveToLocalCacheAsync, mutation]
    );

    /**
     * This hook returns a function for updating an analysis.
     */
    const updateAnalysisAsync = useCallback(
        async (analysis) => {
            // Do some pre-processing before saving
            const updatedAnalysis = {
                ...analysis,
                path: pathname,
            };
            utils.cleanBudgetReferences(updatedAnalysis);
            delete updatedAnalysis.currentResults;
            delete updatedAnalysis.messages;
            if (updatedAnalysis.blocks) {
                updatedAnalysis.blocks.forEach((block) => {
                    delete block.currentResults;
                });
            }

            // Mark this save as isPending if there is a save in progress
            updatedAnalysis.isPending = isSaving;

            // Do the save
            await save(updatedAnalysis);
        },
        [isSaving, pathname, save]
    );

    return updateAnalysisAsync;
}

export function useIsFetchingModelResults() {
    const { data: analysis } = useAnalysis();
    const { isStale } = useAnalysisResults(analysis);

    return isStale;
}

export function useUploadOverseerFarmFileAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (overseerFarmFile) => {
            const timeout = 1000 * 60 * 2; // 2 minutes

            try {
                await httpClient.post(`admin/upload`, { package: overseerFarmFile }, timeout);
            } catch (error) {
                throw new Error(error.message);
            }
        },
        onSuccess: (_, variables) => {
            const farmId = variables.budget?.farmId || variables.farm?.id;
            if (farmId) {
                queryClient.invalidateQueries({ queryKey: ["farm-details", farmId] });
            }
        },
    });

    return (overseerFarmFile) => mutation.mutateAsync(overseerFarmFile);
}

export function useUploadXmlFileAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async ({ xml, farmId, analysisType, name, year }) => {
            const timeout = 1000 * 60 * 2; // 2 minutes
            await httpClient.postBinary(`farms/${farmId}/budgetXml/?budgetType=${analysisType}&name=${encodeURIComponent(name)}&yearEnd=${year}`, xml, timeout, "application/xml");
        },
        onSuccess: (_, variables) => {
            if (variables.farmId) {
                queryClient.invalidateQueries({ queryKey: ["farm-details", variables.farmId] });
            }
        },
    });

    return (xml, farmId, analysisType, name, year) => mutation.mutateAsync({ xml, farmId, analysisType, name, year });
}

export function useUpload3rdPartyJsonFileAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async ({ json, farmId, analysisType, name, year }) => {
            const timeout = 1000 * 60 * 2; // 2 minutes
            await httpClient.postBinary(`farms/${farmId}/analysis/loadjson?budgetType=${analysisType}&name=${encodeURIComponent(name)}&yearEnd=${year}`, json, timeout);
        },
        onSuccess: (_, variables) => {
            if (variables.farmId) {
                queryClient.invalidateQueries({ queryKey: ["farm-details", variables.farmId] });
            }
        },
    });

    return (json, farmId, analysisType, name, year) => mutation.mutateAsync({ json, farmId, analysisType, name, year });
}

export function useDeleteAnalysisAsync() {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const mutation = useMutation({
        mutationFn: async (analysis) => {
            const timeout = 1000 * 60 * 2; // 2 minutes
            try {
                await httpClient.del(`farms/${analysis.farmId}/budgets/${analysis.id}`, null, timeout);

                // Mutate the react-query cache to remove the analysis from the farm so that the analysis list
                // does not need to wait for the refetch after invalidating the cache to show the updated list
                const farm = queryClient.getQueryData(["farm-details", analysis.farmId]);
                if (farm) {
                    farm.budgets = farm.budgets.filter((b) => b.id !== analysis.id);
                }
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else if (error.status === 404) {
                    // Do nothing, the farm has already been deleted
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSettled: (_, __, variables) => {
            queryClient.invalidateQueries({ queryKey: ["farm-details", variables.farmId] });
            if (variables.datasetId) {
                dispatch(getDataset(variables.datasetId));
            }
        },
    });

    return (analysis) => mutation.mutateAsync(analysis);
}

export function useRemoveAnalysisFromDatasetAsync() {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const mutation = useMutation({
        mutationFn: async (analysis) => {
            analysis.datasetId = null;
            const timeout = 1000 * 60 * 2; // 2 minutes
            try {
                await httpClient.put(`farms/${analysis.farmId}/budgets/${analysis.id}`, analysis, timeout);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else if (error.status === 404) {
                    // Do nothing, the farm has already been deleted
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSettled: (_, __, variables) => {
            queryClient.invalidateQueries({ queryKey: ["farm-details", variables.farmId] });
            if (variables.datasetId) {
                dispatch(getDataset(variables.datasetId));
            }
        },
    });

    return (analysis) => mutation.mutateAsync(analysis);
}

export function useReplaceAnalysisAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async ({ farmId, targetAnalysisId, sourceAnalysisId }) => {
            const timeout = 1000 * 60 * 2; // 2 minutes
            try {
                const content = {
                    farmId,
                    targetAnalysisId,
                    sourceAnalysisId,
                };
                await httpClient.put(`farms/${farmId}/budgets/${targetAnalysisId}/replace`, content, timeout);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSettled: (_, __, variables) => {
            queryClient.invalidateQueries({ queryKey: ["farm-details", variables.farmId] });
        },
    });

    return (farmId, targetAnalysisId, sourceAnalysisId) => mutation.mutateAsync({ farmId, targetAnalysisId, sourceAnalysisId });
}

export function useAnalysisAuditLog(farmId, analysisId) {
    const timeout = 1000 * 60 * 2; // 2 minutes

    const query = useQuery({
        queryKey: ["farm-details", farmId, "analysis-details", analysisId, "audit-log"],
        queryFn: async () => httpClient.get(`farms/${farmId}/budgets/${analysisId}/auditlog`, timeout),
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error,
    };
}

export function useAnalysisBenchmarks(farmId, analysisId) {
    const timeout = 1000 * 60 * 2; // 2 minutes

    const query = useQuery({
        queryKey: ["farm-details", farmId, "analysis-details", analysisId, "benchmarks"],
        queryFn: async () => httpClient.get(`farms/${farmId}/budgets/${analysisId}/benchmarks`, timeout),
        retry: false,
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 5, // 5 minutes
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error,
    };
}

export function useCarbonSequestration(farmId, analysisId) {
    const query = useQuery({
        queryKey: ["model-results", "forestcarbon", farmId, analysisId],
        queryFn: async () => httpClient.get(`farms/${farmId}/analysis/${analysisId}/forestcarbon`),
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        isLoading: query.isLoading,
        error: query.error?.message,
        data: query.data,
    };
}

export function useGrantAnalyisAccessAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async ({ analysis, options }) => {
            try {
                await httpClient.put(`farms/${analysis.farmId}/budgets/${analysis.id}/access`, options);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSettled: (_, __, variables) => {
            queryClient.invalidateQueries({ queryKey: ["farm-details", variables.analysis.farmId] });
        },
    });

    return (analysis, options) => mutation.mutateAsync({ analysis, options });
}

export function useAddAnalysisToStudentGroupAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async ({ analysis, options }) => {
            try {
                await httpClient.post(`farms/${analysis.farmId}/budgets/${analysis.id}/addToStudentGroup`, options);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSettled: (_, __, variables) => {
            queryClient.invalidateQueries({ queryKey: ["farm-details", variables.analysis.farmId] });
        },
    });

    return (analysis, options) => mutation.mutateAsync({ analysis, options });
}

export function useMyFarmGroups() {
    const query = useQuery({
        queryKey: ["dashboard", "my-farm-groups"],
        queryFn: async () => httpClient.get(`farmGroups`),
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error,
    };
}

const setEnterpriseName = (rd = {}, refData) => {
    const { enterpriseTypes = [] } = refData;

    const { benchmarkedFarms = {} } = rd;
    const { farms = [] } = benchmarkedFarms;
    for (const farm of farms) {
        const { enterprises = [] } = farm;
        for (const enterprise of enterprises) {
            enterprise.label = utils.valueToText(enterpriseTypes, enterprise.type);
        }
    }
};

export function useReportFarmTags(farms, setFarmIds) {
    const [farmTag, setFarmTag] = useState();
    const { data: farmTags } = useFarmTags();
    const [availableFarmTags, setAvailableFarmTags] = useState();
    const [farmsWithTags, setFarmsWithTags] = useState();

    useEffect(() => {
        if (!farms || !farmTags) {
            return;
        }
        const farmsWithTags = farms.map((farm) => {
            const myFarmTag = farmTags.find((mft) => mft.farmId === farm.id) || {};
            return {
                ...farm,
                tags: myFarmTag.tags || [],
            };
        });
        setFarmsWithTags(farmsWithTags);
    }, [farms, farmTags]);

    useEffect(() => {
        if (farmsWithTags) {
            const availableFarmTags = farmsWithTags
                .reduce((tags, farmWithTags) => {
                    const newTags = farmWithTags.tags.filter((tag) => !tags.includes(tag));
                    return tags.concat(newTags);
                }, [])
                .sort((a, b) => (a > b ? 1 : -1));
            setAvailableFarmTags(availableFarmTags);
        }
    }, [farmsWithTags]);

    useEffect(() => {
        if (farmsWithTags) {
            const selectedFarmIds = farmTag ? farmsWithTags.filter((farmWithTags) => farmWithTags.tags.includes(farmTag)).map((farmWithTags) => farmWithTags.id) : [];
            setFarmIds(selectedFarmIds);
        }
    }, [farmTag, farmsWithTags, setFarmIds]);

    return { availableFarmTags, setFarmTag, farmTag };
}

export function useFarmGroupReportData(farmGroupId, filter) {
    const refData = useRefData();
    const [year, setYear] = useState();
    const { data: farm } = useFarm();
    const [farmIds, setFarmIds] = useState();
    const { data: farmGroup } = useFarmGroup(farmGroupId);
    const [farms, setFarms] = useState();

    useEffect(() => {
        if (farmGroup) {
            const farms = farmGroup?.memberFarms?.map((member) => ({ id: member.farmId, name: member.farmName, address: member.farmAddress }));
            setFarms(farms);
        }
    }, [farmGroup]);

    const { data: reportData, error: reportDataError, isLoading: reportDataLoading } = useFarmGroupReportContent(farmGroupId, farmIds, filter);
    const { loading: historyLoading, error: historyError, data: historyData } = useFarmGroupHistory(farmGroupId, filter);
    const { availableFarmTags, setFarmTag, farmTag } = useReportFarmTags(farms, setFarmIds);
    const { isLoading: publicationsLoading, error: publicationError, data: publications } = useFarmGroupPublications(farmGroupId, filter);

    const availableYears = farmGroup?.summary
        ?.map((s) => s.year)
        ?.sort()
        ?.reverse();

    useEffect(() => {
        if (!year) {
            if (availableYears && availableYears.length > 0) {
                setYear(availableYears[0]);
            } else if (farmGroup) {
                setYear(new Date().getFullYear());
            }
        }
    }, [year, availableYears, farmGroup]);

    setEnterpriseName(reportData, refData);

    return {
        farm,
        farmGroup,
        availableYears,
        year,
        farmIds,
        onFarmTagChanged: setFarmTag,
        availableFarmTags,
        reportDataLoading,
        farmTag,
        reportData,
        reportDataError,
        historyData,
        historyError,
        historyLoading,
        publications: farmIds?.length > 0 ? publications?.filter((p) => farmIds.includes(p.farmId)) : publications,
        publicationsLoading,
        publicationError,
        farms,
    };
}

export function useAccountReportData(filter) {
    const allRules = filter.filterGroups.flatMap((fg) => fg.rules);
    const currentYear = new Date().getFullYear();
    const yearRule = allRules.filter((r) => r.type === "Year");
    const maxRule = yearRule.length > 0 ? Math.max(...yearRule.filter((r) => !isNaN(r.values[0])).map((r) => Number(r.values[0]))) : currentYear;
    const [maxYear, setMaxYear] = useState(Number(maxRule));
    useEffect(() => {
        if (maxYear !== Number(maxRule)) {
            setMaxYear(Number(maxRule));
        }
    }, [maxRule, maxYear]);

    const authContext = useAuthContext();
    const refData = useRefData();
    const { accountId } = authContext;
    const { data: farm } = useFarm();
    const { data: myPublicationsSummary } = useMyPublicationsSummary();
    const [farmIds, setFarmIds] = useState();
    const { data: farms } = useFarmsPublishedToMyOrg();
    const { data: reportData, error: reportDataError, isLoading: reportDataLoading } = useAccountReportContent(accountId, farmIds, filter);

    const availableYears = myPublicationsSummary
        ?.map((s) => s.year)
        ?.sort()
        ?.reverse();

    const { availableFarmTags, setFarmTag, farmTag } = useReportFarmTags(farms, setFarmIds);
    const { loading: historyLoading, error: historyError, data: historyData } = useAccountHistory(accountId, filter);
    const { isLoading: publicationsLoading, error: publicationError, data: publications } = useAccountReportPublications(accountId, filter);

    setEnterpriseName(reportData, refData);

    return {
        farm,
        availableYears,
        //onFilterChanged: setFilters,
        onFarmTagChanged: setFarmTag,
        availableFarmTags,
        farmIds,
        reportData,
        reportDataError,
        reportDataLoading,
        historyData,
        historyError,
        historyLoading,
        publications: farmIds?.length > 0 ? publications?.filter((p) => farmIds.includes(p.farmId)) : publications,
        publicationsLoading,
        publicationError,
        farmTag,
        farms,
    };
}

export function useFarmsPublishedToMyOrg() {
    const query = useQuery({
        queryKey: ["reporting", "publications", "farms"],
        queryFn: async () => httpClient.get("publications/farms"),
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error,
    };
}

export function useFarmGroup(farmGroupId) {
    const query = useQuery({
        queryKey: ["reporting", "farm-group-details", farmGroupId],
        queryFn: async () => httpClient.get(`farmGroups/${farmGroupId}`),
        enabled: !!farmGroupId,
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error,
    };
}

export function useFarmGroupReportContent(farmGroupId, farmIds = [], filter) {
    const query = useQuery({
        queryKey: ["reporting", "account", farmGroupId, "farmIds", farmIds.join("|"), "filters", JSON.stringify(filter)],
        queryFn: async () => httpClient.post(`reporting/farmgroup/${farmGroupId}/content`, { farmGroupId, filterSet: filter, farmIds }),
        enabled: !!farmGroupId && !!filter,
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error,
    };
}

export function useFarmGroupsByFarm(farmId) {
    const timeout = 1000 * 60 * 2; // 2 minutes

    const query = useQuery({
        queryKey: ["reporting", "farm-details", farmId, "farm-groups"],
        queryFn: async () => httpClient.get(`farms/${farmId}/farmGroups`, timeout),
        enabled: !!farmId,
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error,
    };
}

export function useAcceptFarmGroupInvitationAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async ({ farmGroupId, farmId }) => {
            const timeout = 1000 * 60 * 2; // 2 minutes
            try {
                await httpClient.put(`farmGroups/${farmGroupId}/invitations/${farmId}/accept`, null, timeout);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["dashboard"] });
        },
    });

    return (farmGroupId, farmId) => mutation.mutateAsync({ farmGroupId, farmId });
}

export function useDeclineFarmGroupInvitationAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async ({ farmGroupId, farmId }) => {
            const timeout = 1000 * 60 * 2; // 2 minutes
            try {
                await httpClient.put(`farmGroups/${farmGroupId}/invitations/${farmId}/decline`, null, timeout);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["dashboard"] });
        },
    });

    return (farmGroupId, farmId) => mutation.mutateAsync({ farmGroupId, farmId });
}

export function useCreatePublicationAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (publication) => {
            const timeout = 1000 * 60 * 2; // 2 minutes
            try {
                await httpClient.put(`farms/${publication.farmId}/budgets/${publication.budgetId}/publications`, publication, timeout);
            } catch (error) {
                if (error.status === 426) {
                    throw new Error("Publication modified by another user. Please refresh your browser and try again.");
                } else if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["farm-details", variables.farmId] });
        },
    });

    return (publication) => mutation.mutateAsync(publication);
}

export function useUpdatePublicationAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (publication) => {
            const timeout = 1000 * 60 * 2; // 2 minutes

            try {
                await httpClient.put(`publications/${publication.id}`, publication, timeout);
            } catch (error) {
                if (error.status === 426) {
                    throw new Error("Publication modified by another user. Please refresh your browser and try again.");
                } else if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["farm-details", variables.farmId] });
        },
    });

    return (publication) => mutation.mutateAsync(publication);
}

export function useDeletePublicationAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async ({ farm, publication }) => {
            const timeout = 1000 * 60 * 2; // 2 minutes
            try {
                await httpClient.del(`publications/${publication.id}`, null, timeout);

                // Mutate the react-query cache to remove the publication from the farm so that the publication list
                // does not need to wait for the refetch after invalidating the cache to show the updated list
                farm.publications = farm.publications.filter((p) => p.id !== publication.id);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else if (error.status === 404) {
                    // Do nothing, the farm has already been deleted
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSettled: (_, __, variables) => {
            queryClient.invalidateQueries({ queryKey: ["farm-details", variables.publication.farmId] });
        },
    });

    return (farm, publication) => mutation.mutateAsync({ farm, publication });
}

export function useCreateWorkingCopyAsync() {
    const mutation = useMutation({
        mutationFn: async ({ publication, newWorkingCopyId }) => {
            const timeout = 1000 * 60 * 2; // 2 minutes
            try {
                await httpClient.post(`publications/${publication.id}/workingcopy/${newWorkingCopyId}`, undefined, timeout);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
    });

    return (publication, newWorkingCopyId) => mutation.mutateAsync({ publication, newWorkingCopyId });
}

export function useSetPublicationAsPlanAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (publication) => {
            const timeout = 1000 * 60 * 2; // 2 minutes

            try {
                await httpClient.put(`publications/${publication.id}/plan`, null, timeout);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["farm-details", variables.farmId] });
        },
    });

    return (publication) => mutation.mutateAsync(publication);
}

export function useOnPaymentComplete() {
    const queryClient = useQueryClient();

    return useCallback(() => {
        queryClient.invalidateQueries({ queryKey: ["dashboard"] });
        queryClient.invalidateQueries({ queryKey: ["farm-details"] });
    }, [queryClient]);
}

function useFarmDetails(farmId) {
    const [error, setError] = useState();

    const query = useQuery({
        queryKey: ["farm-details", farmId],
        queryFn: async () => httpClient.get(`v1/farm-details/${farmId}`),
        enabled: !!farmId && error?.status !== 404 && error?.status !== 403,
        retry: false,
        refetchOnWindowFocus: true,
    });

    useEffect(() => {
        if (query.error) {
            setError(query.error);
        }
    }, [query.error]);

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        farm: query.data,
        error,
    };
}

function usePublicFarmDetails(farmId, error) {
    const enabled = !!farmId && error?.status === 403;

    const query = useQuery({
        queryKey: ["farm-details", farmId],
        queryFn: async () => httpClient.get(`search/farms/${farmId}`),
        retry: false,
        refetchOnWindowFocus: true,
        enabled,
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        farm: query.data,
        error: query.error,
    };
}
