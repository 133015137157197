import React from "react";
import { v4 as uuidv4, NIL as NIL_UUID } from "uuid";
import * as utils from "common/utils";
import * as domain from "common/domain";
import * as animalUtils from "../containers/BudgetHome/Animals/_utils";
import * as cropUtils from "../containers/BudgetHome/Crops/_utils";
import * as icons from "common/icons";
import * as climateUtils from "../containers/BudgetHome/Climate/_utils";
import * as supplementUtils from "../containers/BudgetHome/Supplements/_utils";
import * as fertiliserUtils from "../containers/BudgetHome/Fertiliser/_utils";
import * as irrigatorUtils from "../containers/BudgetHome/Irrigation/_utils";
import * as structureUtils from "../containers/BudgetHome/Effluent/_utils";
import numeral from "numeral";
import moment from "moment";

export const farmDetailsViewModel = (budget, refData) => {
    const fields = ["Total area", "Productive block area", "Nitrogen conversion efficiency (NCE)", "N surplus"];
    const productiveBlocks = [domain.BlockType.ProductiveCrop, domain.BlockType.ProductiveFruit, domain.BlockType.ProductivePasture];
    const farmData = {};
    const { currentResults = {} } = budget;
    const { towns = [], regions = [] } = refData;

    farmData[fields[0]] = budget.totalFarmArea > 0 ? `${budget.totalFarmArea} ha` : "Not entered";
    farmData[fields[1]] = budget.blocks.filter((b) => productiveBlocks.includes(b.type)).reduce((a, b) => a + b.areaInHectares, 0);
    farmData[fields[1]] = `${farmData[fields[1]] ? farmData[fields[1]].toFixed(2) : 0} ha`;
    farmData[fields[2]] = currentResults.nce ? currentResults.nce + "%" : "-";
    farmData[fields[3]] = currentResults.nSurplus ? currentResults.nSurplus + " kg/ha" : "-";
    const results = fields.map((field) => ({ name: field, value: farmData[field] }));
    if (currentResults.nearestTown) {
        results.push({ name: "Nearest town", value: utils.valueToText(towns, currentResults.nearestTown) });
    } else if (currentResults.region) {
        results.push({ name: "Region", value: utils.valueToText(regions, currentResults.region) });
    }
    return results;
};

export const farmResultsViewModel = (analysis) => {
    const { currentResults = {} } = analysis;
    const { otherValues = {} } = currentResults;
    const otherValueKeys = Object.keys(otherValues)
        .filter((k) => !k.startsWith("GHG"))
        .map((k) => k)
        .sort();
    return otherValueKeys.map((key) => ({ name: otherValues[key].name, value: otherValues[key].value, key }));
};

export const farmSoilsViewModel = (farm, analysis, refData) => {
    const { farmSoilBlocks = [], blocks = [] } = analysis;
    const soils = analysis.useFarmSoils ? farm.soils.filter((soil) => farmSoilBlocks.find((fsb) => fsb.soilId === soil.id)) : analysis.soils;

    const productiveBlocks = analysis.blocks.filter((b) => b.isProductive && b.type !== "FodderCrop");
    let totalProductiveArea = productiveBlocks.reduce((total, block) => (total += isNaN(block.areaInHectares) ? 0 : block.areaInHectares), 0);

    //avoid % by 0
    totalProductiveArea = totalProductiveArea > 0 ? totalProductiveArea : 1;

    if (!soils || soils.length === 0) return [[], undefined];

    const soilMeta = soils.map((soil, i) => {
        const soilFarmSoilBlocks = farmSoilBlocks.filter((fsb) => fsb.soilId === soil.id);
        const blockIds = soilFarmSoilBlocks.map((fsb) => fsb.blockId);
        const soilBlocks = blocks.filter((blk) => blockIds.includes(blk.id));

        const soilTotalArea = soilFarmSoilBlocks.reduce((t, fsb) => (t += fsb.area), 0);
        const blocksNames = soilBlocks.reduce((allNames, block) => (allNames += allNames.length > 0 ? `, ${block.name}` : block.name), "");
        const percentageOfProductiveBlocks = utils.round((soilTotalArea / totalProductiveArea) * 100, 1);

        const olsenP = soilBlocks.reduce((total, soilBlock) => {
            const soilTest = (analysis.soilTests || []).find((st) => st.id === soilBlock.soilTestId);
            if (soilTest) {
                const proportionedOlsenP = (soilBlock.areaInHectares / soilTotalArea) * soilTest.nutrients.P;
                total += proportionedOlsenP;
            }
            return total;
        }, 0);

        return { soil, soilBlocks, soilTotalArea, blocksNames, percentageOfProductiveBlocks, blockCount: soilBlocks.length, olsenP: utils.round(olsenP, 0) };
    });

    soilMeta.sort((a, b) => b.percentageOfProductiveBlocks - a.percentageOfProductiveBlocks);
    const blockIdsWithSoils = farmSoilBlocks.map((fsb) => fsb.blockId);

    const blocksWithNoSoil = productiveBlocks.filter((b) => !blockIdsWithSoils.includes(b.id));
    const noSoils = {
        blocks: blocksWithNoSoil,
        blocksTotalArea: blocksWithNoSoil.length > 0 ? totalProductiveArea - soilMeta.reduce((total, meta) => (total += meta.soilTotalArea), 0) : 0,
        percentageOfProductive: blocksWithNoSoil.length > 0 ? 100 - soilMeta.reduce((total, meta) => (total += meta.percentageOfProductiveBlocks), 0) : 0,
    };

    const soilModified = (soil) => {
        if (soil.advancedSettings && Object.keys(soil.advancedSettings).length > 0) return true;

        if (soil.profileDrainageClass && soil.profileDrainageClass !== "Usedefault") return true;
        if (soil.isStony !== undefined) return true;
        if (soil.topSoilTexture && soil.topSoilTexture !== "Undefined") return true;
        if (soil.bottomRootingDepth) return true;
        if (soil.impededLayerDepth) return true;
        if (soil.parentMaterial && soil.parentMaterial !== "Undefined") return true;
        if (soil.nonStandardLayerDepth) return true;
        if (soil.textureGroup) return true;

        return false;
    };

    const soilData = soilMeta.map((soilMeta, i) => {
        const { soil, blocksNames, soilTotalArea, blockCount, olsenP } = soilMeta;
        const description = soil.sibling && soil.sibling.description && soil.sibling.description.length > 0 ? soil.sibling.description : undefined;
        const soilProfile = soil && soil.soilGroup && utils.getSoilProfile(soil);
        const profileDrainageClass = soil.profileDrainageClass || soilProfile.profileDrainageClass;
        const drainageClass = profileDrainageClass ? utils.valueToText(refData.drainageClasses, profileDrainageClass) : "Default";

        return {
            smapReference: soil.sibling && soil.sibling.smapReference,
            name: soil.name,
            group: utils.valueToText(refData.soilGroups, soil.soilGroup),
            order: soil.soilOrder === "Undefined" ? "-" : soil.soilOrder,
            drainageClass,
            blocksNames,
            blockCount,
            description,
            modified: soilModified(soil) ? "Yes" : "No",
            totalArea: utils.round(soilTotalArea, 1),
            percentageOfProductive: soilMeta.percentageOfProductiveBlocks,
            olsenP,
        };
    });

    const blocksWithNoSoils = blocksWithNoSoil.length > 0 && {
        blocks: noSoils.blocks.reduce((allNames, block) => (allNames += allNames.length > 0 ? `, ${block.name}` : block.name), ""),
        totalArea: utils.round(noSoils.blocksTotalArea, 1),
        percentageOfProductive: utils.round(noSoils.percentageOfProductive, 1),
    };

    return [soilData, blocksWithNoSoils, totalProductiveArea];
};

export const enterpriseViewModel = (farm, analysis, refData) => {
    const { enterprises = [] } = analysis;
    const enterpriseIsDairyPeak = (ent) => ent.peakCowNumbers && ent.specificationMethod !== "MonthlyStockRec" && ent.type === "Dairy";
    const enterpriseIsRsu = (ent) => ent.specificationMethod === "RSU" && ["Deer", "Sheep", "Beef", "DairyGrazing"].includes(ent.type);
    const enterpriseIsOutdoorPigs = (ent) => ent.type === "OutdoorPigs";
    const enterpriseIsOther = (ent) => ent.type === "Other";

    const so = (enterpriseType) => {
        //The order needs to be slightly different from alphabetical.
        //Dairy and DairyReplacements need to be together but the naughty
        //goats slip in when alpahbetical
        switch (enterpriseType) {
            case "Beef":
                return 0;
            case "DairyGrazing":
                return 1;
            case "Dairy":
                return 2;
            case "DairyReplacements":
                return 3;
            case "DairyGoat":
                return 4;
            case "Deer":
                return 5;
            case "Other":
                return 6;
            case "OutdoorPigs":
                return 7;
            case "Sheep":
                return 8;
            default:
                return 9;
        }
    };

    return enterprises
        .sort((a, b) => (so(a.type) > so(b.type) ? 1 : -1))
        .map((enterprise) => {
            let result = {
                id: enterprise.id,
                enterpriseType: utils.valueToText(refData.enterpriseTypes, enterprise.type),
                enterpriseTypeEnum: enterprise.type,
                icon: utils.getAnimalIcon(enterprise.type),
                url: `/app/farm/${farm.id}/analysis/${analysis.id}/animals/enterprises/${enterprise.id}`,
                slots: animalUtils.animalMonthlyCounts(enterprise),
                mobs: enterprise.mobs,
            };
            result.slotsTotal = result.slots.reduce((sum, slot) => (sum += parseInt(slot.text, 10)), 0);
            result.type = "Standard";

            if (enterpriseIsRsu(enterprise)) {
                result.totalRsu = enterprise.numberOfRSU;
                result.type = "Rsu";
            } else if (enterpriseIsDairyPeak(enterprise)) {
                const { peakCowNumbers = {} } = enterprise;
                result.peakCowsMilked = enterprise.peakCowNumbers.amount;
                result.averageMobWeight = enterprise.peakCowNumbers.averageMobWeight;
                result.type = "DairyPeak";
                result.calvingDetails = peakCowNumbers.calvingDetails;
            } else if (enterpriseIsOutdoorPigs(enterprise)) {
                result = getOutdoorPigsViewModel(farm, analysis, refData);
            } else if (enterpriseIsOther(enterprise)) {
                result.type = "Other";
                result.otherLivestockTotalsByType = (enterprise.otherLivestocks || []).reduce((results, livestock) => {
                    const rsu = parseFloat(livestock.rsu);
                    const number = parseInt(livestock.number, 10);
                    const group = results.find((g) => g.type === livestock.type);
                    if (group) {
                        group.rsu += rsu;
                        group.number += number;
                    } else {
                        const livestockType = animalUtils.getOtherLivestockType(livestock.type);
                        results.push({
                            type: livestockType ? livestockType.text : livestock.type,
                            rsu,
                            number,
                        });
                    }
                    return results;
                }, []);
            } else {
                const peakDairy = analysis.enterprises.find((e) => enterpriseIsDairyPeak(e));
                if (peakDairy && enterprise.type === "DairyReplacements") {
                    const { dairyReplacementGrazing = [] } = refData;
                    result.dairyReplacementGrazingMessage = utils.valueToText(dairyReplacementGrazing, peakDairy.peakCowNumbers.replacementGrazing) || "Replacement method not set. Please update the Dairy enterprise.";
                    result.dairyReplacementGrazingSet = peakDairy.peakCowNumbers.replacementGrazing ? true : false;
                    result.type = "DairyReplacements";
                }
            }
            return result;
        });
};

export const getOutdoorPigsViewModel = (farm, budget, refData) => {
    const enterprises = budget.enterprises || [];
    const outdoorPigsEnterpriseType = "OutdoorPigs";
    const outdoorPigsEnterprise = enterprises.find((enterprise) => enterprise.type === outdoorPigsEnterpriseType) || {};
    const outdoorPigs = outdoorPigsEnterprise.outdoorPigs || {};
    const outdoorPigBlocks = (budget.blocks || []).filter((block) => block.type === "ProductiveOutdoorPigs");
    const outdoorPigDefaults = refData.outdoorPigDefaults || {};
    const outdoorPigStockClassGroups = refData.outdoorPigStockClassGroups || [];
    const outdoorPigStockClasses = (refData.outdoorPigStockClasses || []).filter((o) => !["Boars", "Undefined"].includes(o.value));
    const outdoorPigFeedCompositionGroups = refData.outdoorPigFeedCompositionGroups || [];

    const viewModel = {
        id: outdoorPigsEnterprise.id || uuidv4(),
        enterpriseType: utils.valueToText(refData.enterpriseTypes, outdoorPigsEnterpriseType),
        enterpriseTypeEnum: outdoorPigsEnterpriseType,
        icon: utils.getAnimalIcon(outdoorPigsEnterpriseType),
        url: `/app/farm/${farm.id}/analysis/${budget.id}/animals/enterprises/${outdoorPigsEnterpriseType.toLowerCase()}`,
        slots: [],
        type: outdoorPigsEnterpriseType,
        growOutUnitOnly: outdoorPigs.growOutUnitOnly,
        numbers: outdoorPigs.numbers,
    };

    // Outdoor pigs block area allocations
    viewModel.areaAllocations = outdoorPigBlocks.map((block) => {
        let areaAllocation = (outdoorPigs.areaAllocations || []).find((a) => block.id === a.blockId);
        if (!areaAllocation) {
            areaAllocation = {
                blockId: block.id,
                laneAndOtherAllocation: 0,
                stockAllocations: {},
            };
        }

        const allocations = outdoorPigStockClassGroups.map((o) => {
            const stockClassGroup = o.value;
            const label = utils.getOutdoorPigStockClassGroupDisplayText(stockClassGroup);
            const percentage = areaAllocation.stockAllocations[stockClassGroup] || 0;
            return { stockClassGroup, label, percentage };
        });
        allocations.push({ label: "Lanes and other areas", percentage: areaAllocation.laneAndOtherAllocation });

        return {
            blockId: block.id,
            blockName: block.name,
            blockArea: block.areaInHectares,
            allocations,
        };
    });

    // Outdoor pigs green cover
    viewModel.greenCoverApplications = (outdoorPigs.greenCoverApplications || []).map((greenCoverApplication) => {
        const blockIds = greenCoverApplication.blockIds.map((id) => id);
        const applications = Object.keys(greenCoverApplication.seasons).map((key) => {
            const stockClassGroup = key;
            const seasons = greenCoverApplication.seasons[stockClassGroup];
            return { stockClassGroup, seasons };
        });
        return {
            blockIds,
            applications,
        };
    });

    // Outdoor pigs feeding system
    if (!outdoorPigs.feedingSystem) {
        outdoorPigs.feedingSystem = {
            feedingMethods: {},
            noBirdLossOccurs: false,
            creepFeedSupplied: false,
        };
        outdoorPigStockClassGroups.forEach((o) => (outdoorPigs.feedingSystem.feedingMethods[o.value] = { usePellets: false }));
    }
    viewModel.feedingSystem = {
        ...outdoorPigs.feedingSystem,
        feedingMethods: outdoorPigStockClassGroups.map((o) => {
            const stockClassGroup = o.value;
            const label = utils.getOutdoorPigStockClassGroupDisplayText(stockClassGroup);
            return { ...outdoorPigs.feedingSystem.feedingMethods[stockClassGroup], stockClassGroup, label };
        }),
    };

    // Outdoor pigs feed amounts
    if (!outdoorPigs.feedAmounts) {
        outdoorPigs.feedAmounts = {};
        outdoorPigStockClasses.forEach((o) => {
            const stockClass = o.value;
            const defaultFeedAmount = outdoorPigDefaults.feedAmountDefaults[stockClass];
            const months = domain.calendarYear.map((m) => ({ month: m, amount: defaultFeedAmount }));
            outdoorPigs.feedAmounts[stockClass] = { months };
        });
    }
    viewModel.nonSowFeedAmounts = outdoorPigStockClasses
        .filter((o) => !o.value.startsWith("Sows"))
        .map((o) => {
            const stockClass = o.value;
            const defaultFeedAmount = outdoorPigDefaults.feedAmountDefaults[stockClass];
            const feedAmount = outdoorPigs.feedAmounts[o.value];
            const override = feedAmount ? feedAmount.months.some((m) => m.amount !== defaultFeedAmount) : false;
            const months = feedAmount ? feedAmount.months : domain.calendarYear.map((m) => ({ month: m, amount: defaultFeedAmount }));
            return { stockClass, override, months };
        });
    viewModel.sowFeedAmounts = outdoorPigStockClasses
        .filter((o) => o.value.startsWith("Sows"))
        .map((o) => {
            const stockClass = o.value;
            const defaultFeedAmount = outdoorPigDefaults.feedAmountDefaults[stockClass];
            const feedAmount = outdoorPigs.feedAmounts[o.value];
            const override = feedAmount ? feedAmount.months.some((m) => m.amount !== defaultFeedAmount) : false;
            const months = feedAmount ? feedAmount.months : domain.calendarYear.map((m) => ({ month: m, amount: defaultFeedAmount }));
            return { stockClass, override, months };
        });

    // Outdoor pigs feed composition
    if (!outdoorPigs.feedCompositions) {
        outdoorPigs.feedCompositions = {};
        outdoorPigFeedCompositionGroups.forEach((o) => {
            const feedCompositionGroup = o.value;
            const feedCompositionDefaults = outdoorPigDefaults.feedCompositionDefaults[feedCompositionGroup];
            outdoorPigs.feedCompositions[feedCompositionGroup] = feedCompositionDefaults;
        });
    }
    viewModel.nonSowFeedCompositions = outdoorPigFeedCompositionGroups
        .filter((o) => !o.value.startsWith("Sows"))
        .map((o) => {
            const feedCompositionGroup = o.value;
            const feedCompositionDefaults = outdoorPigDefaults.feedCompositionDefaults[feedCompositionGroup];
            const feedCompositions = outdoorPigs.feedCompositions[feedCompositionGroup] || feedCompositionDefaults;
            const override = isOutdoorPigsFeedCompositionOverridden(feedCompositions, feedCompositionDefaults);
            return { ...feedCompositions, override, feedCompositionGroup };
        });
    viewModel.sowFeedCompositions = outdoorPigFeedCompositionGroups
        .filter((o) => o.value.startsWith("Sows"))
        .map((o) => {
            const feedCompositionGroup = o.value;
            const feedCompositionDefaults = outdoorPigDefaults.feedCompositionDefaults[feedCompositionGroup];
            const feedCompositions = outdoorPigs.feedCompositions[feedCompositionGroup] || feedCompositionDefaults;
            const override = isOutdoorPigsFeedCompositionOverridden(feedCompositions, feedCompositionDefaults);
            return { ...feedCompositions, override, feedCompositionGroup };
        });

    return viewModel;
};

const isOutdoorPigsFeedCompositionOverridden = (feedCompositions, feedCompositionDefaults) => {
    const override = Object.keys(feedCompositions).some((key) => {
        const defaultAmount = feedCompositionDefaults[key];
        const actualAmount = feedCompositions[key];
        if (key !== "nutrients") {
            return defaultAmount !== actualAmount;
        } else {
            return Object.keys(feedCompositions.nutrients).some((nutrientKey) => feedCompositions.nutrients[nutrientKey] !== feedCompositionDefaults.nutrients[nutrientKey]);
        }
    });
    return override;
};

export const cropViewModel = (analysis, refData) => {
    const fodderBlockAreas = utils.getFodderBlockAreas(analysis.blocks);

    var crops = {};
    for (var block of analysis.blocks.filter((b) => (b.pasture && b.pasture.pastureCategory) || b.fruit)) {
        const pastureResults = block.currentResults && block.currentResults.pastureResults ? block.currentResults.pastureResults : {};
        const area = block.type === domain.BlockType.FodderCrop ? block.rotationArea : fodderBlockAreas.pastureBlockIds.has(block.id) ? block.areaInHectares - block.areaInHectares * fodderBlockAreas.ratio : block.areaInHectares || 0;

        //console.log(block.name + "-" + area)
        var key = block.pasture ? block.pasture.pastureCategory : block.fruit.cropType;
        var grown = pastureResults.pastureGrowth ? (pastureResults.pastureGrowth * area) / 1000 : 0;
        var intake = pastureResults.pastureIntake ? (pastureResults.pastureIntake * area) / 1000 : 0;
        var supplements = pastureResults.supplementsRemoved ? (pastureResults.supplementsRemoved * area) / 1000 : 0;

        if (!crops[key]) {
            crops[key] = { area: area, yield: 0, grown: grown, intake: intake, supplements: supplements };

            if (block.pasture) {
                crops[key].type = "Pasture";
                crops[key].icon = icons.pasture;
                crops[key].name = utils.valueToText(refData.pastureTypes, block.pasture.pastureCategory);
            } else {
                crops[key].icon = utils.getFruitIcon(block.fruit.cropType);
                crops[key].name = block.fruit.cropType;
                crops[key].yield = block.fruit.productYield;
                const yieldData = (refData && refData.fruitTypes && refData.fruitTypes[block.fruit.cropType]) || {};
                crops[key].units = yieldData && yieldData.unitsDescription;
            }
        } else {
            crops[key].area += area;
            crops[key].yield += block.fruit ? block.fruit.productYield : 0;
            crops[key].grown += grown;
            crops[key].intake += intake;
            crops[key].supplements += supplements;
        }
    }

    /* eslint-disable no-unused-vars */
    for (let cropBlock of analysis.blocks.filter((b) => b.crops && b.crops.length > 0)) {
        /* eslint-disable no-unused-vars */
        for (let crop of cropBlock.crops) {
            if (cropBlock.type === domain.BlockType.FodderCrop) {
                if (crop.events.find((e) => e.month === cropBlock.monthResown && e.reportingYear)) continue;
            }
            if (crop.events.find((e) => e.type === "Cropsown" && e.reportingYear)) {
                const cropKey = crop.cropId;
                const area = cropBlock.areaInHectares ? (cropBlock.areaInHectares * cropBlock.cropBlock.cultivatedAreaPercent) / 100 : cropBlock.rotationArea;
                if (!crops[cropKey]) {
                    let { cropCategories = [] } = refData;
                    const selectedCategory = cropCategories.find((c) => c.value === crop.category);
                    const cropTypes = selectedCategory ? selectedCategory.children : [];
                    const selectedCropType = cropTypes.find((t) => t.value === crop.cropId) || undefined;
                    const uom = selectedCropType && selectedCropType.yieldUnits ? selectedCropType.yieldUnits?.replace("T/ha", "t")?.replace("dry matter", "DM") : "t DM";
                    crops[cropKey] = { icon: utils.getCropIcon(crop), name: cropUtils.getCropNameLabel(crop, refData), area: area, yield: crop.productYield ? utils.round(crop.productYield * area, 2) : undefined, units: uom };
                } else {
                    crops[cropKey].area += area;
                    crops[cropKey].yield += utils.round(crop.productYield * area, 2);
                }
            }
        }
    }

    return crops;
};

export const nutrientLossViewModel = (analysis) => {
    let latestResults = analysis.currentResults || {};

    const nLost = !latestResults.nitrogenOverview || latestResults.nitrogenOverview.farmLossPerHa === undefined ? "-" : latestResults.nitrogenOverview.farmLossPerHa;
    const pLost = !latestResults.phosphorusOverview || latestResults.phosphorusOverview.farmLossPerHa === undefined ? "-" : latestResults.phosphorusOverview.farmLossPerHa;
    const totalNLost = !latestResults.nitrogenOverview || latestResults.nitrogenOverview.farmTotalLoss === undefined ? "-" : latestResults.nitrogenOverview.farmTotalLoss;
    const totalPLost = !latestResults.phosphorusOverview || latestResults.phosphorusOverview.farmTotalLoss === undefined ? "-" : latestResults.phosphorusOverview.farmTotalLoss;
    const ghg = latestResults.ghgResults && latestResults.ghgResults.total !== undefined ? latestResults.ghgResults.total : "-";
    const totalGhg = latestResults.ghgResults && latestResults.ghgResults.total !== undefined ? utils.round((latestResults.ghgResults.total * latestResults.ghgResults.reportingArea) / 1000, 1) : "-";

    const version = analysis.modelVersion;

    return { nLost, pLost, totalNLost, totalPLost, ghg, totalGhg, version };
};

export const climateViewModel = (farm, budget, block, refData) => {
    const requiresClimate = utils.requiresClimate(block.type);

    const snowfall = (budget.snowfalls || []).find((snowfall, index) => {
        return snowfall.blockIds.some((blockId) => blockId === block.id);
    });
    const canHaveSnowfall = climateUtils.canHaveSnowfall(block.type);

    let averageTemp = "-";
    let averageRain = "-";
    let annualPET = "-";
    const overrideClimate = { overrideClimateLocation: false };
    if (requiresClimate) {
        if (block.climate) {
            averageTemp = block.climate.averageTemp + " ˚ C";
            averageRain = block.climate.averageRain.toLocaleString() + " mm/yr";
            if (block.climate.annualPetInMM && block.climate.annualPetInMM > 0) {
                annualPET = block.climate.annualPetInMM.toLocaleString() + " mm/yr";
            } else if (block.climate.annualPET) {
                annualPET = utils.valueToText(refData.petValues, block.climate.annualPET);
            }

            if (block.overrideClimateLocation) {
                overrideClimate.overrideClimateLocation = true;
                overrideClimate.longitude = block.climateLongitude;
                overrideClimate.latitude = block.climateLatitude;
            }
        } else if (farm.defaultClimate) {
            averageTemp = `Using farm climate (${farm.defaultClimate.averageTemp} ˚ C)`;
            averageRain = `Using farm climate (${farm.defaultClimate.averageRain} mm/yr)`;
            annualPET = `Using farm climate (${farm.defaultClimate.annualPetInMM.toLocaleString()} mm/yr)`;
        }
    }

    return {
        averageTemp,
        averageRain,
        annualPET,
        canHaveSnowfall: canHaveSnowfall ? (snowfall ? "Yes" : "None") : "Not required",
        snowfall,
        ...overrideClimate,
    };
};

export const supplementsViewModel = (analysis, refData) => {
    const supplements = analysis.feedSupplements || [];

    return supplements.map((supplement) => {
        const isHarvested = supplement.type === "Harvested";
        const sourceType = isHarvested ? "Harvested:" : "Imported:";
        const sourcedFrom = supplementUtils.getSourcedFrom(analysis, supplement, refData);
        const category = utils.valueToText(refData.supplementCategories, supplement.category);
        const feed = supplement.name ? supplement.name.replace("Dairy goat feed", "Dried distillers grain") : "-";

        const amountTypeMeta = supplementUtils.getAmountTypeMeta(supplement.amountType);
        const amounts = supplementUtils.getAmounts(analysis, supplement);
        const icon = utils.getSupplementIcon(supplement);
        const distributedTo = supplementUtils.getDistributedTo(analysis, supplement, refData);

        const { messages = [] } = analysis;
        const hasErrors = messages.some((m) => m.category === "Supplements" && m.entityType === "FeedSupplement" && m.entityId === supplement.id && m.severity === "Error");
        const hasWarnings = messages.some((m) => m.category === "Supplements" && m.entityType === "FeedSupplement" && m.entityId === supplement.id && m.severity === "Warning");
        return {
            ...supplement,
            sourceType,
            sourcedFrom,
            category,
            feed,
            amountTypeMeta,
            amounts,
            icon,
            distributedTo,
            hasErrors,
            hasWarnings,
        };
    });
};

export const blockIrrigationViewModel = (budget, irrigators) => {
    // Sample view model shape...
    /*
        const viewModel = [
            { id: '123', name: 'Cut and carry block', type: 'ProductivePasture', area: 34.5, irrigationSystems: [{ id: 'abc', name: 'Pivot 1, With a much longer name', percentage: 15, 1eadOnly: true, isDrawn: true }, { id: 'def', name: 'Pivot 2', percentage: 12 }] },
            { id: '456', name: 'Grazing block', type: 'ProductivePasture', area: 12.6, irrigationSystems: [{ id: 'abc', name: 'Pivot 1', percentage: 88, isReadOnly: true, isDrawn: true }, { id: 'ghi', name: 'Pivot 3', percentage: 5, isReadOnly: true, isDrawn: true }] },
            { id: '789', name: 'Kiwifruit block', type: 'ProductiveFruit', area: 6.1, irrigationSystems: [{ id: 'def', name: 'Pivot 2', percentage: 73 }] },
            { id: '101', name: 'Fodder crop block', type: 'FodderCrop', area: 10, irrigationSystems: [{ id: 'abc', name: 'Pivot 1', percentage: 100, isReadOnly: true, isDrawn: false }] }
        ];
    */
    const viewModel = [];

    // Map the api structure to the view model
    irrigators.forEach((system) => {
        // A single system can only be either drawn or non-drawn, not both.
        if ((budget.features || []).some((f) => f.properties.irrigatorId === system.id)) {
            const intersectedBlocks = irrigatorUtils.getBlocksIntersectedByDrawnIrrigators(budget, system.id);
            intersectedBlocks.forEach((intersectedBlock) => {
                let viewModelBlock = viewModel.find((b) => b.id === intersectedBlock.id);
                if (!viewModelBlock) {
                    viewModelBlock = {
                        id: intersectedBlock.id,
                        name: intersectedBlock.name,
                        type: intersectedBlock.type,
                        area: intersectedBlock.area,
                        irrigationSystems: [],
                    };
                    viewModel.push(viewModelBlock);
                }

                const blocksWithTooManySystems = irrigatorUtils.getBlocksWithTooManyIrrigators(system, budget, intersectedBlocks);
                const thisBlockDoesNotHaveTooManySystems = !blocksWithTooManySystems.some((b) => b.id === viewModelBlock.id);

                if (thisBlockDoesNotHaveTooManySystems) {
                    viewModelBlock.irrigationSystems.splice(0, 0, {
                        id: system.id,
                        name: system.name,
                        isDrawn: true,
                        isReadOnly: true,
                        percentage: intersectedBlock.irrigatedPercentage,
                    });
                }
            });

            // Special case for FodderCrop blocks. Because they don't appear on the map, they can also be selected
            // from the block selector when the irrigation system is drawn.
            const fodderCropBlocks = (budget.blocks || []).filter((b) => b.type === "FodderCrop");
            const fodderCropBlocksWithApplications = fodderCropBlocks.filter((fodderCropBlock) => system.applications.some((app) => app.blockIds.includes(fodderCropBlock.id)));
            fodderCropBlocksWithApplications.forEach((fodderCropBlock) => {
                viewModel.push({
                    id: fodderCropBlock.id,
                    name: fodderCropBlock.name,
                    type: fodderCropBlock.type,
                    area: fodderCropBlock.rotationArea,
                    irrigationSystems: [
                        {
                            id: system.id,
                            name: system.name,
                            isDrawn: false,
                            isReadOnly: true,
                            percentageOfNonDrawnArea: 100,
                        },
                    ],
                });
            });
        } else if (system.nonDrawnArea && system.nonDrawnArea.length > 0) {
            system.nonDrawnArea.forEach((nonDrawn) => {
                let block = viewModel.find((b) => b.id === nonDrawn.blockId);
                if (!block) {
                    const blockFromBudget = (budget.blocks || []).find((b) => b.id === nonDrawn.blockId);
                    if (blockFromBudget) {
                        block = {
                            id: blockFromBudget.id,
                            name: blockFromBudget.name,
                            type: blockFromBudget.type,
                            area: blockFromBudget.areaInHectares || blockFromBudget.rotationArea,
                            irrigationSystems: [],
                        };
                        viewModel.push(block);
                    }
                }

                if (block && !block.irrigationSystems.some((sys) => sys.id === system.id)) {
                    block.irrigationSystems.push({
                        id: system.id,
                        name: system.name,
                        isDrawn: false,
                        isReadOnly: block.type === "FodderCrop" ? true : false,
                        percentageOfNonDrawnArea: nonDrawn.percentageOfNonDrawnArea,
                    });
                }
            });
        }
    });

    // Drawn areas are fixed from the drawing. Non-drawn areas are stored as a % of the non-drawn area and need
    // to be translated to a % for the view model to use for the slider
    viewModel.forEach((block) => {
        const totalPercentage = block.irrigationSystems.reduce((sum, sys) => (sum += sys.percentage || 0), 0);
        const remainingPercentage = utils.round(100 - totalPercentage, 1);
        block.irrigationSystems.forEach((sys) => {
            const isNonDrawn = !sys.isDrawn;
            if (isNonDrawn) {
                if (sys.percentageOfNonDrawnArea === 0) {
                    // Any newly added applications will default to 0 to force the user to set a % manually.
                    sys.percentage = 0;
                } else if (sys.percentageOfNonDrawnArea > 0) {
                    // Any existing ones need to be converted from % of non-drawn to % for slider.
                    sys.percentage = utils.round(remainingPercentage * (sys.percentageOfNonDrawnArea / 100), 1);
                }
            }
        });
    });
    return viewModel;
};

export const getAnalysisViewModel = (farm, analysis, refData) => {
    return {
        name: analysis.name,
        details: analysis,
        summary: monthlyBlockValuesModel(refData, analysis, farm),
    };
};

export const monthlyBlockValuesModel = (refData, budget, farm) => {
    const { fertiliser = [], enterprises = [], irrigators = [], blocks = [] } = budget;
    const animalResults = budget.currentResults && budget.currentResults.animalResults;
    const nutrientBudgetCategories = (budget.currentResults && budget.currentResults.nutrientBudget && budget.currentResults.nutrientBudget.nutrientCategories) || {};

    const fertIcon = utils.getFertiliserIcon("General");
    const fodderBlockAreas = utils.getFodderBlockAreas(blocks);

    let summary = { productiveArea: 0, croppingArea: 0, pastureArea: 0, fruitArea: 0, climateArea: 0, temperature: 0, rainfall: 0, pet: 0, croppingNLoss: 0, pastureProduction: 0, milkProduction: 0, animalRSU: 0, rsuPerHa: 0, supplementsImportedNKG: 0, blocks: [], categories: [] };

    summary.supplementsImportedNKG = (nutrientBudgetCategories["Supplements"] && nutrientBudgetCategories["Supplements"].subTotals["Imported"] && nutrientBudgetCategories["Supplements"].subTotals["Imported"].nutrients["N"]) || 0;

    const farmRsuCategory = { name: "RSU", label: "Total RSU", rows: [] };
    const animalCountCategory = { name: "AnimalCount", label: "Animal numbers", rows: [] };
    const farmFertiliserCategory = { name: "Fertiliser", label: "Total fertiliser (kg)", rows: [] };
    const farmIrrigationCategory = { name: "Irrigation", label: "Total irrigation (mm)", rows: [] };
    if (animalResults) summary.categories.push(farmRsuCategory);
    if (animalResults) summary.categories.push(animalCountCategory);
    summary.categories.push(farmFertiliserCategory);
    summary.categories.push(farmIrrigationCategory);

    const enterprisesVm = enterpriseViewModel({ id: budget.farmId }, budget, refData);

    const totalRsuRow = { name: "Total", labels: ["All enterprises"], months: [], uom: "RSU" };

    const enterpriseMap = enterprises.reduce((map, e) => {
        map[e.id] = { name: utils.valueToText(refData.enterpriseTypes, e.type), icon: utils.getAnimalIcon(e.type) };
        if (animalResults && animalResults[e.id]) {
            summary.animalRSU += animalResults[e.id].total;
            summary.rsuPerHa += animalResults[e.id].totalPerHa;
            summary.rsuGrazedPerHa += animalResults[e.id].grazedPerHa;

            const { sources = {} } = animalResults[e.id] || {};
            const entVm = enterprisesVm.find((ev) => ev.id === e.id);

            const enterpriseRsuRow = {
                name: map[e.id].name,
                labels: [map[e.id].name],
                months: domain.farmYear.map((m) => {
                    let mTotal = 0;
                    Object.keys(sources).forEach((st, i) => {
                        const stMonth = sources[st].produced.find((ms) => ms.month === m) || { proportion: 0, perAnimal: 0, value: 0 };
                        mTotal += stMonth.perAnimal;
                    });
                    let totalRsuRowMonth = totalRsuRow.months.find((_) => _.month === m);
                    if (!totalRsuRowMonth) {
                        totalRsuRowMonth = { month: m, reportingYear: true, value: 0 };
                        totalRsuRow.months.push(totalRsuRowMonth);
                    }
                    totalRsuRowMonth.value += mTotal;
                    return { month: m, reportingYear: true, value: mTotal };
                }),
                uom: "RSU",
            };

            if (entVm.slots.length > 0 && entVm.type === "Standard") {
                const enterpriseCountRow = {
                    name: map[e.id].name,
                    labels: [map[e.id].name],
                    uom: "Count",
                };
                enterpriseCountRow.months = domain.farmYear.map((m, mi) => {
                    const slot = entVm.slots.find((slot) => slot.monthIndx === mi) || entVm.slots.find((slot) => slot.month === m);
                    return { month: m, reportingYear: true, value: slot && !isNaN(slot.text) ? slot.text : 0 };
                });
                animalCountCategory.rows.push(enterpriseCountRow);
            }

            farmRsuCategory.rows.push(enterpriseRsuRow);
        }

        summary.milkProduction += e.annualMilkSolidsYield_kgyr || 0;
        return map;
    }, {});

    if (farmRsuCategory.rows.length > 1) farmRsuCategory.rows.unshift(totalRsuRow);

    const drainageMonths = blocks
        .filter((b) => b.isProductive)
        .reduce((a, b) => {
            const blockResults = b.currentResults || {};
            blockResults.soilIrrigation &&
                blockResults.soilIrrigation
                    .filter((s) => s.results)
                    .forEach((si) => {
                        const drainage = si.results.find((r) => r.type === "WaterDrainage600");
                        if (drainage) {
                            Object.keys(drainage.results).forEach((m) => {
                                var existingMonth = a.find((em) => em.month === m);
                                if (!existingMonth) {
                                    existingMonth = { month: m, value: 0 };
                                    a.push(existingMonth);
                                }

                                existingMonth.value += drainage.results[m] * blockResults.area;
                            });
                        }
                    });
            return a;
        }, []);

    blocks
        .filter((b) => b.isProductive)
        .forEach((b) => {
            const blockResults = b.currentResults || {};
            const blockMonths = { id: b.id, name: b.name, type: b.type, pasture: b.pasture && b.pasture.pastureCategory, pet: b.climate && b.climate.annualPetInMM, rain: b.climate && b.climate.averageRain, temperature: b.climate && b.climate.averageTemp, fertiliser: 0, irrigation: 0, paw: 0, isCutAndCarry: false, categories: [] };
            blockMonths.pastureGrowth = blockResults.pastureResults && blockResults.pastureResults.pastureGrowth;
            blockMonths.paw = blockResults.soilIrrigation && blockResults.soilIrrigation.filter((s) => s.otherValues).reduce((sum, s) => (sum += (s.otherValues.paw60 * s.percentageOfBlock) / 100), 0);
            blockMonths.isCutAndCarry = b.type === "ProductivePasture" && !b.runoffCharacteristics;
            const area = b.type === domain.BlockType.FodderCrop ? b.rotationArea : fodderBlockAreas.pastureBlockIds.has(b.id) ? b.areaInHectares - b.areaInHectares * fodderBlockAreas.ratio : b.areaInHectares || 0;

            if (b.climate && b.areaInHectares) {
                summary.temperature += (b.climate.averageTemp || 0) * b.areaInHectares;
                summary.rainfall += (b.climate.averageRain || 0) * b.areaInHectares;
                summary.pet += (b.climate.annualPetInMM || 0) * b.areaInHectares;
                summary.climateArea += b.areaInHectares;
            } else if (farm && farm.defaultClimate && b.areaInHectares) {
                summary.temperature += (farm.defaultClimate.averageTemp || 0) * b.areaInHectares;
                summary.rainfall += (farm.defaultClimate.averageRain || 0) * b.areaInHectares;
                summary.pet += (farm.defaultClimate.annualPetInMM || 0) * b.areaInHectares;
                summary.climateArea += b.areaInHectares;
            }
            if (blockMonths.pastureGrowth && b.areaInHectares) summary.pastureProduction += blockMonths.pastureGrowth * area;

            summary.productiveArea += area;
            if (b.type === domain.BlockType.ProductiveCrop || b.type === domain.BlockType.FodderCrop) summary.croppingArea += area;
            else if (b.type === domain.BlockType.ProductivePasture) summary.pastureArea += area;
            else if (b.type === domain.BlockType.ProductiveFruit) summary.fruitArea += area;

            summary.blocks.push(blockMonths);

            if (blockResults.pastureResults && blockResults.pastureResults.pastureRsu) {
                const rsuCategory = { name: "RSU", label: "RSU/ha", rows: [] };

                blockMonths.categories.push(rsuCategory);

                const totalRow = { name: "Total", labels: [], months: [], uom: "RSU/ha" };
                rsuCategory.rows.push(totalRow);

                Object.keys(blockResults.pastureResults.pastureRsu).forEach((eid) => {
                    totalRow.labels.push(enterpriseMap[eid].name);

                    rsuCategory.rows.push({ labels: [enterpriseMap[eid].name], icon: enterpriseMap[eid].icon, months: blockResults.pastureResults.pastureRsu[eid] });

                    blockResults.pastureResults.pastureRsu[eid].forEach((m) => {
                        let totalMonth = totalRow.months.find((totalm) => totalm.month === m.month && totalm.reportingYear === m.reportingYear);
                        if (!totalMonth) {
                            totalMonth = { month: m.month, reportingYear: m.reportingYear, value: 0 };
                            totalRow.months.push(totalMonth);
                        }
                        totalMonth.value = utils.round(totalMonth.value + m.value, 2);
                    });
                });
            }
        });

    const nutrientsToUse = ["N", "P", "K", "S"];

    fertiliser
        .filter((f) => !f.isLastPreviousLime)
        .forEach((f) => {
            const dmFraction = f.dmContent > 0 ? f.dmContent / 100 : 1;

            f.applications.forEach((a) => {
                let kgsNutrientPerHaPerMonth = {};

                if (a.amount > 0) {
                    const nutrientsOnApplication = fertiliserUtils.nutrientsOnApplication(f);
                    const factoryEffluent = f.dairyFactoryEffluent && refData.factoryEffluent.find((r) => r.value === f.dairyFactoryEffluent);
                    const { nutrients = {} } = nutrientsOnApplication ? a : factoryEffluent || f;

                    Object.keys(nutrients)
                        .filter((k) => nutrientsToUse.includes(k))
                        .forEach((k) => {
                            kgsNutrientPerHaPerMonth[k] = fertiliserUtils.calcTonnesPerHaMonth(a.amount, a.unit, budget.blocks, a.blockIds) * dmFraction * nutrients[k] * 10;
                        });
                } else {
                    // soluble or organic so amount is in nutrients
                    Object.keys(a.nutrients)
                        .filter((k) => nutrientsToUse.includes(k))
                        .forEach((k) => {
                            kgsNutrientPerHaPerMonth[k] = fertiliserUtils.calcTonnesPerHaMonth(a.nutrients[k], a.unit, budget.blocks, a.blockIds) * 1000;
                        });
                }
                const totalArea = fertiliserUtils.getApplicationBlockArea(a.blockIds, budget.blocks, fodderBlockAreas);

                a.blockIds.forEach((blockId, blockIdIndex) => {
                    const blockMonths = summary.blocks.find((bm) => bm.id === blockId);
                    let category = blockMonths.categories.find((c) => c.name === "Fertiliser");
                    if (!category) {
                        category = { name: "Fertiliser", label: "Fertiliser applied (kg/ha)", rows: [], uom: "kg/ha" };
                        blockMonths.categories.push(category);
                    }

                    nutrientsToUse.forEach((k) => {
                        let totalRow = category.rows.find((r) => r.name === k);
                        if (!totalRow) {
                            totalRow = { name: k, labels: [`${k}`], icon: fertIcon, months: [], uom: "kg/ha" };
                            category.rows.push(totalRow);
                        }
                        let farmTotalRow = farmFertiliserCategory.rows.find((r) => r.name === k);
                        if (!farmTotalRow) {
                            farmTotalRow = { name: k, labels: [`${k}`], icon: fertIcon, months: [], uom: "kg" };
                            farmFertiliserCategory.rows.push(farmTotalRow);
                        }
                        a.months.forEach((m) => {
                            let totalMonth = totalRow.months.find((totalm) => totalm.month === m.month && totalm.reportingYear === m.reportingYear);
                            if (!totalMonth) {
                                totalMonth = { month: m.month, reportingYear: m.reportingYear, value: 0 };
                                totalRow.months.push(totalMonth);
                            }
                            let farmTotalMonth = farmTotalRow.months.find((totalm) => totalm.month === m.month && totalm.reportingYear === m.reportingYear);
                            if (!farmTotalMonth) {
                                farmTotalMonth = { month: m.month, reportingYear: m.reportingYear, value: 0 };
                                farmTotalRow.months.push(farmTotalMonth);
                            }

                            totalMonth.value += numeral(numeral(kgsNutrientPerHaPerMonth[k]).format("0")).value();
                            if (blockIdIndex === 0) farmTotalMonth.value += numeral(numeral(kgsNutrientPerHaPerMonth[k] * totalArea).format("0")).value();

                            if (k === "N") blockMonths.fertiliser += numeral(numeral(kgsNutrientPerHaPerMonth[k]).format("0")).value();
                        });
                    });
                });
            });
            // change to KGs
        });

    const farmTotalRow = { name: "Applied", labels: ["Total applied"], months: [], uom: "kilolitres" };
    farmIrrigationCategory.rows.push(farmTotalRow);

    const irrigatorModel = blockIrrigationViewModel(budget, irrigators);

    irrigators.forEach((i) => {
        const { irrigatorTypes = [], irrigatorSource = [] } = refData;
        const { nutrients } = i;
        const source = irrigatorSource.find((s) => s.value === i.nutrientSource);
        const sourceText = source ? source.text : "Custom";
        const irrigatorType = utils.valueToText(irrigatorTypes, i.type);
        const icon = utils.getIrrigatorIcon(i.type);
        const nutrientsText = `N:${nutrients.N || 0} P:${nutrients.P || 0} K:${nutrients.K || 0} S:${nutrients.S || 0} Ca:${nutrients.Ca || 0} Mg:${nutrients.Mg || 0} Na:${nutrients.Na || 0}`;
        const name = `${i.name} (${irrigatorType}): ${sourceText}  |  ${nutrientsText}`;

        budget.blocks.forEach((b) => {
            const blockResults = b.currentResults || {};
            const blockIrrigatorModel = irrigatorModel.find((im) => im.id === b.id);

            if (blockResults.irrigationResults && blockResults.irrigationResults[i.id]) {
                const blockMonths = summary.blocks.find((bm) => bm.id === b.id);
                let category = blockMonths.categories.find((c) => c.name === "Irrigation");
                if (!category) {
                    category = { name: "Irrigation", label: "Irrigation applied (mm)", rows: [{ name: "Applied", labels: ["Avg applied (mm)"], uom: "mm", months: [], icon }], subCategories: [] };
                    blockMonths.categories.push(category);
                }
                let subcategory = category.subCategories.find((c) => c.id === i.id);
                if (!subcategory) {
                    subcategory = { id: i.id, name: i.id, label: name, rows: [] };
                    category.subCategories.push(subcategory);
                }

                const supplied = { name: "Supplied", labels: ["Supplied (mm)"], uom: "mm", months: [], icon };
                const applied = { name: "Applied", labels: ["Applied (mm)"], uom: "mm", months: [], icon };
                const depth = { name: "Depth", labels: ["Depth (mm)"], uom: "mm", months: [], icon };
                const period = { name: "Return", labels: ["Return (days)"], uom: "mm", months: [], icon };
                blockResults.irrigationResults[i.id].months.forEach((m) => {
                    if (m.reportingYear) {
                        supplied.months.push({ month: m.month, reportingYear: m.reportingYear, value: m.supplied });
                        applied.months.push({ month: m.month, reportingYear: m.reportingYear, value: m.applied });

                        const totalRow = category.rows.find((r) => r.name === "Applied");
                        let appliedMonth = totalRow.months.find((am) => am.month === m.month && am.reportingYear === m.reportingYear);
                        if (!appliedMonth) {
                            appliedMonth = { month: m.month, reportingYear: m.reportingYear, value: 0 };
                            totalRow.months.push(appliedMonth);
                        }
                        let farmAppliedMonth = farmTotalRow.months.find((am) => am.month === m.month && am.reportingYear === m.reportingYear);
                        if (!farmAppliedMonth) {
                            farmAppliedMonth = { month: m.month, reportingYear: m.reportingYear, value: 0 };
                            farmTotalRow.months.push(farmAppliedMonth);
                        }

                        appliedMonth.value = utils.round(appliedMonth.value + (m.applied * blockResults.irrigationResults[i.id].blockPercentage) / 100, 0);
                        farmAppliedMonth.value = blockIrrigatorModel && utils.round(farmAppliedMonth.value + ((m.applied * blockResults.irrigationResults[i.id].blockPercentage) / 100) * blockIrrigatorModel.area * 10, 0);
                        blockMonths.irrigation = blockIrrigatorModel && utils.round(blockMonths.irrigation + m.applied * blockIrrigatorModel.area * 10, 0);
                    }
                    depth.months.push({ month: m.month, reportingYear: m.reportingYear, value: m.applicationDepth });
                    period.months.push({ month: m.month, reportingYear: m.reportingYear, value: m.returnPeriod });
                });

                subcategory.rows.push(supplied);
                subcategory.rows.push(applied);
                subcategory.rows.push(depth);
                subcategory.rows.push(period);
            }
        });
    });
    if (summary.climateArea) {
        summary.temperature = utils.round(summary.temperature / summary.climateArea, 1);
        summary.rainfall = utils.round(summary.rainfall / summary.climateArea, 0);
        summary.pet = utils.round(summary.pet / summary.climateArea, 0);
    }
    summary.pastureProduction = utils.round(summary.pastureProduction / summary.pastureArea, 0);
    summary.animalRSU = utils.round(summary.animalRSU, 1);
    summary.rsuPerHa = utils.round(summary.rsuPerHa, 1);
    summary.irrigationSystems = irrigatorViewModel(budget, refData);
    summary.irrigatedArea = summary.irrigationSystems.reduce((sum, irrigationSystem) => (sum += irrigationSystem.area), 0);
    summary.drainageMonths = drainageMonths;

    return summary;
};

export const fertiliserViewModel = (budget, refData) => {
    const fertilisers = budget.fertiliser || [];
    const fodderBlockAreas = utils.getFodderBlockAreas(budget.blocks);
    const farmNutrientTotals = { N: 0, P: 0, K: 0, S: 0, Ca: 0, Mg: 0, Na: 0 };

    const farmFertilisers = fertilisers
        .filter((f) => !f.isLastPreviousLime)
        .map((fertiliser) => {
            const icon = utils.getFertiliserIcon(fertiliser.material);
            const { applications = [] } = fertiliser;
            let total = applications.filter((a) => a.amount > 0).reduce((sum, a) => (sum += fertiliserUtils.calcTonnesPerMonth(a.amount, a.unit, fertiliserUtils.getApplicationBlockArea(a.blockIds, budget.blocks, fodderBlockAreas)) * a.months.filter((m) => m.reportingYear).length), 0);
            const dmFraction = fertiliser.dmContent > 0 ? fertiliser.dmContent / 100 : 1;
            const nutrientTotals = {};
            total = total * 1000;
            if (fertiliserUtils.nutrientsOnApplication(fertiliser)) {
                // soluble or organic so amount is in nutrients
                /* eslint-disable no-unused-vars */
                for (let a of applications) {
                    let numMonths = a.months.filter((m) => m.reportingYear).length;
                    let totalBlockSize = fertiliserUtils.getApplicationBlockArea(a.blockIds, budget.blocks, fodderBlockAreas);
                    const { nutrients = {} } = a;
                    /* eslint-disable no-unused-vars */
                    for (let n of Object.keys(nutrients)) {
                        if (!nutrientTotals[n]) nutrientTotals[n] = 0;
                        const tonnes = fertiliserUtils.calcTonnesPerMonth(a.nutrients[n], a.unit, totalBlockSize) * numMonths * 1000;
                        nutrientTotals[n] += tonnes;
                        farmNutrientTotals[n] += tonnes;
                    }
                }
            } else {
                const factoryEffluent = fertiliser.organicType === "Dairyfactory" && fertiliser.dairyFactoryEffluent && refData.factoryEffluent.find((r) => r.value === fertiliser.dairyFactoryEffluent && !["None", "Userdefined"].includes(r.value));
                const { nutrients = {} } = factoryEffluent || fertiliser;
                /* eslint-disable no-unused-vars */
                for (let n of Object.keys(nutrients)) {
                    if (!nutrientTotals[n]) nutrientTotals[n] = 0;
                    const tonnes = (dmFraction * nutrients[n] * total) / 100;
                    nutrientTotals[n] += tonnes;
                    farmNutrientTotals[n] += tonnes;
                }
            }
            // change to KGs
            return {
                ...fertiliser,
                total,
                icon,
                displayName: fertiliser.productName + (fertiliser.organicType ? "-" + utils.valueToText(refData.otherFertilisers, fertiliser.organicType) : ""),
                nutrientTotals,
            };
        });
    return { fertilisers: farmFertilisers, nutrients: farmNutrientTotals };
};

export const irrigatorViewModel = (budget, refData) => {
    const { irrigators = [] } = budget;
    const drawnAreas = irrigatorUtils.drawnAreas(budget);
    const blockIrrigatorModel = blockIrrigationViewModel(budget, irrigators);
    const fodderDetails = utils.getFodderBlockAreas(budget.blocks);

    return irrigators.map((irrigator) => {
        const irrigatorType = utils.valueToText(refData.irrigatorTypes, irrigator.type);
        const icon = utils.getIrrigatorIcon(irrigator.type);
        const ia = drawnAreas.find((ia) => ia.irrigatorId === irrigator.id);
        const area = ia && ia.area;

        const slots = domain.farmYear.map((month) => {
            /* eslint-disable no-unused-vars */
            for (const application of irrigator.applications) {
                if (application.months.find((m) => m.reportingYear && m.month === month)) {
                    var applied = irrigator.results && irrigator.results[month] ? irrigator.results[month].applied + " mm" : "";
                    return { month, icon: icon, iconText: applied };
                }
            }
            return { month };
        });

        for (const application of irrigator.applications) {
            application.area = 0;

            for (const blockId of application.blockIds) {
                const b = blockIrrigatorModel.find((b) => b.id === blockId);
                if (!b) continue;
                const area = fodderDetails.pastureBlockIds.has(b.id) ? b.area - b.area * fodderDetails.ratio : b.area;
                const irrigationSys = (b.irrigationSystems || []).find((s) => s.id === irrigator.id);
                if (irrigationSys) {
                    const irrigatedPercentage = irrigationSys.percentage;
                    const irrigatedArea = utils.round(area * (irrigatedPercentage / 100), 1);
                    application.area += irrigatedArea;
                }
            }

            switch (application.schedule) {
                case "None":
                case "Visual":
                    application.group = 1;
                    break;
                case "Manual":
                case "Probes":
                    switch (application.soilMoistureUsage) {
                        case "TriggerPoint":
                            application.group = 2;
                            break;
                        case "TargetDepth":
                            application.group = 3;
                            break;
                        case "TriggerPointTargetDepth":
                            application.group = 4;
                            break;
                        default:
                            application.group = 0;
                            break;
                    }
                    break;
                case "Depth":
                    application.group = 5;
                    break;
                case "SoilMoistureTapes":
                case "Frost":
                case "BorderDyke":
                case "Flood":
                    application.group = 0;
                    break;
                default:
                    application.group = 0;
                    break;
            }
        }

        return {
            ...irrigator,
            icon,
            irrigatorType,
            drawn: irrigator.drawnAreas && irrigator.drawnAreas.length > 0,
            slots,
            area,
        };
    });
};

export const riparianNamePairs = (block, budget) => {
    const riparianStrip = block.riparianStrip;
    const isPopulatedRiparianStrip = block.type === domain.BlockType.NonProductiveRiparian && riparianStrip && riparianStrip.catchmentArea > 0;
    if (!isPopulatedRiparianStrip) return;
    return [
        { name: "Riparian strip catchment area", value: `${riparianStrip.catchmentArea} ha` },
        { name: "Riparian strip length", value: `${riparianStrip.stripLength} m` },
        { name: "Riparian strip width", value: `${riparianStrip.pathLength} m` },
    ];
};

export const cropNamePairs = (block, refData) => {
    const { blockTypes = [], slopes = [], pastureTypes = [], hydrophobicity = [], pruningDisposalMethods = [], swardTypes = [], swardAnimalSources = [], swardAnimalTypes = [], preCropManagement = [], pugOccurence = [] } = refData;

    const rdv = (value, refDataList) => {
        const refDataItem = refDataList.find((r) => r.value === value);
        return refDataItem ? refDataItem.text : "";
    };
    //Crops
    const cropDetails = [{ name: "Block type", value: rdv(block.type, blockTypes) }];

    switch (block.type) {
        case domain.BlockType.FodderCrop:
            cropDetails.push({ name: "Rotation name", value: block.name });
            cropDetails.push({ name: "Area", value: `${block.rotationArea}ha` });
            if (process.env.REACT_APP_PRODUCT === "sci" || block.lowNMineralisation) {
                cropDetails.push({ name: "Low N mineralisation capacity", value: block.lowNMineralisation ? "Yes" : "No" });
            }
            cropDetails.push({ name: "Month resown in pasture", value: block.monthResown });
            cropDetails.push({ name: "Months since fertiliser applied to pasture", value: block.nonPastureApplication });
            break;
        case domain.BlockType.ProductiveCrop: {
            const { cropBlock = {} } = block;
            cropDetails.push({ name: "Cultivated area", value: `${cropBlock.cultivatedAreaPercent || 100} %` });
            cropDetails.push({ name: "Headlands and tracks", value: `${cropBlock.headlandAreaPercent} %` });
            cropDetails.push({ name: "Other areas", value: `${cropBlock.otherAreaPercentage} %` });
            cropDetails.push({ name: "Crop rotation final month", value: block.monthResown });
            cropDetails.push({ name: "Years in pasture", value: cropBlock.yearsInPasture });
            cropDetails.push({ name: "Prior land use", value: rdv(cropBlock.preCrop, preCropManagement) });
            break;
        }
        case domain.BlockType.ProductiveFruit: {
            const { fruit = {} } = block;
            const fruitTypes = Object.keys(refData.fruitTypes || []).map((key) => {
                const fruitName = refData.fruitTypes[key].name;
                return { value: fruitName, text: fruitName };
            });
            const yieldData = refData.fruitTypes[fruit.cropType] || {};
            cropDetails.push({ name: "Fruit type", value: rdv(fruit.cropType, fruitTypes) });
            cropDetails.push({ name: "Product yield", value: `${fruit.productYield} ${yieldData.unitsDescription}` });
            cropDetails.push({ name: "Reject rate", value: `${fruit.rejectPercentage} %` });
            cropDetails.push({ name: "Age of current trees", value: `${fruit.ageOfTrees_yrs} years` });
            cropDetails.push({ name: "Pruning management method", value: rdv(fruit.pruningDisposalMethod, pruningDisposalMethods) });
            cropDetails.push({ name: "Sward type", value: rdv(fruit.swardType, swardTypes) });
            if (["Herbicidestrip", "Fullpasture"].includes(fruit.swardType)) {
                cropDetails.push({ name: "Animals grazing sward", value: rdv(fruit.swardAnimalSource, swardAnimalSources) });
                if (["NonFarmAnimals"].includes(fruit.swardAnimalSource)) {
                    cropDetails.push({ name: "Animal type", value: rdv(fruit.swardAnimalType, swardAnimalTypes) });
                }
            }
            fruit.numberOfSpraysCAN && cropDetails.push({ name: "Calcium ammonium nitrate", value: `${fruit.numberOfSpraysCAN} sprays per year` });
            fruit.numberOfSpraysCN && cropDetails.push({ name: "Calcium nitrate", value: `${fruit.numberOfSpraysCN} sprays per year` });
            fruit.numberOfSpraysUrea && cropDetails.push({ name: "Urea and other N sprays", value: `${fruit.numberOfSpraysUrea} sprays per year` });
            break;
        }
        case domain.BlockType.ProductivePasture: {
            //Pasture
            const { pasture = {} } = block;
            cropDetails.push({ name: "Topography", value: rdv(pasture.topography, slopes) });
            cropDetails.push({ name: "Pasture type", value: rdv(pasture.pastureCategory, pastureTypes) });
            cropDetails.push({ name: "Cultivated in last 5 years", value: pasture.pastureCultivated ? "Yes" : "No" });
            cropDetails.push({ name: "Animals present", value: block.runoffCharacteristics ? "Yes" : "No" });
            if (block.runoffCharacteristics) {
                const { runoffCharacteristics = {} } = block;
                cropDetails.push({ name: "Hydrophobic condition", value: rdv(runoffCharacteristics.hydrophobicCondition, hydrophobicity) });
                cropDetails.push({ name: "Susceptibility to pugging", value: rdv(runoffCharacteristics.pugOccurence, pugOccurence) });
                cropDetails.push({ name: "Is compacted", value: runoffCharacteristics.isCompacted ? "Yes" : "No" });
                cropDetails.push({ name: "Naturally high water table", value: runoffCharacteristics.waterTablePresent ? "Yes" : "No" });
            }
            break;
        }
        default:
            //Not crop block
            break;
    }
    return cropDetails;
};

export const blockCropsViewModel = (block, refData) => {
    const { crops = [] } = block;
    return crops.map((crop) => {
        const area = block.areaInHectares ? (block.areaInHectares * block.cropBlock.cultivatedAreaPercent) / 100 : block.rotationArea;
        const sown = cropUtils.findCropEvent(crop, "Cropsown");
        return {
            displayName: cropUtils.getCropNameLabel(crop, refData),
            icon: utils.getCropIcon(crop),
            area: area,
            yield: crop.productYield ? utils.round(crop.productYield * area, 2) : undefined,
            units: "T DM/yr",
            sown,
            ...crop,
        };
    });
};

export const climateNamePairs = (vm) => {
    const climateDetails = [
        { name: "Average temp", value: vm.averageTemp },
        { name: "Average rainfall", value: vm.averageRain },
        { name: "Annual PET", value: vm.annualPET },
    ];

    if (vm.overrideClimateLocation) {
        climateDetails.push({ name: "Latitude", value: vm.latitude });
        climateDetails.push({ name: "Longitude", value: vm.longitude });
    }
    return climateDetails;
};

export const pastureNamePairs = (block, budget, refData) => {
    const { currentResults = {} } = block;
    const { pastureResults = {} } = currentResults;
    const { enterpriseTypes = [] } = refData;
    const result = pastureResults.pastureRsu &&
        Object.keys(pastureResults.pastureRsu).length > 0 && [
            { name: "Pasture growth", value: `${pastureResults.pastureGrowth.toLocaleString()} kg DM/ha/yr` },
            { name: "Utilisation", value: `${pastureResults.pastureUtilisation} %` },
            { name: "Intake", value: `${pastureResults.pastureIntake.toLocaleString()} kg DM/ha/yr` },
            { name: "Removed", value: `${pastureResults.supplementsRemoved} kg DM/ha/yr` },
        ];

    pastureResults.pastureRsu &&
        Object.keys(pastureResults.pastureRsu).forEach((k) => {
            const enterprise = budget.enterprises.find((e) => e.id === k);
            const enterpriseName = utils.valueToText(enterpriseTypes, enterprise.type);
            const total = pastureResults.pastureRsu[k].reduce((sum, m) => (sum += m.value), 0);
            result.push({ name: enterpriseName, value: utils.round(total, 2) + " rsu/ha" });
        });

    return result;
};

export const supplementNamePairs = (block, budget) => {
    const supplementsProducedByWeightDM = budget.feedSupplements.filter((f) => f.type === "Harvested" && f.amountType === "Weight" && f.isDryWeight).reduce((sum, f) => (sum += f.sources.filter((s) => s.blockId === block.id).reduce((amount, s) => (amount += s.amount), 0)), 0);
    const supplementsProducedByWeight = budget.feedSupplements.filter((f) => f.type === "Harvested" && f.amountType === "Weight" && !f.isDryWeight).reduce((sum, f) => (sum += f.sources.filter((s) => s.blockId === block.id).reduce((amount, s) => (amount += s.amount), 0)), 0);
    const supplementsProducedByVolume = budget.feedSupplements.filter((f) => f.type === "Harvested" && f.amountType === "Volume").reduce((sum, f) => (sum += f.sources.filter((s) => s.blockId === block.id).reduce((amount, s) => (amount += s.amount), 0)), 0);
    const supplementsProducedByBales = budget.feedSupplements.filter((f) => f.type === "Harvested" && f.amountType === "Bales").reduce((sum, f) => (sum += f.sources.filter((s) => s.blockId === block.id).reduce((amount, s) => (amount += s.amount), 0)), 0);
    const supplementsProducedByCuts = budget.feedSupplements.filter((f) => f.type === "Harvested" && f.amountType === "Cuts").reduce((sum, f) => (sum += f.sources.filter((s) => s.blockId === block.id).reduce((amount, s) => (amount += s.amount), 0)), 0);

    if (supplementsProducedByWeightDM > 0 || supplementsProducedByWeight > 0 || supplementsProducedByVolume > 0 || supplementsProducedByBales > 0 || supplementsProducedByCuts > 0) {
        const result = [];
        supplementsProducedByWeightDM > 0 && result.push({ name: "Harvested (DM)", value: `${supplementsProducedByWeightDM} tonnes` });
        supplementsProducedByWeight > 0 && result.push({ name: "Harvested", value: `${supplementsProducedByWeight} tonnes` });
        supplementsProducedByVolume > 0 && result.push({ name: "Harvested", value: `${supplementsProducedByVolume} m3` });
        supplementsProducedByBales > 0 && result.push({ name: "Harvested", value: `${supplementsProducedByBales} bales` });
        supplementsProducedByCuts > 0 && result.push({ name: "Harvested", value: `${supplementsProducedByCuts} cuts` });
        return result;
    }
};

export const irrigatorApplicationNamePairs = (irrigator, application, refData) => {
    const { irrigationSoilMoistureUsage = [], irrigationManagementUnits = [] } = refData;
    const result = [];
    const isFrost = application.schedule === irrigatorUtils.Schedule.Frost;
    const showOutwash = irrigator.type === irrigatorUtils.IrrigatorTypes.BorderDyke && application.schedule === irrigatorUtils.Schedule.BorderDyke;
    const isUserDefined = application.systemDefinition === irrigatorUtils.SystemDefinitions.UserDefined;
    const scheduleConfig = irrigatorUtils.evalScheduleConfig(application, irrigator.type);
    const soilMoistureUsageConfig = irrigatorUtils.evalSoilMoistureUsageConfig(application, irrigator.type);
    const showBasedOn = irrigator.type !== "";
    const showSoilMoistureUsage = scheduleConfig.SoilMoistureUsageControls.find((s) => s.Controls.includes(irrigatorUtils.Ctrl.SoilMoistureUsage));
    const multiManagementSysDef = irrigatorUtils.evalMultiManagementSysDef(irrigator);

    if (isFrost) {
        application.averageFrostTempC && result.push({ name: "Average frost temperature", value: `${application.averageFrostTempC} &deg;C` });
        application.averageFrostDurationHrs && result.push({ name: "Average frost duration", value: `${application.averageFrostDurationHrs} hours` });
        application.numberOfDaysOfFrost && result.push({ name: "Number of days of frost in a month", value: `${application.numberOfDaysOfFrost} days` });
    } else {
        showBasedOn &&
            application.schedule &&
            result.push({
                name: "Based on",
                value: utils.valueToText(
                    domain.irrigationMoistureAssesmentTypes.map((t) => ({ value: t.value, text: t.name })),
                    application.schedule
                ),
            });
        showSoilMoistureUsage && application.soilMoistureUsage && result.push({ name: "Strategy", value: utils.valueToText(irrigationSoilMoistureUsage, application.soilMoistureUsage) });
        application.schedule === irrigatorUtils.Schedule.Depth && application.depthAmountMm && result.push({ name: "Application depth", value: `${application.depthAmountMm} mm` });
        showOutwash && result.push({ name: "Outwash", value: application.outWashOccurs ? "Yes" : "No" });
    }

    if (multiManagementSysDef && soilMoistureUsageConfig.Controls.length > 0) {
        application.systemDefinition === irrigatorUtils.SystemDefinitions.DefaultSingleShift && result.push({ name: "Management System Definition", value: `Default 1 shift per day` });

        application.systemDefinition === irrigatorUtils.SystemDefinitions.DefaultDoubleShift && result.push({ name: "Management System Definition", value: `Default 2 shifts per day` });
    }

    if (isUserDefined && soilMoistureUsageConfig.Controls.length > 0) {
        result.push({ name: "Management System Definition", value: `User defined` });

        soilMoistureUsageConfig.Controls.includes(irrigatorUtils.Ctrl.MinimumDepth) && application.depth && result.push({ name: "Minimum depth", value: `${application.depth} mm/month` });

        soilMoistureUsageConfig.Controls.includes(irrigatorUtils.Ctrl.MaximumDepth) && application.maximumDepth && result.push({ name: "Maximum depth", value: `${application.maximumDepth} mm/application` });

        soilMoistureUsageConfig.Controls.includes(irrigatorUtils.Ctrl.Depth) && application.depth && result.push({ name: "Depth per application", value: `${application.depth} mm/application` });

        soilMoistureUsageConfig.Controls.includes(irrigatorUtils.Ctrl.ReturnPeriod) && application.returnPeriod && result.push({ name: "Return period", value: `${application.returnPeriod} days` });

        soilMoistureUsageConfig.Controls.includes(irrigatorUtils.Ctrl.MinReturnPeriod) && application.returnPeriod && result.push({ name: "Minimum return period", value: `${application.returnPeriod} days` });

        soilMoistureUsageConfig.Controls.includes(irrigatorUtils.Ctrl.Units) && application.units && result.push({ name: "Units", value: utils.valueToText(irrigationManagementUnits, application.units) });

        soilMoistureUsageConfig.Controls.includes(irrigatorUtils.Ctrl.TriggerPoint) && application.trigger && result.push({ name: "Trigger point", value: `${application.trigger} ${application.units === "Deficit" ? "mm" : "%"}` });

        soilMoistureUsageConfig.Controls.includes(irrigatorUtils.Ctrl.Target) && application.target && result.push({ name: "Target", value: `${application.target} ${application.units === "Deficit" ? "mm" : "%"}` });
    }

    return result;
};

export const drainageNamePairs = (block, budget, refData) => {
    const { riparianStrip } = block;

    switch (block.type) {
        case domain.BlockType.NonProductiveWetland: {
            const { wetland } = block;
            if (!wetland) return null;
            const isPopulatedWetland = block.type === domain.BlockType.NonProductiveWetland && wetland && wetland.catchmentArea > 0;
            const catchmentConvergence = isPopulatedWetland ? refData.wetlandConvergence.find((c) => c.value === wetland.catchmentConvergence) : undefined;
            const aquitardDepth = isPopulatedWetland ? refData.wetlandAquitard.find((c) => c.value === wetland.catchmentAquitardDepth) : undefined;
            return [
                { name: "Catchment area", value: `${wetland.catchmentArea}ha` },
                { name: "Catchment convergence", value: catchmentConvergence && catchmentConvergence.text },
                { name: "Aquitard depth", value: aquitardDepth && aquitardDepth.text },
            ];
        }
        case domain.BlockType.NonProductiveRiparian:
            if (!riparianStrip) return null;
            return [
                { name: "Riparian strip catchment area", value: `${riparianStrip.catchmentArea}ha` },
                { name: "Riparian strip length", value: `${riparianStrip.stripLength}m` },
                { name: "Riparian strip width", value: `${riparianStrip.pathLength}m` },
            ];
        default: {
            let drainageList = [];
            let drainageSystem = undefined;
            let drainage = undefined;
            let drainageMethodName = undefined;
            let drainageCondition = undefined;

            if (budget.wetlands) {
                /* eslint-disable no-unused-vars */
                for (const wetland of budget.wetlands.filter((w) => w.catchmentPercentages)) {
                    drainageList = [...drainageList, ...wetland.catchmentPercentages.filter((cp) => cp.blockId === block.id).map((c) => ({ id: wetland.id, type: "unfenced", percentage: c.percentage, wetland: wetland, name: wetland.name }))];
                }
            }
            /* eslint-disable no-unused-vars */
            for (const wetlandBlock of budget.blocks.filter((b) => b.type === domain.BlockType.NonProductiveWetland)) {
                if (wetlandBlock.wetland && wetlandBlock.wetland.catchmentPercentages) {
                    drainageList = [...drainageList, ...wetlandBlock.wetland.catchmentPercentages.filter((cp) => cp.blockId === block.id).map((c) => ({ id: wetlandBlock.id, type: "fenced", percentage: c.percentage, wetland: wetlandBlock.wetland, name: wetlandBlock.name }))];
                }
            }
            const calcBlockArea = (percentage, catchmentArea) => (isNaN(percentage) || isNaN(catchmentArea) ? 0 : utils.truncateDecimal(catchmentArea * (percentage / 100), 2));

            const result = [];

            /* eslint-disable no-unused-vars */
            for (const system of budget.drainageSystems || []) {
                const dr = system.drainage.find((d) => d.id === block.drainageDetailsId);
                if (dr) {
                    drainageSystem = system;
                    drainage = dr;
                    result.push({ name: "Percentage drained", value: `${dr.propDrained}%` });

                    // eslint-disable-next-line
                    drainageCondition = refData.wetlandConditionType.find((c) => c.value === drainage.artificialWetlandCondition) || {};
                    // eslint-disable-next-line
                    drainageMethodName = refData.drainageMethod.find((d) => d.value.toLowerCase() === system.drainageMethod.toLowerCase()) || {};
                    break;
                }
            }

            const nonDrainageBlocks = [domain.BlockType.NonProductiveTreesAndScrub, domain.BlockType.NonProductiveHouse, domain.BlockType.NonProductiveWetland, domain.BlockType.NonProductiveRiparian];
            const isDrainageBlock = !nonDrainageBlocks.includes(block.type);
            const isPastureBlock = block.type === domain.BlockType.ProductivePasture && block.animals && block.animals.length > 0;
            const _isPopulatedRiparianStrip = isPastureBlock && riparianStrip && riparianStrip.catchmentArea > 0;

            if ((!isPastureBlock && isDrainageBlock) || (isPastureBlock && drainageSystem)) {
                result.push({ name: "Drainage method", value: drainageSystem && drainageMethodName && drainageMethodName.value ? drainageMethodName.text : "None" });
            }

            if (_isPopulatedRiparianStrip) {
                result.push({ name: "Grass filter strip catchment area", value: `${riparianStrip.catchmentArea}ha` });
            }

            if (drainage && drainage.artificialWetlandCondition) {
                result.push({ name: "Drains into", value: `${drainageCondition.text} - ${drainage.artificialWetlandArea} ha` });
            }

            if (drainageList.length > 0) {
                result.push({
                    name: "Catchment wetlands",
                    value: (
                        <React.Fragment>
                            {drainageList.map((d, i) => (
                                <div key={i}>{`${d.name} - ${calcBlockArea(d.percentage, d.wetland.catchmentArea)}ha`}</div>
                            ))}
                        </React.Fragment>
                    ),
                });
            }

            return result;
        }
    }
};

export const getBlocksRotatedThru = (fodderBlock, budget) => {
    if (!budget || fodderBlock.type !== domain.BlockType.FodderCrop) return;

    const blocks = (budget.blocks || []).filter((b) => fodderBlock.blockIds.includes(b.id));
    return blocks.map((block) => ({ id: block.id, name: block.name })).sort((a, b) => (a.name < b.name ? -1 : 1));
};

export const getFodderRotations = (pastureBlock, budget) => {
    const fodderRotations = (budget.blocks || []).filter((b) => b.type === domain.BlockType.FodderCrop && b.blockIds.includes(pastureBlock.id));
    if (fodderRotations.length > 0) return fodderRotations.map((rotation) => ({ id: rotation.id, name: rotation.name })).sort((a, b) => (a.name < b.name ? -1 : 1));
};

const getEnterpriseRsu = (id, budget) => {
    const { currentResults = {} } = budget;
    const { animalResults = {} } = currentResults;
    const { sources = {} } = animalResults[id] || {};
    const result = { total: 0, months: [] };

    domain.farmYear.forEach((m, x) => {
        const monthVal = { month: m, amount: 0 };
        Object.keys(sources).forEach((st, i) => {
            const stMonth = sources[st].produced.find((ms) => ms.month === m) || { proportion: 0, perAnimal: 0, value: 0 };
            monthVal.amount += stMonth.perAnimal;
            result.total += monthVal.amount;
        });
        result.months.push(monthVal);
    });
    return result;
};

export const effluentSystemViewModel = (effluentSystem, refData, name, animals, enterpriseType, hasEffluentSystem, invalidSystem, structure, year, id, budget) => {
    const calcRsu = () => {
        if (!budget) return;
        const enterpriseRsu = getEnterpriseRsu(id, budget);
        const result = { total: 0, months: [] };
        animals.forEach((a) => {
            const time = !a.time || a.time === 0 ? 24 : a.time;
            a.months.forEach((m) => {
                const days = moment(`${year}-${m}`, "YYYY-MMMM").daysInMonth();
                const timeAdhj = structure.units === "DaysPerMonth" ? time / days : time / 24;
                const entRsuMonthVal = enterpriseRsu.months.find((r) => r.month === m);
                const adjRsu = entRsuMonthVal ? entRsuMonthVal.amount * (a.percentageOfAnimals / 100) * timeAdhj : 0;
                const month = result.months.find((r) => r.month === m);
                if (month) {
                    month.rsu += adjRsu;
                } else {
                    result.months.push({ month: m, rsu: adjRsu });
                }
                result.total += adjRsu;
            });
        });
        return result;
    };

    const enterprise = enterpriseType && {
        animals,
        enterpriseType,
        icon: utils.getAnimalIcon(enterpriseType),
        rsu: structure.type !== "MilkingShed" && calcRsu(),
        id,
    };

    return {
        name,
        icon: structure ? utils.getStructureIcon(structure.type) : icons.farm,
        managementSystem: effluentSystem && effluentSystem.effluentDisposal ? utils.valueToText(refData.effluentMethod, effluentSystem.effluentDisposal) : "",
        solidsManagement: effluentSystem && effluentSystem.solidManagement && effluentSystem.solidManagement.disposalMethod ? utils.valueToText(refData.solidsManagement, effluentSystem.solidManagement.disposalMethod) : "None",
        pondSolidsManagement: effluentSystem && effluentSystem.solidManagement && effluentSystem.solidManagement.pondDisposalMethod ? utils.valueToText(refData.solidsManagement, effluentSystem.solidManagement.pondDisposalMethod) : "None",
        liquidManagement: effluentSystem && effluentSystem.liquidManagement && effluentSystem.liquidManagement.liquidEffluentDisposal ? utils.valueToText(refData.holdingPondManagement, effluentSystem.liquidManagement.liquidEffluentDisposal) : "None",
        enterprise,
        hasEffluentSystem,
        invalidSystem,
        effluentSystem,
        structure,
    };
};

export const structuresViewModel = (analysis, structures = [], refData) => {
    const { messages = [] } = analysis;

    return structures.map((structure) => {
        const { animals = [], effluentSystem = {} } = structure;
        const { enterpriseTypes = [] } = refData;
        let enterprise = analysis.enterprises.find((e) => e.id === structure.enterpriseId) || {};

        const enterpriseType = enterpriseTypes.find((t) => t.value === enterprise.type) || {};
        const [structureSolids, structureLiquids] = structureUtils.structureEffluent(structure);
        const hasEffluentSystem = structureLiquids || structureSolids;
        const isMilkingShed = structure.type === "MilkingShed";
        const hasErrors = messages.some((m) => m.category === "Effluent" && m.entityType === "Budget" && m.entityId === structure.id && m.severity === "Error");
        const year = analysis.type === "YearEnd" && analysis.year ? analysis.year : 2001; //2001 is not a leap year
        return effluentSystemViewModel(isMilkingShed ? analysis.effluentSystem : effluentSystem, refData, utils.valueToText(refData.structureType, structure.type), animals, enterpriseType, hasEffluentSystem, hasErrors, structure, year, enterprise.id, analysis);
    });
};

export const getOutdoorPigFarrowingVillagesViewModel = (analysis) => {
    const enterprises = analysis.enterprises || [];
    const outdoorPigsEnterprise = enterprises.find((enterprise) => enterprise.type === "OutdoorPigs") || {};
    const outdoorPigs = outdoorPigsEnterprise.outdoorPigs || {};
    const { weaningAge } = (outdoorPigs.numbers || {}).sows;

    const structures = analysis.structures || [];
    const outdoorPigFarrowingVillageStructureType = "OutdoorPigFarrowingVillages";
    const farrowingVillage = structures.find((structure) => structure.type === outdoorPigFarrowingVillageStructureType) || {};

    const viewModel = {
        ...farrowingVillage,
        id: farrowingVillage.id || uuidv4(),
        type: outdoorPigFarrowingVillageStructureType,
        enterpriseId: outdoorPigsEnterprise.id,
        weaningAge,
    };

    return viewModel;
};

export const getOutdoorPigBarnsViewModel = (analysis, refData) => {
    const enterprises = analysis.enterprises || [];
    const outdoorPigsEnterprise = enterprises.find((enterprise) => enterprise.type === "OutdoorPigs") || {};
    const outdoorPigs = outdoorPigsEnterprise.outdoorPigs || {};
    const outdoorPigStockClassGroups = refData.outdoorPigStockClassGroups || [];

    const structures = analysis.structures || [];
    const outdoorPigBarnStructureType = "OutdoorPigBarns";
    const pigBarn = structures.find((structure) => structure.type === outdoorPigBarnStructureType) || {};

    const beddingOption = pigBarn === {} ? 0 : pigBarn.strawDisposal ? 1 : 0;

    const viewModel = {
        ...pigBarn,
        id: pigBarn.id || uuidv4(),
        type: outdoorPigBarnStructureType,
        enterpriseId: outdoorPigsEnterprise.id,
        beddingOption,
        outdoorPigs,
    };

    // Outdoor pigs barn months
    if (!pigBarn.pigBarnPlacements) {
        pigBarn.pigBarnPlacements = {};
        outdoorPigStockClassGroups.forEach((o) => {
            const stockClassGroup = o.value;
            const months = domain.calendarYear.map((m) => ({ month: m }));
            pigBarn.pigBarnPlacements[stockClassGroup] = months;
        });
    }
    viewModel.placements = outdoorPigStockClassGroups.map((o) => {
        const stockClassGroup = o.value;
        const label = utils.getOutdoorPigStockClassGroupDisplayText(stockClassGroup);
        let months = pigBarn.pigBarnPlacements[stockClassGroup];
        if (!months) months = domain.calendarYear.map((m) => ({ month: m }));
        return {
            stockClassGroup,
            label,
            months,
        };
    });

    return viewModel;
};

export const getOutdoorPigHutsViewModel = (analysis) => {
    const enterprises = analysis.enterprises || [];
    const outdoorPigsEnterprise = enterprises.find((enterprise) => enterprise.type === "OutdoorPigs") || {};

    const structures = analysis.structures || [];
    const structureType = "OutdoorPigHuts";
    const structure = structures.find((structure) => structure.type === structureType) || {};

    const viewModel = {
        ...structure,
        id: structure.id || uuidv4(),
        type: structureType,
        enterpriseId: outdoorPigsEnterprise.id,
        strawPerSowDefault: 140,
    };

    // Straw disposals
    if (!viewModel.strawDisposals) {
        viewModel.strawDisposals = [{ proportion: 100 }];
    }

    return viewModel;
};

export const OUTDOOR_PIG_LIQUID_DISPOSAL_OPTIONS = ["Sprayfromsump", "HoldingpondSprayregularly", "HoldingpondStirsprayregularly", "HoldingpondSprayinginfrequently"];

export const OUTDOOR_PIG_SOLID_DISPOSAL_OPTIONS = ["Item2ponddischarge", "HoldingpondSprayregularly", "HoldingpondStirsprayregularly", "HoldingpondSprayinginfrequently"];

export const requiresOutdoorPigLiquidApplications = (outdoorPigStructure) => OUTDOOR_PIG_LIQUID_DISPOSAL_OPTIONS.includes(outdoorPigStructure.effluentDisposal);

export const requiresOutdoorPigSolidApplications = (outdoorPigStructure) => OUTDOOR_PIG_SOLID_DISPOSAL_OPTIONS.includes(outdoorPigStructure.effluentDisposal) && outdoorPigStructure.sludgeDisposal === "SpreadOnBlocks";

export const requiresOutdoorPigStrawCompostApplications = (outdoorPigStructure) => outdoorPigStructure.strawDisposal === "SpreadOnBlocks" || (outdoorPigStructure.strawDisposals || []).some((s) => s.strawDisposal === "Spreadonselectedblocks");

export const requiresOutdoorPigEffluentSystem = (outdoorPigStructure) => requiresOutdoorPigLiquidApplications(outdoorPigStructure) || requiresOutdoorPigSolidApplications(outdoorPigStructure) || requiresOutdoorPigStrawCompostApplications(outdoorPigStructure);

const getHoldingPondManagement = (effluentDisposal) => {
    if (!effluentDisposal) return null;

    switch (effluentDisposal) {
        case "HoldingpondSprayregularly":
            return "SprayRegularly";
        case "HoldingpondStirsprayregularly":
            return "StirSprayRegularly";
        case "HoldingpondSprayinginfrequently":
            return "SprayInfrequently";
        default:
            return null;
    }
};

export const getOutdoorPigEffluentSystemViewModel = (farm, analysis, refData) => {
    const blocks = analysis.blocks.filter((b) => b.type !== "ProductiveOutdoorPigs" && (b.type.startsWith("Productive") || b.type === "FodderCrop"));
    const outdoorPigEffluentSystem = analysis.outdoorPigEffluentSystem || {};
    const outdoorPigStructures = (analysis.structures || []).filter((structure) => ["OutdoorPigHuts", "OutdoorPigBarns", "OutdoorPigFarrowingVillages"].includes(structure.type));

    let villagesHoldingPondManagement = undefined;
    let barnsHoldingPondManagement = undefined;

    const liquidEffluentSources = outdoorPigStructures.reduce((results, structure) => {
        if (requiresOutdoorPigLiquidApplications(structure)) {
            const structureName = utils.valueToText(refData.structureType, structure.type);
            results.push({
                type: structure.type,
                name: structureName,
                url: `/app/farm/${farm.id}/analysis/${analysis.id}/structures/${structure.type}`,
            });

            const holdingPondManagement = getHoldingPondManagement(structure.effluentDisposal);

            if (structure.type === "OutdoorPigFarrowingVillages" && holdingPondManagement) {
                villagesHoldingPondManagement = holdingPondManagement;
            } else if (structure.type === "OutdoorPigBarns" && holdingPondManagement) {
                barnsHoldingPondManagement = holdingPondManagement;
            }
        }
        return results;
    }, []);

    const pondSolidSources = outdoorPigStructures.reduce((results, structure) => {
        if (requiresOutdoorPigSolidApplications(structure)) {
            const structureName = utils.valueToText(refData.structureType, structure.type);
            results.push({ type: structure.type, name: structureName, url: `/app/farm/${farm.id}/analysis/${analysis.id}/structures/${structure.type}` });
        }
        return results;
    }, []);

    const strawCompostSources = outdoorPigStructures.reduce((results, structure) => {
        if (requiresOutdoorPigStrawCompostApplications(structure)) {
            const structureName = utils.valueToText(refData.structureType, structure.type);
            results.push({ type: structure.type, name: structureName, url: `/app/farm/${farm.id}/analysis/${analysis.id}/structures/${structure.type}` });
        }
        return results;
    }, []);

    const viewModel = {
        liquidEffluentSources,
        pondSolidSources,
        strawCompostSources,
        blocks,
        villagesHoldingPondManagement,
        barnsHoldingPondManagement,
    };

    if (liquidEffluentSources.length > 0) {
        viewModel.liquidEffluentApplications = (outdoorPigEffluentSystem.liquidEffluentApplications || []).map((application) => ({ ...application, months: application.months.map((m) => m.month) }));
    }

    if (pondSolidSources.length > 0) {
        viewModel.pondSolidApplications = (outdoorPigEffluentSystem.pondSolidApplications || []).map((application) => ({ ...application, months: application.months.map((m) => m.month) }));
    }

    if (strawCompostSources.length > 0) {
        viewModel.strawCompostApplications = (outdoorPigEffluentSystem.strawCompostApplications || []).map((application) => ({ ...application, months: application.months.map((m) => m.month) }));
    }

    return viewModel;
};

export const getBlockIconsViewModel = (refData, budget, block) => {
    const { rainfallCategory = [], temperatureCategory = [], swardAnimalTypes = [] } = refData;
    const rotationYear = cropUtils.rotationYear(block);

    const blockActivity = [];
    const highRain = rainfallCategory.find((r) => r.value === "High");
    const medRain = rainfallCategory.find((r) => r.value === "Medium");
    const lowRain = rainfallCategory.find((r) => r.value === "Low");
    const highTemp = temperatureCategory.find((r) => r.value === "High");
    const medTemp = temperatureCategory.find((r) => r.value === "Medium");
    const coldTemp = temperatureCategory.find((r) => r.value === "Low");

    const fertiliserIcons = [];
    const irrigatorIcons = [];
    let fruitIcon = undefined;
    let climateIcon = undefined;
    let tempIcon = undefined;
    const enterpriseIcons = [];

    const snowBlockIds = budget.snowfalls ? budget.snowfalls.reduce((a, b) => [...a, ...b.blockIds], []) : [];

    const _toSentenceCase = (text) => {
        if (!text || text.length < 2) return text;
        return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
    };

    if (highTemp && block.climate && block.climate.averageTemp >= highTemp.start) {
        tempIcon = { icon: icons.tempWarm, name: "Warm climate" };
    } else if (medTemp && block.climate && block.climate.averageTemp >= medTemp.start && block.climate.averageTemp <= medTemp.end) {
        tempIcon = { icon: icons.tempMed, name: "Mild climate" };
    } else if (coldTemp && block.climate && block.climate.averageTemp <= coldTemp.end) {
        tempIcon = { icon: icons.tempCold, name: "Warm climate" };
    }

    if (highRain && block.climate && block.climate.averageRain >= highRain.start) {
        climateIcon = { icon: icons.rain, name: "High rain" };
    } else if (medRain && block.climate && block.climate.averageRain >= medRain.start && block.climate.averageRain <= medRain.end) {
        climateIcon = { icon: icons.cloudy, name: "Average rain" };
    } else if (lowRain && block.climate && block.climate.averageRain <= lowRain.end) {
        climateIcon = { icon: icons.sunny, name: "Low rain" };
    }

    const hasSnow = snowBlockIds.includes(block.id);
    const hasSoilTests = block.soilTestId && budget.soilTests.find((t) => t.id === block.soiltestId);
    const hasWetlandDrainage = block.drainageDetailsId != null && block.drainageDetailsId !== NIL_UUID;

    //Fruit
    fruitIcon = block.fruit && { icon: utils.getFruitIcon(block.fruit.cropType), name: _toSentenceCase(block.fruit.cropType) };

    if (["ProductiveCrop", "FodderCrop"].includes(block.type)) {
        const { animalTypes = [] } = refData;
        const f = (budget, id) => budget.enterprises.find((e) => e.id === id) || animalTypes.find((a) => a.value === id);
        var _enterpriseMap = cropUtils.getAnimalsOnCropBlock(block, rotationYear, budget);

        for (var id in _enterpriseMap) {
            const enterprise = id === "NonFarmAnimals" ? { isNonFarmAnimal: true } : f(budget, id);
            if (enterprise) {
                const name = enterprise.isNonFarmAnimal ? "Non farm animals" : utils.valueToText(refData.enterpriseTypes, enterprise.type);
                const months = _enterpriseMap[id];

                const addEntIcon = (icon) => {
                    if (!enterpriseIcons.find((i) => i.icon === icon)) {
                        enterpriseIcons.push({ icon: icon, name: enterprise.isNonFarmAnimal ? "Non-farm" : _toSentenceCase(name), isNonFarmAnimal: enterprise.isNonFarmAnimal });
                    }
                };

                let icon = null;
                if (enterprise.isNonFarmAnimal) {
                    const icons = months.map((s) => cropUtils.getNonFarmAnimalIcon(s.type));
                    icons.forEach((i) => addEntIcon(i));
                } else {
                    icon = utils.getAnimalIcon(enterprise.type);
                    addEntIcon(icon);
                }
            }
        }
    }

    //Animals
    if (block.animals && block.animals.length > 0) {
        /* eslint-disable no-unused-vars */
        for (const animals of block.animals) {
            const enterprise = budget.enterprises.find((e) => e.id === animals.enterpriseId);
            const name = utils.valueToText(refData.enterpriseTypes, enterprise.type);
            enterpriseIcons.push({ icon: utils.getAnimalIcon(enterprise.type), name: _toSentenceCase(name) });
        }
    }
    if (block.type === domain.BlockType.ProductiveOutdoorPigs) {
        enterpriseIcons.push({ icon: utils.getAnimalIcon("outdoorpigs"), name: "Outdoor pigs" });
    }

    //Irrigation
    if (budget.irrigators && budget.irrigators.length > 0) {
        /* eslint-disable no-unused-vars */
        for (const irrigator of budget.irrigators) {
            const name = utils.valueToText(refData.irrigatorTypes, irrigator.type);
            const irrigatorIcon = { icon: utils.getIrrigatorIcon(irrigator.type), name: _toSentenceCase(name) };
            /* eslint-disable no-unused-vars */
            for (const application of irrigator.applications.filter((a) => a.blockIds.includes(block.id))) {
                if (!irrigatorIcons.some((i) => i.name === irrigatorIcon.name)) {
                    irrigatorIcons.push(irrigatorIcon);
                }
            }
        }
    }

    //Fertiliser
    if (budget.fertiliser && budget.fertiliser.length > 0) {
        /* eslint-disable no-unused-vars */
        for (const fertiliser of budget.fertiliser) {
            const fertiliserIcon = { icon: utils.getFertiliserIcon(fertiliser.material), name: _toSentenceCase("Fertiliser " + fertiliser.material) };
            const applications = fertiliser.applications.filter((a) => a.blockIds.includes(block.id) && !a.isLastPreviousLime);
            if (applications.length > 0) {
                if (!fertiliserIcons.find((f) => f.icon === fertiliserIcon.icon)) {
                    fertiliserIcons.push(fertiliserIcon);
                }
            }
        }
    }

    const hasSolidEffluentApp = blockActivity.find((a) => a.type === "Solids" || a.type === "Pond solids" || a.type === "Straw compost");
    const hasLiquidEffluentApp = blockActivity.find((a) => a.type === "Liquids");

    return {
        climateIcon,
        tempIcon,
        hasSnow,
        fruitIcon,
        enterpriseIcons,
        hasSoilTests,
        hasWetlandDrainage,
        fertiliserIcons,
        irrigatorIcons,
        hasSolidEffluentApp,
        hasLiquidEffluentApp,
    };
};
