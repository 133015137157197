import { Component } from "react";
import { Link } from "react-router-dom";
import * as utils from "common/utils";
import Calendar from "components/Calendar";
import * as animalUtils from "containers/BudgetHome/Animals/_utils";
import * as _utils from "./_utils";
import * as domain from "common/domain";
import { useOnline, useRefData } from "common/hooks";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function BlockListCard({ farm, analysis, block }) {
    const online = useOnline();
    const refData = useRefData();

    return <BlockListCardClassComponent farm={farm} analysis={analysis} block={block} online={online} refData={refData} />
}

class BlockListCardClassComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
        };
    }

    _toggleMe = (e) => {
        e.preventDefault();
        this.setState({ isOpen: !this.state.isOpen });
    };

    render() {
        const { farm, analysis, block, refData, online } = this.props;
        const { messages = [] } = analysis;
        const hasErrors = online && messages.some((m) => m.category === "Supplements" && m.entityType === "Block" && m.entityId === block.id && m.severity === "Error");
        const hasWarnings = online && messages.some((m) => m.category === "Supplements" && m.entityType === "Block" && m.entityId === block.id && m.severity === "Warning");
        const alertIcon = hasErrors ? "error" : hasWarnings ? "warning" : undefined;
        const { isOpen } = this.state;
        const cardData = _getCardData(analysis, block, refData);
        const calendarData = _getCalenderData(online, farm, analysis, block, refData, cardData);
        const hasCrops = utils.hasCrops(block);
        const cropName = block.type === domain.BlockType.ProductiveFruit ? "fruit" : block.type === domain.BlockType.ProductiveCrop ? "crops" : "pasture";
        const canHarvestSupplements = hasCrops && _utils.canHarvestSupplements(block);
        const canDistributeSupplements = animalUtils.canHaveAnimals(block) || block.type === "FodderCrop";
        const hasHarvestedSupplements = calendarData && calendarData.some((cal) => cal.slots.length || cal.noMonthsMessage);

        const tableHeading = (
            <ul className="DataWidthTable FarmTable">
                <CardTitle block={block} alertIcon={alertIcon} />
                {canHarvestSupplements && hasHarvestedSupplements && <CardSummaryIcons cardData={cardData} />}
                {canHarvestSupplements && !hasHarvestedSupplements && (
                    <li data-width="md-grow xl-grow" className="FarmTable-link">
                        <Link id={`add-harvested-${block.id}`} to={`/app/farm/${farm.id}/analysis/${analysis.id}/supplements/harvested`} className="IconLink--arrow-plus">
                            Add harvested supplement
                        </Link>
                    </li>
                )}
                {!hasCrops && block.isProductive && block.type !== domain.BlockType.ProductiveOutdoorPigs && (
                    <li data-width="md-grow xl-grow" className="FarmTable-link">
                        <span>
                            This block does not have any {cropName} defined. Select the pasture/crops tab to add {cropName}.
                        </span>
                    </li>
                )}
                {hasCrops && !canHarvestSupplements && !hasHarvestedSupplements && canDistributeSupplements && (
                    <li data-width="md-grow xl-grow" className="FarmTable-link">
                        <span>Supplements have not been distributed to this block</span>
                    </li>
                )}
                {(block.type === domain.BlockType.ProductiveOutdoorPigs || !block.isProductive || (hasCrops && !canHarvestSupplements && !canDistributeSupplements)) && (
                    <li data-width="md-grow xl-grow" className="FarmTable-link">
                        <span>Supplement data not required</span>
                    </li>
                )}
            </ul>
        );

        return (
            <div className="FarmTable_wrapper">
                {tableHeading}
                {hasHarvestedSupplements && (
                    <div className={`FarmTable-supplementary ${isOpen ? "is-open" : "is-closed"}`}>
                        <Calendar data={calendarData} />
                    </div>
                )}
            </div>
        );
    }
}

function CardTitle({ block, alertIcon }) {
    const refData = useRefData();
    const blockIcon = utils.getBlockIcon(block.type);
    const blockType = utils.getBlockTypeText(refData, block);

    return (
        <li className="FarmTable-title" data-width="md-100 xl-40">
            {alertIcon && (
                <span className={`Todo Todo--` + alertIcon}>
                    <i className="icon icon-alert" />
                </span>
            )}
            <div className="FarmTitle">
                <img className="FarmTitle-icon" src={blockIcon} alt="Block" />
                <div className="FarmTitle-heading">
                    <span className="FarmTitle-name" title={block.name}>
                        {block.name}
                    </span>
                    <div className="FarmTitle-sub">{blockType}</div>
                </div>
            </div>
        </li>
    )
}

function CardSummaryIcons({ cardData }) {
    const distinctByCategory =
        cardData &&
        cardData.reduce((results, supplement) => {
            if (supplement.type === "Harvested" && supplement.months.length && !results.some((result) => result.category === supplement.category)) results.push(supplement);

            return results;
        }, []);

    return (
        <li className="FarmTable-summaryIcons" data-width="md-shrink xl-shrink">
            {distinctByCategory &&
                distinctByCategory.map((supplement) => {
                    return <img className="u-p-10" key={supplement.id} src={supplement.icon} width="45" height="45" alt={supplement.iconTitle} title={supplement.iconTitle} />;
                })}
        </li>
    )
}

const _getCardData = (budget, block, refData) => {
    const data = [];

    const { feedSupplements = [], enterprises = [] } = budget;

    feedSupplements.forEach((supplement) => {
        const amountTypeMeta = _utils.getAmountTypeMeta(supplement.amountType);
        const category = utils.valueToText(refData.supplementCategories, supplement.category);
        const icon = utils.getSupplementIcon(supplement);
        const noMonthsMessage = (
            <span className="u-flex u-flexAlignItemsCenter">
                <img className="u-p-5" src={icon} width="45" height="45" alt={category} />
                <span>Monthly feeding % not specified</span>
            </span>
        );

        // Harvested from this block
        const harvested = supplement.sources.filter((source) => source.blockId === block.id);
        if (harvested.length) {
            const total = harvested.reduce((sum, source) => (sum += parseInt(source.amount, 10)), 0);
            const subTitle = `${category} (${total} ${amountTypeMeta.uom})`;

            const months = [];
            harvested.forEach((h) => {
                const month = months.find((m) => m.month === h.month);
                if (month) {
                    month.amount += h.amount;
                    month.iconText = `${month.amount} ${amountTypeMeta.uom}`;
                } else {
                    months.push({
                        month: h.month,
                        amount: h.amount,
                        iconText: `${h.amount} ${amountTypeMeta.uom}`,
                        icon,
                    });
                }
            });

            data.push({
                id: supplement.id,
                type: "Harvested",
                supplementId: supplement.id,
                category: category,
                blockId: block.id,
                title: "Harvested from this block",
                subTitle: subTitle,
                icon: icon,
                iconTitle: category,
                months: months,
            });
        }

        // Distributed to enterprises
        (block.animals || []).forEach((animal) => {
            const enterprise = enterprises.find((e) => e.id === animal.enterpriseId);
            const enterpriseType = utils.valueToText(refData.enterpriseTypes, enterprise.type);
            const destinations = (supplement.destinations || []).filter((d) => d.enterpriseId === animal.enterpriseId);
            destinations.forEach((destination) => {
                const destinationIcon = _utils.getDestinationIcon(budget, destination);
                const subTitle = `${category} (${destination.amount} ${amountTypeMeta.uom})`;
                const months = (destination.applications || []).reduce((months, application) => {
                    Object.keys(application.months || {}).forEach((month) => {
                        //const percentage = application.months[month];
                        if (!months.includes(month) && application.months[month] > 0) {
                            months.push({
                                month: month,
                                icon: icon,
                                //iconText: `${percentage} %`
                            });
                        }
                    });
                    return months;
                }, []);

                data.push({
                    id: destination.id,
                    type: "Distributed",
                    supplementId: supplement.id,
                    category: category,
                    blockId: block.id,
                    title: `Distributed to ${enterpriseType}`,
                    titleIcon: destinationIcon,
                    subTitle: subTitle,
                    icon: icon,
                    iconTitle: category,
                    months: months,
                    noMonthsMessage: months.length === 0 ? noMonthsMessage : null,
                });
            });
        });

        // Distributed to this block
        const destinations = (supplement.destinations || []).filter((d) => (d.applications || []).some((a) => a.blockId === block.id));
        destinations.forEach((destination) => {
            const destinationIcon = _utils.getDestinationIcon(budget, destination);
            const subTitle = supplement.name ? `${category} (${supplement.name.replace("Dairy goat feed", "Dried distillers grain") })` : category;
            const application = destination.applications.find((a) => a.blockId === block.id);

            const months = Object.keys(application.months || {}).reduce((months, month) => {
                const amount = application.months[month];
                if (!months.includes(month) && amount > 0) {
                    months.push({
                        month: month,
                        icon: icon,
                        iconText: `${amount} %`,
                    });
                }
                return months;
            }, []);

            data.push({
                id: destination.id,
                type: "Distributed",
                supplementId: supplement.id,
                category: category,
                blockId: block.id,
                title: `Distributed to this block`,
                titleIcon: destinationIcon,
                subTitle: subTitle,
                icon: icon,
                iconTitle: category,
                months: months,
                noMonthsMessage: months.length === 0 ? noMonthsMessage : null,
            });
        });
    });

    return data;
}

const _getCalenderData = (online, farm, budget, block, refData, cardData) => {
    const filtered = cardData && cardData.filter((data) => data.months.length || data.noMonthsMessage);

    const calendarData =
        filtered &&
        filtered.map((data) => {
            const url = data.type === "Harvested" ? `/app/farm/${farm.id}/analysis/${budget.id}/supplements/harvested/${data.supplementId}` : `/app/farm/${farm.id}/analysis/${budget.id}/supplements/${data.supplementId}/distribution`;

            const subTitle = (
                <Link id={`calendar.itemLink.${data.id}`} to={url} className="FarmTable-value-link">
                    <span id={`calendar.itemText.${data.id}`}>{data.subTitle}</span>
                    <span className="icon icon-edit"></span>
                </Link>
            );

            return {
                id: data.id,
                title: data.title,
                titleIcon: data.titleIcon,
                subTitle: subTitle,
                slots: data.months,
                url: url,
                noMonthsMessage: data.noMonthsMessage,
            };
        });

    return calendarData;
}
