import * as utils from "common/utils";
import { LoadingPanel } from "components/LoadingPanel";
import Watermark from "components/Watermark";
import { SummaryRowForV2API } from "components/PlanningReport";
import { useAnalysisResults } from "containers/BudgetHome";

export default function AnimalsSummary({ before, after }) {
    const { isLoading: beforeIsLoading, data: beforeResults } = useAnalysisResults(before?.details);
    const { isLoading: afterIsLoading, data: afterResults } = useAnalysisResults(after?.details);
    if (!beforeResults?.animals && !afterResults?.animals) {
        return null;
    }

    const isLoading = afterIsLoading || (before && beforeIsLoading);
    const isComparing = !!before && !!after;

    return (
        <div className="ReportSection">
            <h1>Animals</h1>
            <LoadingPanel isLoading={isLoading}>
                <div className="Table Table-Compressed">
                    <Watermark />
                    <table>
                        <thead>
                            <tr>
                                <th data-width="25"></th>
                                <th></th>
                                {isComparing && (
                                    <th className="numeric u-before" data-width="12.5">
                                        {utils.truncateText(before.displayName, 35)}
                                    </th>
                                )}
                                <th className={`numeric ${isComparing ? "u-after" : ""}`} data-width="12.5">
                                    {utils.truncateText(after.displayName, 35)}
                                </th>
                                {isComparing && (
                                    <th className="numeric" data-width="16.7">
                                        Change
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            <SummaryRowForV2API before={beforeResults?.animals?.stockingRates?.total} after={afterResults?.animals?.stockingRates?.total} isComparing={isComparing} category="All enterprises" label="Total RSU" />
                            <SummaryRowForV2API before={beforeResults?.animals?.pastureFed?.totalPercentageOfDietFromPasture} after={afterResults?.animals?.pastureFed?.totalPercentageOfDietFromPasture} isComparing={isComparing} category=" " label="Diet from pasture" dp={1} />
                            <SummaryRowForV2API before={beforeResults?.animals?.pastureFed?.totalPercentageOfTimeOnPasture} after={afterResults?.animals?.pastureFed?.totalPercentageOfTimeOnPasture} isComparing={isComparing} category=" " label="Time on pasture" dp={1} />
                            <SummaryRowForV2API before={beforeResults?.animals?.stockingRates?.byFarmArea} after={afterResults?.animals?.stockingRates?.byFarmArea} isComparing={isComparing} category=" " label="RSU per farm area" dp={2} />
                            <SummaryRowForV2API before={beforeResults?.animals?.production?.liveweightBrought} after={afterResults?.animals?.production?.liveweightBrought} isComparing={isComparing} category=" " label="Total liveweight brought" />
                            <SummaryRowForV2API before={beforeResults?.animals?.production?.liveweightReared} after={afterResults?.animals?.production?.liveweightReared} isComparing={isComparing} category=" " label="Total liveweight reared" />
                            <SummaryRowForV2API before={beforeResults?.animals?.production?.liveweightSold} after={afterResults?.animals?.production?.liveweightSold} isComparing={isComparing} category=" " label="Total liveweight sold" />

                            <SummaryRowForV2API before={beforeResults?.animals?.production?.dairy?.peakCowNumbers} after={afterResults?.animals?.production?.dairy?.peakCowNumbers} isComparing={isComparing} category="Dairy" label="Peak cows (maximum numbers)" />
                            <SummaryRowForV2API before={beforeResults?.animals?.production?.dairy?.milkSolids} after={afterResults?.animals?.production?.dairy?.milkSolids} isComparing={isComparing} category=" " label="Milk solids" />
                            <SummaryRowForV2API before={beforeResults?.animals?.production?.dairy?.milkSolidsPerCow} after={afterResults?.animals?.production?.dairy?.milkSolidsPerCow} isComparing={isComparing} category=" " label="Milk solids per cow" dp={1} />
                            <SummaryRowForV2API before={beforeResults?.animals?.production?.dairy?.milkSolidsPerTotalFarmArea} after={afterResults?.animals?.production?.dairy?.milkSolidsPerTotalFarmArea} isComparing={isComparing} category=" " label="Milk solids per hectare (total farm area)" dp={1} />
                            <SummaryRowForV2API before={beforeResults?.animals?.production?.dairy?.milkSolidsPerTotalGrazingArea} after={afterResults?.animals?.production?.dairy?.milkSolidsPerTotalGrazingArea} isComparing={isComparing} category=" " label="Milk solids per hectare (total grazing area)" dp={1} />
                            <SummaryRowForV2API before={beforeResults?.animals?.production?.dairy?.milkSolidsPerDairyGrazingArea} after={afterResults?.animals?.production?.dairy?.milkSolidsPerDairyGrazingArea} isComparing={isComparing} category=" " label="Milk solids per hectare (dairy grazing area)" dp={1} />
                            <SummaryRowForV2API before={beforeResults?.animals?.production?.dairy?.milkVolume} after={afterResults?.animals?.production?.dairy?.milkVolume} isComparing={isComparing} category=" " label="Milk volume yield" />
                            <SummaryRowForV2API before={beforeResults?.animals?.production?.dairy?.milkVolumePerCow} after={afterResults?.animals?.production?.dairy?.milkVolumePerCow} isComparing={isComparing} category=" " label="Milk volume yield per cow" dp={1} />
                            <SummaryRowForV2API before={beforeResults?.animals?.production?.dairy?.cowsPerTotalFarmArea} after={afterResults?.animals?.production?.dairy?.cowsPerTotalFarmArea} isComparing={isComparing} category=" " label="Cows per hectare (total farm area)" dp={1} />
                            <SummaryRowForV2API before={beforeResults?.animals?.production?.dairy?.cowsPerTotalGrazingArea} after={afterResults?.animals?.production?.dairy?.cowsPerTotalGrazingArea} isComparing={isComparing} category=" " label="Cows per hectare (total grazing area)" dp={1} />
                            <SummaryRowForV2API before={beforeResults?.animals?.production?.dairy?.cowsPerDairyGrazingArea} after={afterResults?.animals?.production?.dairy?.cowsPerDairyGrazingArea} isComparing={isComparing} category=" " label="Cows per hectare (dairy grazing area)" dp={1} />
                            <SummaryRowForV2API before={beforeResults?.animals?.dairyGrassFedStandard?.diet?.percentageOfDietFromGrassFedFeed} after={afterResults?.animals?.dairyGrassFedStandard?.diet?.percentageOfDietFromGrassFedFeed} isComparing={isComparing} category="Dairy grass-fed percentage" label="Wet matter from grass-fed feed" dp={1} />
                            <SummaryRowForV2API before={beforeResults?.animals?.dairyGrassFedStandard?.location?.percentageOfTimeWithAccessToPasture} after={afterResults?.animals?.dairyGrassFedStandard?.location?.percentageOfTimeWithAccessToPasture} isComparing={isComparing} category=" " label="Time with access to pasture" dp={1} />

                            <SummaryRowForV2API before={beforeResults?.animals?.production?.beef?.percentageOfMaleBeefAnimals} after={afterResults?.animals?.production?.beef?.percentageOfMaleBeefAnimals} isComparing={isComparing} category="Beef" label="Percentage of male beef animals" />
                        </tbody>
                    </table>
                </div>
            </LoadingPanel>
        </div>
    )
}
