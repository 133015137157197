import React from "react";
import moment from "moment";
import find from "lodash/find";
import sumBy from "lodash/sumBy";
import findIndex from "lodash/findIndex";
import * as icons from "./icons";
import * as domain from "./domain";
import { roundTo } from "round-to";
import { v4 as uuidv4 } from "uuid";
import { diff } from "deep-object-diff";

const iconMap = new Map();

iconMap.set("Pasture", icons.cropsPermanentPasture);
iconMap.set("Bareground", icons.cropsBareground);
iconMap.set("AnnualRyegrass", icons.cropsPermanentPasture);
iconMap.set("CultivatedOrFallow", icons.priorFallow);
iconMap.set("PriorPasture", icons.cropsPermanentPasture);
iconMap.set("PriorGrain", icons.priorCrop);
iconMap.set("PriorVegetable", icons.priorCrop);
iconMap.set("PriorPastureSeed", icons.cropsSeed);
iconMap.set("RegrowthCloverSeed", icons.cropsSeed);
iconMap.set("RegrowthRyegrassSeed", icons.cropsSeed);
iconMap.set("CropSeed_Basil", icons.cropsSeed);
iconMap.set("CropSeed_Borage", icons.cropsSeed);
iconMap.set("CropSeed_Cabbage", icons.cropsSeed);
iconMap.set("CropSeed_Carrot", icons.cropsSeed);
iconMap.set("CropSeed_Cauliflower", icons.cropsSeed);
iconMap.set("CropSeed_Chia", icons.cropsSeed);
iconMap.set("CropSeed_Chives", icons.cropsSpinach);
iconMap.set("CropSeed_Coriander", icons.cropsSeed);
iconMap.set("CropSeed_Dill", icons.cropsSeed);
iconMap.set("CropSeed_FodderBeets", icons.cropsSeed);
iconMap.set("CropSeed_HoneydewMelon", icons.cropsSeed);
iconMap.set("CropSeed_Kale", icons.cropsSeed);
iconMap.set("CropSeed_Kohlrabi", icons.cropsSeed);
iconMap.set("CropSeed_Leek", icons.cropsSeed);
iconMap.set("CropSeed_Lettuce", icons.cropsSeed);
iconMap.set("CropSeed_OnionsBrown", icons.cropsSeed);
iconMap.set("CropSeed_OnionsRed", icons.cropsSeed);
iconMap.set("CropSeed_Orach", icons.cropsSeed);
iconMap.set("CropSeed_PakChoi", icons.cropsSeed);
iconMap.set("CropSeed_Parsley", icons.cropsSeed);
iconMap.set("CropSeed_Parsnip", icons.cropsSeed);
iconMap.set("CropSeed_Phacelia", icons.cropsSeed);
iconMap.set("CropSeed_Pumpkin", icons.cropsSeed);
iconMap.set("CropSeed_RadishHybrid", icons.cropsSeed);
iconMap.set("CropSeed_RadishOpenPollinated", icons.cropsSeed);
iconMap.set("CropSeed_Rape", icons.cropsSeed);
iconMap.set("CropSeed_RedBeet", icons.cropsSeed);
iconMap.set("CropSeed_Rocket", icons.cropsSeed);
iconMap.set("CropSeed_RockMelon", icons.cropsSeed);
iconMap.set("CropSeed_Sage", icons.cropsSeed);
iconMap.set("CropSeed_Silverbeet", icons.cropsSeed);
iconMap.set("CropSeed_Spinach", icons.cropsSeed);
iconMap.set("CropSeed_SpringOnion", icons.cropsSeed);
iconMap.set("CropSeed_Squash", icons.cropsSeed);
iconMap.set("CropSeed_SugarBeet", icons.cropsSeed);
iconMap.set("CropSeed_Turnips", icons.cropsSeed);
iconMap.set("CropSeed_Watermelon", icons.cropsSeed);
iconMap.set("CropSeed_WongBok", icons.cropsSeed);
iconMap.set("CropSeed_Zucchini", icons.cropsSeed);
iconMap.set("Fibre_Hemp", icons.cropsHemp);
iconMap.set("Fodder_FodderBeets", icons.cropsBeets);
iconMap.set("Fodder_Kale", icons.cropsTurnipLeaf);
iconMap.set("Fodder_Rape", icons.cropsTurnipLeaf);
iconMap.set("Fodder_Swedes", icons.cropsSwede);
iconMap.set("Fodder_TurnipsBulb", icons.cropsTurnip);
iconMap.set("Fodder_TurnipsLeafy", icons.cropsTurnipLeaf);
iconMap.set("Forages_AnnualRyegrass", icons.cropsPermanentPasture);
iconMap.set("Forages_Barley", icons.cropsGrain);
iconMap.set("Forages_Maize", icons.cropsSweetcorn);
iconMap.set("Forages_Oats", icons.cropsOats);
iconMap.set("Forages_RyeCorn", icons.cropsGrain);
iconMap.set("Forages_Triticale", icons.cropsTriticale);
iconMap.set("Forages_Wheat", icons.cropsGrain);
iconMap.set("Grain_Barley", icons.cropsGrain);
iconMap.set("Grain_Beans", icons.cropsBeans);
iconMap.set("Grain_Canola", icons.cropsGrain);
iconMap.set("Grain_Hemp", icons.cropsHemp);
iconMap.set("Grain_Linseed", icons.cropsGrain);
iconMap.set("Grain_Maize", icons.cropsSweetcorn);
iconMap.set("Grain_Oats", icons.cropsOats);
iconMap.set("Grain_Peas", icons.cropsPeas);
iconMap.set("Grain_Quinoa", icons.cropsGrain);
iconMap.set("Grain_RyeCorn", icons.cropsGrain);
iconMap.set("Grain_Sunflower", icons.cropsSunflower);
iconMap.set("Grain_Triticale", icons.cropsGrain);
iconMap.set("Grain_WheatBreed", icons.cropsGrain);
iconMap.set("Grain_WheatFeed", icons.cropsGrain);
iconMap.set("Grain_Lentils", icons.cropsLentils);
iconMap.set("Grain_Chickpeas", icons.cropsChickpeas);
iconMap.set("Grain_Canola", icons.cropsCanola);
iconMap.set("Grain_Linseed", icons.cropsLinseed);
iconMap.set("GreenManure_Lupins", icons.cropsGreenManure);
iconMap.set("GreenManure_Mustard", icons.cropsGreenManure);
iconMap.set("GreenManure_Oats", icons.cropsGreenManure);
iconMap.set("GreenManure_Phacelia", icons.cropsGreenManure);
iconMap.set("GreenManure_Rape", icons.cropsGreenManure);
iconMap.set("GreenManure_RyeCorn", icons.cropsGreenManure);
iconMap.set("GreenManure_Turnips", icons.cropsGreenManure);
iconMap.set("Ornamentals_LillyBulbs", icons.cropsLily);
iconMap.set("Ornamentals_TulipBulbs", icons.cropsTulip);
iconMap.set("PastureSeed_BrownTop", icons.cropsSeed);
iconMap.set("PastureSeed_Chicory", icons.cropsSeed);
iconMap.set("PastureSeed_CloverRed", icons.cropsSeed);
iconMap.set("PastureSeed_CloverWhite", icons.cropsSeed);
iconMap.set("PastureSeed_Cocksfoot", icons.cropsSeed);
iconMap.set("PastureSeed_Lotus", icons.cropsSeed);
iconMap.set("PastureSeed_Lucerne", icons.cropsSeed);
iconMap.set("PastureSeed_Phalaris", icons.cropsSeed);
iconMap.set("PastureSeed_Plantain", icons.cropsSeed);
iconMap.set("PastureSeed_Ryegrass", icons.cropsSeed);
iconMap.set("PastureSeed_TallFescueForage", icons.cropsSeed);
iconMap.set("PastureSeed_TallFescueTurf", icons.cropsSeed);
iconMap.set("PastureSeed_Timothy", icons.cropsSeed);
iconMap.set("VegFruit_Pumpkin", icons.cropsPumpkin);
iconMap.set("VegFruit_RockMelon", icons.cropsRockmelon);
iconMap.set("VegFruit_Squash", icons.cropsPumpkin);
iconMap.set("VegFruit_Sweetcorn", icons.cropsSweetcorn);
iconMap.set("VegFruit_Tomato", icons.cropsTomato);
iconMap.set("VegFruit_Watermelon", icons.cropsWatermelon);
iconMap.set("VegFruit_Zucchini", icons.cropsZucchini);
iconMap.set("VegGreen_BroccoliChineseCut", icons.cropsBroccoli);
iconMap.set("VegGreen_BroccoliHeadOnly", icons.cropsBroccoli);
iconMap.set("VegGreen_BrusselSprouts", icons.cropsBrusselSprouts);
iconMap.set("VegGreen_Cabbage", icons.cropsCabbage);
iconMap.set("VegGreen_Cauliflower", icons.cropsCauliflower);
iconMap.set("VegGreen_Celery", icons.cropsCelery);
iconMap.set("VegGreen_ChoySum", icons.cropsPakchoi);
iconMap.set("VegGreen_Coriander", icons.cropsCoriander);
iconMap.set("VegGreen_Leek", icons.cropsLeek);
iconMap.set("VegGreen_LettuceIceberg", icons.cropsLettuce);
iconMap.set("VegGreen_LettuceLeafy", icons.cropsLettuce);
iconMap.set("VegGreen_PakChoi", icons.cropsPakchoi);
iconMap.set("VegGreen_Silverbeet", icons.cropsSilverbeet);
iconMap.set("VegGreen_Spinach", icons.cropsSpinach);
iconMap.set("VegGreen_SpringOnion", icons.cropsSpringonion);
iconMap.set("VegGreen_WongBok", icons.cropsPakchoi);
iconMap.set("VegLegumes_BeansDried", icons.cropsBeans);
iconMap.set("VegLegumes_BeansGreen", icons.cropsBeans);
iconMap.set("VegLegumes_BroadBeans", icons.cropsBroadbeans);
iconMap.set("VegLegumes_Chickpeas", icons.cropsChickpeas);
iconMap.set("VegLegumes_Lupins", icons.cropsLupin);
iconMap.set("VegLegumes_PeasDried", icons.cropsPeas);
iconMap.set("VegLegumes_PeasGreen", icons.cropsPeas);
iconMap.set("VegLegumes_TickBean", icons.cropsBeans);
iconMap.set("VegRoot_Carrots", icons.cropsRootVege);
iconMap.set("VegRoot_Garlic", icons.cropsGarlic);
iconMap.set("VegRoot_Kumara", icons.cropsKumera);
iconMap.set("VegRoot_OnionsBrown", icons.cropsOnion);
iconMap.set("VegRoot_OnionsRed", icons.cropsRedonion);
iconMap.set("VegRoot_Parsnip", icons.cropsParsnip);
iconMap.set("VegRoot_Potato", icons.cropsPotatoe);
iconMap.set("VegRoot_RedBeet", icons.cropsBeets);
iconMap.set("VegRoot_Shallots", icons.cropsOnion);
iconMap.set("UserDefined1", icons.cropsGreenVege);
iconMap.set("UserDefined2", icons.cropsGreenVege);
iconMap.set("UserDefined3", icons.cropsGreenVege);

export function getBlockList(blocks) {
    const productiveBlocks = blocks.filter((block) => block.isProductive).sort((a, b) => (b.type === a.type ? (b.name.toLowerCase() < a.name.toLowerCase() ? 1 : -1) : b.type > a.type ? 1 : -1));
    const nonProductiveBlocks = blocks.filter((block) => !block.isProductive).sort((a, b) => (b.type === a.type ? (b.name.toLowerCase() < a.name.toLowerCase() ? 1 : -1) : b.type > a.type ? 1 : -1));

    return { productiveBlocks, nonProductiveBlocks };
}

export function unixNow() {
    return Math.floor(moment.utc().valueOf());
}

export function skipReducer(action, prefixes) {
    return !action || !action.type || prefixes.filter((p) => action.type.startsWith(p)).length === 0;
}

export function clone(obj) {
    if (!obj) return undefined;
    return JSON.parse(JSON.stringify(obj));
}

export function valueToText(items, value, fallback = "") {
    const item = getRefDataItem(items, value);
    return item ? item.text : fallback;
}

export function getRefDataItem(items, value) {
    if (!value || !items || items.length === 0) return undefined;
    const item = items.find((item) => item.value.toLowerCase() === value.toLowerCase());
    return item;
}

export function formatLatLng(lat, lng) {
    if (lat != null && lng != null) {
        return `${round(lat, 6)} | ${round(lng, 6)}`;
    } else {
        return "";
    }
}

export function setAnalysisType(farm, analysis) {
    if (analysis.type === "YearEnd" && analysis.year) {
        const writeAccess = canUpdateFarm(farm);
        analysis.year = Number(analysis.year);
        analysis.name = `Year ending ${analysis.year}`;
        analysis.type = writeAccess ? "YearEnd" : "PrivateYearEnd";
    }
}

export function merge(obj, items) {
    items = items || [];
    const index = findIndex(items, { id: obj.id });
    if (index !== -1) {
        const existingItem = items[index];
        if (existingItem.isNew === true) {
            obj.isNew = true;
        }
        items.splice(index, 1, obj);
    } else {
        items.push(obj);
    }
    return items;
}

export function dateLong(utcDate, nodate = "") {
    if (!utcDate && nodate) return nodate;
    const date = moment(utcDate);
    return date.isValid() ? date.format("D MMM YYYY, h:mmA") : nodate;
}

export function dateOnly(utcDate, nodate = "") {
    const date = moment(utcDate);
    return date.isValid() ? date.format("D MMM YYYY") : nodate;
}

export function calculateBudget(budget) {
    if (!budget) return;
    const blocks = budget.blocks || [];
    budget.totalBlockAreaInHectares = round(sumBy(blocks, "areaInHectares"), 1);
    if (!budget.hasCompletedBlocks) {
        budget.totalFarmArea = budget.totalBlockAreaInHectares;
    }
    budget.nonProductiveAreaInHectares = budget.totalFarmArea ? round(budget.totalFarmArea - budget.totalBlockAreaInHectares, 1) : 0;
}

export function getSoilTestFromBudget(budget, soilTestId) {
    if (!budget || !soilTestId) return;
    return find(budget.soilTests, { id: soilTestId });
}

export function strToLatLng(value) {
    let latLng = ["", ""];
    const parts = value.split("|");
    if (parts && parts.length === 2) {
        latLng[0] = parts[0].trim();
        latLng[1] = parts[1].trim();
    }
    return latLng;
}

export function enumToSentence(text) {
    if (!text || !text.length > 0) return "";
    let result = text
        .replace(/([A-Z])/g, " $1")
        .toLowerCase()
        .trim();
    return result.charAt(0).toUpperCase() + result.slice(1);
}

export function truncateText(text, maxLength) {
    return text ? text.substring(0, Math.min(text.length, maxLength)) : "";
}

export function round(value, decimals) {
    const numVal = Number(value);
    if (isNaN(numVal)) return null;
    else return roundTo(numVal, decimals);
}

export function truncateDecimal(obj, decimals) {
    const val = String(obj).trim();
    const parts = val.split(".");
    if (parts.length >= 2) {
        if (decimals === 0) {
            return parts[0];
        }
        if (parts[1].length > decimals) {
            return round(val, decimals);
        }
    }
    return val;
}

export function ctrlVal(e) {
    const { target = {} } = e;
    if (target.type === "checkbox" || target.type === "radio") {
        return target.checked;
    }
    return target.value;
}

export function strToId(str, nullString = "blank") {
    return typeof str === "string" || str instanceof String ? str.replace(/\W/g, "_") : nullString;
}

export function isNumeric(obj, decimalPlaces) {
    // If the last digit is a . then add a 0 before testing so if they type 25. it will be accepted
    let val = String(obj);
    let lastChar = val.substring(val.length - 1);
    if (lastChar === ".") val += "0";

    var objRegExp = new RegExp("^\\s*-?(\\d+(\\.\\d{0," + decimalPlaces + "})?|\\.\\d{0," + decimalPlaces + "})\\s*$", "g");
    if (decimalPlaces === -1) objRegExp = new RegExp("^\\s*-?(\\d+(\\.\\d{0,25})?|\\.\\d{0,25})\\s*$", "g");

    return objRegExp.test(val);
}

export function getBlockIcon(blockType) {
    switch (blockType) {
        case domain.BlockType.ProductiveFruit:
            return icons.fruitBlock;
        case domain.BlockType.FodderCrop:
        case domain.BlockType.ProductiveCrop:
            return icons.cropBlock;
        case domain.BlockType.ProductivePasture:
            return icons.pastureBlock;
        case domain.BlockType.NonProductiveTreesAndScrub:
            return icons.treesAndScrubBlock;
        case domain.BlockType.NonProductiveRiparian:
            return icons.riparianBlock;
        default:
            return icons.farm;
    }
}

export function getStructureIcon(structureType) {
    switch (structureType) {
        case "MilkingShed":
            return icons.milkingShed;
        case "FeedingPad":
        case "OutdoorPigHuts":
            return icons.feedingPad;
        case "CoveredWinteringPad":
        case "UncoveredWinteringPad":
        case "OutdoorPigBarns":
            return icons.winteringPad;
        case "StandoffPad":
        case "OutdoorPigFarrowingVillages":
            return icons.standoffPad;
        default:
            return icons.feedingPad;
    }
}

export function getBlockTypeText(refData, block, includeArea = true) {
    if (!block.type) {
        return "Creating this block";
    }

    let blockType = "";

    const hasAnimals = (block.animals && block.animals.length > 0) || block.hasAnimals;

    if (block.type === domain.BlockType.ProductivePasture && !hasAnimals) {
        blockType = "Cut and carry";
    } else {
        blockType = valueToText(refData.blockTypes, block.type);
    }

    if (includeArea) {
        const blockArea = block.areaInHectares || block.area ? `${block.areaInHectares || block.area} ha` : `${block.rotationArea} ha (rotation)`;

        const topography = block.topography || (block.pasture && block.pasture.topography);
        if (topography) {
            const topographyText = valueToText(refData.slopes, topography);
            blockType += ` - ${topographyText}, ${blockArea}`;
        } else {
            blockType += ` - ${blockArea}`;
        }
    }

    return blockType;
}

export function mapRefDataItems(items) {
    return items
        ? items.map((item) => (
              <option value={item.value} key={item.value}>
                  {item.text}
              </option>
          ))
        : undefined;
}

export const getFruitIcon = (cropType) => {
    let icon = undefined;
    switch (cropType) {
        case "Kiwifruit":
            icon = icons.kiwifruit;
            break;
        case "Apples":
            icon = icons.apple;
            break;
        case "Grapes":
            icon = icons.grapes;
            break;
        case "Avocados":
            icon = icons.avocado;
            break;
        case "Peaches":
            icon = icons.peach;
            break;
        default:
            icon = icons.unknown;
            break;
    }
    return icon;
};

export const getCropIcon = (crop) => {
    return iconMap.get(crop.cropId);
};

export const getPreCropIcon = (cropType) => {
    let icon = undefined;
    switch (cropType) {
        case "Pasture":
            icon = icons.cropsPermanentPasture;
            break;
        case "Fallownocropgrowth":
            icon = icons.priorFallow;
            break;
        case "Graincrop":
            icon = icons.cropsGrain;
            break;
        case "Vegetablecrop":
            icon = icons.priorCrop;
            break;
        case "Firstyearofseedcrop":
            icon = icons.cropsSeed;
            break;
        case "Secondyearofseedcrop":
            icon = icons.cropsSeed;
            break;
        case "OutdoorPigs":
            icon = icons.pig;
            break;
        default:
            icon = icons.unknown;
            break;
    }
    return icon;
};

export const getIrrigatorIcon = (irrigatorType) => {
    let icon = undefined;
    switch (irrigatorType) {
        case "CentrePivot":
            icon = icons.irrigatorLinear;
            break;
        case "Flood":
            icon = icons.irrigatorFlood;
            break;
        case "Solid":
            icon = icons.irrigatorSet;
            break;
        case "Drip":
            icon = icons.irrigatorMicro;
            break;
        case "Spray":
            icon = icons.irrigatorSprayline;
            break;
        case "Travelling":
            icon = icons.irrigatorTravelling;
            break;
        case "BorderDyke":
            icon = icons.irrigatorBorder;
            break;
        default:
            icon = undefined;
            break;
    }
    return icon;
};

export const getFertiliserIcon = (fertiliserType) => {
    let icon = undefined;
    switch (fertiliserType) {
        case "Product":
            icon = icons.fertiliserProduct;
            break;
        case "Lime":
            icon = icons.fertiliserLime;
            break;
        case "Soluble":
            icon = icons.fertiliserSoluble;
            break;
        case "Organic":
            icon = icons.fertiliserOrganic;
            break;
        case "General":
            icon = icons.fertiliserGeneral;
            break;
        default:
            icon = undefined;
            break;
    }
    return icon;
};

export const getAnimalIcon = (enterpriseType) => {
    if (!enterpriseType) return icons.unknown;

    const type = enterpriseType.value || enterpriseType;
    if (!type || type === Object(type)) return icons.unknown;

    switch (type.toLowerCase()) {
        case "sheep":
            return icons.sheep;
        case "beef":
            return icons.beef;
        case "dairygrazing":
            return icons.smallGrazing1;
        case "deer":
            return icons.deer;
        case "dairy":
            return icons.dairy;
        case "dairyreplacements":
            return icons.dairyReplacements;
        case "dairygoat":
            return icons.dairyGoat;
        case "outdoorpigs":
            return icons.pig;
        case "other":
            return icons.other;
        default:
            return icons.unknown;
    }
};

export const hasCrops = (block) => {
    if (block.type === "ProductivePasture" && block.pasture && block.pasture.pastureCategory) return true;

    if (block.type === "ProductiveFruit" && block.fruit && block.fruit.cropType) return true;

    if (["ProductiveCrop", "FodderCrop"].includes(block.type) && block.crops && block.crops.length > 0) return true;

    return false;
};

export const priorLandUseSeed = (block) => {
    const { cropBlock = {} } = block;
    return ["Firstyearofseedcrop", "Secondyearofseedcrop"].includes(cropBlock.preCrop);
};

export const colourNameIndex = ["yellow", "orange", "tan", "red", "brick", "magenta", "grape", "purple", "navy", "blue", "sky"];
export const colourHexIndex = ["#ffba49", "#f87666", "#e39153", "#ad343e", "#973b36", "#c84273", "#8f5687", "#7d63a5", "#4c576c", "#395998", "#509bc2"];
export const graphColours = ["#e6194B", "#3cb44b", "#ffe119", "#4363d8", "#f58231", "#911eb4", "#42d4f4", "#f032e6", "#bfef45", "#fabebe", "#469990", "#e6beff", "#9A6324", "#fffac8", "#800000", "#aaffc3", "#808000", "#ffd8b1", "#000075, #a9a9a9, #ffffff, #000000"];
export const smapSoilPolygonColours = ["#e6194B", "#3cb44b", "#4363d8", "#f58231", "#911eb4", "#42d4f4", "#f032e6", "#bfef45", "#fabebe", "#469990", "#e6beff", "#9A6324", "#fffac8", "#800000", "#aaffc3", "#808000", "#ffd8b1", "#f66364", "#f5b04d", "#71c989", "#93bfeb", "#f5aa85", "#66c2a3", "#bac1c4", "#b90c0d", "#c67a0c", "#358a4d", "#367dc4", "#da5a1b", "#0e8c62", "#69767c", "#5899DA", "#ef8d5d", "#3fb68e", "#9ea8ad", "#9ea8ad", "#4cba6b", "#f29b1d", "#dc0d0e", "#ffba49", "#f87666", "#e39153", "#ad343e", "#973b36", "#c84273", "#8f5687", "#7d63a5", "#4c576c", "#395998", "#509bc2", "#e6194B", "#3cb44b", "#ffe119", "#4363d8", "#f58231", "#911eb4", "#42d4f4", "#f032e6", "#bfef45", "#fabebe", "#469990", "#e6beff", "#9A6324", "#fffac8", "#800000", "#aaffc3", "#808000", "#ffd8b1", "#000075, #a9a9a9, #ffffff, #000000"];

//https://experience.sap.com/fiori-design-web/values-and-names/
export const graphColoursMuted = ["#003f5c", "#665191", "#a05195", "#d45087", "#f95d6a", "#ff7c43", "#ffa600", "#2f4b7c"];
export const seqColourPalette = ["#f66364", "#f5b04d", "#71c989", "#93bfeb", "#f5aa85", "#66c2a3", "#bac1c4", "#b90c0d", "#c67a0c", "#358a4d", "#367dc4", "#da5a1b", "#0e8c62", "#69767c", "#5899DA", "#ef8d5d", "#3fb68e", "#9ea8ad", "#9ea8ad", "#4cba6b", "#f29b1d", "	#dc0d0e"];
export const purpleShades = ["#772d8b", "#864497", "#955ba4", "#a473b1", "#b38abe", "#c2a1cb", "#d1b9d8", "#e0d0e5", "#efe7f2"];

export const getColour = (index) => (colourNameIndex.length > index ? colourNameIndex[index] : colourNameIndex[Math.random() * Math.floor(colourNameIndex.length)]);
export const getGraphColour = (index) => (graphColours.length > index ? graphColours[index] : graphColours[Math.random() * Math.floor(graphColours.length)]);

export const GetBlockColour = (blockType, cutAndCarry) => {
    switch (blockType) {
        case "ProductiveFruit":
            return "#ad343e";
        case "ProductiveCrop":
            return "#c0be38";
        case "ProductivePasture":
            return cutAndCarry ? "#d6905a" : "#65925f";
        case "CutAndCarry":
            return "#d6905a";
        case "ProductiveOutdoorPigs":
            return "#b961a6";
        case "NonProductiveTreesAndScrub":
            return "#2c3b29";
        case "NonProductiveHouse":
            return "#384766";
        case "NonProductiveWetland":
            return "#2a58d4";
        case "NonProductiveRiparian":
            return "#2ac0d4";
        case "FodderCrop":
            return "#9b8233";
        case "NonBlock":
        default:
            return "#ccc";
    }
};

export const GetBlockOrder = (blockType) => {
    switch (blockType) {
        case "ProductivePasture":
            return 1;
        case "CutAndCarry":
            return 2;
        case "ProductiveCrop":
            return 3;
        case "FodderCrop":
            return 4;
        case "ProductiveFruit":
            return 5;
        case "ProductiveOutdoorPigs":
            return 6;
        case "NonProductiveTreesAndScrub":
            return 7;
        case "NonProductiveHouse":
            return 8;
        case "NonProductiveWetland":
            return 9;
        case "NonProductiveRiparian":
            return 10;
        case "NonBlock":
        default:
            return 11;
    }
};

export const getSupplementIcon = (supplement) => {
    const { category, categoryGeneralType } = supplement;
    switch (category) {
        case "Hay":
            return icons.hay;
        case "Silage":
        case "MaizeSilage":
        case "Maize silage":
        case "Grass silage":
            return icons.silage;
        case "Baleage":
            return icons.baleage;
        case "DirectFeeding":
        case "Direct feeding":
            return icons.directFeeding;
        case "GrainsPulses":
        case "Grains":
            return icons.cropsGrain;
        case "Greenfeeds":
            return icons.cropsForages;
        case "Vegeshortproducts":
        case "Vegetable/Fruit":
            return icons.cropsFodder;
        case "Processbyproducts":
        case "Process byproducts":
            return icons.cropsSeed;
        case "Straws":
            return icons.directFeeding;
        case "User defined - Maize/cereal silage":
            return icons.cropsGrain;
        case "User defined - Hay/Silage":
        case "User defined - Other":
            return icons.hay;
        case "User defined - Concentrates":
            return icons.fertiliserSoluble;
        case "User defined - Whole grain":
            return icons.cropsGrain;
        case "Userdefined":
            switch (categoryGeneralType) {
                case "HaySilage":
                    return icons.hay;
                case "Maizecerealsilage":
                    return icons.cropsGrain;
                case "Concentrates":
                    return icons.fertiliserSoluble;
                case "Wholegrain":
                    return icons.cropsGrain;
                case "Other":
                    return icons.hay;
                default:
                    return icons.unknown;
            }
        default:
            return icons.unknown;
    }
};

export const isTouched = (validation, source, id) => (validation && validation[id] && validation[id].touched) || source === undefined || source.key === id || source.id === id;
export const setVal = (ctrlName, currentValidation, source, message) => {
    return { touched: isTouched(currentValidation, source, ctrlName), error: message !== undefined, message: message };
};

export const isJson = (str) => {
    try {
        return JSON.parse(str) && !!str;
    } catch (e) {
        return false;
    }
};

export const getStructureTitle = (budget, structure, refData) => {
    const structureType = valueToText(refData.structureType, structure.type);
    const enterprise = budget.enterprises.find((enterprise) => enterprise.id === structure.enterpriseId);
    if (enterprise) {
        const enterpriseType = valueToText(refData.enterpriseTypes, enterprise.type);
        return `${enterpriseType} - ${structureType}`;
    }
};

export const cleanBudgetReferences = (budget) => {
    //PRD-3720 Clean up grazed percentage for busted budgets
    const { animalDistribution = {}, blocks = [] } = budget;
    const pastureBlocks = blocks.filter((b) => b.type === "ProductivePasture");
    if (animalDistribution.relProdMethod === "Nodifferencebetweenblocks" && pastureBlocks.length === 1) {
        const block = budget.blocks[0];
        const { animals = [] } = block;
        const percentage = animals.length > 0 ? 100 / animals.length : 100;
        animals.forEach((a) => {
            a.percenBlockPastureProd = a.percenBlockPastureProd || percentage;
        });
    }
};

export const deleteOrphanBlockRef = (budget, blockId, options = {}) => {
    if (!budget) return;

    if (budget.features) {
        budget.features = budget.features.map((f) => {
            if (f.properties.blockId === blockId) {
                const properties = {
                    ...f.properties,
                    layer: "UnassignedLand",
                    selected: false,
                };
                delete properties.blockId;
                return { ...f, properties };
            }
            return f;
        });
        budget.hasUnassignedLand = budget.features.some((f) => f.properties.layer === "UnassignedLand");
    }

    if (budget.animalDistribution && budget.blocks.length === 2) {
        budget.animalDistribution.blockStockRatios = "BasedOnAnimalsOnBlock";
    }

    budget.snowfalls = budget.snowfalls
        .map((snowfall) => {
            return {
                ...snowfall,
                blockIds: snowfall.blockIds.filter((id) => id !== blockId),
            };
        })
        .filter((snowfall) => snowfall.blockIds.length > 0);

    if (!options.keepDrainage) {
        if (budget.wetlands) {
            /* eslint-disable no-unused-vars */
            for (const wetland of budget.wetlands) {
                if (wetland.catchmentPercentages) {
                    wetland.catchmentPercentages = wetland.catchmentPercentages.filter((c) => c.blockId !== blockId);
                    if (wetland.catchmentPercentages.length === 0) {
                        wetland.wetlandOffFarmType = "Undefined";
                    }
                }
            }
        }

        const drainageDetailsId = budget.blocks.find((b) => b.id === blockId).drainageDetailsId;
        const { drainageSystems = [] } = budget;
        /* eslint-disable no-unused-vars */
        for (const drainageSystem of budget.drainageSystems) {
            const { drainage = [] } = drainageSystem;
            drainageSystem.drainage = drainage.filter((d) => d.id !== drainageDetailsId);
        }
        budget.drainageSystems = drainageSystems.filter((d) => d.drainage.length > 0);

        /* eslint-disable no-unused-vars */
        for (const block of budget.blocks.filter((b) => b.type === "NonProductiveWetland")) {
            if (block.wetland && block.wetland.catchmentPercentages) {
                block.wetland.catchmentPercentages = block.wetland.catchmentPercentages.filter((c) => c.blockId !== blockId);
            }
        }
    }

    /* eslint-disable no-unused-vars */
    for (const block of budget.blocks.filter((b) => b.type === "FodderCrop" && b.blockIds)) {
        block.blockIds = block.blockIds.filter((b) => b !== blockId);
    }

    if (budget.irrigators) {
        budget.irrigators = budget.irrigators.map((irrigator) => {
            irrigator.applications = irrigator.applications.reduce((apps, app) => {
                const blockIds = app.blockIds.filter((id) => id !== blockId);
                if (blockIds.length > 0) apps.push({ ...app, blockIds });
                return apps;
            }, []);

            if (irrigator.nonDrawnArea && irrigator.nonDrawnArea.length > 0) irrigator.nonDrawnArea = irrigator.nonDrawnArea.filter((nda) => nda.blockId !== blockId);

            return irrigator;
        });
    }

    if (budget.fertiliser) {
        /* eslint-disable no-unused-vars */
        for (const fertiliser of budget.fertiliser) {
            /* eslint-disable no-unused-vars */
            for (const application of fertiliser.applications) {
                if (application.blockIds.includes(blockId)) {
                    application.blockIds.splice(application.blockIds.indexOf(blockId), 1);
                }
            }
            fertiliser.applications = fertiliser.applications.filter((a) => a.blockIds.length > 0);
        }
    }

    if (budget.nitrateInhibitors) {
        const validNitrateInhibitors = [];
        /* eslint-disable no-unused-vars */
        for (const nitrateInhibitor of budget.nitrateInhibitors) {
            /* eslint-disable no-unused-vars */
            for (const application of nitrateInhibitor.applications) {
                if (application.blockIds.includes(blockId)) {
                    application.blockIds.splice(application.blockIds.indexOf(blockId), 1);
                }
                if (application.blockIds.length > 0 && !validNitrateInhibitors.includes(nitrateInhibitor.id)) {
                    validNitrateInhibitors.push(nitrateInhibitor.id);
                }
            }
            nitrateInhibitor.applications = nitrateInhibitor.applications.filter((a) => a.blockIds.length > 0);
        }
        budget.nitrateInhibitors = budget.nitrateInhibitors.filter((i) => validNitrateInhibitors.includes(i.id));
    }

    updateBudgetFeedSupplements(budget, blockId);

    const { structures = [] } = budget;

    const updateEffluentSystem = (effluentSystem) => {
        const { liquidManagement = {}, solidManagement = {} } = effluentSystem;
        let { applications = [] } = liquidManagement;
        let { solidApplications = [], pondApplications = [] } = solidManagement;
        const blockApplications = [...applications, ...solidApplications, ...pondApplications];
        /* eslint-disable no-unused-vars */
        for (const app of blockApplications) {
            app.blockIds = app.blockIds.filter((b) => b !== blockId);
        }
        liquidManagement.applications = applications.filter((a) => a.blockIds.length > 0);
        solidManagement.solidApplications = solidApplications.filter((a) => a.blockIds.length > 0);
        solidManagement.pondApplications = pondApplications.filter((a) => a.blockIds.length > 0);
    };

    /* eslint-disable no-unused-vars */
    for (const structure of structures) {
        const { effluentSystem = {} } = structure;
        updateEffluentSystem(effluentSystem);
    }
    const { effluentSystem = {} } = budget;
    updateEffluentSystem(effluentSystem);

    const remainingSoilTestIds = budget.blocks
        .filter((block) => block.id !== blockId)
        .reduce((results, block) => {
            const soilTestIds = [block.soilTestId];
            return [...results, ...soilTestIds];
        }, []);

    budget.soilTests = budget.soilTests && budget.soilTests.filter((soilTest) => remainingSoilTestIds.includes(soilTest.id));

    if (budget.outdoorPigEffluentSystem) {
        if (budget.outdoorPigEffluentSystem.liquidEffluentApplications) {
            budget.outdoorPigEffluentSystem.liquidEffluentApplications = budget.outdoorPigEffluentSystem.liquidEffluentApplications.filter((app) => !app.blockIds.includes(blockId));
        }

        if (budget.outdoorPigEffluentSystem.pondSolidApplications) {
            budget.outdoorPigEffluentSystem.pondSolidApplications = budget.outdoorPigEffluentSystem.pondSolidApplications.filter((app) => !app.blockIds.includes(blockId));
        }

        if (budget.outdoorPigEffluentSystem.strawCompostApplications) {
            budget.outdoorPigEffluentSystem.strawCompostApplications = budget.outdoorPigEffluentSystem.strawCompostApplications.filter((app) => !app.blockIds.includes(blockId));
        }
    }

    const outdoorPigsEnterprise = (budget.enterprises || []).find((e) => e.type === "OutdoorPigs");
    if (outdoorPigsEnterprise) {
        outdoorPigsEnterprise.outdoorPigs.areaAllocations = outdoorPigsEnterprise.outdoorPigs.areaAllocations.filter((allocation) => allocation.blockId !== blockId);

        const greenCoverApplications = (outdoorPigsEnterprise.outdoorPigs.greenCoverApplications || []).map((application) => {
            return {
                ...application,
                blockIds: application.blockIds.filter((id) => id !== blockId),
            };
        });
        outdoorPigsEnterprise.outdoorPigs.greenCoverApplications = greenCoverApplications.filter((application) => application.blockIds.length > 0);
    }

    if (budget.fertiliserForms && budget.fertiliserForms[blockId]) {
        delete budget.fertiliserForms[blockId];
    }
};

export const updateBudgetFeedSupplements = (budget, blockId) => {
    if (budget.feedSupplements && budget.feedSupplements.length > 0) {
        const supplements = budget.feedSupplements.reduce((supps, supplement) => {
            const sources = (supplement.sources || []).filter((source) => source.blockId !== blockId);

            const destinations = (supplement.destinations || []).reduce((dests, destination) => {
                if (destination.type === "SpecifiedBlocks") {
                    const applications = destination.applications.filter((application) => application.blockId !== blockId);
                    if (applications.length > 0) dests.push({ ...destination, applications });
                } else {
                    dests.push(destination);
                }
                return dests;
            }, []);

            if (sources.length > 0 || destinations.length > 0) supps.push({ ...supplement, sources, destinations });

            return supps;
        }, []);

        budget.feedSupplements = supplements;
    }
};

export const delYear1FromNonCropBlocks = (applications, budget) => {
    /* eslint-disable no-unused-vars */
    for (const application of applications) {
        const caBlocks = application.blockIds.map((blockId) => budget.blocks.find((b) => b.id === blockId));
        if (!caBlocks.some((b) => b.type === "ProductiveCrop") && application.months) {
            application.months = application.months.filter((m) => m.reportingYear);
        }
    }
};

export const splitCropApplications = (application, budget) => {
    const blocks = application.blockIds.map((blockId) => budget.blocks.find((b) => b.id === blockId));
    const cropTypes = ["FodderCrop", "ProductiveCrop"];
    const cropApplications = blocks
        .filter((c) => cropTypes.includes(c.type))
        .map((b) => {
            const cloneApp = clone(application);
            cloneApp.id = uuidv4();
            cloneApp.blockIds = [b.id];
            return cloneApp;
        });
    application.blockIds = blocks.filter((c) => !cropTypes.includes(c.type)).map((b) => b.id);

    if (application.blockIds.length > 0) {
        cropApplications.push(application);
    }

    delYear1FromNonCropBlocks(cropApplications, budget);

    return cropApplications;
};

export const parseDate = (dateString, formatOfTheDateString) => {
    const date = moment(dateString, formatOfTheDateString);
    if (!date.isValid()) return null;

    if (date.month() < 6) date.year(2014);
    else date.year(2013);
    return date;
};

export function getSoilTitle(soil, soilGroups) {
    if (!soil || !soilGroups || soilGroups.length === 0) return "Unknown";

    if (soil.sibling) return soil.sibling.smapReference;

    const soilGroup = soilGroups.find((sg) => sg.value === soil.soilGroup);
    if (!soilGroup) return "Unknown";

    const soilOrder = (soilGroup.children || []).find((c) => c.value === soil.soilOrder);
    if (!soilOrder) return soilGroup.text;

    return `${soilGroup.text}/${soilOrder.text}`;
}

const getBottomRootingDepth = (soil) => (soil.sibling && soil.sibling.bottomRootingDepth !== 0 ? soil.sibling.bottomRootingDepth : soil.bottomRootingDepth);
const getImpededLayerDepth = (soil) => (soil.sibling && soil.sibling.impededLayerDepth !== 0 ? soil.sibling.impededLayerDepth : soil.impededLayerDepth);
const getDrainageClass = (soil) => (soil.sibling && soil.sibling.profileDrainageClass ? soil.sibling.profileDrainageClass : soil.profileDrainageClass);
const getTopSoilTexture = (soil) => (soil.sibling && soil.sibling.topsoilTexture ? soil.sibling.topsoilTexture : soil.topSoilTexture);
const getIsStony = (soil) => (soil.sibling && soil.sibling.isStony !== undefined ? soil.sibling.isStony : soil.isStony);
const getParentMaterial = (soil) => (soil.sibling && soil.sibling.lowerProfileNonStandardLayer ? soil.sibling.lowerProfileNonStandardLayer : soil.parentMaterial);
const getNonStandardLayerDepth = (soil) => (soil.sibling && soil.sibling.nonStandardLayerDepth && soil.sibling.nonStandardLayerDepth !== 0 ? soil.sibling.nonStandardLayerDepth : soil.nonStandardLayerDepth);
const getTextureGroup = (soil) => (soil.sibling && soil.sibling.lowerProfileTexture ? soil.sibling.lowerProfileTexture : soil.textureGroup);

export const getSoilProfile = (soil) =>
    soil && {
        bottomRootingDepth: getBottomRootingDepth(soil),
        impededLayerDepth: getImpededLayerDepth(soil),
        profileDrainageClass: getDrainageClass(soil),
        topSoilTexture: getTopSoilTexture(soil),
        isStony: getIsStony(soil),
        parentMaterial: getParentMaterial(soil),
        nonStandardLayerDepth: getNonStandardLayerDepth(soil),
        textureGroup: getTextureGroup(soil),
        description: soil.sibling && soil.sibling.description && soil.sibling.description.length > 0 ? soil.sibling.description : soil.description,
    };

export const stopPropagation = (e) => {
    if (!e) return;
    e.stopPropagation();
    const { nativeEvent = {} } = e;
    nativeEvent.stopImmediatePropagation();
};

export const compareDate = (sortBy, desc) => (a, b) => {
    const fieldA = a[sortBy];
    const fieldB = b[sortBy];
    const momentA = fieldA ? moment(fieldA) : moment();
    const momentB = fieldB ? moment(fieldB) : moment();
    if (momentA.isBefore(momentB)) return desc ? 1 : -1;
    if (momentA.isAfter(momentB)) return desc ? -1 : 1;
    return 0;
};

export const canMakePayment = (farm) => {
    return hasFarmClaim(farm, "MakePayment");
};

export const canUpdateFarm = (farm) => {
    return hasFarmClaim(farm, "UpdateFarm");
};

export const canManageAnalyses = (farm) => {
    return hasFarmClaim(farm, "ManageAnalyses");
};

export const canManageAccess = (farm) => {
    return hasFarmClaim(farm, "ManageAccess");
};

export const canReadFarm = (farm) => {
    return hasFarmClaim(farm, "ReadFarm");
};

export const canCreateWorkingCopy = (farm) => {
    return hasFarmClaim(farm, "CreateWorkingCopy");
};

export const canGrantPublicationAccess = (farm) => {
    return hasFarmClaim(farm, "GrantAgentAccess");
};

export const isOwner = (farm) => {
    return hasFarmClaim(farm, "Owner");
};

export const hasFarmClaim = (farm, claim) => {
    return farm && farm.farmClaims && claim && farm.farmClaims.includes(claim);
};

export const canEditBudget = (analysis) => {
    return analysis.role === "Write";
};

export const farmRolesToText = (farm, farmRoles) => {
    return valueToText(farmRoles, farm.role);
};

export const soilModified = (soil) => {
    if (soil.advancedSettings && Object.keys(soil.advancedSettings).length > 0) return true;

    if (soil.profileDrainageClass && soil.profileDrainageClass !== "Usedefault") return true;
    if (soil.isStony !== undefined) return true;
    if (soil.topSoilTexture && soil.topSoilTexture !== "Undefined") return true;
    if (soil.bottomRootingDepth) return true;
    if (soil.impededLayerDepth) return true;
    if (soil.parentMaterial && soil.parentMaterial !== "Undefined") return true;
    if (soil.nonStandardLayerDepth) return true;
    if (soil.textureGroup) return true;

    return false;
};

export const councilOrder = [
    {
        value: "NorthlandRegionalCouncil",
        isNorth: true,
    },
    {
        value: "AucklandCouncil",
        isNorth: true,
    },
    {
        value: "WaikatoRegionalCouncil",
        isNorth: true,
    },
    {
        value: "BayOfPlentyCouncil",
        isNorth: true,
    },
    {
        value: "GisborneDistrictCouncil",
        isNorth: true,
    },
    {
        value: "HawkesBayRegionalCouncil",
        isNorth: true,
    },
    {
        value: "TaranakiRegionalCouncil",
        isNorth: true,
    },
    {
        value: "HorizonsRegionalCouncil",
        isNorth: true,
    },
    {
        value: "WellingtonRegionalCouncil",
        isNorth: true,
    },
    {
        value: "TasmanDistrictCouncil",
        isNorth: false,
    },
    {
        value: "NelsonCityCouncil",
        isNorth: false,
    },
    {
        value: "MarlboroughCityCouncil",
        isNorth: false,
    },
    {
        value: "WestCoastRegionalCouncil",
        isNorth: false,
    },
    {
        value: "EnvironmentCanterbury",
        isNorth: false,
    },
    {
        value: "OtagoRegionalCouncil",
        isNorth: false,
    },
    {
        value: "EnvironmentSouthland",
        isNorth: false,
    },
];

export const sortCouncils = (councils = []) => {
    const sortOrder = councilOrder.map((c) => c.value);
    return councils.sort((a, b) => sortOrder.indexOf(a.value) - sortOrder.indexOf(b.value));
};

export const isEqualSoil = (existingSoil = {}, newSoil = {}) => {
    const diffObj = diff(newSoil, existingSoil);
    delete diffObj.sibling;
    delete diffObj.id;
    delete diffObj.sessionId;
    delete diffObj.title;
    delete diffObj.subTitle;
    delete diffObj.isNew;
    delete diffObj.addingBy;
    delete diffObj.smapReference;
    delete diffObj.percentageOfBlock;

    const diffKeys = Object.keys(diffObj);
    diffKeys.forEach((diffKey) => {
        if (!existingSoil[diffKey] && !newSoil[diffKey]) {
            delete diffObj[diffKey];
        }

        if (diffObj[diffKey]) {
            if (Object.keys(diffObj[diffKey]).length === 0) {
                delete diffObj[diffKey];
            }
        }
    });

    const { sibling: existingSibling = {} } = existingSoil;
    const { sibling: newSibling = {} } = newSoil;
    if (existingSibling.smapReference !== newSibling.smapReference) {
        diffObj.smapReference = "S-Map different";
    }

    return Object.keys(diffObj).length === 0;
};

export const getFodderBlockAreas = (blocks) => {
    let pastureBlockIds = new Set();

    const fodderArea = blocks
        .filter((f) => f.type === "FodderCrop")
        .reduce((a, b) => {
            b.blockIds.forEach((item) => pastureBlockIds.add(item));
            return a + b.rotationArea;
        }, 0);

    const pastoralArea = [...pastureBlockIds].reduce((a, b) => {
        return a + blocks.find((c) => c.id === b).areaInHectares;
    }, 0);

    return { pastureBlockIds, fodderArea, pastoralArea, ratio: fodderArea / pastoralArea };
};

export const getOutdoorPigStockClassGroupDisplayText = (stockClassGroup) => {
    switch (stockClassGroup) {
        case "SowsMating":
            return "Dry sows (mating)";
        case "SowsDry":
            return "Dry sows (gestating)";
        case "SowsLactating":
            return "Lactating sows";
        case "Gilts":
            return "Replacements (gilts & boars)";
        case "Growers":
            return "Growers & finishers";
        default:
            return "";
    }
};

export const MAX_DATA_POINTS = 10000;

export const hexToRGBA = (hex, a) => {
    let r = 0,
        g = 0,
        b = 0;

    if (hex.length === 4) {
        // 3 digits
        r = "0x" + hex[1] + hex[1];
        g = "0x" + hex[2] + hex[2];
        b = "0x" + hex[3] + hex[3];
    } else if (hex.length === 7) {
        // 6 digits
        r = "0x" + hex[1] + hex[2];
        g = "0x" + hex[3] + hex[4];
        b = "0x" + hex[5] + hex[6];
    }

    return `rgba(${+r}, ${+g}, ${+b}, ${a})`;
};

export const getRegionCenter = (region) => {
    switch (region) {
        case "Northland":
            return { lat: -35.4075, lng: 173.799898 };
        case "Auckland":
            return { lat: -36.766845, lng: 174.587899 };
        case "WaikatoCoromandel":
            return { lat: -37.613863, lng: 175.189948 };
        case "BayofPlenty":
            return { lat: -38.017978, lng: 176.343591 };
        case "CentralPlateau":
            return { lat: -38.986503, lng: 175.817967 };
        case "KingCountryTaihape":
            return { lat: -39.676912, lng: 175.797617 };
        case "Taranaki":
            return { lat: -39.341028, lng: 174.28503 };
        case "ManawatuWanganui":
            return { lat: -40.227512, lng: 175.565292 };
        case "Wellington":
            return { lat: -41.116755, lng: 175.323514 };
        case "EastCoastNorthIsland":
            return { lat: -38.667803, lng: 178.028294 };
        case "WestCoastSouthIsland":
            return { lat: -43.387132, lng: 170.183535 };
        case "Nelson":
            return { lat: -41.318966, lng: 173.22557 };
        case "Marlborough":
            return { lat: -41.50833, lng: 173.825875 };
        case "Canterbury":
            return { lat: -43.48964, lng: 172.108454 };
        case "Otago":
            return { lat: -45.255638, lng: 169.392271 };
        case "Southland":
            return { lat: -45.739194, lng: 168.44156 };
        case "HighCountry300m":
            return { lat: -43.495286, lng: 171.540081 };
        default:
            return { lat: -43.48964, lng: 172.108454 }; //Canterbury
    }
};

export function requiresClimate(blockType) {
    return ["ProductiveCrop", "ProductiveFruit", "ProductiveOutdoorPigs", "ProductivePasture", "NonProductiveHouse", "NonProductiveTreesAndScrub"].includes(blockType);
}

export const currencyFormatter = Intl.NumberFormat("en-NZ", { style: "currency", currency: "NZD" });

export const numeric = {
    format: (value, dp = 0) => {
        // Return "-" if the value is zero, undefined or NaN
        if (!value || isNaN(value)) {
            return "-";
        }
        return value.toLocaleString("en", { minimumFractionDigits: dp, maximumFractionDigits: dp })
    },
    formatAllowZero: (value, dp = 0) => {
        // Only return "-" if the value is undefined or NaN, so zero is displayed as 0
        if (value === undefined || isNaN(value)) {
            return "-";
        }
        return value.toLocaleString("en", { minimumFractionDigits: dp, maximumFractionDigits: dp })
    },
    formatWithUnit: (valueWithUnit, dp = 0) => {
        // Return "-" if the value is zero, undefined or NaN
        if (!valueWithUnit?.value || isNaN(valueWithUnit.value)) {
            return "-";
        }
        if (valueWithUnit.unit.notation === "%") {
            return `${valueWithUnit.value.toLocaleString("en", { minimumFractionDigits: dp, maximumFractionDigits: dp })}${valueWithUnit.unit.notation}`;
        }
        return `${valueWithUnit.value.toLocaleString("en", { minimumFractionDigits: dp, maximumFractionDigits: dp })} ${valueWithUnit.unit.notation}`;
    },
    formatAllowZeroWithUnit: (valueWithUnit, dp = 0) => {
        // Return "-" if the value is zero, undefined or NaN
        if (valueWithUnit?.value === undefined || isNaN(valueWithUnit.value)) {
            return "-";
        }
        if (valueWithUnit.unit.notation === "%") {
            return `${valueWithUnit.value.toLocaleString("en", { minimumFractionDigits: dp, maximumFractionDigits: dp })}${valueWithUnit.unit.notation}`;
        }
        return `${valueWithUnit.value.toLocaleString("en", { minimumFractionDigits: dp, maximumFractionDigits: dp })} ${valueWithUnit.unit.notation}`;
    }
}

export const accounting = {
    format: (value, dp = 0) => {
        if (!value || isNaN(value)) {
            return "-";
        }
        return value.toLocaleString("en", { minimumFractionDigits: dp, maximumFractionDigits: dp })
    }
}

export const month = {
    format: (fullMonthName) => fullMonthName?.substring(0, 3) || ""
}
