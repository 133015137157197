import { Fragment } from "react";
import { useRefData } from "common/hooks";
import * as utils from "common/utils";
import * as domain from "common/domain";
import { BlockResultsWidget } from "components/ResultsWidgets";

export default function BlockSummary({ analysis, block, climateDetails }) {
    const refData = useRefData();

    const { soils = [], farmSoilBlocks } = analysis;
    const blockIcon = utils.getBlockIcon(block.type);
    const blockSoils = farmSoilBlocks.filter((fsb) => fsb.blockId === block.id);
    const hasSoil = block.isProductive && blockSoils.length > 0;

    return (
        <>
            <div className="BlockTitle">
                <img className="FarmTitle-icon u-icon-md u-p-5" src={blockIcon} alt="Block Type" />
                <div id={block.id} className="FarmTitle-heading">
                    <span className="FarmTitle-link">
                        <span className="FarmTitle-name u-title-md u-font-600" title={block.name}>
                            {block.name}
                        </span>
                    </span>
                    <div className="FarmTitle-sub" id={`${block.id}_BT`}>
                        {utils.getBlockTypeText(refData, block)}
                    </div>
                </div>
                <BlockResultsWidget analysis={analysis} block={block} />
            </div>

            <div className="h5">Block details</div>

            <div className="u-flex">
                <div className="Print-Table u-minw-400 u-pr-xxl ">
                    <table>
                        <tbody>
                            <tr>
                                <td>Area</td>
                                <td className="Nvp-Value">{block.type === domain.BlockType.FodderCrop ? block.rotationArea : block.areaInHectares} ha</td>
                                {climateDetails &&
                                    climateDetails.map((c, i) => (
                                        <Fragment key={"climate-" + block.id + "-" + i}>
                                            <td>{c.name}</td>
                                            <td className="Nvp-Value">{c.value}</td>
                                        </Fragment>
                                    ))}
                            </tr>

                            {block.countOfResidents && block.countOfResidents > 0 && (
                                <tr>
                                    <td>Number of people</td>
                                    <td className="Nvp-Value">{block.countOfResidents}</td>
                                </tr>
                            )}
                            {block.sewageDisposalMethod && (
                                <tr>
                                    <td>Sewage disposal method</td>
                                    <td className="Nvp-Value">{utils.valueToText(refData.sewageDisposalMethods, block.sewageDisposalMethod)}</td>
                                </tr>
                            )}
                            {block.bushType && (
                                <tr>
                                    <td>Bush type</td>
                                    <td className="Nvp-Value">{utils.valueToText(refData.bushTypes, block.bushType)}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {hasSoil && (
                <div className="u-flex">
                    <div className="u-mt-md">
                        <div className="h5">Soils</div>
                        <ul className="DataWidthTable FarmTable u-ml-0 u-pt-0">
                            {blockSoils.map((fsb, i) => {
                                const soil = soils.find((s) => s.id === fsb.soilId) || {};
                                const area = fsb.area;
                                return (
                                    <li className="u-pt-sm u-pb-0" key={i}>
                                        <div className="FarmTable-keyValuePair">
                                            <div className="u-flex u-flexColumn u-mr-sm u-textNoWrap">
                                                <span className="FarmTable-keyValue-lead">{fsb.percentageOfBlock}%</span>
                                                <small>{utils.round(area, 2)} ha</small>
                                            </div>
                                            <div className="FarmTable-keyValue u-pr-lg u-textNoWrap">
                                                <span className="FarmTable-key">{soil.subTitle}</span>
                                                <span className="FarmTable-value">{soil.sibling && soil.soilOrder ? soil.soilOrder : ""}</span>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            )}
        </>
    );
}
