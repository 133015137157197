import { Bar } from "react-chartjs-2";
import * as utils from "common/utils";
import Alert from "components/Alert";
import { Grid, GridCell } from "components/Grid";
import ProgressBar from "components/ProgressBar";

export default function GhgEmissions({ aggregatedData }) {
    if (!aggregatedData || !aggregatedData.results || aggregatedData.totalArea === 0) return null;

    const bgColours = ["#9D211B", "#FFAD29", "#1E499F"];

    const data = {
        labels: ["Methane", "Nitrous oxide", "Carbon dioxide"],
        datasets: [
            {
                backgroundColor: bgColours,
                borderWidth: 1,
                hoverBackgroundColor: bgColours,
                data: [utils.round((aggregatedData.results.ghgMethaneKg || 0) / 1000, 1), utils.round((aggregatedData.results.ghgNitrousOxideKg || 0) / 1000, 1), utils.round((aggregatedData.results.ghgCarbonDioxideKg || 0) / 1000, 1)],
            },
        ],
    };

    const options = {
        indexAxis: "y",
        responsive: true,
        scales: {
            x: {
                grid: {
                    drawOnChartArea: true,
                },
                ticks: {
                    callback: function (label, index, labels) {
                        return Number(label).toLocaleString();
                    },
                },
                title: {
                    display: true,
                    text: "t CO2e/yr",
                },
            },
            y: {
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return (
        <div className="HeroPanel u-mt-md u-page-break u-print-border">
            <div className="HeroPanel-title">GHG emissions</div>
            <Alert type="info" html="Shows the aggregated totals for all farm GHG emissions. The figures are in tonnes of CO2e per year broken down by methane, nitrous oxide and carbon dioxide." />

            <Grid>
                <GridCell className="u-xxl-width1of2 u-xl-width1of1">
                    <div className="Table u-mt-md">
                        <table>
                            <thead>
                                <tr>
                                    <th>Emission source</th>
                                    <th className="u-textRight">t CO2e/yr</th>
                                    <th className="u-textCenter">Percentage of emissions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(() => {
                                    const methane = aggregatedData.results.ghgMethaneKg / 1000 || 0;
                                    const nitrousOxide = aggregatedData.results.ghgNitrousOxideKg / 1000 || 0;
                                    const carbonDioxide = aggregatedData.results.ghgCarbonDioxideKg / 1000 || 0;
                                    const total = methane + nitrousOxide + carbonDioxide || 1; // Avoid division by zero
                                    const methanePercentage = utils.round((methane / total) * 100, 1);
                                    const nitrousOxidePercentage = utils.round((nitrousOxide / total) * 100, 1);
                                    const carbonDioxidePercentage = utils.round((carbonDioxide / total) * 100, 1);
                                    const methaneColour = { name: utils.getColour(4), hex: utils.colourHexIndex[4] };
                                    const n2oColour = { name: utils.getColour(0), hex: utils.colourHexIndex[0] };
                                    const co2Colour = { name: utils.getColour(9), hex: utils.colourHexIndex[9] };

                                    return (
                                        <>
                                            <tr className={`Table-tr--${methaneColour.name}`}>
                                                <td>Methane</td>
                                                <td className="u-textRight" id="totalMethaneGhg">
                                                    {utils.numeric.format(methane)}
                                                </td>
                                                <td className="u-textRight">
                                                    <ProgressBar colour={methaneColour.hex} endLabel={`${methanePercentage}%`} progress={methanePercentage} showZero={true} />
                                                </td>
                                            </tr>
                                            <tr className={`Table-tr--${n2oColour.name}`}>
                                                <td>Nitrous oxide</td>
                                                <td className="u-textRight" id="totalN2OGhg">
                                                    {utils.numeric.format(nitrousOxide)}
                                                </td>
                                                <td className="u-textRight">
                                                    <ProgressBar colour={n2oColour.hex} endLabel={`${nitrousOxidePercentage}%`} progress={nitrousOxidePercentage} showZero={true} />
                                                </td>
                                            </tr>
                                            <tr className={`Table-tr--${co2Colour.name}`}>
                                                <td>Carbon dioxide</td>
                                                <td className="u-textRight" id="totalCO2Ghg">
                                                    {utils.numeric.format(carbonDioxide)}
                                                </td>
                                                <td className="u-textRight">
                                                    <ProgressBar colour={co2Colour.hex} endLabel={`${carbonDioxidePercentage}%`} progress={carbonDioxidePercentage} showZero={true} />
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })()}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td className="u-textRight" id="totalGhg">
                                        {utils.numeric.format(aggregatedData.results.ghgMethaneKg / 1000 + aggregatedData.results.ghgNitrousOxideKg / 1000 + aggregatedData.results.ghgCarbonDioxideKg / 1000)}
                                    </td>
                                    <td className="u-textRight">100%</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </GridCell>
                <GridCell className="u-lg-width1of2 u-md-width1of2 u-print-none">
                    <div className="u-pt-xl u-pl-xl u-pb-lg">
                        <div className="u-flex u-flexJustifyCenter">
                            <div style={{ width: "540px" }}>
                                <Bar data={data} options={options} />
                            </div>
                        </div>
                    </div>
                </GridCell>
            </Grid>
        </div>
    );
}
