import { useRefData } from "common/hooks";
import * as utils from "common/utils";
import { Grid, GridCell } from "components/Grid";
import ProgressBar from "components/ProgressBar";
import Alert from "components/Alert";
import { Bar } from "react-chartjs-2";

const Livestock = ({ aggregatedData }) => {
    const refData = useRefData();

    if (!aggregatedData || !aggregatedData.results || aggregatedData.totalArea === 0) return null;

    const { enterprises = [] } = aggregatedData;

    if (enterprises.length === 0) return null;

    const data = {
        labels: enterprises.map((e) => utils.valueToText(refData.enterpriseTypes, e.type)),
        datasets: [
            {
                label: "Crops",
                data: enterprises.map((b) => utils.round(b.rsuCrops, 1)),
                backgroundColor: utils.graphColours[14],
                hoverBackgroundColor: utils.graphColours[14],
            },
            {
                label: "Pasture",
                data: enterprises.map((b) => utils.round(b.rsuPasture, 1)),
                backgroundColor: utils.graphColours[1],
                hoverBackgroundColor: utils.graphColours[1],
            },
            {
                label: "Supplements",
                data: enterprises.map((b) => utils.round(b.rsuSupplements, 1)),
                backgroundColor: utils.graphColours[3],
                hoverBackgroundColor: utils.graphColours[3],
            },
        ],
    };

    const options = {
        maintainAspectRatio: true,
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                title: { display: true, text: "RSU" },
            },
        },
    };

    const rsuTotal = enterprises.reduce((total, enterprise) => (total += enterprise.rsuCrops + enterprise.rsuPasture + enterprise.rsuSupplements), 0);

    return (
        <div className="HeroPanel u-mt-md u-page-break u-print-border">
            <div className="HeroPanel-title">Livestock</div>
            <Alert type="info" html="Shows the <b>animal enterprises</b> on farms in the group. RSU (revised stock unit) is a measure of the energy requirements of the animals and is equivalent to 6000 MJ ME per year. The table and graph compares the amount of energy those animals got from eating pasture, crops and supplements." />
            <Grid>
                <GridCell className="u-lg-width2of3 u-md-width1of1">
                    <div className="Table u-mt-md">
                        <table>
                            <thead>
                                <tr>
                                    <th colSpan={2}></th>
                                    <th colSpan={6} className="u-pl-lg">
                                        Revised Stock Units (RSU)
                                    </th>
                                </tr>
                                <tr>
                                    <th className="th--shrink">Enterprise</th>
                                    <th className="u-textRight th--shrink">Farms</th>
                                    <th className="u-textRight th--shrink">Crops</th>
                                    <th className="u-textRight th--shrink">Pasture</th>
                                    <th className="u-textRight th--shrink">Supplements</th>
                                    <th className="u-textRight th--shrink">Total</th>
                                    <th className="u-textRight th--shrink">Per ha</th>
                                    <th className="u-textCenter">RSU percentage</th>
                                </tr>
                            </thead>
                            <tbody>
                                {enterprises.map((enterprise, i) => {
                                    const rsu = enterprise.rsuCrops + enterprise.rsuPasture + enterprise.rsuSupplements;
                                    const rusPerHa = rsu / aggregatedData.totalArea;
                                    const progress = utils.round((rsu / rsuTotal) * 100, 1);
                                    const { farmCount = 0 } = enterprise;
                                    return (
                                        <tr key={i}>
                                            <td>{utils.valueToText(refData.enterpriseTypes, enterprise.type)}</td>
                                            <td className="u-textRight">{farmCount.toLocaleString()}</td>
                                            <td className="u-textRight">{(enterprise.rsuCrops && utils.round((100 * enterprise.rsuCrops) / rsu, 1).toLocaleString() + "%") || "0%"}</td>
                                            <td className="u-textRight">{(enterprise.rsuPasture && utils.round((100 * enterprise.rsuPasture) / rsu, 1).toLocaleString() + "%") || "0%"}</td>
                                            <td className="u-textRight">{(enterprise.rsuSupplements && utils.round((100 * enterprise.rsuSupplements) / rsu, 1).toLocaleString() + "%") || "0%"}</td>
                                            <td className="u-textRight">
                                                <b>{utils.round(rsu, 0).toLocaleString()}</b>
                                            </td>
                                            <td className="u-textRight">
                                                <b>{utils.round(rusPerHa, 2).toLocaleString()}</b>
                                            </td>
                                            <td>
                                                <ProgressBar colour={utils.graphColours[1]} endLabel={`${progress}%`} progress={progress} showZero={true} />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </GridCell>
                <GridCell className="u-lg-width1of3 u-md-width1of1">
                    <div className="u-pt-xl">
                        <Bar data={data} options={options} />
                    </div>
                </GridCell>
            </Grid>
        </div>
    );
};

export default Livestock;
