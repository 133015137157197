import { useRefData } from "common/hooks";
import * as utils from "common/utils";
import { Grid, GridCell } from "components/Grid";
import { Doughnut } from "react-chartjs-2";

export default function Irrigation({ aggregatedData }) {
    const refData = useRefData();

    if (!aggregatedData || !aggregatedData.irrigation || aggregatedData.irrigation.length === 0 || aggregatedData.irrigatedArea === 0) return null;

    const { irrigation = [] } = aggregatedData;

    const totalArea = irrigation.reduce((s, i) => (s += i.area), 0);

    const systems = irrigation
        .sort((a, b) => (a.system < b.system ? 1 : -1))
        .reduce((map, i) => {
            if (!map[i.system]) {
                map[i.system] = 0;
            }
            map[i.system] += i.area;
            return map;
        }, {});

    const management = irrigation
        .sort((a, b) => (a.management < b.management ? 1 : -1))
        .reduce((map, i) => {
            if (!map[i.management]) {
                map[i.management] = 0;
            }
            map[i.management] += i.area;
            return map;
        }, {});

    const systemColours = refData.irrigatorTypes.map((t, i) => {
        return { type: t.value, colour: utils.seqColourPalette[i] };
    });
    const managementColours = refData.irrigationSoilMoistureUsage.map((t, i) => {
        return { type: t.value, colour: utils.seqColourPalette[i + 1] };
    });
    managementColours.push({ type: "Undefined", colour: utils.seqColourPalette[0] });

    const systemData = {
        labels: Object.keys(systems).map((k) => utils.valueToText(refData.irrigatorTypes, k)),
        datasets: [
            {
                data: Object.keys(systems).map((k) => systems[k]),
                backgroundColor: Object.keys(systems).map((k) => systemColours.find((c) => c.type === k).colour),
                hoverBackgroundColor: Object.keys(systems).map((k) => systemColours.find((c) => c.type === k).colour),
            },
        ],
    };

    const managementData = {
        labels: Object.keys(management).map((k) => (k === "Undefined" ? "Fixed" : utils.valueToText(refData.irrigationSoilMoistureUsage, k))),
        datasets: [
            {
                data: Object.keys(management).map((k) => management[k]),
                backgroundColor: Object.keys(management).map((k) => managementColours.find((c) => c.type === k).colour),
                hoverBackgroundColor: Object.keys(management).map((k) => managementColours.find((c) => c.type === k).colour),
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        responsive: false,
        plugins: {
            legend: {
                display: true,
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        const { dataset } = tooltipItem;
                        return `${tooltipItem.label}: ${utils.round(dataset.data[tooltipItem.dataIndex], 0).toLocaleString()} ha (${utils.round((100 * dataset.data[tooltipItem.dataIndex]) / totalArea, 0).toLocaleString()}%)`;
                    },
                },
            },
        },
    };

    return (
        <div className="HeroPanel u-mt-md u-page-break u-print-border">
            <div className="HeroPanel-title">Irrigation systems and management methods</div>
            <Grid className="u-page_break">
                <GridCell className="u-xxl-width1of2 u-xl-width1of1">
                    <div className="u-flex u-flexJustifyCenter">
                        <Doughnut data={systemData} options={options} height={400} width={400} />
                    </div>
                </GridCell>
                <GridCell className="u-xxl-width1of2 u-xl-width1of1 u-page_break">
                    <div className="u-flex u-flexJustifyCenter">
                        <Doughnut data={managementData} options={options} height={400} width={400} />
                    </div>
                </GridCell>
            </Grid>
        </div>
    );
}
